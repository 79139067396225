import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import Loading from "../../loading";
import Error from "../../error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TrackAdminButtons from "./track-admin-buttons";
import List from "../courses/list";
import { GetTrackQuery } from "../../../queries/get_track";
import InfiniteScroll from "react-infinite-scroller";
import { getTrack_getTrack } from "../../../queries/codegen/getTrack";

function Track(props) {
  return (
    <GetTrackQuery variables={{ id: props.id }}>
      {({ loading, error, data, fetchMore }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        document.title = `Thinkzoom - Tracks: ${data.getTrack.name}`;
        return (
          <TrackBody
            {...props}
            track={data.getTrack}
            onLoadMore={() => {
              void fetchMore({
                variables: { offset: data.getTrack.courses.length }
              });
            }}
          />
        );
      }}
    </GetTrackQuery>
  );
}

class TrackBody extends PureComponent<{
  url: string;
  track: getTrack_getTrack;
  onLoadMore: () => void;
}> {
  constructor(props) {
    super(props);
  }

  render() {
    const url = this.props.url;
    const data = this.props.track;

    return (
      <InfiniteScroll
        loadMore={this.props.onLoadMore}
        hasMore={this.props.track.moreRecords}
        loader={<Loading key={0} />}
        threshold={500}
        useWindow={false}
      >
        <TrackAdminButtons track={data} url={url} />
        <h1 className="clearfix">
          <FontAwesomeIcon icon={["fal", "graduation-cap"]} /> {data.name}{" "}
          {!data.published && (
            <span className="badge badge-warning">unpublished</span>
          )}
        </h1>
        <p>{data.description}</p>
        <List data={this.props.track.courses} url={url} />
        {!this.props.track.moreRecords && (
          <div className="alert alert-info">
            There are no more courses for this track.
          </div>
        )}
      </InfiniteScroll>
    );
  }
}

export default Track;
