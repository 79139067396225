import { gql } from "@apollo/client";

const CREATE_OR_UPDATE_CLASSROOM_SESSION = gql`
  mutation createOrUpdateClassroomSession(
    $classroomSession: ClassroomSessionAttributes!
  ) {
    createOrUpdateClassroomSession(
      input: { classroomSession: $classroomSession }
    ) {
      classroomSession {
        id
        classroomCourseId
      }
    }
  }
`;

export default CREATE_OR_UPDATE_CLASSROOM_SESSION;
