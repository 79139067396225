import * as React from "react";
import Loading from "../loading";
import Error from "../error";
import { Line } from "react-chartjs-2";

import { GetChartsLeaderboardQuery } from "../../queries/charts_leaderboard";
import { GetChartsTopCoursesQuery } from "../../queries/charts_top_courses";
import { GetChartsTopFavoritesQuery } from "../../queries/charts_top_favorites";
import { GetChartsTopRecommendationsQuery } from "../../queries/charts_top_recommendations";
import { GetChartsTopTopicsQuery } from "../../queries/charts_top_topics";
import { GetChartsTopUsersQuery } from "../../queries/charts_top_users";
import { GetChartsTotalViewsQuery } from "../../queries/charts_total_views";

export const ChartLeaderboardWidget = (): JSX.Element => (
  <GetChartsLeaderboardQuery>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      return <ChartBody data={data.charts_leaderboard} />;
    }}
  </GetChartsLeaderboardQuery>
);

export const ChartTopCoursesWidget = (): JSX.Element => (
  <GetChartsTopCoursesQuery>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      return <ChartBody data={data.charts_top_courses} />;
    }}
  </GetChartsTopCoursesQuery>
);

export const ChartTopFavoritesWidget = (): JSX.Element => (
  <GetChartsTopFavoritesQuery>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      return <ChartBody data={data.charts_top_favorites} />;
    }}
  </GetChartsTopFavoritesQuery>
);

export const ChartTopRecommendationsWidget = (): JSX.Element => (
  <GetChartsTopRecommendationsQuery>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      return <ChartBody data={data.charts_top_recommendations} />;
    }}
  </GetChartsTopRecommendationsQuery>
);

export const ChartTopTopicsWidget = (): JSX.Element => (
  <GetChartsTopTopicsQuery>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      return <ChartBody data={data.charts_top_topics} />;
    }}
  </GetChartsTopTopicsQuery>
);

export const ChartTopUsersWidget = (): JSX.Element => (
  <GetChartsTopUsersQuery>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      return <ChartBody data={data.charts_top_users} />;
    }}
  </GetChartsTopUsersQuery>
);

export const ChartTotalViewsWidget = ({ id }: { id?: string }): JSX.Element => (
  <GetChartsTotalViewsQuery variables={{ campusId: id }}>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      return <LineChartBody data={data.charts_total_views} />;
    }}
  </GetChartsTotalViewsQuery>
);

class ChartBody extends React.Component<
  { data: { label: string; value: number; amount: string }[] },
  { backgroundColor: string }
> {
  constructor(props) {
    super(props);
    const backgroundColors = [
      "chart__bar-container__bar--green",
      "chart__bar-container__bar--blue",
      "chart__bar-container__bar--orange"
    ];

    const backgroundColor =
      backgroundColors[Math.floor(Math.random() * backgroundColors.length)];

    this.state = {
      backgroundColor: backgroundColor
    };
  }

  render() {
    const data = this.props.data;
    if (data.length <= 0) {
      return (
        <div className="alert alert-info">
          There is no data to display for this date range.
        </div>
      );
    }

    return (
      <React.Fragment>
        {data.map((item, index) => {
          return (
            <div className="chart__item" key={index}>
              {item.label}
              <div className="chart__bar-container">
                <div
                  className={
                    "chart__bar-container__bar " + this.state.backgroundColor
                  }
                  style={{
                    width: `${(item.value / data[0].value) * 0.9 * 100 + 10}%`
                  }}
                >
                  {item.amount}
                  <svg viewBox="0 0 20 60">
                    <polygon points="0,0 20,30 0,60 20,60 20,0" />
                  </svg>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

class LineChartBody extends React.Component<
  {
    data: {
      viewDate: string;
      startCount: number;
      completedCount: number;
      examCount: number;
    }[];
  },
  { backgroundColor: string }
> {
  constructor(props) {
    super(props);
    const backgroundColors = [
      "chart__bar-container__bar--green",
      "chart__bar-container__bar--blue",
      "chart__bar-container__bar--orange"
    ];

    const backgroundColor =
      backgroundColors[Math.floor(Math.random() * backgroundColors.length)];

    this.state = {
      backgroundColor: backgroundColor
    };
  }

  render() {
    const data = this.props.data;
    if (data.length <= 0) {
      return (
        <div className="alert alert-info">
          There is no data to display for this date range.
        </div>
      );
    }

    const dataSets = {
      labels: data.map((row) => {
        return row.viewDate;
      }),
      datasets: [
        {
          label: "Start Count",
          data: data.map((row) => {
            return row.startCount;
          }),
          borderColor: "#248DC1",
          backgroundColor: "rgba(0, 0, 0, 0.015)",
          borderWidth: 1,
          pointRadius: 3,
          pointHoverRadius: 5,
          // lineTension: 1,
          pointBorderWidth: 1
        },
        {
          label: "Completed Count",
          data: data.map((row) => {
            return row.completedCount;
          }),
          borderColor: "#8FC32E",
          backgroundColor: "rgba(0, 0, 0, 0.015)",
          borderWidth: 1,
          pointRadius: 3,
          pointHoverRadius: 5,
          // lineTension: 1,
          pointBorderWidth: 1
        },
        {
          label: "Exam Count",
          data: data.map((row) => {
            return row.examCount;
          }),
          borderColor: "#F37C35",
          backgroundColor: "rgba(0, 0, 0, 0.015)",
          borderWidth: 1,
          pointRadius: 3,
          pointHoverRadius: 5,
          // lineTension: 1,
          pointBorderWidth: 1
        }
      ]
    };
    return (
      <Line
        data={dataSets}
        width={100}
        height={75}
        options={{
          backgroundColor: "rgba(255, 255, 255, 1)",
          pointStyle: "circle",
          legend: {
            position: "bottom",
            labels: {
              usePointStyle: true,
              boxWidth: 20
            }
          },
          tooltips: {
            mode: "point"
          }
        }}
      />
    );
  }
}
