import * as React from "react";
import Loading from "../../loading";
import Error from "../../error";
import TrackListItem from "./track_list_item";
import { GetTracksQuery, GET_TRACKS } from "../../../queries/get_tracks";
import CREATE_TRACK from "../../../queries/create_track";
import InfiniteScroll from "react-infinite-scroller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Fancy from "../../forms/fancy";
import { Input, Textarea } from "../../forms/input";
import { Button } from "../../buttons";
import { CustomInput } from "reactstrap";
import { getTracks_getTracks } from "../../../queries/codegen/getTracks";

interface Props {
  type: string;
}

const TrackList = ({ type }: Props): JSX.Element => (
  <GetTracksQuery>
    {({ loading, error, data, fetchMore }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      return (
        <TrackListBody
          getTracks={data.getTracks}
          type={type}
          onLoadMore={() => {
            void fetchMore({
              variables: { offset: data.getTracks.tracks.length }
            });
          }}
        />
      );
    }}
  </GetTracksQuery>
);

export default TrackList;

class TrackListBody extends React.PureComponent<{
  onLoadMore: () => void;
  getTracks: getTracks_getTracks;
  type: string;
}> {
  render() {
    return (
      <div className="email-list-wrapper scrollable" style={{ overflowX: "hidden" }}>
        {this.props.getTracks.currentUser.canManageTracks && (
          <div className="list-view-group-container">
            <div className="email-list-tools" style={{ textAlign: "initial" }}>
              <NewTrack canManageReseller={this.props.getTracks.currentUser.canManageReseller} />
            </div>
          </div>
        )}
        <InfiniteScroll
          loadMore={this.props.onLoadMore}
          hasMore={this.props.getTracks.moreRecords}
          loader={<Loading key={0} />}
          useWindow={false}
        >
          <div className="list-view-group-container">
            <ul className="email-list-item">
              {this.props.getTracks.tracks.map((track, index) => {
                return <TrackListItem type={this.props.type} key={index} item={track} />;
              })}
            </ul>
            {!this.props.getTracks.moreRecords && (
              <p className="text-center pt-3">You have reached the end of the list.</p>
            )}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}

class NewTrack extends React.PureComponent<
  { canManageReseller: boolean },
  { modalIsOpen: boolean; name: String }
> {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      name: ""
    };
  }

  toggleModal = () => {
    this.setState((state) => {
      return { modalIsOpen: !state.modalIsOpen, name: "" };
    });
  };

  onNameChange = (e, onChange) => {
    this.setState({ ...this.state, name: e.target.value });
    onChange(e);
  };

  render() {
    return (
      <React.Fragment>
        <button type="button" className="btn btn-link" onClick={this.toggleModal}>
          <FontAwesomeIcon fixedWidth icon={["fal", "plus"]} /> Create a Track
        </button>
        <Modal className="modal-dialog modal-lg" isOpen={this.state.modalIsOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Create a Track</ModalHeader>
          <ModalBody>
            <div className="padding-15">
              <Fancy
                mutation={CREATE_TRACK}
                onComplete={{
                  message: "Your track has been created.",
                  callback: (e) => {
                    if (e.createTrack !== null) {
                      this.toggleModal();
                    }
                  }
                }}
                update={(cache, data) => {
                  window.location.href = `/catalog/tracks/${data.createTrack.track.id}`;
                }}
                refetchQueries={[{ query: GET_TRACKS }]}
                variables={{
                  track: {
                    name: null,
                    description: null,
                    availableToDescendants: false,
                    published: false
                  }
                }}
                render={({ errors, onChange, state }) => {
                  return (
                    <React.Fragment>
                      <Input
                        onChange={(e) => this.onNameChange(e, onChange)}
                        field="name"
                        errors={errors}
                        value={this.state.name}
                        autoComplete="off"
                      >
                        Name
                      </Input>
                      <Textarea onChange={onChange} field="description" errors={errors}>
                        Description
                      </Textarea>
                      {this.props.canManageReseller && (
                        <CustomInput
                          type="checkbox"
                          name="availableToDescendants"
                          label="Available to descendants"
                          id="availableToDescendants"
                          value={state.track.published}
                          onChange={(event) => {
                            const target = {
                              name: "availableToDescendants",
                              type: "text",
                              value: event.target.checked
                            };
                            onChange({ target });
                          }}
                        />
                      )}
                      <CustomInput
                        type="checkbox"
                        name="publish"
                        label="Published"
                        id="publish"
                        value={state.track.published}
                        onChange={(event) => {
                          const target = {
                            name: "published",
                            type: "text",
                            value: event.target.checked
                          };
                          onChange({ target });
                        }}
                      />

                      <Button primary disabled={this.state.name.length <= 0}>
                        Create Track
                      </Button>
                    </React.Fragment>
                  );
                }}
              />
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
