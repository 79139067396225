import { gql } from "@apollo/client";

const CREATE_GROUP = gql`
  mutation createGroup($group: GroupAttributes!) {
    createGroup(input: { group: $group }) {
      group {
        id
        name
        description
        active
      }
    }
  }
`;

export default CREATE_GROUP;
