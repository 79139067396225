import * as React from "react";

import Fancy from "../forms/fancy";
import { Button } from "../buttons";
import { Filestack } from "../filestack";
import { GetAllTopicsQuery } from "../../queries/get_all_topics";
import CreatableSelect from "react-select/lib/Creatable";
import * as _ from "lodash";
import Error from "../error";

export const ClassroomCourseEditForm = (props) => (
  <QueryTopics Component={ClassroomCourse} {...props} />
);

const toSelectOption = ({ name }: { name: string }) => ({
  value: name,
  label: name
});

const QueryTopics = ({ Component, ...props }) => (
  <GetAllTopicsQuery variables={{ limit: 1000 }}>
    {({ loading, error, data }) => {
      if (error) return <Error />;
      if (loading) return <Component topicOptions={[]} {...props} />;
      const topics = data.getAllTopics.topics.map(toSelectOption);
      return <Component topicOptions={topics} {...props} />;
    }}
  </GetAllTopicsQuery>
);

type Props = {
  topicOptions: Token[];
  topics: Token[];
  mutation: string;
  classroomCourse: ClassroomCourseType;
  refetchQueries: Query[];
  update: (cache: any, data: any) => void;
};
type State = { classroomCourse: ClassroomCourseType; topics: Token[] };
type Token = { label: string; value: string };
type ClassroomCourseType = {
  name: string;
  description: string;
  topicNames: string[];
  thumbnail: string;
};
type Query = {
  query: string;
  variables: {
    id: string;
  };
};

class ClassroomCourse extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      topics: this.props.topics,
      classroomCourse: this.props.classroomCourse
    };
  }

  updateOptions = (options: Pick<State, "topics">, callback) =>
    this.setState(options, callback);

  render() {
    return (
      <Fancy
        mutation={this.props.mutation}
        variables={{
          classroomCourse: this.state.classroomCourse
        }}
        onComplete={{
          message: "Your course has been saved.",
          callback: () => {
            return null;
          }
        }}
        update={this.props.update}
        refetchQueries={this.props.refetchQueries}
        render={({ errors, onChange, state: { classroomCourse: course } }) => (
          <React.Fragment>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="classroom-course-name">Name</label>
                  <input
                    onChange={onChange}
                    type="text"
                    id="classroom-course-name"
                    name="name"
                    defaultValue={course.name}
                    className={"form-control"}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="classroom-course-description">
                    Description
                  </label>
                  <textarea
                    name="description"
                    id="classroom-course-description"
                    onChange={onChange}
                    className="form-control"
                    defaultValue={course.description}
                  />
                </div>
              </div>
            </div>
            <CreatableSelectWithLabel
              value={this.state.topics}
              options={this.props.topicOptions.filter(
                (topic) => !_.includes(this.state.topics, topic)
              )}
              isMulti
              onChange={(selected: Token[]) => {
                const target = {
                  name: "topicNames",
                  type: "list",
                  value: selected.map(({ value }) => value)
                };
                this.updateOptions({ topics: selected }, () =>
                  onChange({ target })
                );
              }}
              onCreate={(label) => {
                const option = {
                  label,
                  value: label
                };
                const target = {
                  name: "topicNames",
                  type: "list",
                  value: [...this.state.topics, option].map(
                    ({ value }) => value
                  )
                };
                this.updateOptions(
                  { topics: [...this.state.topics, option] },
                  () => onChange({ target })
                );
              }}
            >
              Topics
            </CreatableSelectWithLabel>
            <div className="mb-3 m-4 p-3 border">
              {course.thumbnail && (
                <img
                  className="mrg-top-30"
                  src={course.thumbnail}
                  alt={course.name}
                  height="200"
                />
              )}
              <div className="alert alert-info">
                You may upload a thumbnail for the course.
              </div>
              <Filestack
                options={{ accept: ["image/*"] }}
                onSuccess={(result) => {
                  const file = result.filesUploaded[0];
                  const target = {
                    name: "thumbnail",
                    type: "string",
                    value: file.url
                  };
                  onChange({ target });
                }}
                render={(onPick) => (
                  <React.Fragment>
                    <a
                      href="javascript:void(0);"
                      className="btn btn-sm btn-rounded btn-info"
                      onClick={onPick}
                    >
                      <i className="fal fa-cloud-upload" /> Upload
                    </a>
                    {course.thumbnail && (
                      <button
                        className="btn btn-sm btn-rounded btn-danger"
                        onClick={() => {
                          const target = {
                            name: "thumbnail",
                            type: "string",
                            value: null
                          };
                          onChange({ target });
                        }}
                      >
                      <i className="fal fa-trash" />  Remove
                      </button>
                    )}
                  </React.Fragment>
                )}
              />
            </div>
            <button className="btn btn-primary btn-rounded">Save Course</button>
          </React.Fragment>
        )}
      />
    );
  }
}

const CreatableSelectWithLabel = ({ children, ...props }) => (
  <div className="row">
    <div className="col-12">
      <div className="form-group">
        <label>{children}</label>
        <CreatableSelect {...props} />
      </div>
    </div>
  </div>
);
