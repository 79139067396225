import * as React from "react";
import Fancy from "../forms/fancy";
import UPDATE_CLASSROOM_LOCATION from "../../queries/update_classroom_location";
import { Fields } from "./fields";
export const UpdateLocationForm = ({ location, updateLocation, cancel }) => (
  <Fancy
    mutation={UPDATE_CLASSROOM_LOCATION}
    variables={{
      classroomLocation: location
    }}
    onComplete={{
      message: "Your location has been created",
      callback: () => {}
    }}
    update={(cache, { updateClassroomLocation }) => {
      updateLocation(updateClassroomLocation.classroomLocation);
    }}
    render={({ errors, onChange }) => (
      <Fields
        errors={errors}
        onChange={onChange}
        location={location}
        submitLabel="Update Location"
        cancel={cancel}
      />
    )}
  />
);
