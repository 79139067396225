import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getTopTopicsChart } from "./codegen/getTopTopicsChart";

export const CHARTS_TOP_TOPICS = gql`
  query getTopTopicsChart {
    charts_top_topics {
      id
      label
      value
      amount
    }
  }
`;

export const GetChartsTopTopicsQuery = (
  props: Omit<QueryComponentOptions<getTopTopicsChart>, "query">
): JSX.Element => (
  <Query<getTopTopicsChart> query={CHARTS_TOP_TOPICS} {...props} />
);
