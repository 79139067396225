import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortableHandle } from "react-sortable-hoc";
import * as React from "react";

interface Props {
  widget_id: string;
}

export const DragHandle = SortableHandle((props: Props) => {
  return (
    <span
      className="btn btn-icon btn-flat btn-rounded widget-handle"
      id={`widget-drag-${props.widget_id}`}
    >
      <FontAwesomeIcon icon={["fal", "arrows"]} />
    </span>
  );
});
