import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import REMOVE_ASSIGNMENT from "../../queries/remove_assignment";
import CREATE_ASSIGNMENT from "../../queries/create_assignment";
import {
  GET_ASSIGNMENTS,
  GetAssignmentsQuery
} from "../../queries/get_assignments";
import { Search } from "../catalog/manage_members";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Requirements from "../catalog/groups/requirements";
import Loading from "../loading";
import Error from "../error";
import InfiniteScroll from "react-infinite-scroller";
import * as $ from "jquery";
import {
  getAssignments,
  getAssignments_getAssignments
} from "../../queries/codegen/getAssignments";

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  ApolloProvider
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Mutation } from "@apollo/client/react/components";
import { cache } from "../../cache";
import { FunctionComponent } from "react";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement() {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: (_string: string) => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

export type Requirement = boolean | string;
export type Error = { message: string };

interface Props {
  courseId: string;
}

const Assignments: FunctionComponent<Props> = ({
  courseId
}: Props): JSX.Element => (
  <ApolloProvider client={client}>
    <GetAssignmentsQuery variables={{ courseId: courseId, limit: 15 }}>
      {({ loading, error, data, fetchMore }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        return (
          <AssignmentBody
            data={data.getAssignments}
            courseId={courseId}
            onLoadMore={async () => {
              await fetchMore({
                query: GET_ASSIGNMENTS,
                variables: {
                  courseId: courseId,
                  offset: data.getAssignments.users.length,
                  limit: 15
                }
              });
            }}
          />
        );
      }}
    </GetAssignmentsQuery>
  </ApolloProvider>
);

export default Assignments;

class AssignmentBody extends React.Component<
  {
    data: getAssignments_getAssignments;
    onLoadMore: () => void;
    courseId: string;
  },
  {
    currentUserId: number | null;
    modalIsOpen: boolean;
    requirement: Requirement;
    error: Error | null;
  }
> {
  constructor(props) {
    super(props);

    this.state = {
      currentUserId: null,
      modalIsOpen: false,
      requirement: false,
      error: null
    };
  }

  componentDidMount() {
    this.updateAssignmentCount();
  }

  componentDidUpdate() {
    this.updateAssignmentCount();
  }

  updateAssignmentCount = () => {
    $(".js-assignment-count").text(this.props.data.totalCount);
  };

  toggleModal = () => {
    this.setState((state) => {
      return {
        modalIsOpen: !state.modalIsOpen,
        requirement: false,
        error: null
      };
    });
  };

  selectItemFromSearch = (item) => {
    this.setState({ currentUserId: item.id }, this.toggleModal);
  };

  createAssignment = () => {
    const result = client.mutate({
      mutation: CREATE_ASSIGNMENT,
      variables: {
        assignment: Object.assign(
          { courseId: this.props.courseId, userId: this.state.currentUserId },
          this.translateRequirement()
        )
      },
      refetchQueries: [
        {
          query: GET_ASSIGNMENTS,
          variables: { courseId: this.props.courseId, limit: 15 }
        }
      ]
    });
    result.then(({ data }) => {
      this.toggleModal();
    });
    result.catch(({ graphQLErrors }) => {
      this.setState({ error: graphQLErrors[0] });
    });
  };

  translateRequirement = () => {
    let required = false;
    let requiredDate = null;
    const { requirement } = this.state;

    if (typeof requirement === "boolean") {
      required = requirement;
    } else if (typeof requirement === "string") {
      required = true;
      requiredDate = requirement;
    }

    return {
      required: required,
      requiredDate: requiredDate
    };
  };

  updateRequirement = (requirement) => {
    this.setState(requirement);
  };

  render() {
    return (
      <React.Fragment>
        <div className="padding-15" style={{ height: 700, overflow: "auto" }}>
          <Search
            onItemClick={(item) => this.selectItemFromSearch(item)}
            client={client}
            type={["user"]}
            existingIds={[]}
          />
          <InfiniteScroll
            loadMore={this.props.onLoadMore}
            hasMore={this.props.data.moreRecords}
            loader={<Loading key={0} />}
            useWindow={false}
          >
            {this.props.data.users.map((user, index) => {
              return (
                <div key={index} className="media">
                  <img className="mr-3" src={user.avatar} alt={user.fullName} />
                  <div className="media-body">
                    <h4 className="my-0">
                      <a href={`/users/${user.id}`}>{user.fullName}</a>
                    </h4>
                    {user.fullName !== user.username && (
                      <small className="text-muted">
                        {user.username}
                        &nbsp;
                      </small>
                    )}
                  </div>
                  <div style={{ flex: 1, alignSelf: "center" }}>
                    <span
                      style={{ fontSize: "medium" }}
                      className={`badge badge-${user.status.badge}`}
                    >
                      {user.status.message}
                    </span>
                  </div>
                  <div>
                    <Mutation
                      mutation={REMOVE_ASSIGNMENT}
                      refetchQueries={[
                        {
                          query: GET_ASSIGNMENTS,
                          variables: {
                            courseId: this.props.courseId,
                            limit: 15
                          }
                        }
                      ]}
                    >
                      {(removeAssignment) => (
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            removeAssignment({
                              variables: {
                                courseId: this.props.courseId,
                                userId: user.id
                              }
                            });
                          }}
                        >
                          <FontAwesomeIcon fixedWidth icon={["fal", "trash"]} />
                        </button>
                      )}
                    </Mutation>
                  </div>
                </div>
              );
            })}
            {!this.props.data.moreRecords && (
              <div className="alert alert-info">
                There are no more user assignments for this course.
              </div>
            )}
          </InfiniteScroll>
        </div>
        <Modal
          className="modal-dialog modal-lg"
          isOpen={this.state.modalIsOpen}
          toggle={this.toggleModal}
        >
          <ModalHeader toggle={this.toggleModal}>
            Create User Requirement
          </ModalHeader>
          <ModalBody>
            <div className="padding-15">
              {this.state.error && (
                <div className="alert alert-danger">
                  {this.state.error.message}
                </div>
              )}
              <Requirements
                updateRequirement={this.updateRequirement}
                requirement={this.state.requirement}
                hideInterval={true}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-primary float-right"
              onClick={this.createAssignment}
            >
              Create Requirement
            </button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
