import * as React from "react";
import * as moment from "moment";
import { humanizeTimestamp } from "../../date_utilities";

//TODO: Make sure we can handle "ending" parts of activity notifications
const ActivityNotification = ({ activity }) => {
  const { owner, trackable, message, createdAt, ending } = activity;
  return (
    <li className="activity-list-item">
      <img
        className="thumb-img img-circle"
        src={owner.thumb}
        alt={owner.name}
      />
      <div className="info">
        <span className="title">
          <span className="font-size-14 text-semibold">
            <a href={owner.link}>{owner.name}</a>
          </span>{" "}
          <span className="text-gray">
            {message}
            {trackable.score && (
              <React.Fragment>
                {trackable.score}
                {ending}{" "}
                <span className="text-semibold">
                  <a href={trackable.link}>{trackable.name}</a>
                </span>
              </React.Fragment>
            )}
            {!trackable.score && (
              <React.Fragment>
                <span className="text-semibold">
                  <a href={trackable.link}>{trackable.name}</a>
                </span>{" "}
                {ending}{" "}
              </React.Fragment>
            )}
          </span>
        </span>
        <span className="sub-title">{humanizeTimestamp(createdAt)}</span>
      </div>
    </li>
  );
};

export default ActivityNotification;
