import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressCircle from "../progress-circle";
import GroupFooter from "./group-footer";
import { toggleCatalogOpen } from "../utilities";

function GroupListItem(props) {
  return (
    <a href={`/catalog/${props.type}/${props.item.id}`}>
      <li className="catalog-item" onClick={toggleCatalogOpen}>
        {!props.currentAccount.disableProgressWheel &&
          Number.isInteger(props.item.progress) && (
            <div className="progress-small-right">
              <ProgressCircle progress={props.item.progress} />
            </div>
          )}
        <p className="catalog-item__title">{props.item.name}</p>
        <p>{props.item.description}</p>

        <div className="d-inline-block pdd-right-15 text-muted">
          <FontAwesomeIcon icon={["fal", "users"]} fixedWidth />
          <span className="pdd-left-5">{props.item.userCount}</span>
        </div>
        <div className="d-inline-block pdd-right-15 text-muted">
          <FontAwesomeIcon icon={["fal", "tv"]} fixedWidth />
          <span className="pdd-left-5">{props.item.courseCount}</span>
        </div>
        <div>
          <GroupFooter
            count={props.item.requiredCount}
            date={props.item.dueDate}
          />
        </div>
      </li>
    </a>
  );
}

export default GroupListItem;
