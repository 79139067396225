import * as React from "react";
import { WidgetList } from "./widget_list";
import WidgetModal from "./modal";
import arrayMove from "array-move";
import { GET_WIDGETS } from "../../queries/get_widgets";
import UPDATE_WIDGETS from "../../queries/update_widgets";
import { IconButton } from "../buttons";
import { UncontrolledTooltip } from "reactstrap";
import { ApolloClient } from "@apollo/client";
import { Widget } from "./widget";

interface Props {
  widgets: Widget[];
  client: ApolloClient<any>;
  userId: string;
}

interface State {
  modalIsOpen: boolean;
}

export class Widgets extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false
    };
  }

  toggleModal = () => {
    this.setState((prevState) => {
      return {
        modalIsOpen: !prevState.modalIsOpen
      };
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;

    const widgets = arrayMove(this.props.widgets, oldIndex, newIndex);
    const widgetIds = widgets.map((widget) => {
      return widget.id;
    });

    this.props.client.mutate({
      mutation: UPDATE_WIDGETS,
      update: (cache, { data: { updateWidgets } }) => {
        cache.writeQuery({
          query: GET_WIDGETS,
          variables: { userId: this.props.userId },
          data: {
            widgets: updateWidgets.widgets
          }
        });
      },
      variables: { widgetIds: widgetIds, userId: this.props.userId },
      optimisticResponse: {
        __typename: "Mutation",
        updateWidgets: {
          __typename: "Widget",
          widgets: widgets
        }
      }
    });
  };

  render() {
    let widgets = null;
    if (this.props.widgets.length > 0) {
      widgets = (
        <WidgetList
          axis="xy"
          widgets={this.props.widgets}
          onSortEnd={this.onSortEnd}
          useDragHandle={true}
          userId={this.props.userId}
        />
      );
    } else {
      widgets = (
        <div className="alert alert-info">
          You don't have any widgets. Click the add widget button to create some
          and customize your dashboard.
        </div>
      );
    }

    return (
      <React.Fragment>
        <IconButton
          icon={["fal", "cog"]}
          style={{ position: "absolute", top: "95px", right: "10px" }}
          onClick={this.toggleModal}
          small
          pill
          id="add-widget"
        />
        <UncontrolledTooltip
          placement="bottom"
          target="#add-widget"
          delay={{ show: 0, hide: 0 }}
        >
          Add a Widget
        </UncontrolledTooltip>
        {this.state.modalIsOpen && (
          <WidgetModal
            client={this.props.client}
            modalIsOpen={this.state.modalIsOpen}
            toggleModal={this.toggleModal}
            userId={this.props.userId}
          />
        )}
        {widgets}
      </React.Fragment>
    );
  }
}
