import { gql, QueryResult, useQuery } from "@apollo/client";
import { ISO8601Date } from "../iso8601date";
import {
  GetAgendaItems,
  GetAgendaItemsVariables,
  GetAgendaItems_agendaItems,
  GetAgendaItems_agendaItems_ClassroomSessionAgendaItem,
  GetAgendaItems_agendaItems_RequiredCourseAgendaItem
} from "./codegen/GetAgendaItems";

export type AgendaItem = GetAgendaItems_agendaItems;
export type ClassroomSessionAgendaItem = GetAgendaItems_agendaItems_ClassroomSessionAgendaItem;
export type RequiredCourseAgendaItem = GetAgendaItems_agendaItems_RequiredCourseAgendaItem;

export const GET_AGENDA_ITEMS = gql`
  query GetAgendaItems($startDate: String!, $endDate: String!) {
    agendaItems(startDate: $startDate, endDate: $endDate) {
      name
      link
      id
      ... on ClassroomSessionAgendaItem {
        startTime
        endTime
        waitlisted
        location
      }
      ... on RequiredCourseAgendaItem {
        dueDate
      }
    }
  }
`;

export const useAgendaItems = (
  startDate: ISO8601Date,
  endDate: ISO8601Date
): QueryResult<GetAgendaItems, GetAgendaItemsVariables> =>
  useQuery<GetAgendaItems, GetAgendaItemsVariables>(GET_AGENDA_ITEMS, {
    variables: { startDate, endDate }
  });

export const isRequiredCourseAgendaItem = (
  agendaItem: ClassroomSessionAgendaItem | RequiredCourseAgendaItem
): agendaItem is RequiredCourseAgendaItem => {
  return agendaItem.__typename === "RequiredCourseAgendaItem";
};

export const isClassroomSessionAgendaItem = (
  agendaItem: ClassroomSessionAgendaItem | RequiredCourseAgendaItem
): agendaItem is ClassroomSessionAgendaItem => {
  return agendaItem.__typename === "ClassroomSessionAgendaItem";
};
