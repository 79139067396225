import { gql } from "@apollo/client";

const GENERATE_TOPIC_REPORT = gql`
  mutation generateTopicReport($topicId: ID!) {
    generateTopicReport(input: { topicId: $topicId }) {
      id
    }
  }
`;

export default GENERATE_TOPIC_REPORT;
