import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getLeaderboardChart } from "./codegen/getLeaderboardChart";

export const CHARTS_LEADERBOARD = gql`
  query getLeaderboardChart {
    charts_leaderboard {
      id
      label
      value
      amount
    }
  }
`;

export const GetChartsLeaderboardQuery = (
  props: Omit<QueryComponentOptions<getLeaderboardChart>, "query">
): JSX.Element => (
  <Query<getLeaderboardChart> query={CHARTS_LEADERBOARD} {...props} />
);
