import { gql } from "@apollo/client";

const UPDATE_COURSE_TRACKS = gql`
  mutation updateCourseTracks($courseIds: [ID!]!, $trackId: ID!) {
    updateCourseTracks(input: { courseIds: $courseIds, trackId: $trackId }) {
      courseIds
    }
  }
`;

export default UPDATE_COURSE_TRACKS;
