import * as React from "react";
import ReactFilestack from "filestack-react";
import * as filestack from "filestack-js";

const APIKEY = "AGPWwCQxbRzikLTTQRNn6z";
const Filestack = ({ options, onSuccess, render, ...props }) => (
  <ReactFilestack
    apikey={APIKEY}
    options={Object.assign({}, options, {
      storeTo: { location: "s3" },
      fromSources: ["local_file_system", "googledrive", "box", "dropbox"],
      customText: {
        "Select Files to Upload": "Select a File to Upload",
        "or Drag and Drop, Copy and Paste Files":
          "or Drag and Drop, Copy and Paste a File",
        "Drop your files anywhere": "Drop your file anywhere",
        "We only extract images and never modify or delete them.":
          "We only extract files and never modify or delete them."
      },
      maxFiles: 1
    })}
    onSuccess={result => onSuccess(result)}
    render={({ onPick }) => render(onPick)}
  />
);

class FilestackDropPane extends React.Component<{
  onSuccess: (result: any) => void;
  accept: string[];
  documentOnly?: boolean;
}> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const client = filestack.init(APIKEY);
    let customText = "Drag and drop videos or course documents";
    if (this.props.documentOnly) {
      customText = "Drag and drop course documents";
    }

    const makeDropPane = container => {
      const options = {
        container,
        displayMode: "dropPane",
        dropPane: {
          overlay: false,
          onSuccess: this.props.onSuccess,
          customText: customText
        },
        accept: this.props.accept
      };
      client.picker(options as any).open();
    };
    makeDropPane("contentUpload");
  }

  render() {
    return (
      <React.Fragment>
        <div id="contentUpload" style={{ height: "200px", padding: "20px" }} />
        {!this.props.documentOnly && (
          <p>
            <a
              href="#"
              className="btn btn-success"
              data-toggle="modal"
              data-target="#recorder_launchv2"
              data-backdrop="static"
            >
              <i className="fal fa-video" /> record
            </a>
          </p>
        )}
      </React.Fragment>
    );
  }
}

export { Filestack, FilestackDropPane };
