import { gql } from "@apollo/client";

const UPDATE_CLASSROOM_LOCATION = gql`
  mutation updateClassroomLocation(
    $classroomLocation: ClassroomLocationAttributes!
  ) {
    updateClassroomLocation(input: { classroomLocation: $classroomLocation }) {
      classroomLocation {
        id
        name
        equipmentAvailable
        address
        capacity
      }
    }
  }
`;

export default UPDATE_CLASSROOM_LOCATION;
