import { Token } from "./index";
import * as React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";
export const AssociationsSection = ({
  groups,
  groupOptions,
  updateOptions,
  onChange,
  courseForm,
  tracks,
  trackOptions,
  topics,
  topicOptions
}) => (
  <React.Fragment>
    <SelectWithLabel
      value={groups}
      options={groupOptions.filter(group => !groups.includes(group))}
      isMulti
      onChange={selected => {
        const target = {
          name: "groupIds",
          type: "list",
          value: selected.map(({ value }) => value)
        };
        updateOptions({ groups: selected }, () => onChange({ target }));
      }}
    >
      Groups
    </SelectWithLabel>
    <div className="checkbox checkbox-inline">
      <input
        id="restrictToGroup"
        defaultChecked={courseForm.restrictToGroup}
        name="restrictToGroup"
        type="checkbox"
        onChange={onChange}
      />
      <label htmlFor="restrictToGroup">Restrict To Group</label>
    </div>
    <SelectWithLabel
      value={tracks}
      options={trackOptions.filter(track => !tracks.includes(track))}
      isMulti
      onChange={selected => {
        const target = {
          name: "trackIds",
          type: "list",
          value: selected.map(({ value }) => value)
        };
        updateOptions({ tracks: selected }, () => onChange({ target }));
      }}
    >
      Tracks
    </SelectWithLabel>
    <CreatableSelectWithLabel
      value={topics}
      options={topicOptions.filter(topic => !topics.includes(topic))}
      isMulti
      onChange={(selected: Token[]) => {
        const target = {
          name: "topicIds",
          type: "list",
          value: selected.map(({ value }) => value)
        };
        updateOptions({ topics: selected }, () => onChange({ target }));
      }}
      onCreateOption={label => {
        const option = {
          label,
          value: label.toLowerCase().replace(/\W/g, "-")
        };
        const target = {
          name: "topicIds",
          type: "list",
          value: [...topics, option].map(({ value }) => value)
        };
        updateOptions({ topics: [...topics, option] }, () =>
          onChange({ target })
        );
      }}
    >
      Topics
    </CreatableSelectWithLabel>
  </React.Fragment>
);

const SelectWithLabel = ({ children, ...props }) => (
  <div className="row">
    <div className="col-12">
      <div className="form-group">
        <label>{children}</label>
        <Select {...props} />
      </div>
    </div>
  </div>
);

const CreatableSelectWithLabel = ({ children, ...props }) => (
  <div className="row">
    <div className="col-12">
      <div className="form-group">
        <label>{children}</label>
        <CreatableSelect {...props} />
      </div>
    </div>
  </div>
);
