import moment, { MomentInput } from "moment";
import pluralize from "pluralize";

const groupStatus = (
  count: number,
  date: MomentInput
): { color: string; content: string } => {
  if (count > 0) {
    if (date) {
      if (moment(date).isBefore(moment())) {
        return {
          color: "danger",
          content: `Past Due: ${moment(date).format("MMMM Do YYYY")}`
        };
      } else {
        return {
          color: "info",
          content: `Due: ${moment(date).format("MMMM Do YYYY")}`
        };
      }
    } else {
      return {
        color: "info",
        content: `${count} Required ${pluralize("Course", count, false)}`
      };
    }
  }
  return { color: "", content: "" };
};
export default groupStatus;
