import * as React from "react";
import { Mutation } from "@apollo/client/react/components";
import Loading from "../loading";
import Error from "../error";
import { GetUserQuery, GET_USER } from "../../queries/get_user";
import { GetAchievementsQuery } from "../../queries/get_achievements";
import { GetExamsQuery } from "../../queries/get_exams";
import { GetCertificatesQuery } from "../../queries/get_certificates";
import { GetActivityQuery } from "../../queries/get_activity";
import FOLLOW_USER from "../../queries/follow_user";
import UNFOLLOW_USER from "../../queries/unfollow_user";
import { UncontrolledTooltip } from "reactstrap";
import { Button, IconButton } from "../buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as moment from "moment";
import Exam from "../exam";

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  ApolloProvider
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { cache } from "../../cache";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement() {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: (string: string) => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

function User(props) {
  return (
    <ApolloProvider client={client}>
      <GetUserQuery variables={{ id: props.id }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <Error />;

          const user = data.getUser;
          document.title = `Thinkzoom - ${user.fullName}`;
          let combatMeter;
          if (
            user.id !== user.currentUser.id &&
            user.points > 0 &&
            user.currentUser.points > 0
          ) {
            combatMeter = (
              <div className="combat-meter">
                <div
                  className="combat-meter__user combat-meter__user--player"
                  style={{
                    width: `${
                      (user.currentUser.points /
                        (user.currentUser.points + user.points)) *
                      100
                    }%`
                  }}
                >
                  <svg viewBox="0 0 10 10" preserveAspectRatio="none">
                    <polygon points="0,0 10,0 0,10 0,0" />
                  </svg>
                </div>
                <div className="combat-meter__label combat-meter__label--player">
                  {user.currentUser.fullName} has {user.currentUser.points}{" "}
                  lifetime points
                </div>
                <div
                  className="combat-meter__user combat-meter__user--opponent"
                  style={{
                    width: `${
                      (user.points / (user.points + user.currentUser.points)) *
                      100
                    }%`
                  }}
                />
                <div className="combat-meter__label combat-meter__label--opponent">
                  {user.fullName} has {user.points} lifetime points
                </div>
              </div>
            );
          }

          return (
            <React.Fragment>
              {combatMeter}
              <div className="row">
                <div className="col-md-3">
                  <div className="widget-profile-1 card">
                    <div className="profile border bottom">
                      <img
                        className="mrg-top-30"
                        src={user.avatar}
                        alt={user.fullName}
                      />
                      <h4 className="mrg-top-20 no-mrg-btm text-semibold">
                        {user.fullName}
                      </h4>
                      <p>{user.jobTitle}</p>
                      {user.showLifetime && user.totalTime > 0 && (
                        <p>Lifetime Hours: {user.totalTime}</p>
                      )}

                      {!user.disableQuad && (
                        <p>
                          <a href="/quad/followers">
                            {user.followerCount} Followers
                          </a>{" "}
                          |{" "}
                          <a href="/quad/following">
                            Following {user.followingCount}
                          </a>
                        </p>
                      )}
                    </div>
                    <div className="pdd-horizon-30 pdd-vertical-20">
                      <h5 className="text-semibold mrg-btm-5">Bio</h5>
                      <p
                        className="list-info overflow-y-auto relative scrollable"
                        style={{ maxHeight: "250px" }}
                      >
                        {user.bio}
                      </p>
                      <div className="mrg-top-30 text-center">
                        <ul className="list-unstyled list-inline">
                          {user.currentUser.id !== user.id && (
                            <React.Fragment>
                              {!user.disableQuad && (
                                <li className="list-inline-item no-pdd-horizon">
                                  {!user.currentUser.isFollowing && (
                                    <Mutation
                                      mutation={FOLLOW_USER}
                                      refetchQueries={[
                                        {
                                          query: GET_USER,
                                          variables: { id: user.id }
                                        }
                                      ]}
                                    >
                                      {(followUser) => (
                                        <Button
                                          success
                                          onClick={() => {
                                            followUser({
                                              variables: { userId: user.id }
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={["fal", "user-plus"]}
                                          />{" "}
                                          Follow
                                        </Button>
                                      )}
                                    </Mutation>
                                  )}
                                  {user.currentUser.isFollowing && (
                                    <Mutation
                                      mutation={UNFOLLOW_USER}
                                      refetchQueries={[
                                        {
                                          query: GET_USER,
                                          variables: { id: user.id }
                                        }
                                      ]}
                                    >
                                      {(unfollowUser) => (
                                        <Button
                                          danger
                                          onClick={() => {
                                            unfollowUser({
                                              variables: { userId: user.id }
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={["fal", "user-minus"]}
                                          />{" "}
                                          Unfollow
                                        </Button>
                                      )}
                                    </Mutation>
                                  )}
                                </li>
                              )}
                              {user.email && !user.disableMessageSending && (
                                <li className="list-inline-item no-pdd-horizon">
                                  <Button
                                    info
                                    onClick={() =>
                                      (window.location.href = `/messages/new?email=${user.email}`)
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={["fal", "envelope"]}
                                    />{" "}
                                    Message
                                  </Button>
                                </li>
                              )}
                            </React.Fragment>
                          )}
                          {(user.currentUser.canManageUser ||
                            user.id == user.currentUser.id) && (
                            <li className="list-inline-item no-pdd-horizon">
                              <IconButton
                                primary
                                icon="pencil"
                                onClick={() =>
                                  (window.location.href = `/users/${user.id}/edit`)
                                }
                              >
                                Edit
                              </IconButton>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <RecentActivity
                    userId={user.id}
                    fullName={user.fullName}
                    avatar={user.avatar}
                  />
                  {user.id == user.currentUser.id && (
                    <ExamHistory
                      userId={user.id}
                      disableExamResultsForwarding={
                        user.disableExamResultsForwarding
                      }
                    />
                  )}
                  <Achievements userId={user.id} />
                </div>
              </div>
            </React.Fragment>
          );
        }}
      </GetUserQuery>
    </ApolloProvider>
  );
}

function RecentActivity(props) {
  return (
    <div className="card">
      <div className="card-heading border bottom">
        <h4 className="card-title">Recent Activity</h4>
      </div>
      <div className="card-block">
        <GetActivityQuery variables={{ userId: props.userId }}>
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) return <Error />;
            if (data.getActivityStream.length == 0) {
              return (
                <div className="alert alert-info">
                  Once you complete some activities, they will be displayed
                  here.
                </div>
              );
            }

            return (
              <div className="widget-feed">
                <ul
                  className="list-info overflow-y-auto relative scrollable ps-container ps-theme-default ps-active-y"
                  style={{ maxHeight: "340px" }}
                  data-ps-id="443ec233-8b26-9a92-7744-610927a7798c"
                >
                  {data.getActivityStream.map(
                    ({ id, course, verb, timestamp, score }) => {
                      return (
                        <li
                          key={id}
                          className="border bottom mrg-btm-10 list-info"
                        >
                          <div className="pdd-vertical-10">
                            <img
                              className="thumb-img img-circle"
                              src={props.avatar}
                              alt={props.fullName}
                            />
                            <div className="info">
                              <a href="" className="text-link">
                                <span className="title">
                                  <b className="font-size-15">
                                    {props.fullName}
                                  </b>
                                </span>
                              </a>
                              <span className="sub-title">{timestamp}</span>
                            </div>
                            <div className="mrg-top-10">
                              <p className="no-mrg-btm">
                                {props.fullName} {verb}{" "}
                                <a href={`/courses/${course.id}`}>
                                  {course.name}
                                </a>
                              </p>
                            </div>
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            );
          }}
        </GetActivityQuery>
      </div>
    </div>
  );
}

function ExamHistory(props) {
  return (
    <div className="card">
      <div className="card-heading border bottom">
        <h4 className="card-title">Exam History</h4>
      </div>
      <div className="card-block">
        <div className="tab-info">
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <a
                href="#card-tab-1"
                className="nav-link active"
                role="tab"
                data-toggle="tab"
                aria-expanded="false"
              >
                Exams
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#card-tab-2"
                className="nav-link"
                role="tab"
                data-toggle="tab"
              >
                Certificates
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              role="tabpanel"
              className="tab-pane fade active show"
              id="card-tab-1"
              aria-expanded="false"
            >
              <div className="pdd-horizon-15 pdd-vertical-20">
                <GetExamsQuery variables={{ userId: props.userId }}>
                  {({ loading, error, data }) => {
                    if (loading) return <Loading />;
                    if (error) return <Error />;

                    return (
                      <ul
                        className="list-group list-group-flush"
                        style={{ height: "300px", overflowY: "auto" }}
                      >
                        {data.getExams.map((exam, index) => {
                          return (
                            <li key={index} className="list-group-item">
                              <div className="row">
                                <div className="col-sm-1">
                                  <FontAwesomeIcon
                                    icon={["fal", "list-alt"]}
                                    size="2x"
                                  />
                                </div>
                                <div className="col-sm-11">
                                  <h4>{exam.name}</h4>
                                  <h6>{exam.value}%</h6>
                                  <small className="float-right">
                                    {moment(exam.createdAt).fromNow()}
                                  </small>
                                  <div>
                                    {exam.questions && (
                                      <React.Fragment>
                                        <Exam
                                          data={exam}
                                          review
                                          disableExamResultsForwarding={
                                            props.disableExamResultsForwarding
                                          }
                                        />
                                        <a
                                          href={`/users/${props.userId}/completed_exams/${exam.id}.pdf`}
                                          className="btn btn-secondary btn-sm"
                                        >
                                          <FontAwesomeIcon
                                            icon={["fal", "download"]}
                                          />{" "}
                                          Download
                                        </a>
                                      </React.Fragment>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    );
                  }}
                </GetExamsQuery>
              </div>
            </div>
            <div role="tabpanel" className="tab-pane fade" id="card-tab-2">
              <div className="pdd-horizon-15 pdd-vertical-20">
                <GetCertificatesQuery variables={{ userId: props.userId }}>
                  {({ loading, error, data }) => {
                    if (loading) return <Loading />;
                    if (error) return <Error />;

                    return (
                      <ul
                        className="list-group list-group-flush"
                        style={{ height: "300px", overflowY: "auto" }}
                      >
                        {data.getCertificates.map((certificate, index) => {
                          return (
                            <li key={index} className="list-group-item">
                              <div className="row">
                                <div className="col-sm-1">
                                  <FontAwesomeIcon
                                    icon={["fal", "award"]}
                                    size="2x"
                                  />
                                </div>
                                <div className="col-sm-11">
                                  <h4>{certificate.name}</h4>
                                  <h6>{certificate.score}%</h6>
                                  <small className="float-right">
                                    {moment(certificate.createdAt).fromNow()}
                                  </small>
                                  <div>
                                    <a
                                      href={certificate.url}
                                      className="btn btn-secondary btn-sm"
                                    >
                                      <FontAwesomeIcon
                                        icon={["fal", "download"]}
                                      />{" "}
                                      Download
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    );
                  }}
                </GetCertificatesQuery>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Achievements(props) {
  return (
    <div className="card">
      <div className="card-heading border bottom">
        <h4 className="card-title">Achievements</h4>
      </div>
      <GetAchievementsQuery variables={{ userId: props.userId }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <Error />;
          return (
            <div
              className="card-block"
              style={{ height: "300px", overflowY: "auto" }}
            >
              {data.getAchievements.map((badge, index) => {
                return (
                  <React.Fragment key={index}>
                    <img
                      id={`badge-${index}`}
                      src={badge.image}
                      alt={badge.name}
                      height="100"
                      width="100"
                      className="m-2"
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`badge-${index}`}
                      delay={{ show: 0, hide: 0 }}
                    >
                      {badge.description}
                    </UncontrolledTooltip>
                  </React.Fragment>
                );
              })}
            </div>
          );
        }}
      </GetAchievementsQuery>
    </div>
  );
}

export default User;
