import * as React from "react";
import Groups from "./groups";
import Tracks from "./tracks";
import Activities from "./activities";
import ExamAssignments from "./exam_assignments";
import {
  CatalogCoursesFavoriteWidget,
  CatalogCoursesSuggestedWidget,
  CatalogCoursesRequiredWidget,
  CatalogCoursesRecommendedWidget,
  CatalogCoursesFeaturedWidget,
  CatalogCoursesUnpublishedWidget
} from "./course_list";
import {
  ChartLeaderboardWidget,
  ChartTopTopicsWidget,
  ChartTopCoursesWidget,
  ChartTotalViewsWidget,
  ChartTopRecommendationsWidget,
  ChartTopFavoritesWidget,
  ChartTopUsersWidget
} from "./chart";
import { Widget } from "./widget";
import { CalendarWidget } from "./calendar_widget";
import { BOBWidget } from "./bob_widget";

export const ComponentLoader = ({
  widget
}: {
  widget: Widget;
}): JSX.Element => {
  const { url, size } = widget;

  switch (url) {
    case "/catalog/groups?enrolled=true":
      return <Groups url={url} size={size} />;
    case "/catalog/tracks?enrolled=true":
      return <Tracks url={url} size={size} />;
    case "/activities":
      return <Activities url={url} />;
    case "/exam_assignments":
      return <ExamAssignments url={url} size={size} />;
    case "/catalog/courses/suggested":
      return <CatalogCoursesSuggestedWidget url={url} size={size} />;
    case "/catalog/courses/required":
      return <CatalogCoursesRequiredWidget url={url} size={size} />;
    case "/catalog/courses/recommended":
      return <CatalogCoursesRecommendedWidget url={url} size={size} />;
    case "/catalog/courses/favorite":
      return <CatalogCoursesFavoriteWidget url={url} size={size} />;
    case "/catalog/courses/featured":
      return <CatalogCoursesFeaturedWidget url={url} size={size} />;
    case "/catalog/courses/unpublished":
      return <CatalogCoursesUnpublishedWidget url={url} size={size} />;
    case "/charts/leaderboard":
      return <ChartLeaderboardWidget />;
    case "/charts/top_topics":
      return <ChartTopTopicsWidget />;
    case "/charts/top_courses":
      return <ChartTopCoursesWidget />;
    case "/charts/total_views":
      return <ChartTotalViewsWidget />;
    case "/charts/top_recommendations":
      return <ChartTopRecommendationsWidget />;
    case "/charts/top_favorites":
      return <ChartTopFavoritesWidget />;
    case "/charts/top_users":
      return <ChartTopUsersWidget />;
    case "/calendar":
      return <CalendarWidget />;
    case "/bob_daily_course":
      return <BOBWidget />;
    default:
      throw new Error(`${url} is not a supported widget url`);
  }
};
