import { Input, Quill } from "../forms/input";
import * as React from "react";
import CreatableSelect from "react-select/lib/Creatable";
import { isEmpty } from "lodash";
export const StartSection = ({ onChange, errors, courseForm }) => {
  let keywords;
  if (isEmpty(courseForm.keywords)) {
    keywords = [];
  } else {
    keywords = courseForm.keywords.split(",").map(keyword => {
      return { value: keyword, label: keyword };
    });
  }

  return (
    <React.Fragment>
      <Input
        onChange={onChange}
        field="name"
        errors={errors}
        defaultValue={courseForm.name}
      >
        Name
      </Input>
      <Quill
        onChange={value => {
          let target = {
            name: "description",
            type: "text",
            value: value
          };
          onChange({ target });
        }}
        field="description"
        errors={errors}
        defaultValue={courseForm.description}
      >
        Description
      </Quill>
      <CreatableSelectWithLabel
        value={keywords}
        isMulti
        onChange={selected => {
          const target = {
            name: "keywords",
            type: "string",
            value: selected.map(({ value }) => value).join(",")
          };
          onChange({ target });
        }}
        onCreateOption={label => {
          let keywords;
          if (isEmpty(courseForm.keywords)) {
            keywords = [label];
          } else {
            keywords = [...courseForm.keywords.split(","), label];
          }

          const target = {
            name: "keywords",
            type: "string",
            value: keywords.join(",")
          };
          onChange({ target });
        }}
      >
        Keywords
      </CreatableSelectWithLabel>
    </React.Fragment>
  );
};

const CreatableSelectWithLabel = ({ children, ...props }) => (
  <div className="row">
    <div className="col-12">
      <div className="form-group">
        <label>{children}</label>
        <CreatableSelect {...props} />
      </div>
    </div>
  </div>
);
