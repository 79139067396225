import * as React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "./colors";

const Card = styled.div`
  position: relative;
  border-radius: 3px;
`;

const Image = styled.img`
  width: 100%;
`;

const Title = styled.h4`
  padding: 1em;
  font-size: 15px;
`;

const CardFooter = styled.div`
  line-height: 2em;
  height: 2em;
  color: #fff;
  padding-left: 1em;
`;

export interface CourseCardProps {
  image: string;
  duration: string;
  name: string;
  courseId: string;
  icon: any;
}

export class CourseCard extends React.Component<CourseCardProps> {
  constructor(props) {
    super(props);
  }

  render() {
    const { image, duration, name, courseId, icon } = this.props;

    return (
      <Card className="border">
        <a
          href={`/catalog/courses/${courseId}`}
          title={name}
          data-turbolinks="false"
        >
          <span
            className="embed-responsive embed-responsive-16by9"
            style={{ overflow: "visible" }}
          >
            <Image src={image} className="embed-responsive-item" />
            {icon && icon.class && (
              <span
                className="courselist-length px-2"
                style={{
                  bottom: "0",
                  background: colors[icon["class"]]
                }}
              >
                {icon.tooltip}
              </span>
            )}
          </span>
        </a>
        {duration && (
          <span
            className="courselist-length px-2"
            style={{ top: "5px", bottom: "auto" }}
          >
            {duration}
          </span>
        )}
        <Title>
          <a
            href={`/catalog/courses/${courseId}`}
            data-turbolinks="false"
            title={name}
            style={{
              whiteSpace: "pre-wrap",
              display: "block",
              minHeight: `${19.6 * 4}px`
            }}
          >
            {truncate(name)}
          </a>
        </Title>
        {icon && icon.style && (
          <CardFooter className={`bg-${icon.style}`}>
            <FontAwesomeIcon icon={["fal", icon.icon]} /> {icon.tooltip}
          </CardFooter>
        )}
      </Card>
    );
  }
}

export class CourseCardNoLink extends React.Component<{
  image: string;
  name: string;
  onClick: () => void;
}> {
  constructor(props) {
    super(props);
  }

  render() {
    const { image, name, onClick } = this.props;

    return (
      <Card className="border">
        <a href={"javascript:void(0);"} title={name} onClick={onClick}>
          <span
            className="embed-responsive embed-responsive-16by9"
            style={{ overflow: "visible" }}
          >
            <Image src={image} className="embed-responsive-item" />
          </span>
        </a>
        <Title>
          <a
            href={"javascript:void(0);"}
            title={name}
            onClick={onClick}
            style={{
              whiteSpace: "pre-wrap",
              display: "block",
              minHeight: `${21.6 * 4}px`
            }}
          >
            {truncate(name)}
          </a>
        </Title>
      </Card>
    );
  }
}

const truncate = (string) => {
  if (string.length > 80) {
    return string.substring(0, 80).trim() + "…";
  } else {
    return string;
  }
};
