import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getTopic,
  getTopicVariables,
  getTopic_getTopic,
  getTopic_getTopic_courses
} from "./codegen/getTopic";

export type Topic = getTopic_getTopic;
export type TopicCourse = getTopic_getTopic_courses;

export const GET_TOPIC = gql`
  query getTopic($id: ID!, $limit: Int, $offset: Int) {
    getTopic(id: $id, limit: $limit, offset: $offset) {
      id
      name
      moreRecords
      description
      featured
      courses {
        id
        name
        thumbnail
        duration
        fullDescription
        shortDescription
        completed
        required
        statusIcon {
          id
          icon
          style
          tooltip
        }
      }
      related {
        id
        slug
        name
        thumbnail
        featured
      }
      currentUser {
        id
        canManageReports
        canManageContent
      }
    }
  }
`;

export const GetTopicQuery = (
  props: Omit<QueryComponentOptions<getTopic, getTopicVariables>, "query">
): JSX.Element => (
  <Query<getTopic, getTopicVariables> query={GET_TOPIC} {...props} />
);
