import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getPackageTopics,
  getPackageTopicsVariables
} from "./codegen/getPackageTopics";

export const GET_PACKAGE_TOPICS = gql`
  query getPackageTopics($id: ID!, $offset: Int, $limit: Int) {
    getPackageTopics(id: $id, offset: $offset, limit: $limit) {
      id
      moreRecords
      topics {
        id
        slug
        name
        description
        courseCount
      }
      currentUser {
        id
        canUpdateContent
      }
    }
  }
`;

export const GetPackageTopicsQuery = (
  props: Omit<
    QueryComponentOptions<getPackageTopics, getPackageTopicsVariables>,
    "query"
  >
): JSX.Element => (
  <Query<getPackageTopics, getPackageTopicsVariables>
    query={GET_PACKAGE_TOPICS}
    {...props}
  />
);

export default GET_PACKAGE_TOPICS;
