import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getUnreadCount } from "./codegen/getUnreadCount";

const GET_UNREAD_COUNT = gql`
  query getUnreadCount {
    unreadCount {
      count
    }
  }
`;

export const GetUnreadCountQuery = (
  props: Omit<QueryComponentOptions<getUnreadCount>, "query">
): JSX.Element => <Query<getUnreadCount> query={GET_UNREAD_COUNT} {...props} />;
