import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getUnpublishedCourses } from "./codegen/getUnpublishedCourses";

export const CATALOG_COURSES_UNPUBLISHED = gql`
  query getUnpublishedCourses {
    catalog_courses_unpublished {
      id
      name
      shortDescription
      thumbnail
      duration
      statusIcon {
        id
        icon
        style
        tooltip
      }
    }
  }
`;

export const GetCatalogCoursesUnpublishedQuery = (
  props: Omit<QueryComponentOptions<getUnpublishedCourses>, "query">
): JSX.Element => (
  <Query<getUnpublishedCourses>
    query={CATALOG_COURSES_UNPUBLISHED}
    {...props}
  />
);
