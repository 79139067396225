import { Input } from "../forms/input";
import DatePicker from "../forms/date_picker";
import * as React from "react";
import * as moment from "moment";
export const OptionsSection = ({ courseForm, onChange, errors }) => (
  <React.Fragment>
    {" "}
    <div className="checkbox checkbox-inline">
      <input
        id="ceuAvailable"
        defaultChecked={courseForm.ceuAvailable}
        name="ceuAvailable"
        type="checkbox"
        onChange={onChange}
      />
      <label htmlFor="ceuAvailable">CEU's available</label>
    </div>
    <div className="checkbox checkbox-inline">
      <input
        id="disableScrubber"
        defaultChecked={courseForm.disableScrubber}
        name="disableScrubber"
        type="checkbox"
        onChange={onChange}
      />
      <label htmlFor="disableScrubber">
        Disable skipping through this course
      </label>
    </div>
    <div className="checkbox checkbox-inline">
      <input
        id="publishedOn"
        defaultChecked={courseForm.publishedOn !== null}
        name="publishedOn"
        type="checkbox"
        onChange={event => {
          let target;
          if (event.target.checked) {
            target = {
              name: "publishedOn",
              type: "text",
              value: moment().toISOString()
            };
          } else {
            target = {
              name: "publishedOn",
              type: "text",
              value: null
            };
          }
          onChange({ target });
        }}
      />
      <label htmlFor="publishedOn">Published</label>
    </div>
    <div className="checkbox checkbox-inline">
      <input
        id="surveyEnabled"
        defaultChecked={courseForm.surveyEnabled}
        name="surveyEnabled"
        type="checkbox"
        onChange={onChange}
      />
      <label htmlFor="surveyEnabled">Survey enabled</label>
    </div>
    {courseForm.surveyEnabled && (
      <Input
        onChange={onChange}
        field="surveyUrl"
        errors={errors}
        defaultValue={courseForm.surveyUrl}
      >
        Survey URL
      </Input>
    )}
    {courseForm.ceuAvailable && (
      <Input
        onChange={onChange}
        field="ceuTrackingNumber"
        errors={errors}
        defaultValue={courseForm.ceuTrackingNumber}
      >
        CEU Tracking Number
      </Input>
    )}
    <div className="row">
      <div className="col-12">
        <div className="form-group">
          <label>
            Expiration Date
            <DatePicker
              date={courseForm.expiresAt}
              update={date => {
                const target = {
                  name: "expiresAt",
                  type: "text",
                  value: date
                };
                onChange({ target });
              }}
              disabled={false}
            />
          </label>
        </div>
      </div>
    </div>
  </React.Fragment>
);
