import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getClassroomCourse,
  getClassroomCourseVariables
} from "./codegen/getClassroomCourse";

export const GET_CLASSROOM_COURSE = gql`
  query getClassroomCourse($id: ID!) {
    getClassroomCourse(id: $id) {
      id
      name
      description
      thumbnail
      topics {
        id
        value
        label
      }
      sessions {
        id
        classroomCourseId
        instructor
        startTime
        endTime
        prerequisites
        waitlist
        openEnrollment
        virtual
        signupDeadline
        minimumRegistrations
        maximumRegistrations
        url
        sessionCode
        location {
          id
          name
        }
        registration {
          id
          user {
            id
          }
          waitlisted
        }
        registrations {
          id
          user {
            id
            fullName
          }
          activities {
            id
            user {
              id
            }
            event
            timestamp
            duration
            value
          }
        }
        handouts {
          id
          name
          thumbnail
        }
      }
      currentUser {
        id
        canManageTraining
      }
    }
  }
`;

export const GetClassroomCourseQuery = (
  props: Omit<
    QueryComponentOptions<getClassroomCourse, getClassroomCourseVariables>,
    "query"
  >
): JSX.Element => (
  <Query<getClassroomCourse, getClassroomCourseVariables>
    query={GET_CLASSROOM_COURSE}
    {...props}
  />
);
