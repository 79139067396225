import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getTopics,
  getTopicsVariables,
  getTopics_getTopics_currentUser,
  getTopics_getTopics_topics
} from "./codegen/getTopics";

export type Topic = getTopics_getTopics_topics;
export type TopicsCurrentUser = getTopics_getTopics_currentUser;

export const GET_TOPICS = gql`
  query getTopics($offset: Int, $limit: Int) {
    getTopics(offset: $offset, limit: $limit) {
      moreRecords
      topics {
        id
        slug
        name
        description
        courseCount
      }
      currentUser {
        id
        canUpdateContent
      }
    }
  }
`;

export const GetTopicsQuery = (
  props: Omit<QueryComponentOptions<getTopics, getTopicsVariables>, "query">
): JSX.Element => (
  <Query<getTopics, getTopicsVariables> query={GET_TOPICS} {...props} />
);
