import * as React from "react";
import Loading from "../loading";
import Error from "../error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CourseCard } from "../course_cards";
import { GetCatalogCoursesFavoriteQuery } from "../../queries/catalog_courses_favorite";
import { GetCatalogCoursesFeaturedQuery } from "../../queries/catalog_courses_featured";
import { GetCatalogCoursesRecommendedQuery } from "../../queries/catalog_courses_recommended";
import { GetCatalogCoursesRequiredQuery } from "../../queries/catalog_courses_required";
import { GetCatalogCoursesSuggestedQuery } from "../../queries/catalog_courses_suggested";
import { GetCatalogCoursesUnpublishedQuery } from "../../queries/catalog_courses_unpublished";
import { WidgetUrl } from "./widget";

interface WidgetCourseListProps {
  url: WidgetUrl;
  size: number;
}

export const CatalogCoursesFavoriteWidget = ({
  url,
  size
}: WidgetCourseListProps): JSX.Element => (
  <GetCatalogCoursesFavoriteQuery>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      return (
        <Courses
          url={url}
          courses={data.catalog_courses_favorite}
          size={size}
        />
      );
    }}
  </GetCatalogCoursesFavoriteQuery>
);

export const CatalogCoursesFeaturedWidget = ({
  url,
  size
}: WidgetCourseListProps): JSX.Element => (
  <GetCatalogCoursesFeaturedQuery>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      return (
        <Courses
          url={url}
          courses={data.catalog_courses_featured}
          size={size}
        />
      );
    }}
  </GetCatalogCoursesFeaturedQuery>
);

export const CatalogCoursesRecommendedWidget = ({
  url,
  size
}: WidgetCourseListProps): JSX.Element => (
  <GetCatalogCoursesRecommendedQuery>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      return (
        <Courses
          url={url}
          courses={data.catalog_courses_recommended}
          size={size}
        />
      );
    }}
  </GetCatalogCoursesRecommendedQuery>
);

export const CatalogCoursesRequiredWidget = ({
  url,
  size
}: WidgetCourseListProps): JSX.Element => (
  <GetCatalogCoursesRequiredQuery>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      return (
        <Courses
          url={url}
          courses={data.catalog_courses_required}
          size={size}
        />
      );
    }}
  </GetCatalogCoursesRequiredQuery>
);

export const CatalogCoursesSuggestedWidget = ({
  url,
  size
}: WidgetCourseListProps): JSX.Element => (
  <GetCatalogCoursesSuggestedQuery>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      return (
        <Courses
          url={url}
          courses={data.catalog_courses_suggested}
          size={size}
        />
      );
    }}
  </GetCatalogCoursesSuggestedQuery>
);

export const CatalogCoursesUnpublishedWidget = ({
  url,
  size
}: WidgetCourseListProps): JSX.Element => (
  <GetCatalogCoursesUnpublishedQuery>
    {({ loading, error, data }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      return (
        <Courses
          url={url}
          courses={data.catalog_courses_unpublished}
          size={size}
        />
      );
    }}
  </GetCatalogCoursesUnpublishedQuery>
);

interface Props {
  url: string;
  courses: {
    id: string;
    name: string;
    shortDescription: string | null;
    thumbnail: string | null;
    duration: string | null;
    statusIcon: {
      id: string;
      icon: string | null;
      style: string | null;
      tooltip: string | null;
    } | null;
  }[];
  size: number;
}

class Courses extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }
  componentDidMount() {
    this.defineCarousel();
  }
  componentDidUpdate() {
    this.defineCarousel();
  }
  defineCarousel() {
    const url = this.props.url;
    ($ as any)('[data-url="' + url + '"]').owlCarousel({
      loop: false,
      navText: [
        "<i class='fal fa-chevron-left'></i>",
        "<i class='fal fa-chevron-right'></i>"
      ],
      nav: true,
      dots: false,
      margin: 15,
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        550: {
          items: 3
        },
        768: {
          items: 4
        },
        1024: {
          items: 5
        },
        1400: {
          items: 6
        },
        1700: {
          items: 7
        }
      },
      responsiveBaseElement: $('[data-url="' + url + '"]')[0]
    });
  }
  render() {
    // is this just grabbing the first key?
    const courses = this.props.courses;
    if (courses.length <= 0) {
      return (
        <div className="alert alert-info">There are no courses to display.</div>
      );
    }

    if (this.props.size == 4) {
      return (
        <ul className="list-group list-group-flush">
          {courses.map((course, index) => {
            return (
              <li className="list-group-item px-0" key={index}>
                <div className="media">
                  <figure className="mr-3 mb-0">
                    <img src={course.thumbnail} alt={course.name} width="50" />
                    <figcaption>
                      <small>
                        <FontAwesomeIcon icon={["fal", "clock"]} />{" "}
                        {course.duration}
                      </small>
                    </figcaption>
                  </figure>
                  <div className="media-body">
                    <h5 className="mt-0 pt-0 mb-1">
                      <a
                        data-turbolinks="false"
                        href={`catalog/courses/${course.id}`}
                      >
                        {course.name}
                      </a>
                    </h5>
                    {course.statusIcon && course.statusIcon.icon && (
                      <div>
                        <FontAwesomeIcon
                          icon={["fas", "dot-circle"]}
                          className={`text-${course.statusIcon.style}`}
                        />
                        <span className="pdd-left-5">
                          {course.statusIcon.tooltip}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return (
        <div
          className="owl-carousel"
          style={{ zIndex: 0 }}
          data-url={this.props.url}
        >
          {courses.map(function (course, index) {
            return (
              <CourseCard
                name={course.name}
                courseId={course.id}
                duration={course.duration}
                icon={course.statusIcon}
                image={course.thumbnail}
                key={index}
              />
            );
          })}
        </div>
      );
    }
  }
}
