import * as React from "react";
import * as moment from "moment";
import { faUserSecret } from "@fortawesome/pro-solid-svg-icons";
import * as _ from "lodash";

export const Activity = ({ activity }) => {
  const { duration, timestamp, value, event, passingScore } = activity[0];
  const groupedActivity = {
    duration,
    timestamp,
    value,
    event,
    passingScore,
    users: _.uniqBy(activity, ({ user }) => user.id).map(({ user }, index) => (
      <li key={`user-${index}`}>{user.fullName}</li>
    ))
  };
  if (groupedActivity.event === "exam") {
    return <ExamActivity activity={groupedActivity} />;
  } else {
    return <CompletedActivity activity={groupedActivity} />;
  }
};

const ExamActivity = ({ activity }) => (
  <div>
    {activity.value >= activity.passingScore ? "Passed" : "Failed"} with a score
    of {activity.value}% on {moment(activity.timestamp).format("MM/DD/YYYY")}{" "}
    <ul>{activity.users}</ul>
  </div>
);

const CompletedActivity = ({ activity }) => (
  <div>
    Took {activity.duration} hours to complete on{" "}
    {moment(activity.timestamp).format("MM/DD/YYYY")} <ul>{activity.users}</ul>
  </div>
);
