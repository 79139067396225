import { gql } from "@apollo/client";

const CREATE_CLASSROOM_COURSE = gql`
  mutation createClassroomCourse($classroomCourse: ClassroomCourseAttributes!) {
    createClassroomCourse(input: { classroomCourse: $classroomCourse }) {
      classroomCourse {
        id
      }
    }
  }
`;

export default CREATE_CLASSROOM_COURSE;
