import { gql } from "@apollo/client";

const GENERATE_GROUP_REPORT = gql`
  mutation generateGroupReport($groupId: ID!) {
    generateGroupReport(input: { groupId: $groupId }) {
      id
    }
  }
`;

export default GENERATE_GROUP_REPORT;
