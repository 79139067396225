import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getComments, getCommentsVariables } from "./codegen/getComments";

const GET_COMMENTS = gql`
  query getComments($courseId: ID!) {
    getComments(courseId: $courseId) {
      id
      name
      url
      content
      avatarUrl
      createdAt
      updatedAt
      ancestry
    }
  }
`;

export const GetCommentsQuery = (
  props: Omit<QueryComponentOptions<getComments, getCommentsVariables>, "query">
): JSX.Element => (
  <Query<getComments, getCommentsVariables> query={GET_COMMENTS} {...props} />
);
