import React, { PureComponent, Fragment } from "react";
import ReactDOM from "react-dom";
import Loading from "../../loading";
import Error from "../../error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditList from "../courses/edit_list";
import { ApolloConsumer } from "@apollo/client";
import { GetGroupQuery, Group, GroupCourse } from "../../../queries/get_group";
import REMOVE_COURSE_GROUP from "../../../queries/remove_course_group";
import UPDATE_COURSE_GROUPS from "../../../queries/update_course_groups";
import arrayMove from "array-move";

import AddCourses from "./add_courses";

interface Props {
  id: string;
  url: string;
}

const GroupEdit = (props: Props): JSX.Element => {
  return (
    <GetGroupQuery
      variables={{ id: props.id, limit: 0 }}
      fetchPolicy="network-only"
    >
      {({ loading, error, data, refetch }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        document.title = `Thinkzoom - Groups: ${data.getGroup.name} - Edit`;
        if (data.getGroup.currentUser.canCreateGroup) {
          return <GroupEditBody {...props} group={data.getGroup} refetch={() => refetch()} />;
        } else {
          return (
            <div className="alert alert-danger">
              You are not authorized to edit this group.
            </div>
          );
        }
      }}
    </GetGroupQuery>
  );
};

interface GroupEditBodyProps {
  group: Group;
  url: string;
  refetch?: any;
}

interface State {
  isModalOpen: boolean;
  courses: GroupCourse[];
}

class GroupEditBody extends PureComponent<GroupEditBodyProps, State> {
  constructor(props: GroupEditBodyProps) {
    super(props);

    this.state = {
      isModalOpen: false,
      courses: props.group.courses
    };
  }

  componentDidUpdate(prevProps: Readonly<GroupEditBodyProps>, prevState: Readonly<State>, snapshot?: any): void {
    if(prevProps.group.courses != this.props.group.courses){
      const courses = this.state.courses;
      const updatedCourses = courses.map((course, i) => {
        const c = this.props.group.courses.find((v, i) => v.id == course.id);
        return c;
      })
      this.setState({...this.state, courses: updatedCourses})
    }
  }

  toggleModal = () => {
    this.setState((state) => {
      return {
        isModalOpen: !state.isModalOpen
      };
    });
  };

  removeCourse = (courseId, client) => {
    const result = client.mutate({
      mutation: REMOVE_COURSE_GROUP,
      variables: { courseId: courseId, groupId: this.props.group.id }
    });
    result.then(({ data }) => {
      this.setState((state) => {
        return {
          courses: [...state.courses].filter((course) => {
            return course.id !== courseId;
          })
        };
      });
    });
  };

  addCourses = (courses, addToEnd) => {
    this.setState((state) => {
      if (addToEnd) {
        return { courses: [...state.courses, ...courses] };
      } else {
        return { courses: [...courses.reverse(), ...state.courses] };
      }
    });
  };

  onSortEnd = (client, { oldIndex, newIndex }) => {
    const courses = arrayMove(this.state.courses, oldIndex, newIndex);
    const courseIds = courses.map((course) => {
      return course.id;
    });

    client.mutate({
      mutation: UPDATE_COURSE_GROUPS,
      update: () => {
        this.setState({ courses: courses });
      },
      variables: { courseIds: courseIds, groupId: this.props.group.id }
    });
  };

  render() {
    const url = this.props.url;
    const data = this.props.group;

    return (
      <Fragment>
        <div className="float-right">
          <button
            className="btn btn-rounded btn-info"
            onClick={this.toggleModal}
          >
            Add Courses
          </button>
          <a
            href={url.split("/").slice(0, -1).join("/")}
            className="btn btn-rounded btn-primary"
          >
            Done Editing
          </a>
          <ApolloConsumer>
            {(client) => (
              <AddCourses
                isOpen={this.state.isModalOpen}
                toggle={this.toggleModal}
                client={client}
                groupId={data.id}
                addCourses={this.addCourses}
                courseIds={this.state.courses.map(({ id }) => id)}
              />
            )}
          </ApolloConsumer>
        </div>
        <h1 className="clearfix">
          <FontAwesomeIcon icon={["fal", "users"]} /> {data.name}
        </h1>
        <p>{data.description}</p>
        <div className="card">
          <div className="card-body">
            <EditList
              data={this.state.courses}
              removeCourse={this.removeCourse}
              url={url}
              id={this.props.group.id}
              canRemoveCourse={true}
              canUpdateCourse={true}
              onSortEnd={this.onSortEnd}
              refetch={() => { this.props.refetch() }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default GroupEdit;
