import { gql } from "@apollo/client";

const CREATE_WIDGET = gql`
  mutation createWidget(
    $name: String!
    $url: String!
    $size: Int!
    $sizeOptions: [Int!]!
    $userId: ID
  ) {
    createWidget(
      input: {
        name: $name
        url: $url
        size: $size
        sizeOptions: $sizeOptions
        userId: $userId
      }
    ) {
      widget {
        id
        url
        size
        name
        sizeOptions
      }
    }
  }
`;

export default CREATE_WIDGET;
