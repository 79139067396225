import * as React from "react";

export const ExamsSection = () => (
  <React.Fragment>
    <p>Click the button below to manage exams for this course.</p>
    <a
      href="#modal-edit"
      className="btn btn-info"
      data-toggle="modal"
      data-target="#modal-edit"
    >
      Manage exams
    </a>
  </React.Fragment>
);
