import * as React from "react";

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  ApolloProvider,
  useQuery
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { UncontrolledTooltip } from "reactstrap";
import Select from "react-select";

import GET_USER_TOKENS, {
  GetUserTokensQuery,
  useUserTokens
} from "../../queries/get_user_tokens";
import GET_CLASSROOM_SESSION, {
  GetClassroomSession,
  useClassroomSession,
  ClassroomSession,
  ClassroomRegistration
} from "../../queries/get_classroom_session";
import Loading from "../loading";
import Error from "../error";
import * as moment from "moment";
import Fancy from "../forms/fancy";
import { Button } from "../buttons";
import UPDATE_CLASSROOM_WAITLIST from "../../queries/update_classroom_waitlist";
import { Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import { cache } from "../../cache";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement(): any {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: () => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

type Props = {
  users: Token[];
  userOptions: Token[];
  registrations: ClassroomRegistration[];
};

type Token = { label: string; value: string };

type User = {
  id: string;
  fullName: string;
};

interface ManageWaitlistContainerProps {
  sessionId: string;
}

const ManageWaitlistContainer: FunctionComponent<ManageWaitlistContainerProps> = ({
  sessionId
}: ManageWaitlistContainerProps): JSX.Element => {
  return (
    <ApolloProvider client={client}>
      <ManageWaitlist sessionId={sessionId} />
    </ApolloProvider>
  );
};

export default ManageWaitlistContainer;

const ManageWaitlist = ({ sessionId }: { sessionId: string }): JSX.Element => {
  const classroomSessionResult = useClassroomSession(sessionId);

  const userTokensResult = useUserTokens();

  if (classroomSessionResult.loading || userTokensResult.loading)
    return <Loading />;
  if (classroomSessionResult.error || userTokensResult.error) return <Error />;

  const users = userTokensResult.data.getUserTokens.map((user: User) => ({
    value: user.id,
    label: user.fullName
  }));
  const session = classroomSessionResult.data.getClassroomSession;
  const classroomRegistrations = session.registrations;

  const userOptions = () =>
    users.filter(
      (user) =>
        !classroomRegistrations.map(({ user }) => user.id).includes(user.value)
    );

  const registrations = () =>
    classroomRegistrations.filter((registration) => !registration.waitlisted);

  const waitlistedRegistrations = () =>
    classroomRegistrations.filter((registration) => registration.waitlisted);

  const potentialRegistrations = () =>
    classroomRegistrations.map(({ user, waitlisted }) => ({
      userId: user.id,
      waitlisted
    }));

  return (
    <View
      userOptions={userOptions()}
      registrations={registrations()}
      waitlistedRegistrations={waitlistedRegistrations()}
      potentialRegistrations={potentialRegistrations()}
      session={session}
      sessionId={sessionId}
    />
  );
};

const View = ({
  userOptions,
  registrations,
  waitlistedRegistrations,
  potentialRegistrations,
  sessionId,
  session
}) => (
  <React.Fragment>
    <h2>Registration for {session.course.name}</h2>
    <p>
      Located in {session.location.name} at{" "}
      {moment(session.startTime).format("MMMM Do, YYYY @ h:mm A")}
    </p>

    <div className="card">
      <div className="card-body">
        <h2 className="card-title">Registered Users</h2>
        <Fancy
          submitOnUpdate
          mutation={UPDATE_CLASSROOM_WAITLIST}
          variables={{
            classroomWaitlist: {
              classroomSessionId: sessionId,
              userId: null,
              status: "ACTIVE"
            }
          }}
          onComplete={{
            message: "The list of registrants has been updated.",
            callback: () => {}
          }}
          update={(cache, { updateClassroomWaitlist }) => {
            const { getClassroomSession } = cache.readQuery({
              query: GET_CLASSROOM_SESSION,
              variables: { id: sessionId }
            });
            cache.writeQuery({
              query: GET_CLASSROOM_SESSION,
              variables: { id: sessionId },
              data: {
                getClassroomSession: Object.assign({}, getClassroomSession, {
                  registrations: [
                    updateClassroomWaitlist.registration,
                    ...getClassroomSession.registrations
                  ]
                })
              }
            });
          }}
          render={({ onChange }) => (
            <SelectWithLabel
              options={userOptions}
              value={null}
              placeholder={"Search for a user to register…"}
              onChange={({ value }) => {
                onChange({
                  target: {
                    name: "userId",
                    type: "test",
                    value: value
                  }
                });
              }}
            >
              Register a user for this course
            </SelectWithLabel>
          )}
        />
        <Table hover>
          <thead>
            <tr>
              <th />
              <th>Name</th>
              <th>Time of registration</th>
            </tr>
          </thead>
          <tbody>
            {registrations.map((registration, index) => (
              <tr key={`registered-list-${registration.id}`}>
                <td>
                  <Fancy
                    submitOnUpdate
                    mutation={UPDATE_CLASSROOM_WAITLIST}
                    variables={{
                      classroomWaitlist: {
                        classroomSessionId: sessionId,
                        userId: registration.user.id,
                        status: "ACTIVE"
                      }
                    }}
                    onComplete={{
                      message: "The list of registrants has been updated.",
                      callback: () => {}
                    }}
                    update={(cache, { updateClassroomWaitlist }) => {
                      const { getClassroomSession } = cache.readQuery({
                        query: GET_CLASSROOM_SESSION,
                        variables: { id: sessionId }
                      });
                      if (updateClassroomWaitlist.status === "DROPPED") {
                        cache.writeQuery({
                          query: GET_CLASSROOM_SESSION,
                          variables: { id: sessionId },
                          data: {
                            getClassroomSession: Object.assign(
                              {},
                              getClassroomSession,
                              {
                                registrations: getClassroomSession.registrations.filter(
                                  ({ id }) =>
                                    id !==
                                    updateClassroomWaitlist.registration.id
                                )
                              }
                            )
                          }
                        });
                      } else {
                        cache.writeQuery({
                          query: GET_CLASSROOM_SESSION,
                          variables: { id: sessionId },
                          data: {
                            getClassroomSession: Object.assign(
                              {},
                              getClassroomSession,
                              {
                                registrations: getClassroomSession.registrations.map(
                                  (registration) => {
                                    if (
                                      registration.id ===
                                      updateClassroomWaitlist.registration.id
                                    ) {
                                      return Object.assign({}, registration, {
                                        waitlisted: true
                                      });
                                    } else {
                                      return registration;
                                    }
                                  }
                                )
                              }
                            )
                          }
                        });
                      }
                    }}
                    render={({ onChange }) => (
                      <React.Fragment>
                        <button
                          className="btn btn-rounded btn-danger"
                          type="button"
                          id={`registration-deny-${registration.id}`}
                          onClick={() => {
                            onChange({
                              target: {
                                name: "status",
                                type: "test",
                                value: "DROPPED"
                              }
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={["fal", "user-times"]} />
                        </button>
                        <UncontrolledTooltip
                          placement="top"
                          target={`registration-deny-${registration.id}`}
                        >
                          Remove from session
                        </UncontrolledTooltip>{" "}
                        <button
                          className="btn btn-rounded btn-secondary"
                          type="button"
                          id={`registration-waitlist-${registration.id}`}
                          onClick={() => {
                            onChange({
                              target: {
                                name: "status",
                                type: "test",
                                value: "WAITLISTED"
                              }
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={["fal", "user-clock"]} />
                        </button>
                        <UncontrolledTooltip
                          placement="top"
                          target={`registration-waitlist-${registration.id}`}
                        >
                          Move to waitlist
                        </UncontrolledTooltip>
                      </React.Fragment>
                    )}
                  />
                </td>
                <td>{registration.user.longIdentifier}</td>
                <td>
                  {moment(registration.createdAt).format("MM/DD/YYYY @ h:mm A")}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>

    <div className="card">
      <div className="card-body">
        <h2 className="card-title">Waitlist</h2>
        <Table hover>
          <thead>
            <tr>
              <th />
              <th>Name</th>
              <th>Time of waitlist registration</th>
            </tr>
          </thead>
          <tbody>
            {waitlistedRegistrations.map((registration, index) => (
              <tr key={`waitlisted-list-${registration.id}`}>
                <td>
                  <Fancy
                    submitOnUpdate
                    mutation={UPDATE_CLASSROOM_WAITLIST}
                    variables={{
                      classroomWaitlist: {
                        classroomSessionId: sessionId,
                        userId: registration.user.id,
                        status: "ACTIVE"
                      }
                    }}
                    onComplete={{
                      message: "The list of registrants has been updated.",
                      callback: () => {}
                    }}
                    update={(cache, { updateClassroomWaitlist }) => {
                      const { getClassroomSession } = cache.readQuery({
                        query: GET_CLASSROOM_SESSION,
                        variables: { id: sessionId }
                      });
                      if (updateClassroomWaitlist.status === "DROPPED") {
                        cache.writeQuery({
                          query: GET_CLASSROOM_SESSION,
                          variables: { id: sessionId },
                          data: {
                            getClassroomSession: Object.assign(
                              {},
                              getClassroomSession,
                              {
                                registrations: getClassroomSession.registrations.filter(
                                  ({ id }) =>
                                    id !==
                                    updateClassroomWaitlist.registration.id
                                )
                              }
                            )
                          }
                        });
                      } else {
                        cache.writeQuery({
                          query: GET_CLASSROOM_SESSION,
                          variables: { id: sessionId },
                          data: {
                            getClassroomSession: Object.assign(
                              {},
                              getClassroomSession,
                              {
                                registrations: getClassroomSession.registrations.map(
                                  (registration) => {
                                    if (
                                      registration.id ===
                                      updateClassroomWaitlist.registration.id
                                    ) {
                                      return Object.assign({}, registration, {
                                        waitlisted: false
                                      });
                                    } else {
                                      return registration;
                                    }
                                  }
                                )
                              }
                            )
                          }
                        });
                      }
                    }}
                    render={({ onChange }) => (
                      <React.Fragment>
                        <button
                          className="btn btn-rounded btn-success"
                          type="button"
                          id={`waitlist-approve-${registration.id}`}
                          onClick={() => {
                            onChange({
                              target: {
                                name: "status",
                                type: "test",
                                value: "ACTIVE"
                              }
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={["fal", "user-check"]} />
                        </button>
                        <UncontrolledTooltip
                          placement="top"
                          target={`waitlist-approve-${registration.id}`}
                        >
                          Approve waitlist request
                        </UncontrolledTooltip>{" "}
                        <button
                          className="btn btn-rounded btn-danger"
                          type="button"
                          id={`waitlist-deny-${registration.id}`}
                          onClick={() => {
                            onChange({
                              target: {
                                name: "status",
                                type: "test",
                                value: "DROPPED"
                              }
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={["fal", "user-times"]} />
                        </button>
                        <UncontrolledTooltip
                          placement="top"
                          target={`waitlist-deny-${registration.id}`}
                        >
                          Deny waitlist request
                        </UncontrolledTooltip>
                      </React.Fragment>
                    )}
                  />
                </td>
                <td>{registration.user.longIdentifier}</td>
                <td>
                  {moment(registration.createdAt).format("MM/DD/YYYY @ h:mm A")}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  </React.Fragment>
);

const SelectWithLabel = ({ children, ...props }) => (
  <div className="row">
    <div className="col-12">
      <div className="form-group">
        <label>{children}</label>
        <Select {...props} />
      </div>
    </div>
  </div>
);
