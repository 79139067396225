import * as React from "react";
import Loading from "../loading";
import Error from "../error";
import createChannel from "../../cable";
import { GetActivitiesQuery, Activity } from "../../queries/get_activities";
import moment from "moment";

interface Props {
  url: string;
}

function Activities(props: Props): JSX.Element {
  const url = props.url;
  return (
    <GetActivitiesQuery>
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        return <ActivitiesBody url={url} activities={data.activities} />;
      }}
    </GetActivitiesQuery>
  );
}

interface ActivitiesBodyProps {
  url: string;
  activities: Activity[];
}

class ActivitiesBody extends React.Component<ActivitiesBodyProps> {
  constructor(props: ActivitiesBodyProps) {
    super(props);
  }

  componentDidMount() {
    this.loadActionCable();
  }

  loadActionCable() {
    createChannel("ActivitiesChannel", {
      connected: () => {
        console.log("I am connected");
      },
      disconnected: () => {
        console.log("I am disconnected");
      },
      received: () => {}
    });
  }

  render() {
    const { activities } = this.props;
    if (activities.length <= 0) {
      return (
        <div className="alert alert-info">There is no activity to display.</div>
      );
    }

    return (
      <div className="activity-list">
        <ul>
          {activities.map(function (activity, index) {
            return <ProcessActivity {...activity} key={index} />;
          })}
        </ul>
      </div>
    );
  }
}

function ProcessActivity(props: Activity) {
  let trackable = null;

  if (props.trackable.score) {
    trackable = (
      <div>
        <a href={props.owner.link}>{props.owner.name}</a>
        <span>{props.message}</span>
        <span>{props.trackable.score}</span>
        <span>{props.ending}</span>
        <a href={`catalog${props.trackable.link}`}>{props.trackable.name}</a>
      </div>
    );
  } else {
    trackable = (
      <div>
        <a href={props.owner.link}>{props.owner.name}</a>
        <span>{props.message}</span>
        <a href={`catalog${props.trackable.link}`}>{props.trackable.name}</a>
        <span>{props.ending}</span>
        {props.recipient && (
          <a href={props.recipient.link}>{props.recipient.name}</a>
        )}
      </div>
    );
  }

  return (
    <li>
      <span className="u-pic img-circle">
        <img src={props.owner.thumb} />
      </span>
      <span className="u-desc">
        <span className="u-time">
          <small>{moment(props.createdAt).fromNow()}</small>
          {trackable}
        </span>
      </span>
    </li>
  );
}

export default Activities;
