import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getAssignments,
  getAssignmentsVariables
} from "./codegen/getAssignments";

export const GET_ASSIGNMENTS = gql`
  query getAssignments($courseId: ID!, $limit: Int, $offset: Int) {
    getAssignments(courseId: $courseId, limit: $limit, offset: $offset) {
      totalCount
      moreRecords
      users {
        id
        fullName
        username
        avatar
        status {
          id
          badge
          message
        }
      }
    }
  }
`;

export const GetAssignmentsQuery = (
  props: Omit<
    QueryComponentOptions<getAssignments, getAssignmentsVariables>,
    "query"
  >
): JSX.Element => (
  <Query<getAssignments, getAssignmentsVariables>
    query={GET_ASSIGNMENTS}
    {...props}
  />
);
