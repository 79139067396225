import { gql } from "@apollo/client";

const CLONE_GROUP = gql`
  mutation cloneGroup($group: GroupAttributes!) {
    cloneGroup(input: { group: $group }) {
      group {
        id
        name
        description
        active
      }
    }
  }
`;

export default CLONE_GROUP;
