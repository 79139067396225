import * as React from "react";

type Props = {
  headings: string[];
  sections: JSX.Element[];
  finishButton: JSX.Element;
};

type State = {
  index: number;
};
export default class Wizard extends React.Component<Props, State> {
  state = { index: 0 };
  moveTo = (index: number) => {
    const { length } = this.props.sections;
    if (index < 0) {
      this.setState({ index: 0 });
    } else if (index < length) {
      this.setState({ index });
    } else {
      this.setState({ index: length - 1 });
    }
  };
  headings = () =>
    this.props.headings.map((heading, index) => (
      <li className="nav-item" key={index}>
        <a
          href="#fdsafd"
          data-toggle="tab"
          onClick={() => this.moveTo(index)}
          className={`${index === this.state.index && "active show"}`}
        >
          <span className="step">{index + 1}</span>
          <span className="title">{heading}</span>
        </a>
      </li>
    ));
  sections = () =>
    this.props.sections.map((section, index) => (
      <div
        className={`tab-pane fade ${index === this.state.index && "in active"}`}
        key={index}
      >
        <div className="row">
          <div className="col-md-10 mr-auto ml-auto mb-5">{section}</div>
        </div>
      </div>
    ));
  progressPercentage = () => {
    const percentage =
      (this.state.index / this.props.sections.length) *
      (100 / (this.props.sections.length - 1) + 100);
    if (percentage < 0) {
      return 0;
    } else if (percentage > 100) {
      return 100;
    } else {
      return percentage;
    }
  };

  render() {
    const { index } = this.state;
    const { finishButton } = this.props;
    const { length } = this.props.sections;
    return (
      <div id="rootwizard" className="form-wizard col-md-10 ml-auto mr-auto">
        <ul className="nav nav-pills nav-fill">{this.headings()}</ul>
        <div
          id="bar"
          className="progress progress-info"
          style={{
            width: "75%"
          }}
        >
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow={this.progressPercentage()}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ width: `${this.progressPercentage()}%`, height: "5px" }}
          />
        </div>
        <div className="tab-content">
          {this.sections()}
          <div className="wizard-pager">
            <div className="">
              <button
                type="button"
                className="btn btn-secondary button-previous btn-rounded"
                onClick={event => {
                  event.preventDefault();
                  this.moveTo(index - 1);
                }}
              >
                Previous
              </button>

              {index === length - 1 && finishButton}

              {index !== length - 1 && (
                <button
                  type="button"
                  className="btn btn-primary button-next float-right btn-rounded"
                  onClick={event => {
                    event.preventDefault();
                    this.moveTo(index + 1);
                  }}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
