import * as React from "react";
import Loading from "../loading";
import Error from "../error";
import { Widgets } from "../widgets";
import { GetWidgetsQuery } from "../../queries/get_widgets";
import { GetDashboardQuery } from "../../queries/get_dashboard";
import * as $ from "jquery";

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  ApolloProvider,
  ApolloConsumer
} from "@apollo/client";
import { cache } from "../../cache";

import { onError } from "@apollo/client/link/error";
import { isWidgetUrl, Widget } from "../widgets/widget";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement() {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: (string: string) => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

interface Props {
  userId: string;
}

const Dashboard: React.FunctionComponent<Props> = (
  props: Props
): JSX.Element => {
  return (
    <ApolloProvider client={client}>
      <GetDashboardQuery>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <Error />;

          const dashboardData = data.dashboard;

          return (
            <React.Fragment>
              <AccountName accountName={dashboardData.accountName} />
              {!dashboardData.setupStatus && <SetupStatus />}
              {dashboardData.dashboardContent && (
                <DashboardContent
                  dashboardContent={dashboardData.dashboardContent}
                />
              )}
            </React.Fragment>
          );
        }}
      </GetDashboardQuery>
      <GetWidgetsQuery variables={{ userId: props.userId }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <Error />;

          const widgets: Widget[] = data.widgets
            .map((widget): Widget | undefined => {
              if (isWidgetUrl(widget.url)) {
                return {
                  __typename: "Widget",
                  url: widget.url,
                  id: widget.id,
                  name: widget.name,
                  sizeOptions: widget.sizeOptions,
                  size: widget.size
                };
              }
            })
            .filter(
              (widget: Widget | undefined): widget is Widget =>
                widget !== undefined
            );
          return (
            <ApolloConsumer>
              {(client) => (
                <Widgets
                  widgets={widgets}
                  client={client}
                  userId={props.userId}
                />
              )}
            </ApolloConsumer>
          );
        }}
      </GetWidgetsQuery>
    </ApolloProvider>
  );
};

export default Dashboard;

function AccountName(props) {
  return (
    <div className="clearfix">
      <p className="lead">Welcome to {props.accountName}.</p>
    </div>
  );
}

function SetupStatus() {
  return (
    <div className="alert alert-info">
      <div className="row">
        <div className="col-sm-1">
          <i className="fal fa-cog fa-4x fa-spin" />
        </div>
        <div className="col-ms-11">
          <p className="lead">
            Thank you for your interest in Thinkzoom. We are currently loading
            your content. We will notify you when it is complete.
          </p>
        </div>
      </div>
    </div>
  );
}

class DashboardContent extends React.Component<{ dashboardContent: string }> {
  componentDidMount() {
    const $dashboardContent = $(".js-dashboard-content");
    if ($dashboardContent.html().length == 0) {
      $dashboardContent.hide();
    }
  }

  render() {
    return (
      <div className="card w-100">
        <div
          className="card-body js-dashboard-content"
          dangerouslySetInnerHTML={{ __html: this.props.dashboardContent }}
        />
      </div>
    );
  }
}
