import * as React from "react";
import StatusCorner from "../status-corner";
import { Card, CardBody, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Parser from 'html-react-parser';
import { RenderRequirement } from './edit_list';

class List extends React.PureComponent<
  {
    data: any[];
    url: string;
    admin?: boolean;
  },
  {}
> {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.data.length > 0) {
      return (
        <React.Fragment>
          {this.props.data.map((course, index) => {
            return <ListBody key={index} course={course} {...this.props} />;
          })}
        </React.Fragment>
      );
    } else {
      return <div className="alert alert-info">There are no courses.</div>;
    }
  }
}

class ListBody extends React.Component<
  {
    course: any;
    url: string;
    admin?: boolean;
  },
  {}
> {
  constructor(props) {
    super(props);
  }

  render() {
    const course = this.props.course;

    return (
      <div className="courselist-item">
        <div className="courselist-thumbnail">
          <div className="embed-responsive embed-responsive-16by9">
            <div className="embed-responsive-item">
              {course.thumbnail ? (
                <a
                  href={this.props.url + "/courses/" + course.id}
                  data-turbolinks="false"
                >
                  <img
                    className="mr-3 img-fluid border"
                    src={course.thumbnail}
                    alt={course.name}
                  />
                </a>
              ) : (
                <Card className="bg-light">
                  <CardBody className="text-center">
                    <a
                      href={this.props.url + "/courses/" + course.id}
                      data-turbolinks="false"
                      className="text-dark"
                    >
                      <FontAwesomeIcon
                        icon={["fal", "chalkboard-teacher"]}
                        size="6x"
                      />
                    </a>
                  </CardBody>
                </Card>
              )}
            </div>
          </div>
          {course.duration && (
            <div className="courselist-length">{course.duration}</div>
          )}
        </div>
        <div className="courselist-details">
          <h5 className="mt-0">
            <a
              href={this.props.url + "/courses/" + course.id}
              data-turbolinks="false"
            >
              {course.name}
            </a>
            { this.props.admin ?
              <div className="mt-1">
                <small><RenderRequirement requirement={course.requirement}/></small>
              </div>
            : null }
          </h5>
          <div className="courselist-description">{Parser(course.fullDescription)}</div>
        </div>
        {course.statusIcon && (
          <StatusCorner
            icon={course.statusIcon.icon}
            look={course.statusIcon.style}
            tooltip={course.statusIcon.tooltip}
            key_id={course.id}
          />
        )}
      </div>
    );
  }
}

export default List;
