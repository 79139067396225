import * as React from "react";
import Loading from "../loading";
import Error from "../error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressCircle from "../catalog/progress-circle";
import { Card, CardBody, CardTitle, CardText } from "reactstrap";
import { GetCatalogTracksQuery, Track } from "../../queries/get_catalog_tracks";

interface Props {
  url: string;
  size: number;
}

function Tracks(props: Props): JSX.Element {
  const url = props.url;
  return (
    <GetCatalogTracksQuery fetchPolicy="network-only">
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        return (
          <TracksBody
            url={url}
            tracks={data.getCatalogTracks}
            size={props.size}
          />
        );
      }}
    </GetCatalogTracksQuery>
  );
}

class TracksBody extends React.PureComponent<Props & { tracks: Track[] }> {
  constructor(props: Props & { tracks: Track[] }) {
    super(props);
  }

  componentDidMount() {
    this.defineCarousel();
  }

  componentDidUpdate() {
    this.defineCarousel();
  }

  defineCarousel() {
    const url = this.props.url;
    ($ as any)('[data-url="' + url + '"]').owlCarousel({
      loop: false,
      navText: [
        "<i class='fal fa-chevron-left'></i>",
        "<i class='fal fa-chevron-right'></i>"
      ],
      nav: true,
      dots: false,
      margin: 15,
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        550: {
          items: 3
        },
        768: {
          items: 4
        },
        1024: {
          items: 5
        },
        1400: {
          items: 6
        },
        1700: {
          items: 7
        }
      },
      responsiveBaseElement: $('[data-url="' + url + '"]')[0]
    });
  }

  render() {
    const { tracks } = this.props;
    if (tracks.length <= 0) {
      return (
        <div className="alert alert-info">
          You are not enrolled in any tracks.
        </div>
      );
    }

    if (this.props.size == 4) {
      return (
        <table className="table table-lg table-hover">
          <tbody>
            {tracks.map((track, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div className="list-info">
                      <div className="info" style={{ paddingLeft: "0" }}>
                        <span className="title">
                          <a href={`catalog/tracks/${track.id}`}>
                            {track.name}
                          </a>
                        </span>
                        <span className="sub-title">{track.description}</span>
                        <div className="d-inline-block">
                          <FontAwesomeIcon icon={["fal", "tv"]} />
                          <span className="pdd-left-5">
                            {track.courseCount}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {Number.isInteger(track.progress) && (
                      <div className="progress-small-right">
                        <ProgressCircle progress={track.progress} />
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return (
        <div
          className="owl-carousel"
          style={{ zIndex: 0 }}
          data-url={this.props.url}
        >
          {tracks.map((track, index) => {
            return (
              <Card key={index} style={{ height: "250px" }}>
                <CardBody>
                  <CardTitle>
                    <a href={`catalog/tracks/${track.id}`}>{track.name}</a>
                  </CardTitle>
                  <CardText>{track.description}</CardText>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  >
                    <div className="d-inline-block">
                      <FontAwesomeIcon icon={["fal", "tv"]} />
                      <span className="pdd-left-5">{track.courseCount}</span>
                    </div>
                    {Number.isInteger(track.progress) && (
                      <div className="progress-small-right">
                        <ProgressCircle progress={track.progress} />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            );
          })}
        </div>
      );
    }
  }
}

export default Tracks;
