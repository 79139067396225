import { gql } from "@apollo/client";

const UPLOAD_SCORM_PACKAGE = gql`
  mutation uploadScormPackage($url: String!, $title: String) {
    uploadScormPackage(input: { url: $url, title: $title }) {
      id
    }
  }
`;

export default UPLOAD_SCORM_PACKAGE;
