import * as React from "react";
import { toggleCatalogOpen } from "../utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default ({ type, item, ...props }) => (
  <a href={`/catalog/${type}/${item.slug}`}>
    <li className="catalog-item" onClick={toggleCatalogOpen}>
      <p className="catalog-item__title">{item.name}</p>
      <p>{item.description}</p>
      <div className="d-inline-block pdd-right-15 text-muted">
        <FontAwesomeIcon icon={["fal", "tv"]} />
        <span className="pdd-left-5">{item.courseCount}</span>
      </div>
    </li>
  </a>
);
