import * as React from "react";
import Notification from "./notification";
import Icon from "../icon";
import { Mutation } from "@apollo/client/react/components";
import MARK_NOTIFICATION_AS_READ from "../../queries/mark_notification_as_read";
import GET_NOTIFICATIONS from "../../queries/get_notifications";

type Props = {
  notifications: NotificationObject[];
};

type NotificationObject = {
  id: string;
  subject: string;
  body: string;
  unread: boolean;
  link: string;
  createdAt: string;
};

export default class Notifications extends React.Component<Props> {
  notifications = () => this.props.notifications.map(this.toNotification);

  toNotification = (notification, index) => {
    return <Notification key={index} {...notification} />;
  };

  render() {
    return (
      <View
        NotificationComponents={this.notifications}
        notificationIds={this.props.notifications.map(({ id }) => id)}
        updatedCount={
          this.props.notifications.filter(({ unread }) => unread).length
        }
      />
    );
  }
}

const View = ({ NotificationComponents, notificationIds, updatedCount }) => (
  <li className="notifications dropdown">
    {updatedCount !== 0 && <span className="counter">{updatedCount}</span>}

    <a href="" className="dropdown-toggle" data-toggle="dropdown">
      <Icon icon="bell" />
    </a>
    <ul className="dropdown-menu border">
      <li className="notice-header">
        <Icon icon="bell" />
        <span className="pdd-left-10">Notifications</span>

        <Mutation
          mutation={MARK_NOTIFICATION_AS_READ}
          update={(cache, data) => {
            const { getNotifications } = cache.readQuery({
              query: GET_NOTIFICATIONS
            });
            cache.writeQuery({
              query: GET_NOTIFICATIONS,
              data: {
                getNotifications: Object.assign({}, getNotifications, {
                  notifications: getNotifications.notifications.map(
                    (notification) => {
                      if (notificationIds.includes(notification.id)) {
                        return Object.assign({}, notification, {
                          unread: false
                        });
                      } else {
                        return notification;
                      }
                    }
                  )
                })
              }
            });
          }}
        >
          {(markNotificationAsRead) => (
            <button
              className="btn btn-default btn-sm float-right"
              onClick={() => {
                markNotificationAsRead({
                  variables: { ids: notificationIds },
                  optimisticResponse: {
                    __typename: "Mutation",
                    markNotificationAsRead: {
                      ids: notificationIds,
                      __typename: "MarkNotificationAsReadPayload"
                    }
                  }
                });
              }}
            >
              <Icon icon="eye" /> Mark all as read
            </button>
          )}
        </Mutation>
      </li>
      <li>
        <ul className="list-info overflow-y-auto relative scrollable">
          <NotificationComponents />
        </ul>
      </li>
    </ul>
  </li>
);
