import * as React from "react";
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  ApolloProvider
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { cache } from "../../../cache";
import Search from "./search";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement() {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: (attribute: string) => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

class Index extends React.PureComponent<{}, {}> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ApolloProvider client={client}>
        <Search />
      </ApolloProvider>
    );
  }
}

export default Index;
