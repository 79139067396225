import * as React from "react";
import Loading from "../loading";
import Error from "../error";
import CourseListItem from "./course_list_item";
import { GET_GROUP, GetGroupQuery } from "../../queries/get_group";
import { GET_TOPIC, GetTopicQuery } from "../../queries/get_topic";
import { GetTrackQuery, GET_TRACK } from "../../queries/get_track";
import InfiniteScroll from "react-infinite-scroller";

function GroupCourseList(props: {
  id: string;
  courseId: string;
  type: string;
}): JSX.Element {
  return (
    <GetGroupQuery variables={{ id: props.id }}>
      {({ loading, error, data, fetchMore }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        return (
          <CourseListNavigation
            data={data}
            noOptions={false}
            moreRecords={data.getGroup.moreRecords}
            onLoadMore={({ offset }) => {
              void fetchMore({
                variables: {
                  offset: offset ? data.getGroup.courses.length : null,
                  id: props.id
                },
                query: GET_GROUP
              });
            }}
            {...props}
          />
        );
      }}
    </GetGroupQuery>
  );
}

function TrackCourseList({
  id,
  courseId,
  type
}: {
  id: string;
  courseId: string;
  type: string;
}): JSX.Element {
  return (
    <GetTrackQuery variables={{ id }}>
      {({ loading, error, data, fetchMore }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        return (
          <CourseListNavigation
            data={data}
            id={id}
            courseId={courseId}
            type={type}
            moreRecords={data.getTrack.moreRecords}
            onLoadMore={async ({ offset }) => {
              await fetchMore({
                variables: {
                  offset: offset ? data.getTrack.courses.length : null,
                  id
                },
                query: GET_TRACK
              });
            }}
          />
        );
      }}
    </GetTrackQuery>
  );
}

function TopicCourseList(props) {
  return (
    <GetTopicQuery variables={{ id: props.id }}>
      {({ loading, error, data, fetchMore }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        return (
          <CourseListNavigation
            data={data}
            noOptions={props.noOptions}
            moreRecords={data.getTopic.moreRecords}
            onLoadMore={({ offset }) => {
              fetchMore({
                variables: {
                  offset: offset ? data.getTopic.courses.length : null,
                  id: props.id
                },
                query: GET_TOPIC
              });
            }}
            {...props}
          />
        );
      }}
    </GetTopicQuery>
  );
}

interface CourseListNavigationProps {
  data: any;
  id: string;
  noOptions?: boolean;
  onLoadMore: any;
  moreRecords: boolean;
  courseId: string;
  type: string;
}

class CourseListNavigation extends React.PureComponent<
  CourseListNavigationProps,
  { hideCompleted: boolean; onlyRequired: boolean }
> {
  constructor(props: CourseListNavigationProps) {
    super(props);
    this.state = {
      hideCompleted: false,
      onlyRequired: false
    };
  }

  componentDidMount() {
    //$(".email-list-item").scrollTop($(".catalog-nav-active")[0].offsetTop);
  }

  hideCompleted = (event) => {
    this.setState({ hideCompleted: event.target.checked });
  };

  onlyRequired = (event) => {
    this.setState({ onlyRequired: event.target.checked });
  };

  render() {
    const data = this.props.data[Object.keys(this.props.data)[0]];
    let items = data.courses;

    if (this.state.hideCompleted) {
      items = items.filter(({ completed }) => !completed);
    }

    if (this.state.onlyRequired) {
      items = items.filter(({ required }) => required);
    }

    if (items.length > 0) {
      items = items.map((item, index) => {
        return (
          <CourseListItem
            url={`/catalog/${this.props.type}/${this.props.id}/courses`}
            key={index}
            item={item}
            courseId={this.props.courseId}
          />
        );
      });
    } else {
      items = (
        <div className="alert alert-info">
          There are no courses to display. If you believe this to be an error,
          please make sure you don't have any filters enabled.
        </div>
      );
    }

    let options;
    if (!this.props.noOptions) {
      options = (
        <React.Fragment>
          <div className="checkbox checkbox-inline">
            <input
              id="hideCompleted"
              value="hideCompleted"
              name="hideCompleted"
              type="checkbox"
              onChange={this.hideCompleted}
            />
            <label htmlFor="hideCompleted">Hide Completed</label>
          </div>
          <div className="checkbox checkbox-inline">
            <input
              id="onlyRequired"
              value="onlyRequired"
              name="onlyRequired"
              type="checkbox"
              onChange={this.onlyRequired}
            />
            <label htmlFor="onlyRequired">Only Required</label>
          </div>
        </React.Fragment>
      );
    }

    return (
      <div
        className="email-list-wrapper scrollable"
        style={{ overflowX: "hidden" }}
      >
        <InfiniteScroll
          loadMore={() =>
            this.props.onLoadMore({
              offset: true
            })
          }
          hasMore={this.props.moreRecords}
          loader={<Loading key={0} />}
          threshold={500}
          useWindow={false}
        >
          <div className="list-view-group-container">
            <div className="email-list-tools" style={{ textAlign: "initial" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline"
                }}
              >
                <h2>{data.name}</h2>
                <a data-turbolinks="false" href={`/catalog/${this.props.type}`}>
                  Show all {this.props.type}
                </a>
              </div>
              {options}
            </div>
            <ul className="email-list-item">{items}</ul>
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}

export { GroupCourseList, TrackCourseList, TopicCourseList };
