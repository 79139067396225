import { gql } from "@apollo/client";

const UPDATE_WIDGET_SIZE = gql`
  mutation updateWidgetSize($widgetId: ID!, $size: Int!, $userId: ID) {
    updateWidgetSize(
      input: { widgetId: $widgetId, size: $size, userId: $userId }
    ) {
      widget {
        id
        url
        size
        name
        sizeOptions
      }
    }
  }
`;

export default UPDATE_WIDGET_SIZE;
