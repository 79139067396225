import { gql } from "@apollo/client";

const UPDATE_GROUP = gql`
  mutation updateGroup($group: GroupAttributes!) {
    updateGroup(input: { group: $group }) {
      group {
        id
        name
        description
        active
      }
    }
  }
`;

export default UPDATE_GROUP;
