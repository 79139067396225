import React, { PureComponent, Fragment } from "react";
import ReactDOM from "react-dom";
import Loading from "../../loading";
import Error from "../../error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditList from "../courses/edit_list";
import { ApolloConsumer } from "@apollo/client";
import { GetTrackQuery } from "../../../queries/get_track";
import REMOVE_COURSE_TRACK from "../../../queries/remove_course_track";
import UPDATE_COURSE_TRACKS from "../../../queries/update_course_tracks";
import arrayMove from "array-move";

import AddCourses from "./add_courses";
import {
  getTrack_getTrack,
  getTrack_getTrack_courses
} from "../../../queries/codegen/getTrack";

function TrackEdit(props) {
  return (
    <GetTrackQuery variables={{ id: props.id, limit: 0 }}>
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        document.title = `Thinkzoom - Tracks: ${data.getTrack.name} - Edit`;
        if (data.getTrack.currentUser.canManageTracks) {
          return <TrackEditBody {...props} track={data.getTrack} />;
        } else {
          return (
            <div className="alert alert-danger">
              You are not authorized to edit this track.
            </div>
          );
        }
      }}
    </GetTrackQuery>
  );
}

class TrackEditBody extends PureComponent<
  {
    track: getTrack_getTrack;
    url: string;
  },
  { isModalOpen: boolean; courses: getTrack_getTrack_courses[] }
> {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      courses: props.track.courses
    };
  }

  toggleModal = () => {
    this.setState((state) => {
      return {
        isModalOpen: !state.isModalOpen
      };
    });
  };

  removeCourse = (courseId, client) => {
    const result = client.mutate({
      mutation: REMOVE_COURSE_TRACK,
      variables: { courseId: courseId, trackId: this.props.track.id }
    });
    result.then(({ data }) => {
      this.setState((state) => {
        return {
          courses: [...state.courses].filter((course) => {
            return course.id !== courseId;
          })
        };
      });
    });
  };

  addCourses = (courses, addToEnd) => {
    this.setState((state) => {
      if (addToEnd) {
        return { courses: [...state.courses, ...courses] };
      } else {
        return { courses: [...courses.reverse(), ...state.courses] };
      }
    });
  };

  onSortEnd = (client, { oldIndex, newIndex }) => {
    const courses = arrayMove(this.state.courses, oldIndex, newIndex);
    const courseIds = courses.map((course) => {
      return course.id;
    });

    client.mutate({
      mutation: UPDATE_COURSE_TRACKS,
      update: () => {
        this.setState({ courses: courses });
      },
      variables: { courseIds: courseIds, trackId: this.props.track.id }
    });
  };

  render() {
    const url = this.props.url;
    const data = this.props.track;

    return (
      <Fragment>
        <div className="float-right">
          <button
            className="btn btn-rounded btn-info"
            onClick={this.toggleModal}
          >
            Add Courses
          </button>
          <a
            href={url.split("/").slice(0, -1).join("/")}
            className="btn btn-rounded btn-primary"
          >
            Done Editing
          </a>
          <ApolloConsumer>
            {(client) => (
              <AddCourses
                isOpen={this.state.isModalOpen}
                toggle={this.toggleModal}
                client={client}
                addCourses={this.addCourses}
                trackId={data.id}
                courseIds={this.state.courses.map(({ id }) => id)}
              />
            )}
          </ApolloConsumer>
        </div>
        <h1 className="clearfix">
          <FontAwesomeIcon icon={["fal", "users"]} /> {data.name}
        </h1>
        <p>{data.description}</p>
        <div className="card">
          <div className="card-body">
            <EditList
              data={this.state.courses}
              removeCourse={this.removeCourse}
              url={url}
              id={this.props.track.id}
              canRemoveCourse={true}
              // This is just a guess, as it used to be omitted until TypeScript
              // found the error, probably years after this was first written.
              canUpdateCourse={false}
              onSortEnd={this.onSortEnd}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TrackEdit;
