import * as React from "react";
import Search from "./search";
import ProfileLinks from "../profile_links";
import { createConsumer } from "../../cable";
import { GetActivitiesQuery } from "../../queries/get_activities";
import Error from "../error";

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  ApolloProvider
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { cache } from "../../cache";

import Icon from "../icon";
import Activities from "../profile_links/activities";
import { GetNotifications } from "../../queries/get_notifications";
import * as Noty from "noty";
import "./noty.scss";
import { FunctionComponent } from "react";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement() {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: (string: string) => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

type Props = {
  id: string;
  avatar: string;
  fullName: string;
  flash: {
    [key: string]: string;
  };
  cable: any;
  activities: Activity[];
  notifications: NotificationObject[];
  refetchNotifications: () => void;
  showActivities: boolean;
  showProfile: boolean;
};

type State = {
  activities: Activity[];
  sidePanelOpen: boolean;
};

type NotificationObject = {
  id: string;
  subject: string;
  body: string;
  unread: boolean;
  link: string;
  createdAt: string;
};

type Activity = {
  id: string;
  message: string;
  ending: string;
  createdAt: string;
};

interface HeaderContainerProps {
  id: string;
}

const HeaderContainer: FunctionComponent<HeaderContainerProps> = (
  props: Props
): JSX.Element => {
  const cable = createConsumer();

  return (
    <ApolloProvider client={client}>
      <QueryAll Component={Header} cable={cable} {...props} />
    </ApolloProvider>
  );
};

export default HeaderContainer;

const QueryAll = ({ Component, cable, ...props }) => {
  const withNotifications = (containerProps) => (
    <QueryNotifications Component={Component} {...containerProps} />
  );

  return (
    <QueryActivities Component={withNotifications} cable={cable} {...props} />
  );
};

const QueryActivities = ({ Component, cable, ...props }) => (
  <GetActivitiesQuery>
    {({ loading, error, data }) => {
      if (error) return <Error />;
      if (loading)
        return <Component activities={[]} cable={cable} {...props} />;
      return (
        <Component activities={data.activities} cable={cable} {...props} />
      );
    }}
  </GetActivitiesQuery>
);

const QueryNotifications = ({ Component, ...props }) => (
  <GetNotifications>
    {({ loading, error, data, refetch }) => {
      if (error) return <Error />;
      if (loading) return <Component notifications={[]} {...props} />;

      return (
        <Component
          notifications={data.getNotifications.notifications}
          refetchNotifications={refetch}
          {...props}
        />
      );
    }}
  </GetNotifications>
);

class Header extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activities: [],
      sidePanelOpen: false
    };
  }

  componentDidMount() {
    const { flash } = this.props;
    Object.keys(flash || {}).map((type) =>
      new Noty({
        text: flash[type],
        type: this.notyType(type),
        theme: "bootstrap-v4",
        closeWith: ["click", "button"]
      }).show()
    );

    this.props.cable.subscriptions.create(
      { channel: "MessagesChannel" },
      {
        received: (message) => {
          this.props.refetchNotifications();
        }
      }
    );

    this.props.cable.subscriptions.create(
      { channel: "ActivitiesChannel" },
      {
        received: (activity) => {
          this.setState((state) => ({
            activities: [activity, ...state.activities]
          }));
        }
      }
    );
  }

  notyType = (type) => {
    switch (type) {
      case "success":
        return "success";
      case "error":
        return "error";
      case "alert":
        return "warning";
      default:
        return "info";
    }
  };

  toggleSidePanel = (event) => {
    event.preventDefault();
    this.setState((state) => ({ sidePanelOpen: !state.sidePanelOpen }));
  };

  render() {
    const { cable, ...props } = this.props;
    return (
      <View
        newActivities={this.state.activities}
        toggleSidePanel={this.toggleSidePanel}
        sidePanelOpen={this.state.sidePanelOpen}
        {...props}
      />
    );
  }
}

const View = ({
  id,
  avatar,
  fullName,
  flash,
  activities,
  newActivities,
  notifications,
  toggleSidePanel,
  sidePanelOpen,
  showActivities,
  showProfile
}) => (
  <React.Fragment>
    <div className="header navbar js-navbar" style={{ zIndex: 3 }}>
      <div className="header-container">
        <Search />
        <ProfileLinks
          id={id}
          avatar={avatar}
          fullName={fullName}
          flash={flash}
          newActivities={newActivities}
          notifications={notifications}
          toggleSidePanel={toggleSidePanel}
          showActivities={showActivities}
          showProfile={showProfile}
        />
      </div>
    </div>
    {showActivities && (
      <div className={`side-panel ${sidePanelOpen ? "side-panel-open" : ""}`}>
        <div className="side-panel-wrapper bg-white">
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item active">
              <a
                className="nav-link"
                href="#activity_feed"
                role="tab"
                data-toggle="tab"
              >
                <span>Activities</span>
              </a>
            </li>
            <li className="panel-close">
              <a
                className="side-panel-toggle"
                href="#"
                onClick={toggleSidePanel}
              >
                <Icon icon="times" />
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              id="activity_feed"
              role="tabpanel"
              className="tab-pane fade in active"
              style={{ overflowY: "scroll" }}
            >
              <Activities
                activities={activities}
                newActivities={newActivities}
              />
            </div>
          </div>
        </div>
      </div>
    )}
  </React.Fragment>
);
