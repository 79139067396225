import { gql, ApolloError, useQuery } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import * as React from "react";
import { getUserTokens } from "./codegen/getUserTokens";

const GET_USER_TOKENS = gql`
  query getUserTokens {
    getUserTokens {
      id
      fullName
    }
  }
`;

export const useUserTokens = (): {
  loading: boolean;
  error: ApolloError;
  data: getUserTokens;
} => {
  const { loading, error, data } = useQuery<getUserTokens>(GET_USER_TOKENS);

  return { loading, error, data };
};

export const GetUserTokensQuery = (
  props: Omit<QueryComponentOptions<getUserTokens>, "query">
): JSX.Element => <Query<getUserTokens> query={GET_USER_TOKENS} {...props} />;

export default GET_USER_TOKENS;
