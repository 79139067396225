import { gql } from "@apollo/client";

const DELETE_WIDGET = gql`
  mutation deleteWidget($widgetId: ID!, $userId: ID) {
    deleteWidget(input: { widgetId: $widgetId, userId: $userId }) {
      id
    }
  }
`;

export default DELETE_WIDGET;
