import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StatusCorner from "../status-corner";
import ProgressCircle from "../progress-circle";
import { toggleCatalogOpen } from "../utilities";

function TrackListItem(props) {
  return (
    <a href={`/catalog/${props.type}/${props.item.id}`}>
      <li className="catalog-item" onClick={toggleCatalogOpen}>
        {Number.isInteger(props.item.progress) && (
          <div className="progress-small-right">
            <ProgressCircle progress={props.item.progress} />
          </div>
        )}
        <p className="catalog-item__title">
          {props.item.name}{" "}
          {!props.item.published && (
            <span className="badge badge-warning">unpublished</span>
          )}
        </p>
        <p>{props.item.description}</p>
        <div className="d-inline-block pdd-right-15 text-muted">
          <FontAwesomeIcon icon={["fal", "tv"]} />
          <span className="pdd-left-5">{props.item.courseCount}</span>
        </div>
        {props.item.enrolled && (
          <StatusCorner
            icon="user"
            look="primary"
            tooltip="Enrolled"
            size="sm"
            key_id={props.item.id}
          />
        )}
      </li>
    </a>
  );
}

export default TrackListItem;
