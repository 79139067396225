import * as React from "react";
import * as ReactDOM from "react-dom";
import * as moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilestackDropPane } from "../filestack";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button } from "../buttons";
import Fancy from "../forms/fancy";
import { Input, Textarea } from "../forms/input";
import CREATE_CONTENT from "../../queries/create_content";

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  ApolloProvider
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { cache } from "../../cache";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement() {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: (string: string) => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

class ContentUpload extends React.Component<
  {},
  { modalIsOpen: boolean; file: any }
> {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      file: null
    };
  }

  toggleModal = () => {
    this.setState((state) => {
      return { modalIsOpen: !state.modalIsOpen };
    });
  };

  render() {
    return (
      <ApolloProvider client={client}>
        <FilestackDropPane
          onSuccess={(result) => {
            const file = result[0];
            this.setState({ file });
            this.toggleModal();
          }}
          accept={[
            "video/*",
            ".pdf",
            ".doc",
            ".docx",
            ".ppt",
            ".pptx",
            ".xls",
            ".xlsx"
          ]}
        />
        <Modal
          className="modal-dialog modal-lg"
          isOpen={this.state.modalIsOpen}
          toggle={this.toggleModal}
        >
          <ModalHeader toggle={this.toggleModal}>Add Content</ModalHeader>
          <ModalBody>
            <div className="padding-15">
              <Fancy
                mutation={CREATE_CONTENT}
                update={(cache, { createContent }) => {
                  this.toggleModal();
                  window.location.href = `/contents/${createContent.content.id}/edit`;
                }}
                onComplete={{
                  message: "Content successfully saved.",
                  callback: () => {
                    return null;
                  }
                }}
                variables={{
                  content: {
                    name: null,
                    description: null,
                    file:
                      this.state.file === null
                        ? this.state.file
                        : {
                            key: this.state.file.key,
                            url: this.state.file.url,
                            filename: this.state.file.filename
                          }
                  }
                }}
                render={({ errors, onChange }) => {
                  return (
                    <React.Fragment>
                      <Input onChange={onChange} field="name" errors={errors}>
                        Name
                      </Input>
                      <Textarea
                        onChange={onChange}
                        field="description"
                        errors={errors}
                      >
                        Description
                      </Textarea>

                      <Button primary>Create Content</Button>
                    </React.Fragment>
                  );
                }}
              />
            </div>
          </ModalBody>
        </Modal>
      </ApolloProvider>
    );
  }
}

export default ContentUpload;
