import { gql, useQuery, ApolloError } from "@apollo/client";
import * as React from "react";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getClassroomSession,
  getClassroomSessionVariables,
  getClassroomSession_getClassroomSession,
  getClassroomSession_getClassroomSession_registrations
} from "./codegen/getClassroomSession";

const GET_CLASSROOM_SESSIONS = gql`
  query getClassroomSession($id: ID!) {
    getClassroomSession(id: $id) {
      id
      classroomCourseId
      course {
        name
      }
      startTime
      endTime
      waitlist
      openEnrollment
      virtual
      signupDeadline
      minimumRegistrations
      maximumRegistrations
      location {
        name
      }
      registrations {
        id
        waitlisted
        createdAt
        user {
          id
          fullName
          longIdentifier
        }
        activities {
          id
          user {
            id
            fullName
          }
          event
          timestamp
          duration
          value
          passingScore
        }
      }
    }
  }
`;

export type ClassroomSession = getClassroomSession_getClassroomSession;

export type ClassroomRegistration = getClassroomSession_getClassroomSession_registrations;

export const useClassroomSession = (
  id: string
): {
  loading: boolean;
  error: ApolloError;
  data: getClassroomSession;
} => {
  const { loading, error, data } = useQuery<
    getClassroomSession,
    getClassroomSessionVariables
  >(GET_CLASSROOM_SESSIONS, {
    variables: { id }
  });

  return { loading, error, data };
};

export const GetClassroomSession = (
  props: Omit<
    QueryComponentOptions<getClassroomSession, getClassroomSessionVariables>,
    "query"
  >
): JSX.Element => (
  <Query<getClassroomSession, getClassroomSessionVariables>
    query={GET_CLASSROOM_SESSIONS}
    {...props}
  />
);

export default GET_CLASSROOM_SESSIONS;
