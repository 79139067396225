import { gql } from "@apollo/client";

const CREATE_ASSIGNMENT = gql`
  mutation createAssignment($assignment: AssignmentAttributes!) {
    createAssignment(input: { assignment: $assignment }) {
      userId
    }
  }
`;

export default CREATE_ASSIGNMENT;
