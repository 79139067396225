import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ComponentLoader } from "./component_loader";
import { UncontrolledTooltip } from "reactstrap";
import { SortableElement } from "react-sortable-hoc";
import { Mutation } from "@apollo/client/react/components";
import DELETE_WIDGET from "../../queries/delete_widget";
import UPDATE_WIDGET_SIZE from "../../queries/update_widget_size";
import { GET_WIDGETS } from "../../queries/get_widgets";
import { DragHandle } from "./drag_handle";
import { widgets_widgets } from "../../queries/codegen/widgets";

interface Props {
  widget: Widget;
  userId: string;
}

export type WidgetUrl =
  | "/catalog/groups?enrolled=true"
  | "/charts/leaderboard"
  | "/catalog/courses/suggested"
  | "/exam_assignments"
  | "/catalog/courses/required"
  | "/charts/top_topics"
  | "/activities"
  | "/charts/top_courses"
  | "/catalog/tracks?enrolled=true"
  | "/charts/total_views"
  | "/catalog/courses/recommended"
  | "/catalog/courses/favorite"
  | "/charts/top_recommendations"
  | "/catalog/courses/featured"
  | "/charts/top_favorites"
  | "/charts/top_users"
  | "/catalog/courses/unpublished"
  | "/calendar"
  | "/bob_daily_course";

export const isWidgetUrl = (url: string): url is WidgetUrl =>
  [
    "/catalog/groups?enrolled=true",
    "/charts/leaderboard",
    "/catalog/courses/suggested",
    "/exam_assignments",
    "/catalog/courses/required",
    "/charts/top_topics",
    "/activities",
    "/charts/top_courses",
    "/catalog/tracks?enrolled=true",
    "/charts/total_views",
    "/catalog/courses/recommended",
    "/catalog/courses/favorite",
    "/charts/top_recommendations",
    "/catalog/courses/featured",
    "/charts/top_favorites",
    "/charts/top_users",
    "/catalog/courses/unpublished",
    "/calendar",
    "/bob_daily_course"
  ].includes(url);

export type Widget = widgets_widgets;

export const Widget = SortableElement((props: Props) => {
  return (
    <div className={`col-md-${props.widget.size}`}>
      <div className="card" style={{ height: "350px" }}>
        <div className="portlet">
          <ul className="portlet-item navbar">
            {props.widget.sizeOptions.length > 1 && (
              <React.Fragment>
                <li className="dropdown" id={`widget-size-${props.widget.id}`}>
                  <Mutation
                    mutation={UPDATE_WIDGET_SIZE}
                    update={(cache, { data: { updateWidgetSize } }) => {
                      const { widgets } = cache.readQuery({
                        query: GET_WIDGETS,
                        variables: { userId: props.userId }
                      });
                      cache.writeQuery({
                        query: GET_WIDGETS,
                        variables: { userId: props.userId },
                        data: {
                          widgets: [...widgets].map((widget) => {
                            if (widget.id == updateWidgetSize.widget.id) {
                              return updateWidgetSize.widget;
                            } else {
                              return widget;
                            }
                          })
                        }
                      });
                      // TODO: This is a good spot to update the carousel if
                      // this widget has one.
                    }}
                  >
                    {(updateWidgetSize) => (
                      <React.Fragment>
                        <button
                          type="button"
                          className="btn btn-icon btn-flat btn-rounded dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <FontAwesomeIcon icon={["fal", "window-maximize"]} />
                        </button>
                        <ul className="dropdown-menu">
                          {props.widget.sizeOptions.map((size, index) => {
                            let sizeLabel = "Small";
                            switch (size) {
                              case 8:
                                sizeLabel = "Medium";
                                break;
                              case 12:
                                sizeLabel = "Large";
                                break;
                            }
                            return (
                              <li key={index}>
                                <button
                                  type="button"
                                  className={
                                    props.widget.size == size
                                      ? "btn btn-link dropdown-item active"
                                      : "btn btn-link dropdown-item"
                                  }
                                  onClick={() =>
                                    updateWidgetSize({
                                      variables: {
                                        widgetId: props.widget.id,
                                        userId: props.userId,
                                        size: size
                                      },
                                      optimisticResponse: {
                                        __typename: "Mutation",
                                        updateWidgetSize: {
                                          __typename: "Widget",
                                          widget: Object.assign(
                                            { size: size },
                                            props.widget
                                          )
                                        }
                                      }
                                    })
                                  }
                                >
                                  <span>{sizeLabel}</span>
                                </button>
                              </li>
                            );
                          })}
                        </ul>
                      </React.Fragment>
                    )}
                  </Mutation>
                </li>
                <UncontrolledTooltip
                  placement="top"
                  target={`widget-size-${props.widget.id}`}
                  delay={{ show: 0, hide: 0 }}
                >
                  Change this widget's size
                </UncontrolledTooltip>
              </React.Fragment>
            )}
            <li>
              <DragHandle widget_id={props.widget.id} />
            </li>
            <UncontrolledTooltip
              placement="top"
              target={`widget-drag-${props.widget.id}`}
              delay={{ show: 0, hide: 0 }}
            >
              Reorder this widget
            </UncontrolledTooltip>
            <li>
              <Mutation
                mutation={DELETE_WIDGET}
                update={(cache, { data: { deleteWidget } }) => {
                  const { widgets } = cache.readQuery({
                    query: GET_WIDGETS,
                    variables: { userId: props.userId }
                  });
                  cache.writeQuery({
                    query: GET_WIDGETS,
                    variables: { userId: props.userId },
                    data: {
                      widgets: [...widgets].filter((widget) => {
                        return widget.id != deleteWidget.id;
                      })
                    }
                  });
                }}
              >
                {(deleteWidget) => (
                  <React.Fragment>
                    <button
                      type="button"
                      id={`widget-delete-${props.widget.id}`}
                      className="btn btn-icon btn-flat btn-rounded"
                      data-toggle="card-delete"
                      onClick={() =>
                        deleteWidget({
                          variables: {
                            widgetId: props.widget.id,
                            userId: props.userId
                          },
                          optimisticResponse: {
                            __typename: "Mutation",
                            deleteWidget: {
                              __typename: "Widget",
                              id: props.widget.id
                            }
                          }
                        })
                      }
                    >
                      <FontAwesomeIcon icon={["fal", "times"]} />
                    </button>
                  </React.Fragment>
                )}
              </Mutation>
            </li>
            <UncontrolledTooltip
              placement="top"
              target={`widget-delete-${props.widget.id}`}
              delay={{ show: 0, hide: 0 }}
            >
              Delete this widget
            </UncontrolledTooltip>
          </ul>
        </div>
        <div className="card-heading">
          <h4 className="card-title">{props.widget.name}</h4>
        </div>

        <div
          className="card-body"
          style={props.widget.size == 4 ? { overflow: "auto" } : {}}
        >
          <ComponentLoader widget={props.widget} />
        </div>
      </div>
    </div>
  );
});
