import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getDashboard } from "./codegen/getDashboard";

const GET_DASHBOARD = gql`
  query getDashboard {
    dashboard {
      id
      accountName
      setupStatus
      dashboardContent
    }
  }
`;

export const GetDashboardQuery = (
  props: Omit<QueryComponentOptions<getDashboard>, "query">
): JSX.Element => <Query<getDashboard> query={GET_DASHBOARD} {...props} />;
