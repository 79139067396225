import { gql } from "@apollo/client";

const GENERATE_TRACK_REPORT = gql`
  mutation generateTrackReport($trackId: ID!) {
    generateTrackReport(input: { trackId: $trackId }) {
      id
    }
  }
`;

export default GENERATE_TRACK_REPORT;
