import * as React from "react";

import { Search } from "../catalog/manage_members";
import {
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from "reactstrap";
import CREATE_ASSIGNMENT from "../../queries/create_assignment";
import { useRemoveAssignment } from "../../queries/remove_assignment";
import Requirements from "../catalog/groups/requirements";
import * as _ from "lodash";
import Loading from "../loading";
import Error from "../error";
import InfiniteScroll from "react-infinite-scroller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getCourseAssignments_getCourseAssignments,
  getCourseAssignments_getCourseAssignments_courses
} from "../../queries/codegen/getCourseAssignments";

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  ApolloProvider,
  useMutation,
  MutationFunctionOptions
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { cache } from "../../cache";
import {
  GetCourseAssignmentsQuery,
  GET_COURSE_ASSIGNMENTS
} from "../../queries/get_course_assignments";
import { FunctionComponent, useState } from "react";
import StatusCorner from "../catalog/status-corner";
import {
  removeAssignment,
  removeAssignmentVariables
} from "../../queries/codegen/removeAssignment";
import Parser from 'html-react-parser';

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement() {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: (_string: string) => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

export type Requirement = boolean | string;
export type Error = { message: string };

interface Props {
  userId: string;
}

const Course: FunctionComponent<Props> = ({ userId }: Props): JSX.Element => (
  <ApolloProvider client={client}>
    <GetCourseAssignmentsQuery variables={{ userId: userId, limit: 15 }}>
      {({ loading, error, data, fetchMore }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        return (
          <CourseBody
            data={data.getCourseAssignments}
            userId={userId}
            onLoadMore={async () => {
              await fetchMore({
                query: GET_COURSE_ASSIGNMENTS,
                variables: {
                  userId: userId,
                  offset: data.getCourseAssignments.courses.length,
                  limit: 15
                }
              });
            }}
          />
        );
      }}
    </GetCourseAssignmentsQuery>
  </ApolloProvider>
);

export default Course;

const CourseBody = ({
  userId,
  data,
  onLoadMore
}: {
  userId: string;
  data: getCourseAssignments_getCourseAssignments;
  onLoadMore: () => void;
}) => {
  const [currentCourseId, setCurrentCourseId] = useState<number | null>(null);
  const [requirement, setRequirement] = useState<Requirement>(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
    setRequirement(false);
    setError(null);
  };

  const selectItemFromSearch = (item) => {
    setCurrentCourseId(item.id);
    toggleModal();
  };

  const createAssignment = () => {
    const result = client.mutate({
      mutation: CREATE_ASSIGNMENT,
      variables: {
        assignment: Object.assign(
          { courseId: currentCourseId, userId: userId },
          translateRequirement()
        )
      },
      refetchQueries: [
        {
          query: GET_COURSE_ASSIGNMENTS,
          variables: { userId: userId, limit: 15 }
        }
      ]
    });
    result.then(({ data }) => {
      toggleModal();
    });
    result.catch(({ graphQLErrors }) => {
      setError(graphQLErrors[0]);
    });
  };

  const translateRequirement = () => {
    let required = false;
    let requiredDate,
      requiredInterval,
      requiredBeginDays = null;

    if (typeof requirement === "boolean") {
      required = requirement;
    } else if (typeof requirement === "string") {
      required = true;
      requiredDate = requirement;
    }

    return {
      required: required,
      requiredDate: requiredDate,
      requiredInterval: requiredInterval,
      requiredBeginDays: requiredBeginDays
    };
  };

  return (
    <React.Fragment>
      <Card className="mt-4">
        <CardBody>
          <CardTitle>Assign Courses</CardTitle>
          <Search
            onItemClick={(item) => selectItemFromSearch(item)}
            client={client}
            type={["course"]}
            existingIds={[]}
          />
          <div className="mt-4">
            <InfiniteScroll
              loadMore={onLoadMore}
              hasMore={data.moreRecords}
              loader={<Loading key={0} />}
              threshold={500}
              useWindow={true}
            >
              <List
                courseAssignments={data}
                userId={userId}
                courses={data.courses}
                url={"/catalog"}
              />
              {!data.moreRecords && (
                <div className="alert alert-info">
                  There are no more courses for this user.
                </div>
              )}
            </InfiniteScroll>
          </div>
        </CardBody>
      </Card>
      <Modal
        className="modal-dialog modal-lg"
        isOpen={modalIsOpen}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}>
          Create Course Requirement
        </ModalHeader>
        <ModalBody>
          <div className="padding-15">
            {error && <div className="alert alert-danger">{error.message}</div>}
            <Requirements
              updateRequirement={({
                requirement: newRequirement
              }: {
                requirement: Requirement;
              }) => setRequirement(newRequirement)}
              requirement={requirement}
              hideInterval={true}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary float-right"
            onClick={createAssignment}
          >
            Create Requirement
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

const List = ({
  courses,
  courseAssignments,
  url,
  userId
}: {
  courses: getCourseAssignments_getCourseAssignments_courses[];
  courseAssignments: getCourseAssignments_getCourseAssignments;
  url: string;
  userId: string;
}): JSX.Element => {
  const [removeAssignment] = useRemoveAssignment(courseAssignments);

  if (courses.length > 0) {
    return (
      <React.Fragment>
        {courses.map((course) => {
          return (
            <ListBody
              key={course.id}
              userId={userId}
              course={course}
              removeAssignment={removeAssignment}
              url={url}
            />
          );
        })}
      </React.Fragment>
    );
  } else {
    return <div className="alert alert-info">There are no courses.</div>;
  }
};

const ListBody = ({
  course,
  removeAssignment,
  url,
  userId
}: {
  course: getCourseAssignments_getCourseAssignments_courses;
  removeAssignment: (
    options: MutationFunctionOptions<
      removeAssignment,
      removeAssignmentVariables
    >
  ) => Promise<unknown>;
  userId: string;
  url: string;
}): JSX.Element => {
  return (
    <div className="courselist-item">
      <div className="courselist-thumbnail">
        <div className="embed-responsive embed-responsive-16by9">
          <div className="embed-responsive-item">
            {course.thumbnail ? (
              <a href={url + "/courses/" + course.id} data-turbolinks="false">
                <img
                  className="mr-3 img-fluid border"
                  src={course.thumbnail}
                  alt={course.name}
                />
              </a>
            ) : (
              <Card className="bg-light">
                <CardBody className="text-center">
                  <a
                    href={url + "/courses/" + course.id}
                    data-turbolinks="false"
                    className="text-dark"
                  >
                    <FontAwesomeIcon
                      icon={["fal", "chalkboard-teacher"]}
                      size="6x"
                    />
                  </a>
                </CardBody>
              </Card>
            )}
          </div>
        </div>
        {course.duration && (
          <div className="courselist-length">{course.duration}</div>
        )}
      </div>
      <div className="courselist-details">
        <h5 className="mt-0">
          <a href={url + "/courses/" + course.id} data-turbolinks="false">
            {course.name}
          </a>
          <React.Fragment>
            <button
              id={`remove-requirement-${course.id}`}
              type="button"
              className="ml-2 btn btn-sm btn-danger"
              onClick={() =>
                removeAssignment({
                  variables: { courseId: course.id, userId: userId }
                })
              }
            >
              <FontAwesomeIcon icon={["fal", "trash"]} />
            </button>
            <UncontrolledTooltip
              placement="top"
              target={`#remove-requirement-${course.id}`}
              delay={{ show: 0, hide: 0 }}
            >
              Remove Requirement
            </UncontrolledTooltip>
          </React.Fragment>
        </h5>
        <div className="courselist-description">{Parser(course.fullDescription)}</div>
      </div>
      {course.statusIcon && (
        <StatusCorner
          icon={course.statusIcon.icon}
          look={course.statusIcon.style}
          tooltip={course.statusIcon.tooltip}
          key_id={course.id}
        />
      )}
    </div>
  );
};
