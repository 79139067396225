import React from "react";
import ReactDOM from "react-dom";

const sizes = ["sm"];

function ProgressCircle(props) {
  const size = sizes.includes(props.size) ? `--${props.size}` : "";

  return (
    <div className="progress-circle">
      <div className="progress-circle__title">{props.progress}%</div>
      <svg
        className="progress-circle__image"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-5 -5 42 42"
      >
        <circle
          className="progress-circle__image__background"
          cx="16"
          cy="16"
          r="16"
          fill="transparent"
          strokeWidth="5"
        />

        <circle
          className="progress-circle__image__foreground"
          cx="16"
          cy="16"
          r="16"
          fill="transparent"
          strokeWidth="5"
          strokeDasharray={`${props.progress} 100`}
        />
      </svg>
    </div>
  );
}
export default ProgressCircle;
