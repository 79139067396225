import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getContent, getContentVariables } from "./codegen/getContent";

const GET_CONTENT = gql`
  query getContent($id: ID!) {
    getContent(id: $id) {
      id
      name
      playlist {
        mediaid
        title
        duration
        image
        tracks {
          id
          file
          label
          kind
        }
        sources {
          id
          file
          label
          duration
        }
      }
    }
  }
`;

export const GetContentQuery = (
  props: Omit<QueryComponentOptions<getContent, getContentVariables>, "query">
): JSX.Element => (
  <Query<getContent, getContentVariables> query={GET_CONTENT} {...props} />
);
