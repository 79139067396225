import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getTotalViewsChart,
  getTotalViewsChartVariables
} from "./codegen/getTotalViewsChart";

export const CHARTS_TOTAL_VIEWS = gql`
  query getTotalViewsChart($campusId: ID) {
    charts_total_views(campusId: $campusId) {
      id
      viewDate
      startCount
      completedCount
      examCount
    }
  }
`;

export const GetChartsTotalViewsQuery = (
  props: Omit<
    QueryComponentOptions<getTotalViewsChart, getTotalViewsChartVariables>,
    "query"
  >
): JSX.Element => (
  <Query<getTotalViewsChart, getTotalViewsChartVariables>
    query={CHARTS_TOTAL_VIEWS}
    {...props}
  />
);
