import * as React from "react";
import CREATE_CLASSROOM_LOCATION from "../../queries/create_classroom_location";
import GET_LOCATIONS from "../../queries/get_locations";
import Fancy from "../forms/fancy";
import { Fields } from "./fields";
export const CreateLocationForm = ({ toggleForm, addLocation }) => (
  <Fancy
    mutation={CREATE_CLASSROOM_LOCATION}
    variables={{
      classroomLocation: {
        name: "",
        equipmentAvailable: "",
        address: "",
        capacity: null
      }
    }}
    onComplete={{
      message: "",
      callback: ({ createClassroomLocation }) => {
        if (createClassroomLocation) {
          toggleForm();
        }
      }
    }}
    refetchQueries={[{ query: GET_LOCATIONS }]}
    update={(cache, { createClassroomLocation }) => {
      addLocation(createClassroomLocation.classroomLocation);
    }}
    render={({ errors, onChange }) => (
      <Fields
        errors={errors}
        onChange={onChange}
        cancel={(event) => {
          toggleForm();
        }}
      />
    )}
  />
);
