import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getRecommendedCourses } from "./codegen/getRecommendedCourses";

export const CATALOG_COURSES_RECOMMENDED = gql`
  query getRecommendedCourses {
    catalog_courses_recommended {
      id
      name
      shortDescription
      thumbnail
      duration
      statusIcon {
        id
        icon
        style
        tooltip
      }
    }
  }
`;

export const GetCatalogCoursesRecommendedQuery = (
  props: Omit<QueryComponentOptions<getRecommendedCourses>, "query">
): JSX.Element => (
  <Query<getRecommendedCourses>
    query={CATALOG_COURSES_RECOMMENDED}
    {...props}
  />
);
