import React, { CSSProperties } from "react";
import ReactDOM from "react-dom";
import moment, { MomentInput } from "moment";
import _ from "lodash";
import pluralize from "pluralize";

interface Props {
  count: number;
  date: MomentInput;
  style?: CSSProperties;
}

function GroupFooter(props: Props) {
  let color: string;
  let content: string;

  if (props.count > 0) {
    if (props.date) {
      if (moment(props.date).isBefore(moment())) {
        color = "danger";
        content = `Past Due: ${moment(props.date).format("MMMM Do YYYY")}`;
      } else {
        color = "info";
        content = `Due: ${moment(props.date).format("MMMM Do YYYY")}`;
      }
    } else {
      color = "info";
      content = `${props.count} Required ${pluralize(
        "Course",
        props.count,
        false
      )}`;
    }
  }

  return (
    <div className={`badge badge-${color} text-white`} style={props.style}>
      {content}
    </div>
  );
}
export default GroupFooter;
