import { gql } from "@apollo/client";

const UPDATE_COMMENT = gql`
  mutation updateComment($comment: CommentAttributes!) {
    updateComment(input: { comment: $comment }) {
      comment {
        id
        name
        url
        content
        avatarUrl
        createdAt
        updatedAt
        ancestry
      }
    }
  }
`;

export default UPDATE_COMMENT;
