import { ISO8601Date } from "../../iso8601date";
import * as React from "react";
import { DateTime, Interval } from "luxon";
import Loading from "../loading";
import Error from "../error";
import { last, sortBy, times, uniq } from "lodash";
import {
  AgendaItem,
  ClassroomSessionAgendaItem,
  isClassroomSessionAgendaItem,
  isRequiredCourseAgendaItem,
  RequiredCourseAgendaItem,
  useAgendaItems
} from "../../queries/get_agenda_items";
import { faChalkboardTeacher, faTv } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import colors from "../colors";

const now = DateTime.local().setZone(localStorage.getItem('timeZone'));

export const CalendarWidget = ({
  startDate = now.startOf("day"),
  endDate = now.startOf("day").plus({ days: 14 }),
  errorMessage = "Couldn't load the Calendar Widget"
}: {
  startDate?: DateTime;
  endDate?: DateTime;
  errorMessage?: string;
}): JSX.Element => {
  const [start, setStart] = useState<DateTime>(startDate);
  const [end, setEnd] = useState<DateTime>(endDate);

  const { loading, error, data, fetchMore } = useAgendaItems(
    startDate.toISODate(),
    endDate.toISODate()
  );

  const currentInterval = Interval.fromDateTimes(start, end);

  useEffect(() => {
    void fetchMore({
      variables: { startDate: start.toISODate(), endDate: end.toISODate() }
    });
  }, [fetchMore, start, end]);

  if (loading) return <Loading />;
  if (error) return <Error message={errorMessage} />;

  const items: (AgendaItem & {
    date: ISO8601Date;
  })[] = data.agendaItems
    .map((item):
      | (AgendaItem & {
          date: ISO8601Date;
        })
      | (AgendaItem & {
          date: ISO8601Date;
        })[] => {
      if (isRequiredCourseAgendaItem(item)) {
        // Create a uniform interface to sort all items by
        return { date: item.dueDate, ...item };
      }

      const startTime = DateTime.fromISO(item.startTime);
      const endTime = DateTime.fromISO(item.endTime);

      const numberOfDatesOnCalendar = Interval.fromDateTimes(
        startTime,
        endTime
      ).count("days");

      return times(numberOfDatesOnCalendar, (index): AgendaItem & {
        date: ISO8601Date;
      } => {
        const date: ISO8601Date = startTime
          .startOf("day")
          .plus({ days: index })
          .toISODate();

        return { date, ...item };
      });
    })
    .flat();

  const sortedItems = sortBy(items, (item) => item.date);

  const uniqueDates = uniq(sortedItems.map(({ date }) => date));

  const uniqueDateTimes = uniqueDates.map((date) => DateTime.fromISO(date));

  // This is a bit clunky, but it lets us display an understandable message if
  // there aren't any agenda items when this component initially loads:
  const lastDateTime = last(uniqueDateTimes) || startDate.minus({ days: 1 });

  return (
    <div className="calendar-event" style={{ paddingBottom: 20 }}>
      <ul data-testid="events" className="event-list">
        {}
        {uniqueDates.map((date) => (
          <div data-testid={date} key={date}>
            <strong>{DateTime.fromISO(date).toFormat("MMMM d")}</strong>
            <div style={{ marginLeft: "10px" }}>
              {sortedItems
                .filter((item) => item.date === date)
                .map((item, index) => {
                  if (isClassroomSessionAgendaItem(item)) {
                    return <ClassroomSessionListItem {...item} key={index} />;
                  } else {
                    return <RequiredCourseListItem {...item} key={index} />;
                  }
                })}
            </div>
          </div>
        ))}
        {!currentInterval.contains(lastDateTime) && (
          <div
            className="event-items"
            data-testid={`${lastDateTime
              .plus({ days: 1 })
              .toISODate()}-${end.toISODate()}`}
          >
            <strong>
              {lastDateTime.plus({ days: 1 }).toFormat("MMMM d")}–
              {end.toFormat("MMMM d")}
            </strong>
            <div style={{ marginLeft: "10px" }}>
              <span className="bullet default"></span>
              <span style={{ paddingLeft: 10 }}>Nothing on the agenda</span>
            </div>
          </div>
        )}
      </ul>
      <button
        onClick={() => {
          setStart(start.plus({ days: 14 }));
          setEnd(end.plus({ days: 14 }));
        }}
        className="btn btn-info btn-sm"
        data-testid="load-next-14-days"
      >
        Load next 14 days
      </button>
    </div>
  );
};

const RequiredCourseListItem = ({
  date,
  link,
  name
}: RequiredCourseAgendaItem & { date: ISO8601Date }) => {
  return (
    <li
      key={`${date}-${link}`}
      data-testid={`${date}-${link}`}
      className="event-items"
    >
      <a href={link}>
        <span
          className="bullet info"
          style={{ borderColor: colors.info }}
        ></span>
        <span className="event-name">
          <FontAwesomeIcon icon={faTv} /> {name}
        </span>
        <div className="event-detail">
          <em>due by this date</em>
        </div>
      </a>
    </li>
  );
};

const ClassroomSessionListItem = ({
  date,
  link,
  name,
  waitlisted,
  location,
  startTime,
  endTime
}: ClassroomSessionAgendaItem & { date: ISO8601Date }) => {
  const DateTimeLabel = ({
    startTime,
    endTime,
    currentDate
  }: {
    startTime: DateTime;
    endTime: DateTime;
    currentDate: DateTime;
  }) => {
    const timeZone = localStorage.getItem('timeZone');
    if (currentDate > startTime) {
      return <span>continued</span>;
    } else if (startTime.setZone(timeZone).startOf("day") === endTime.setZone(timeZone).startOf("day")) {
      return (
        <span>
          from {startTime.setZone(timeZone).toFormat("h:mm a")} until {endTime.setZone(timeZone).toFormat("h:mm a")}
        </span>
      );
    } else {
      return <span>starting at {startTime.setZone(timeZone).toFormat("h:mm a")}</span>;
    }
  };

  return (
    <li
      key={`${date}-${link}`}
      data-testid={`${date}-${link}`}
      className="event-items"
    >
      <a href={link}>
        {waitlisted ? (
          <span
            className="bullet warning"
            data-testid={`${link}-waitlisted`}
          ></span>
        ) : (
          <span className="bullet success"></span>
        )}
        <span className="event-name">
          <FontAwesomeIcon icon={faChalkboardTeacher} /> {name}{" "}
          {waitlisted && <em>(waitlisted)</em>}
        </span>
        <div className="event-detail">
          <DateTimeLabel
            startTime={DateTime.fromISO(startTime)}
            endTime={DateTime.fromISO(endTime)}
            currentDate={DateTime.fromISO(date)}
          />
          —<i>{location}</i>
        </div>
      </a>
    </li>
  );
};
