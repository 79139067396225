import { gql } from "@apollo/client";

const UPDATE_COURSE_TOPICS = gql`
  mutation updateCourseTopics($courseIds: [ID!]!, $topicId: ID!) {
    updateCourseTopics(input: { courseIds: $courseIds, topicId: $topicId }) {
      courseIds
    }
  }
`;

export default UPDATE_COURSE_TOPICS;
