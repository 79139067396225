import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getFeaturedCourses } from "./codegen/getFeaturedCourses";

export const CATALOG_COURSES_FEATURED = gql`
  query getFeaturedCourses {
    catalog_courses_featured {
      id
      name
      shortDescription
      thumbnail
      duration
      statusIcon {
        id
        icon
        style
        tooltip
      }
    }
  }
`;

export const GetCatalogCoursesFeaturedQuery = (
  props: Omit<QueryComponentOptions<getFeaturedCourses>, "query">
): JSX.Element => (
  <Query<getFeaturedCourses> query={CATALOG_COURSES_FEATURED} {...props} />
);
