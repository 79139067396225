import * as React from "react";
import * as ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as $ from "jquery";
import SweetAlert from "sweetalert2-react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip
} from "reactstrap";
import Fancy from "../../forms/fancy";
import { Mutation } from "@apollo/client/react/components";
import { GET_GROUPS } from "../../../queries/get_groups";
import UPDATE_GROUP from "../../../queries/update_group";
import REGENERATE_GROUP from "../../../queries/regenerate_group";
import CLONE_GROUP from "../../../queries/clone_group";
import DELETE_GROUP from "../../../queries/delete_group";
import GENERATE_GROUP_REPORT from "../../../queries/generate_group_report";
import { Input, Textarea } from "../../forms/input";
import { Button } from "../../buttons";
import Loading from "../../loading";
import { ManageGroupMembers } from "../manage_members";
import REMOVE_MEMBERSHIP from "../../../queries/remove_membership";
import CREATE_MEMBERSHIP from "../../../queries/create_membership";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement(): any {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: () => {
        return "";
      }
    };
  }
}

class GroupAdminButtons extends React.PureComponent<
  { url: string; group: any },
  {
    dropdownOpen: boolean;
    editModalIsOpen: boolean;
    cloneModalIsOpen: boolean;
    alertIsOpen: boolean;
    membersModalIsOpen: boolean;
  }
> {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      editModalIsOpen: false,
      cloneModalIsOpen: false,
      alertIsOpen: false,
      membersModalIsOpen: false
    };
  }

  toggleAlert = () => {
    this.setState((prevState) => {
      return { alertIsOpen: !prevState.alertIsOpen };
    });
  };

  toggle = () => {
    this.setState((state) => {
      return {
        dropdownOpen: !state.dropdownOpen
      };
    });
  };

  toggleEditModal = () => {
    this.setState((prevState) => {
      // TODO: when we call this, the modal won't ever open,
      // as setFeedback changes the state on the top level application component.
      // This causes every component under it to unmount, so the setState never gets called correctly.
      // this.props.setFeedback("We just made thing");
      return {
        editModalIsOpen: !prevState.editModalIsOpen
      };
    });
  };

  toggleCloneModal = () => {
    this.setState((prevState) => {
      // TODO: when we call this, the modal won't ever open,
      // as setFeedback changes the state on the top level application component.
      // This causes every component under it to unmount, so the setState never gets called correctly.
      // this.props.setFeedback("We just made thing");
      return {
        cloneModalIsOpen: !prevState.cloneModalIsOpen
      };
    });
  };

  toggleMembersModal = () => {
    this.setState((prevState) => {
      return {
        membersModalIsOpen: !prevState.membersModalIsOpen
      };
    });
  };

  render() {
    const url = this.props.url;
    return (
      <React.Fragment>
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="float-right">
          <DropdownToggle caret color="default" className="btn-rounded">
            <FontAwesomeIcon fixedWidth icon={["fal", "cogs"]} /> Admin
          </DropdownToggle>
          <DropdownMenu>
            {this.props.group.currentUser.canManageReports && (
              <GenerateReport groupId={this.props.group.id} />
            )}
            {this.props.group.currentUser.canCreateGroup && (
              <React.Fragment>
                {!this.props.group.currentAccount.disableProgressWheel && (
                  <React.Fragment>
                    <RegenerateProgress group={this.props.group} />
                    <div className="dropdown-item">
                      <a href={`${url}/member_progress`} style={{ color: "#212529" }}>
                        <FontAwesomeIcon fixedWidth icon={["fal", "table"]} /> Create Progress Report
                      </a>
                    </div>
                  </React.Fragment>
                )}
                <DropdownItem onClick={this.toggleEditModal}>
                  <FontAwesomeIcon fixedWidth icon={["fal", "pencil"]} /> Edit Group
                </DropdownItem>
                <DropdownItem onClick={this.toggleCloneModal}>
                  <FontAwesomeIcon fixedWidth icon={["fal", "clone"]} /> Clone Group
                </DropdownItem>
                <DeleteGroup
                  group={this.props.group}
                  toggleAlert={this.toggleAlert}
                  alertIsOpen={this.state.alertIsOpen}
                />
                <div className="dropdown-item">
                  <a href={`${url}/edit`} style={{ color: "#212529" }}>
                    <FontAwesomeIcon fixedWidth icon={["fal", "book"]} /> Manage Courses
                  </a>
                </div>
              </React.Fragment>
            )}
            {(this.props.group.currentUser.canCreateGroup ||
              this.props.group.currentUser.canReadReports ||
              this.props.group.ownerId == this.props.group.currentUser.id) && (
              <DropdownItem onClick={this.toggleMembersModal}>
                <FontAwesomeIcon fixedWidth icon={["fal", "users"]} /> Manage Members
              </DropdownItem>
            )}
            {(this.props.group.currentUser.canManageGroup ||
              this.props.group.currentUser.canReadGroups ||
              this.props.group.ownerId == this.props.group.currentUser.id) && (
              <DropdownItem
                onClick={() =>
                  $.ajax({
                    type: "POST",
                    url: "/group_course_exports",
                    headers: { "X-CSRF-Token": currentCsrfToken() },
                    data: { group_id: this.props.group.id }
                  })
                }
              >
                <FontAwesomeIcon fixedWidth icon={["fal", "file-excel"]} /> Export Course List
              </DropdownItem>
            )}
          </DropdownMenu>
        </ButtonDropdown>
        {this.props.group.currentUser.canCreateGroup && (
          <React.Fragment>
            <GroupEditForm
              group={this.props.group}
              toggleModal={this.toggleEditModal}
              modalIsOpen={this.state.editModalIsOpen}
            />
            <CloneGroup
              group={this.props.group}
              toggleModal={this.toggleCloneModal}
              modalIsOpen={this.state.cloneModalIsOpen}
            />
          </React.Fragment>
        )}
        {(this.props.group.currentUser.canCreateGroup ||
          this.props.group.currentUser.canReadReports ||
          this.props.group.ownerId == this.props.group.currentUser.id) && (
          <ManageGroupMembers
            id={this.props.group.id}
            toggleModal={this.toggleMembersModal}
            modalIsOpen={this.state.membersModalIsOpen}
          />
        )}
      </React.Fragment>
    );
  }
}

class GenerateReport extends React.PureComponent<
  {
    groupId: number;
  },
  {}
> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Mutation
        mutation={GENERATE_GROUP_REPORT}
        update={(cache, { data }) => {
          window.location.href = `/reports/forms/${data.generateGroupReport.id}/edit`;
        }}
      >
        {(generateGroupReport) => (
          <React.Fragment>
            <DropdownItem
              onClick={() => {
                generateGroupReport({
                  variables: { groupId: this.props.groupId }
                });
              }}
            >
              <FontAwesomeIcon fixedWidth icon={["fal", "chart-bar"]} /> Generate Report
            </DropdownItem>
          </React.Fragment>
        )}
      </Mutation>
    );
  }
}

class DeleteGroup extends React.PureComponent<
  {
    group: any;
    toggleAlert: () => void;
    alertIsOpen: boolean;
  },
  {}
> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Mutation
        mutation={DELETE_GROUP}
        refetchQueries={[{ query: GET_GROUPS }]}
        update={() => {
          window.location.href = "/catalog/groups";
        }}
      >
        {(deleteGroup) => (
          <React.Fragment>
            <DropdownItem onClick={this.props.toggleAlert}>
              <FontAwesomeIcon fixedWidth icon={["fal", "trash"]} /> Delete Group
            </DropdownItem>
            <SweetAlert
              show={this.props.alertIsOpen}
              title="Delete Group"
              text="Are you sure you want to delete this group?"
              showCancelButton={true}
              onCancel={() => {
                this.props.toggleAlert();
              }}
              onConfirm={() => {
                this.props.toggleAlert();
                deleteGroup({
                  variables: {
                    groupId: this.props.group.id
                  }
                });
              }}
            />
          </React.Fragment>
        )}
      </Mutation>
    );
  }
}

function RegenerateProgress(props) {
  return (
    <Mutation mutation={REGENERATE_GROUP}>
      {(regenerateGroup) => (
        <DropdownItem
          onClick={() =>
            regenerateGroup({
              variables: { groupId: props.group.id }
            })
          }
        >
          <FontAwesomeIcon fixedWidth icon={["fal", "sync"]} /> Regenerate Progress
        </DropdownItem>
      )}
    </Mutation>
  );
}

class GroupEditForm extends React.PureComponent<
  { group: any; toggleModal: () => void; modalIsOpen: boolean },
  { name: String }
> {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.group.name
    };
  }

  onNameChange(e, onChange) {
    this.setState({ ...this.state, name: e.target.value });
    onChange(e);
  }

  render() {
    return (
      <Modal
        className="modal-dialog modal-lg"
        isOpen={this.props.modalIsOpen}
        toggle={this.props.toggleModal}
      >
        <ModalHeader toggle={this.props.toggleModal}>Update {this.props.group.name}</ModalHeader>
        <ModalBody>
          <div className="padding-15">
            <Fancy
              mutation={UPDATE_GROUP}
              onComplete={{
                message: "Your group has been updated",
                callback: (e) => {
                  if (e.updateGroup !== null) {
                    this.props.toggleModal();
                  }
                }
              }}
              variables={{
                group: {
                  id: this.props.group.id,
                  name: this.props.group.name,
                  description: this.props.group.description,
                  active: this.props.group.active
                }
              }}
              render={({ errors, onChange }) => {
                return (
                  <React.Fragment>
                    <Input
                      onChange={(e) => this.onNameChange(e, onChange)}
                      field="name"
                      errors={errors}
                      value={this.state.name}
                    >
                      Name
                    </Input>
                    <Textarea
                      onChange={onChange}
                      defaultValue={this.props.group.description}
                      field="description"
                      errors={errors}
                    >
                      Description
                    </Textarea>

                    <Button disabled={this.state.name.length <= 0} primary>
                      Update Group
                    </Button>
                  </React.Fragment>
                );
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

class CloneGroup extends React.PureComponent<
  {
    group: any;
    toggleModal: () => void;
    modalIsOpen: boolean;
  },
  {}
> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal
        className="modal-dialog modal-lg"
        isOpen={this.props.modalIsOpen}
        toggle={this.props.toggleModal}
      >
        <ModalHeader toggle={this.props.toggleModal}>Clone {this.props.group.name}</ModalHeader>
        <ModalBody>
          <div className="padding-15">
            <p>Edit this name and description for your newly cloned group.</p>
            <Fancy
              mutation={CLONE_GROUP}
              onComplete={{
                message: "We've cloned your group and we're copying over courses to it right now.",
                callback: this.props.toggleModal
              }}
              refetchQueries={[{ query: GET_GROUPS }]}
              variables={{
                group: {
                  id: this.props.group.id,
                  name: this.props.group.name,
                  description: this.props.group.description,
                  active: this.props.group.active
                }
              }}
              render={({ errors, onChange }) => {
                return (
                  <React.Fragment>
                    <Input
                      onChange={onChange}
                      defaultValue={this.props.group.name}
                      field="name"
                      errors={errors}
                    >
                      Name
                    </Input>
                    <Textarea
                      onChange={onChange}
                      defaultValue={this.props.group.description}
                      field="description"
                      errors={errors}
                    >
                      Description
                    </Textarea>

                    <Button primary>Clone Group</Button>
                  </React.Fragment>
                );
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default GroupAdminButtons;
