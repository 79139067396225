import * as React from "react";
import Loading from "../../loading";
import Error from "../../error";
import TopicListItem from "./topic_list_item";
import {
  GetTopicsQuery,
  GET_TOPICS,
  Topic,
  TopicsCurrentUser
} from "../../../queries/get_topics";
import CREATE_TOPIC from "../../../queries/create_topic";
import InfiniteScroll from "react-infinite-scroller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Fancy from "../../forms/fancy";
import { Input, Textarea } from "../../forms/input";
import { Button } from "../../buttons";

const TopicList = ({ type }: { type: string }): JSX.Element => {
  return (
    <GetTopicsQuery>
      {({ loading, error, data, fetchMore }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        const { topics, currentUser, moreRecords } = data.getTopics;

        return (
          <TopicListBody
            topics={topics}
            currentUser={currentUser}
            moreRecords={moreRecords}
            type={type}
            onLoadMore={() => {
              void fetchMore({
                variables: { offset: data.getTopics.topics.length }
              });
            }}
          />
        );
      }}
    </GetTopicsQuery>
  );
};

export default TopicList;

const TopicListBody = ({
  onLoadMore,
  topics,
  type,
  currentUser,
  moreRecords
}: {
  onLoadMore: () => void;
  topics: Topic[];
  type: string;
  currentUser: TopicsCurrentUser;
  moreRecords: boolean;
}) => (
  <div
    className="email-list-wrapper scrollable"
    style={{ overflowX: "hidden" }}
  >
    {currentUser.canUpdateContent && (
      <div className="list-view-group-container">
        <div className="email-list-tools" style={{ textAlign: "initial" }}>
          <NewTopic />
        </div>
      </div>
    )}
    <InfiniteScroll
      loadMore={onLoadMore}
      hasMore={moreRecords}
      loader={<Loading key={0} />}
      useWindow={false}
    >
      <div className="list-view-group-container">
        <ul className="email-list-item">
          {topics.map((topic) => {
            return <TopicListItem type={type} key={topic.id} item={topic} />;
          })}
        </ul>
        {!moreRecords && (
          <p className="text-center pt-3">
            You have reached the end of the list.
          </p>
        )}
      </div>
    </InfiniteScroll>
  </div>
);

class NewTopic extends React.PureComponent<
  Record<string, unknown>,
  { modalIsOpen: boolean }
> {
  constructor(props: Record<string, unknown>) {
    super(props);

    this.state = {
      modalIsOpen: false
    };
  }

  toggleModal = () => {
    this.setState((state) => {
      return { modalIsOpen: !state.modalIsOpen };
    });
  };

  render() {
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-link"
          onClick={this.toggleModal}
        >
          <FontAwesomeIcon fixedWidth icon={["fal", "plus"]} /> Create a Topic
        </button>
        <Modal
          className="modal-dialog modal-lg"
          isOpen={this.state.modalIsOpen}
          toggle={this.toggleModal}
        >
          <ModalHeader toggle={this.toggleModal}>Create a Topic</ModalHeader>
          <ModalBody>
            <div className="padding-15">
              <Fancy
                mutation={CREATE_TOPIC}
                onComplete={{
                  message: "Your topic has been created.",
                  callback: ({ createTopic }) => {
                    if (createTopic) {
                      this.toggleModal();
                    }
                  }
                }}
                update={(cache, data) => {
                  window.location.href = `/catalog/topics/${data.createTopic.topic.slug}`;
                }}
                refetchQueries={[{ query: GET_TOPICS }]}
                variables={{
                  topic: {
                    name: null,
                    description: null
                  }
                }}
                render={({ errors, onChange }) => {
                  return (
                    <React.Fragment>
                      <Input onChange={onChange} field="name" errors={errors}>
                        Name
                      </Input>
                      <Textarea
                        onChange={onChange}
                        field="description"
                        errors={errors}
                      >
                        Description
                      </Textarea>

                      <Button primary>Create Topic</Button>
                    </React.Fragment>
                  );
                }}
              />
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
