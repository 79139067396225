import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default (fullProps: any) => {
  // Avoid passing fixedWidth twice
  const { fixedWidth, icon, ...props } = fullProps;

  if (typeof icon === "string") {
    return <FontAwesomeIcon {...props} icon={["fal", icon]} fixedWidth />;
  } else {
    return <FontAwesomeIcon {...props} icon={icon} fixedWidth />;
  }
};
