import * as React from "react";
import Loading from "../../loading";
import Error from "../../error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopicAdminButtons from "./topic-admin-buttons";
import List from "../courses/list";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { GET_TOPIC, GetTopicQuery, Topic } from "../../../queries/get_topic";
import InfiniteScroll from "react-infinite-scroller";

const TopicContainer = ({
  id,
  url
}: {
  id: string;
  url: string;
}): JSX.Element => (
  <GetTopicQuery variables={{ id: id, limit: 15 }} fetchPolicy="network-only">
    {({ loading, error, data, fetchMore }) => {
      if (loading) return <Loading />;
      if (error) return <Error />;

      document.title = `Thinkzoom - Topics: ${data.getTopic.name}`;
      return (
        <TopicBody
          topic={data.getTopic}
          url={url}
          onLoadMore={() => {
            void fetchMore({
              query: GET_TOPIC,
              variables: {
                id: id,
                offset: data.getTopic.courses.length,
                limit: 15
              }
            });
          }}
        />
      );
    }}
  </GetTopicQuery>
);

export default TopicContainer;

const TopicBody = ({
  topic,
  onLoadMore,
  url
}: {
  topic: Topic;
  onLoadMore: () => void;
  url: string;
}) => (
  <InfiniteScroll
    loadMore={onLoadMore}
    hasMore={topic.moreRecords}
    loader={<Loading key={0} />}
    threshold={500}
    useWindow={false}
  >
    {(topic.currentUser.canManageReports ||
      topic.currentUser.canManageContent) && (
      <TopicAdminButtons url={url} topic={topic} />
    )}
    <h1 className="clearfix">
      <FontAwesomeIcon icon={["fal", "book"]} /> {topic.name}
    </h1>
    <div className="row">
      {topic.related.map(function (related, index) {
        return (
          <div className="col" key={index}>
            <div
              className="card series-card bg-info"
              style={{
                background: `linear-gradient(rgba(15,152,237,0.9), rgba(15,152,237,0.9)), url($
                  related.thumbnail
                })`
              }}
            >
              <a
                href={"/catalog/topics/" + related.slug}
                className="text-white my-auto px-3"
              >
                {related.name}
              </a>
            </div>
          </div>
        );
      })}
    </div>
    <List
      data={topic.courses}
      url={url.replace(/^\/catalog\/packages\/\d+/, "/catalog")}
    />
    {!topic.moreRecords && (
      <div className="alert alert-info">
        There are no more courses for this topic.
      </div>
    )}
  </InfiniteScroll>
);
