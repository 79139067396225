import { gql } from "@apollo/client";

const CREATE_TRACK = gql`
  mutation createTrack($track: TrackAttributes!) {
    createTrack(input: { track: $track }) {
      track {
        id
        name
        description
        published
        availableToDescendants
      }
    }
  }
`;

export default CREATE_TRACK;
