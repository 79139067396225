import { gql } from "@apollo/client";

const UPDATE_COURSE_FORM = gql`
  mutation updateCourseForm($courseForm: CourseFormAttributes!) {
    updateCourseForm(input: { courseForm: $courseForm }) {
      id
    }
  }
`;

export default UPDATE_COURSE_FORM;
