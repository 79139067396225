import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import * as React from "react";
import { getTracks, getTracksVariables } from "./codegen/getTracks";

export const GET_TRACKS = gql`
  query getTracks($offset: Int, $limit: Int) {
    getTracks(offset: $offset, limit: $limit) {
      moreRecords
      tracks {
        id
        name
        description
        courseCount
        progress
        enrolled
        published
      }
      currentUser {
        id
        canManageTracks
        canManageReseller
      }
    }
  }
`;

export const GetTracksQuery = (
  props: Omit<QueryComponentOptions<getTracks, getTracksVariables>, "query">
): JSX.Element => (
  <Query<getTracks, getTracksVariables> query={GET_TRACKS} {...props} />
);
