import { gql } from "@apollo/client";

const REMOVE_CLASSROOM_LOCATION = gql`
  mutation removeClassroomLocation($id: ID!) {
    removeClassroomLocation(input: { id: $id }) {
      id
    }
  }
`;

export default REMOVE_CLASSROOM_LOCATION;
