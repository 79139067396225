import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getFavoriteCourses } from "./codegen/getFavoriteCourses";

export const CATALOG_COURSES_FAVORITE = gql`
  query getFavoriteCourses {
    catalog_courses_favorite {
      id
      name
      shortDescription
      thumbnail
      duration
      statusIcon {
        id
        icon
        style
        tooltip
      }
    }
  }
`;

export const GetCatalogCoursesFavoriteQuery = (
  props: Omit<QueryComponentOptions<getFavoriteCourses>, "query">
): JSX.Element => (
  <Query<getFavoriteCourses> query={CATALOG_COURSES_FAVORITE} {...props} />
);
