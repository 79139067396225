import { gql } from "@apollo/client";

const MARK_NOTIFICATION_AS_READ = gql`
  mutation markNotificationAsRead($ids: [ID!]!) {
    markNotificationAsRead(input: { ids: $ids }) {
      ids
    }
  }
`;

export default MARK_NOTIFICATION_AS_READ;
