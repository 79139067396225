import { gql } from "@apollo/client";

const CREATE_CLASSROOM_LOCATION = gql`
  mutation createClassroomLocation(
    $classroomLocation: ClassroomLocationAttributes!
  ) {
    createClassroomLocation(input: { classroomLocation: $classroomLocation }) {
      classroomLocation {
        id
        name
        equipmentAvailable
        address
        capacity
      }
    }
  }
`;

export default CREATE_CLASSROOM_LOCATION;
