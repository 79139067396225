import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getCatalogGroups } from "./codegen/getCatalogGroups";

const GET_CATALOG_GROUPS = gql`
  query getCatalogGroups {
    getCatalogGroups {
      id
      name
      description
      courseCount
      userCount
      progress
      requiredCount
      dueDate
      currentAccount {
        disableProgressWheel
      }
    }
  }
`;

export const GetCatalogGroupsQuery = (
  props: Omit<QueryComponentOptions<getCatalogGroups>, "query">
): JSX.Element => (
  <Query<getCatalogGroups> query={GET_CATALOG_GROUPS} {...props} />
);
