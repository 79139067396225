import * as React from "react";

function Loading(): JSX.Element {
  return (
    <div style={{ textAlign: "center" }} data-testid="loading">
      <img src="/images/loader.svg" style={{ width: "300px" }} />
    </div>
  );
}

export default Loading;
