import * as React from "react";
import Loading from "../../loading";
import Error from "../../error";
import GroupListItem from "./group_list_item";
import {
  GetGroupsQuery,
  GET_GROUPS,
  Group,
  GroupsCurrentAccount,
  GroupsCurrentUser
} from "../../../queries/get_groups";
import CREATE_GROUP from "../../../queries/create_group";
import InfiniteScroll from "react-infinite-scroller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Fancy from "../../forms/fancy";
import { Input, Textarea } from "../../forms/input";
import { Button } from "../../buttons";

function GroupList({ type }: { type: string }): JSX.Element {
  return (
    <GetGroupsQuery>
      {({ loading, error, data, fetchMore }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        const { groups, currentUser, currentAccount, moreRecords } = data.getGroups;

        return (
          <GroupListBody
            type={type}
            groups={groups}
            currentUser={currentUser}
            currentAccount={currentAccount}
            moreRecords={moreRecords}
            onLoadMore={() => {
              void fetchMore({
                variables: { offset: data.getGroups.groups.length }
              });
            }}
          />
        );
      }}
    </GetGroupsQuery>
  );
}

class GroupListBody extends React.PureComponent<{
  groups: Group[];
  currentUser: GroupsCurrentUser;
  currentAccount: GroupsCurrentAccount;
  onLoadMore: () => void;
  moreRecords: boolean;
  type: string;
}> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="email-list-wrapper scrollable" style={{ overflowX: "hidden" }}>
        {this.props.currentUser.canCreateGroup && (
          <div className="list-view-group-container">
            <div className="email-list-tools" style={{ textAlign: "initial" }}>
              <NewGroup />
            </div>
          </div>
        )}
        <InfiniteScroll
          loadMore={this.props.onLoadMore}
          hasMore={this.props.moreRecords}
          loader={<Loading key={0} />}
          useWindow={false}
        >
          <div className="list-view-group-container">
            <ul className="email-list-item">
              {this.props.groups.map((group) => {
                return (
                  <GroupListItem
                    type={this.props.type}
                    key={group.id}
                    item={group}
                    currentAccount={this.props.currentAccount}
                  />
                );
              })}
            </ul>
            {!this.props.moreRecords && (
              <p className="text-center pt-3">You have reached the end of the list.</p>
            )}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}

class NewGroup extends React.PureComponent<{}, { modalIsOpen: boolean; name: String }> {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      name: ""
    };
  }

  toggleModal = () => {
    this.setState((state) => {
      return { modalIsOpen: !state.modalIsOpen };
    });
  };

  onNameChange(e, onChange) {
    this.setState({ ...this.state, name: e.target.value });
    onChange(e);
  }

  render() {
    return (
      <React.Fragment>
        <button type="button" className="btn btn-link" onClick={this.toggleModal}>
          <FontAwesomeIcon fixedWidth icon={["fal", "plus"]} /> Create a Group
        </button>
        <Modal className="modal-dialog modal-lg" isOpen={this.state.modalIsOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Create a Group</ModalHeader>
          <ModalBody>
            <div className="padding-15">
              <Fancy
                mutation={CREATE_GROUP}
                onComplete={{
                  message: "Your group has been created.",
                  callback: (e) => {
                    if (e.createGroup != null) {
                      this.toggleModal();
                    }
                  }
                }}
                update={(cache, data) => {
                  window.location.href = `/catalog/groups/${data.createGroup.group.id}`;
                }}
                refetchQueries={[{ query: GET_GROUPS }]}
                variables={{
                  group: {
                    name: null,
                    description: null
                  }
                }}
                render={({ errors, onChange }) => {
                  return (
                    <React.Fragment>
                      <Input
                        onChange={(e) => this.onNameChange(e, onChange)}
                        field="name"
                        errors={errors}
                        autoComplete="off"
                        value={this.state.name}
                      >
                        Name
                      </Input>
                      <Textarea onChange={onChange} field="description" errors={errors}>
                        Description
                      </Textarea>

                      <Button primary disabled={this.state.name.length <= 0}>
                        Create Group
                      </Button>
                    </React.Fragment>
                  );
                }}
              />
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default GroupList;
