import * as React from "react";
import * as ReactDOM from "react-dom";
import Loading from "../loading";
import Error from "../error";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { GetContentQuery } from "../../queries/get_content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Player from "../catalog/player";
import { Button } from "../buttons";
import { UncontrolledTooltip } from "reactstrap";

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  ApolloProvider
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { cache } from "../../cache";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement() {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: (string: string) => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

class Contents extends React.Component<{ id: string }> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ApolloProvider client={client}>
        <GetContentQuery variables={{ id: this.props.id }}>
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) return <Error />;

            return <ContentBody content={data.getContent} />;
          }}
        </GetContentQuery>
      </ApolloProvider>
    );
  }
}

class ContentBody extends React.Component<{
  content: { name: string; id: string };
}> {
  constructor(props) {
    super(props);
    document.title = `Thinkzoom - Editing ${this.props.content.name}`;
  }

  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <div className="card-title course-card-title">
              <h1 style={{ alignSelf: "flex-end" }}>
                Editing <em>{this.props.content.name}</em>
              </h1>
              <React.Fragment>
                <Button
                  primary
                  id="create-course"
                  onClick={() => {
                    window.location.href = `/course_forms/new?content_id=${this.props.content.id}`;
                  }}
                >
                  Create Course
                </Button>
                <UncontrolledTooltip
                  placement="bottom"
                  target="#create-course"
                  delay={{ show: 0, hide: 0 }}
                >
                  Create a course from this content
                </UncontrolledTooltip>
              </React.Fragment>
            </div>
            <div className="row">
              <div className="col" />
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="tab-info">
              <ul className="nav nav-tabs js-course-tabs" role="tablist">
                <li className="nav-item">
                  <a
                    href="#details"
                    className="nav-link active"
                    role="tab"
                    data-toggle="tab"
                    aria-expanded="true"
                  >
                    Details
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#community"
                    className="nav-link"
                    role="tab"
                    data-toggle="tab"
                    aria-expanded="false"
                  >
                    Community (<span className="js-comments__count" />)
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#downloads"
                    className="nav-link"
                    role="tab"
                    data-toggle="tab"
                    aria-expanded="false"
                  >
                    Downloads ()
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#notes"
                    className="nav-link"
                    role="tab"
                    data-toggle="tab"
                    aria-expanded="false"
                  >
                    Notes (<span className="js-notes__count" />)
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  role="tabpanel"
                  className="tab-pane fade in active show"
                  id="details"
                  aria-expanded="true"
                />
                <div
                  role="tabpanel"
                  className="tab-pane fade"
                  id="community"
                  aria-expanded="false"
                />
                <div
                  role="tabpanel"
                  className="tab-pane fade"
                  id="downloads"
                  aria-expanded="false"
                >
                  <ul className="course-download-list js-studentmaterial-list" />
                </div>
                <div
                  role="tabpanel"
                  className="tab-pane fade"
                  id="notes"
                  aria-expanded="false"
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Contents;
