import { gql } from "@apollo/client";

const UPDATE_WIDGETS = gql`
  mutation updateWidgets($widgetIds: [ID!]!, $userId: ID) {
    updateWidgets(input: { widgetIds: $widgetIds, userId: $userId }) {
      widgets {
        id
        url
        size
        name
        sizeOptions
      }
    }
  }
`;

export default UPDATE_WIDGETS;
