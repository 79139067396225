import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getGroup,
  getGroupVariables,
  getGroup_getGroup,
  getGroup_getGroup_courses
} from "./codegen/getGroup";

export type Group = getGroup_getGroup;
export type GroupCourse = getGroup_getGroup_courses;

export const GET_GROUP = gql`
  query getGroup($id: ID!, $offset: Int, $limit: Int) {
    getGroup(id: $id, offset: $offset, limit: $limit) {
      id
      moreRecords
      name
      description
      active
      courses {
        id
        name
        thumbnail
        duration
        fullDescription
        shortDescription
        completed
        required
        requirement
        statusIcon {
          id
          icon
          style
          tooltip
        }
      }
      currentUser {
        id
        canCreateGroup
        canManageReports
        canManageGroup
        canReadGroups
      }
      currentAccount {
        disableProgressWheel
      }
    }
  }
`;

export const GetGroupQuery = (
  props: Omit<QueryComponentOptions<getGroup, getGroupVariables>, "query">
): JSX.Element => (
  <Query<getGroup, getGroupVariables> query={GET_GROUP} {...props} />
);
