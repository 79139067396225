import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getWidgetForm } from "./codegen/getWidgetForm";

const GET_WIDGET_FORM = gql`
  query getWidgetForm {
    widget_form {
      widgetOptions {
        id
        url
        name
        sizeOptions
        value
        children {
          id
          url
          name
          sizeOptions
        }
      }
      currentWidgets {
        id
        url
        name
        sizeOptions
        value
        children {
          id
          url
          name
          sizeOptions
        }
      }
    }
  }
`;

export const GetWidgetFormQuery = (
  props: Omit<QueryComponentOptions<getWidgetForm>, "query">
): JSX.Element => <Query<getWidgetForm> query={GET_WIDGET_FORM} {...props} />;
