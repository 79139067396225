import { gql } from "@apollo/client";
import * as React from "react";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getNotifications,
  getNotificationsVariables
} from "./codegen/getNotifications";

const GET_NOTIFICATIONS = gql`
  query getNotifications($offset: Int, $limit: Int) {
    getNotifications(offset: $offset, limit: $limit) {
      moreRecords
      notifications {
        id
        subject
        body
        link
        unread
        createdAt
      }
    }
  }
`;

export const GetNotifications = (
  props: Omit<
    QueryComponentOptions<getNotifications, getNotificationsVariables>,
    "query"
  >
): JSX.Element => (
  <Query<getNotifications, getNotificationsVariables>
    query={GET_NOTIFICATIONS}
    {...props}
  />
);

export default GET_NOTIFICATIONS;
