import * as React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  CardTitle
} from "reactstrap";
import Loading from "../loading";
import Error from "../error";
import { GET_WIDGETS } from "../../queries/get_widgets";
import { GetWidgetFormQuery } from "../../queries/get_widget_form";
import CREATE_WIDGET from "../../queries/create_widget";
import { ApolloClient } from "@apollo/client";

type Props = {
  client: ApolloClient<any>;
  toggleModal: () => void;
  modalIsOpen: boolean;
  userId: string;
};

type State = {
  widgetForm: {
    name: any | null;
    size: any | null;
    url: any | null;
    sizeOptions: any | null;
    children: any | null;
    parent: any | null;
  };
};

class WidgetModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      widgetForm: {
        name: null,
        size: null,
        url: null,
        sizeOptions: null,
        children: null,
        parent: null
      }
    };
  }

  setWidgetType = (widgetType) => {
    if (widgetType.children) {
      this.setState({
        widgetForm: Object.assign({}, this.state.widgetForm, {
          children: widgetType.children,
          parent: widgetType.value
        })
      });
    } else {
      this.setState(
        {
          widgetForm: Object.assign({}, this.state.widgetForm, {
            name: widgetType.name,
            url: widgetType.url,
            sizeOptions: widgetType.sizeOptions
          })
        },
        () => {
          if (widgetType.sizeOptions.length == 1) {
            this.setWidgetSize(widgetType.sizeOptions[0]);
          }
        }
      );
    }
  };

  setWidgetSize = (size) => {
    let widget = this.state.widgetForm;
    widget.size = size;

    this.props.client.mutate({
      mutation: CREATE_WIDGET,
      update: (cache, { data: { createWidget } }) => {
        const { widgets } = cache.readQuery({
          query: GET_WIDGETS,
          variables: {
            userId: this.props.userId
          }
        });
        cache.writeQuery({
          query: GET_WIDGETS,
          data: {
            widgets: [createWidget.widget, ...widgets]
          },
          variables: {
            userId: this.props.userId
          }
        });
      },
      variables: {
        size: widget.size,
        name: widget.name,
        url: widget.url,
        sizeOptions: widget.sizeOptions,
        userId: this.props.userId
      }
    });
    this.props.toggleModal();
  };

  render() {
    return (
      <Modal
        className="Modal__Bootstrap modal-dialog modal-lg"
        isOpen={this.props.modalIsOpen}
        toggle={this.props.toggleModal}
      >
        <ModalHeader toggle={this.props.toggleModal}>Add a Widget</ModalHeader>
        <ModalBody>
          <div className="padding-15">
            <GetWidgetFormQuery fetchPolicy="network-only">
              {({ loading, error, data }) => {
                if (loading) return <Loading />;
                if (error) return <Error />;
                return (
                  <WidgetModalBody
                    data={data}
                    widgetForm={this.state.widgetForm}
                    setWidgetType={this.setWidgetType}
                    setWidgetSize={this.setWidgetSize}
                  />
                );
              }}
            </GetWidgetFormQuery>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

function WidgetModalBody(props) {
  let page = null;
  if (props.widgetForm.name) {
    page = (
      <React.Fragment>
        <h4>Choose a size for this widget:</h4>
        <div className="row">
          {props.widgetForm.sizeOptions.map((size, index) => {
            let sizeLabel = "Small";
            //Need to be cast to string here
            switch (size.toString()) {
              case "8":
                sizeLabel = "Medium";
                break;
              case "12":
                sizeLabel = "Large";
                break;
            }
            return (
              <div className={`col-${size}`} key={index}>
                <Card onClick={() => props.setWidgetSize(size)}>
                  <CardBody>
                    <CardTitle>{sizeLabel}</CardTitle>
                  </CardBody>
                </Card>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  } else if (props.widgetForm.children) {
    page = (
      <React.Fragment>
        <h4>Choose a {props.widgetForm.parent} type:</h4>
        <div className="row">
          {props.widgetForm.children.map((widgetType, index) => {
            return (
              <div key={index} className="col">
                <CheckWidgetAvailability
                  currentWidgets={props.data.widget_form.currentWidgets}
                  widgetType={widgetType}
                  setWidgetType={props.setWidgetType}
                >
                  <CardBody>{widgetType.name}</CardBody>
                </CheckWidgetAvailability>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  } else if (props.data) {
    page = (
      <React.Fragment>
        <h4>Choose a widget type:</h4>
        <div className="row">
          {props.data.widget_form.widgetOptions.map((widgetType, index) => {
            return (
              <div key={index} className="col-md-3 col-sm-4">
                <CheckWidgetAvailability
                  currentWidgets={props.data.widget_form.currentWidgets}
                  widgetType={widgetType}
                  setWidgetType={props.setWidgetType}
                >
                  <CardBody>{widgetType.name}</CardBody>
                </CheckWidgetAvailability>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  return page;
}

function CheckWidgetAvailability(props) {
  if (
    props.currentWidgets.find((widget) => widget.url == props.widgetType.url)
  ) {
    return (
      <div
        className="border border-success rounded text-success no-transition"
        style={{ marginBottom: "20px" }}
        onClick={(event) => event.preventDefault()}
      >
        {props.children}
      </div>
    );
  } else {
    return (
      <div
        className="border rounded"
        onClick={() => props.setWidgetType(props.widgetType)}
        style={{ cursor: "pointer", marginBottom: "20px" }}
      >
        {props.children}
      </div>
    );
  }
}

export default WidgetModal;
