import * as React from "react";

import Fancy from "../forms/fancy";
import UPDATE_COURSE_FORM from "../../queries/update_course_form";
import { Input, Textarea } from "../forms/input";
import { Button } from "../buttons";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Wizard from "./wizard";
import Error from "../error";

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  ApolloProvider
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { cache } from "../../cache";

import { GetGroupsQuery } from "../../queries/get_groups";
import { GetTracksQuery } from "../../queries/get_tracks";
import { GetAllTopicsQuery } from "../../queries/get_all_topics";
import arrayMove from "array-move";

import CREATE_CONTENT from "../../queries/create_content";
import swal from "sweetalert2";
import { ExamsSection } from "./exams_section";
import { AssociationsSection } from "./associations_section";
import { ContentsSection } from "./contents_section";
import { OptionsSection } from "./options_section";
import { StartSection } from "./start_section";
import { ScormContentsSection } from "./scorm_contents_section";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement(): any {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: () => {
        return "";
      }
    };
  }
}

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

type Props = {
  id: string;
  userId: string;
  accountId: string;
  courseId: string | null;
  name: string;
  description: string;
  keywords: string;
  surveyUrl: string;
  surveyEnabled: boolean;
  ceuAvailable: boolean;
  restrictToGroup: boolean;
  disableScrubber: boolean;
  expiresAt: string;
  publishedOn: string;
  groups: Token[];
  groupOptions: Token[];
  groupIds: string[];
  topics: Token[];
  topicOptions: Token[];
  topicIds: string[];
  tracks: Token[];
  trackOptions: Token[];
  trackIds: string[];
  contents: Content[];
  contentIds: string[];
  embeddedVideo: string;
  isScorm: boolean;
};

type State = {
  groups: Token[];
  topics: Token[];
  tracks: Token[];
  videoContents: Content[];
  documentContents: Content[];
  embeddedVideo: string;
  persistToCourse: boolean;
  modalIsOpen: boolean;
  onChange: () => void | null;
  embedAlertIsOpen: boolean;
};

export type Token = { label: string; value: string };
export type Content = {
  id: string;
  name: string;
  fileType: string;
  thumbnail: string;
};

export default (props) => (
  <ApolloProvider client={client}>
    <QueryAll Component={CourseForm} {...props} />
  </ApolloProvider>
);

const QueryAll = ({ Component, ...props }) => {
  const withGroups = (containerProps) => (
    <QueryGroups Component={Component} {...containerProps} />
  );

  const withTopics = (containerProps) => (
    <QueryTopics Component={withGroups} {...containerProps} />
  );

  return <QueryTracks Component={withTopics} {...props} />;
};

const QueryTopics = ({ Component, ...props }) => (
  <GetAllTopicsQuery variables={{ limit: 1000 }}>
    {({ loading, error, data }) => {
      if (error) return <Error />;
      if (loading) return <Component topicOptions={[]} {...props} />;
      const topics = data.getAllTopics.topics.map(toTopicSelectOption);
      return <Component topicOptions={topics} {...props} />;
    }}
  </GetAllTopicsQuery>
);

const QueryTracks = ({ Component, ...props }) => (
  <GetTracksQuery variables={{ limit: 1000 }}>
    {({ loading, error, data }) => {
      if (error) return <Error />;
      if (loading) return <Component trackOptions={[]} {...props} />;
      const tracks = data.getTracks.tracks.map(toSelectOption);
      return <Component trackOptions={tracks} {...props} />;
    }}
  </GetTracksQuery>
);

const QueryGroups = ({ Component, ...props }) => (
  <GetGroupsQuery variables={{ limit: 1000 }}>
    {({ loading, error, data }) => {
      if (error) return <Error />;
      if (loading) return <Component groupOptions={[]} {...props} />;
      const groups = data.getGroups.groups.map(toSelectOption);
      return <Component groupOptions={groups} {...props} />;
    }}
  </GetGroupsQuery>
);

const toSelectOption = ({ id, name }: { id: string; name: string }) => ({
  value: id,
  label: name
});

const toTopicSelectOption = ({
  slug,
  name
}: {
  slug: string;
  name: string;
}) => ({
  value: slug,
  label: name
});

class CourseForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      groups: this.props.groups,
      topics: this.props.topics,
      tracks: this.props.tracks,
      videoContents: this.props.contents.filter((content) => {
        return content.fileType == "video";
      }),
      documentContents: this.props.contents.filter((content) => {
        return content.fileType == "document";
      }),
      embeddedVideo: this.props.embeddedVideo,
      modalIsOpen: false,
      persistToCourse: false,
      onChange: null,
      embedAlertIsOpen: false
    };
  }

  updateOptions = (
    options: Pick<State, "groups" | "topics" | "tracks">,
    callback
  ) => this.setState(options, callback);

  removeContent = (contentId, onChange) => {
    this.setState(
      (state) => {
        return {
          videoContents: state.videoContents.filter((content) => {
            return content.id !== contentId;
          }),
          documentContents: state.documentContents.filter((content) => {
            return content.id !== contentId;
          })
        };
      },
      () => {
        this.addContentsToOnchange(onChange);
      }
    );
  };

  removeEmbedVideo = (onChange) => {
    this.setState({ embeddedVideo: null }, () => {
      const target = {
        name: "embeddedVideo",
        type: "string",
        value: null
      };
      onChange({ target });
    });
  };

  toggleModal = () => {
    this.setState((state) => {
      return { modalIsOpen: !state.modalIsOpen };
    });
  };

  toggleEmbedAlert = () => {
    this.setState((state) => {
      return { embedAlertIsOpen: !state.embedAlertIsOpen };
    });
  };

  addContentsToOnchange = (onChange) => {
    const target = {
      name: "contentIds",
      type: "list",
      value: this.state.videoContents
        .map((content) => content.id)
        .concat(this.state.documentContents.map((content) => content.id))
    };
    onChange({ target });
  };

  selectItemFromSearch = (item, onChange) => {
    this.toggleModal();
    if (this.state.embeddedVideo && item.fileType == "video") {
      swal({
        title: "Video Content Conflict",
        text:
          "Adding this video will remove the embedded youtube video. Are you sure you want to add this video content?",
        type: "warning",
        showCancelButton: true
      }).then((result) => {
        if (result.value) {
          this.updateContent(item, onChange);
        }
      });
    } else {
      this.updateContent(item, onChange);
    }
  };

  updateContent = (item, onChange) => {
    const content = {
      id: item.id,
      name: item.name,
      fileType: item.fileType,
      thumbnail: item.thumbnail
    };
    if (content.fileType == "video") {
      this.setState(
        (state) => {
          return {
            videoContents: [...state.videoContents, content],
            embeddedVideo: null
          };
        },
        () => {
          const target = {
            name: "embeddedVideo",
            type: "string",
            value: this.state.embeddedVideo
          };
          onChange({ target });

          this.addContentsToOnchange(onChange);
        }
      );
    } else if (content.fileType == "document") {
      this.setState(
        (state) => {
          return { documentContents: [...state.documentContents, content] };
        },
        () => {
          this.addContentsToOnchange(onChange);
        }
      );
    }
  };

  embedYoutubeLink = (link, onChange) => {
    let video = link;
    if (!new RegExp("watch").exec(video)) {
      const id = new RegExp("[^/]+(?=/$|$)").exec(video)[0];
      video = `https://youtube.com/watch?v=${id}`;
    }

    this.setState({ embeddedVideo: video, videoContents: [] }, () => {
      const target = {
        name: "embeddedVideo",
        type: "string",
        value: this.state.embeddedVideo
      };
      onChange({ target });

      this.addContentsToOnchange(onChange);
    });
  };

  setOnChange = (onChange) => {
    if (!this.state.onChange) {
      this.setState({ onChange: onChange });
    }
  };

  updateVideoContents = ({ oldIndex, newIndex }, onChange) => {
    this.setState(
      (state) => {
        return {
          videoContents: arrayMove(state.videoContents, oldIndex, newIndex)
        };
      },
      () => {
        this.addContentsToOnchange(onChange);
      }
    );
  };

  updateDocumentContents = ({ oldIndex, newIndex }, onChange) => {
    this.setState(
      (state) => {
        return {
          documentContents: arrayMove(
            state.documentContents,
            oldIndex,
            newIndex
          )
        };
      },
      () => {
        this.addContentsToOnchange(onChange);
      }
    );
  };

  render() {
    const { groups, topics, tracks, contents, ...props } = this.props;
    return (
      <View
        groups={this.state.groups}
        topics={this.state.topics}
        tracks={this.state.tracks}
        stateEmbeddedVideo={this.state.embeddedVideo}
        videoContents={this.state.videoContents}
        documentContents={this.state.documentContents}
        updateOptions={this.updateOptions}
        removeContent={this.removeContent}
        removeEmbedVideo={this.removeEmbedVideo}
        toggleModal={this.toggleModal}
        modalIsOpen={this.state.modalIsOpen}
        selectItemFromSearch={this.selectItemFromSearch}
        embedYoutubeLink={this.embedYoutubeLink}
        onChange={this.state.onChange}
        setOnChange={this.setOnChange}
        updateVideoContents={this.updateVideoContents}
        updateDocumentContents={this.updateDocumentContents}
        toggleEmbedAlert={this.toggleEmbedAlert}
        embedAlertIsOpen={this.state.embedAlertIsOpen}
        {...props}
      />
    );
  }
}

// TODO: Make it possible to create a topic

class View extends React.Component<
  {
    groups: any;
    groupOptions: any;
    tracks: any;
    trackOptions: any;
    topics: any;
    topicOptions: any;
    updateOptions: any;
    courseId: string;
    videoContents: any;
    documentContents: any;
    stateEmbeddedVideo: any;
    removeContent: any;
    removeEmbedVideo: any;
    toggleModal: any;
    modalIsOpen: boolean;
    selectItemFromSearch: any;
    embedYoutubeLink: any;
    onChange: any;
    setOnChange: any;
    updateVideoContents: any;
    updateDocumentContents: any;
    embedAlertIsOpen: boolean;
    isScorm: boolean;
    toggleEmbedAlert: () => void;
  },
  { contentModalIsOpen: boolean; file: any }
> {
  constructor(props) {
    super(props);

    this.state = {
      contentModalIsOpen: false,
      file: null
    };
  }

  setFile = (file) => this.setState({ file });

  toggleContentModal = () => {
    this.setState((state) => {
      return { contentModalIsOpen: !state.contentModalIsOpen };
    });
  };

  render() {
    const {
      groups,
      groupOptions,
      tracks,
      trackOptions,
      topics,
      topicOptions,
      updateOptions,
      courseId,
      videoContents,
      documentContents,
      updateVideoContents,
      updateDocumentContents,
      stateEmbeddedVideo,
      removeContent,
      removeEmbedVideo,
      toggleModal,
      modalIsOpen,
      selectItemFromSearch,
      embedYoutubeLink,
      onChange,
      setOnChange,
      embedAlertIsOpen,
      toggleEmbedAlert,
      ...props
    } = this.props;
    return (
      <React.Fragment>
        <Modal
          className="modal-dialog modal-lg"
          isOpen={this.state.contentModalIsOpen}
          toggle={this.toggleContentModal}
        >
          <ModalHeader toggle={this.toggleContentModal}>
            Add Content
          </ModalHeader>
          <ModalBody>
            <div className="padding-15">
              <Fancy
                mutation={CREATE_CONTENT}
                onComplete={{
                  message: "Your content has been created.",
                  callback: () => {}
                }}
                update={(cache, { createContent }) => {
                  this.toggleContentModal();
                  selectItemFromSearch(createContent.content, onChange);
                }}
                variables={{
                  content: {
                    name: null,
                    description: null,
                    file: this.state.file
                  }
                }}
                render={({ errors, onChange }) => {
                  return (
                    <React.Fragment>
                      <Input onChange={onChange} field="name" errors={errors}>
                        Name
                      </Input>
                      <Textarea
                        onChange={onChange}
                        field="description"
                        errors={errors}
                      >
                        Description
                      </Textarea>

                      <Button primary>Create Content</Button>
                    </React.Fragment>
                  );
                }}
              />
            </div>
          </ModalBody>
        </Modal>
        <Fancy
          mutation={UPDATE_COURSE_FORM}
          variables={{ courseForm: props }}
          onComplete={{
            message: "",
            callback: ({ updateCourseForm }) => {
              if (updateCourseForm) {
                const alert = document.getElementById("react-alert");
                alert.innerText = "Your course has been saved.";
                alert.classList.remove("d-none");
                window.location.href = `/catalog/courses/${updateCourseForm.id}`;
              }
            }
          }}
          render={({ errors, onChange, state: { courseForm: courseForm } }) => {
            setOnChange(onChange);
            const Start = () => StartSection({ onChange, errors, courseForm });
            const Options = () =>
              OptionsSection({ courseForm, onChange, errors });
            const ScormContents = () =>
              ScormContentsSection({
                courseForm,
                onChange
              });
            const Contents = () =>
              ContentsSection({
                videoContents,
                onChange,
                removeContent,
                updateVideoContents,
                stateEmbeddedVideo,
                removeEmbedVideo,
                documentContents,
                updateDocumentContents,
                selectItemFromSearch,
                courseForm,
                toggleEmbedAlert,
                embedAlertIsOpen,
                embedYoutubeLink,
                toggleContentModal: this.toggleContentModal,
                setFile: this.setFile
              });
            const Associations = () =>
              AssociationsSection({
                groups,
                groupOptions,
                updateOptions,
                onChange,
                courseForm,
                tracks,
                trackOptions,
                topics,
                topicOptions
              });
            const Exams = () => <ExamsSection />;
            return (
              <div className="card">
                <div className="card-block">
                  <Wizard
                    headings={
                      props.isScorm
                        ? ["Start", "Options", "Contents", "Associations"]
                        : [
                            "Start",
                            "Options",
                            "Contents",
                            "Associations",
                            "Exams"
                          ]
                    }
                    sections={
                      props.isScorm
                        ? [Start(), Options(), ScormContents(), Associations()]
                        : [
                            Start(),
                            Options(),
                            Contents(),
                            Associations(),
                            Exams()
                          ]
                    }
                    finishButton={
                      courseId ? (
                        <button
                          className="btn btn-primary btn-rounded"
                          style={{ float: "right" }}
                        >
                          Update Course
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary btn-rounded"
                          style={{ float: "right" }}
                        >
                          Create Course
                        </button>
                      )
                    }
                  />
                </div>
              </div>
            );
          }}
        />
      </React.Fragment>
    );
  }
}
