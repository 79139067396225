import * as React from "react";

interface Props {
  message?: string;
}

const Error = ({ message }: Props): JSX.Element => (
  <div className="alert alert-danger" data-testid="error">
    {message
      ? message
      : "Your request could not be completed. Please try again later."}
  </div>
);

export default Error;
