import * as React from "react";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  NormalizedCacheObject,
  ApolloProvider
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { cache } from "../../cache";
import { Button } from "../buttons";
import { Card, CardHeader, CardTitle, CardFooter, CardDeck } from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import * as _ from "lodash";
import REMOVE_CLASSROOM_LOCATION from "../../queries/remove_classroom_location";
import { UpdateLocationForm } from "./update_location_form";
import { CreateLocationForm } from "./create_location_form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement(): any {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: () => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

type Props = {
  locations: Location[];
  client: ApolloClient<NormalizedCacheObject>;
};

type State = {
  locations: Location[];
  showForm: boolean;
  activeLocation: Location | null;
};

type Location = {
  id: string;
  name: string;
  equipmentAvailable: string;
  address: string;
  capacity: number;
};

// To avoid loading twice, I don't use a Query component to load the locations.
// Theoretically, I believe you can preload the cache from the server,
// but the process looks super complicated.
// Since this page is pretty focused, just using locations in Props
// to initialize the State seems sufficient.
export default (props) => (
  <ApolloProvider client={client}>
    <Locations client={client} {...props} />
  </ApolloProvider>
);

class Locations extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      locations: this.props.locations,
      showForm: false,
      activeLocation: null
    };
  }

  list = () => {
    const { locations } = this.state;
    if (locations.length !== 0) {
      return _.chunk(locations, 3).map((group, groupIndex) => (
        <div key={`group-${groupIndex}`} className="card-deck">
          {group.map((location, index) => (
            <Card key={`location-${index}`}>
              <CardBody>
                <CardTitle>
                  <FontAwesomeIcon icon={["fal", "building"]} /> {location.name}
                </CardTitle>

                <p>
                  <span className="font-weight-bold">Address:</span>
                  <pre>{location.address}</pre>
                </p>
                <p>
                  <span className="font-weight-bold">Available Equipment:</span>
                  <br />
                  {location.equipmentAvailable}
                </p>
                <p>
                  <span className="font-weight-bold">Capacity:</span>
                  <br />
                  {location.capacity}
                </p>
              </CardBody>
              <CardFooter>
                <button
                  className="btn btn-info"
                  onClick={() => this.setState({ activeLocation: location })}
                >
                  Edit
                </button>{" "}
                <button
                  className="btn btn-danger"
                  onClick={() => this.deleteLocation(location)}
                >
                  Delete
                </button>
              </CardFooter>
            </Card>
          ))}
        </div>
      ));
    } else {
      return (
        <div className="alert alert-info">
          You have not created any locations yet.
        </div>
      );
    }
  };

  addLocation = (location) =>
    this.setState((state) => ({ locations: [location, ...state.locations] }));

  updateLocation = (location) =>
    this.setState((state) => ({
      activeLocation: null,
      locations: state.locations.map((oldLocation) => {
        if (location.id === oldLocation.id) {
          return location;
        } else {
          return oldLocation;
        }
      })
    }));

  deleteLocation = ({ id }) => {
    this.props.client
      .mutate({
        mutation: REMOVE_CLASSROOM_LOCATION,
        variables: { id }
      })
      .then(({ errors }) => {
        if (errors) {
          // TODO: show an error to the user
          console.log(errors);
        } else {
          this.setState((state) => ({
            locations: state.locations.filter(
              (location) => id.toString() !== location.id.toString()
            )
          }));
        }
      });
  };

  toggleForm = () => this.setState((state) => ({ showForm: !state.showForm }));

  render() {
    return (
      <React.Fragment>
        <h1>Manage Locations</h1>

        {!this.state.showForm && (
          <button className="btn btn-primary mb-1" onClick={this.toggleForm}>
            Create a new location
          </button>
        )}

        {this.state.showForm && !this.state.activeLocation && (
          <Card>
            <CardBody>
              <CreateLocationForm
                toggleForm={this.toggleForm}
                addLocation={this.addLocation}
              />
            </CardBody>
          </Card>
        )}

        {this.state.activeLocation && (
          <Card>
            <CardBody>
              <UpdateLocationForm
                updateLocation={this.updateLocation}
                location={this.state.activeLocation}
                cancel={() => this.setState({ activeLocation: null })}
              />
            </CardBody>
          </Card>
        )}

        {this.list()}
      </React.Fragment>
    );
  }
}

export const blankLocation = {
  name: "",
  address: "",
  equipmentAvailable: "",
  capacity: null
};
