import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getLocations } from "./codegen/getLocations";

const GET_LOCATIONS = gql`
  query getLocations {
    getLocations {
      id
      name
    }
  }
`;

export const GetLocationsQuery = (props) => (
  <Query<getLocations> query={GET_LOCATIONS} {...props} />
);

export default GET_LOCATIONS;
