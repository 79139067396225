import { gql } from "@apollo/client";

const CREATE_COURSE_TRACKS = gql`
  mutation createCourseTracks(
    $courseTracks: CourseTrackAttributes!
    $addToEnd: Boolean!
  ) {
    createCourseTracks(
      input: { courseTracks: $courseTracks, addToEnd: $addToEnd }
    ) {
      courses {
        id
        name
        thumbnail
        duration
        fullDescription
        shortDescription
        completed
        required
        statusIcon {
          id
          icon
          style
          tooltip
        }
      }
    }
  }
`;

export default CREATE_COURSE_TRACKS;
