import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getTopFavoritesChart } from "./codegen/getTopFavoritesChart";

export const CHARTS_TOP_FAVORITES = gql`
  query getTopFavoritesChart {
    charts_top_favorites {
      id
      label
      value
      amount
    }
  }
`;

export const GetChartsTopFavoritesQuery = (
  props: Omit<QueryComponentOptions<getTopFavoritesChart>, "query">
): JSX.Element => (
  <Query<getTopFavoritesChart> query={CHARTS_TOP_FAVORITES} {...props} />
);
