import { InMemoryCache, Reference } from "@apollo/client";
import { uniqBy } from "lodash";

export const cacheConfig = {
  typePolicies: {
    Assignments: {
      keyFields: [],
      fields: { users: { merge: mergeReferences } }
    },
    Classroom: {
      fields: { courses: { merge: mergeReferences } }
    },
    ClassroomList: {
      keyFields: [],
      fields: { topics: { merge: mergeReferences } }
    },
    CourseAssignments: {
      keyFields: ["userId"],
      fields: { courses: { merge: mergeReferences } }
    },
    Group: {
      fields: { courses: { merge: mergeReferences } }
    },
    GroupList: {
      keyFields: [],
      fields: { groups: { merge: mergeReferences } }
    },
    PackageTopicList: {
      fields: { topics: { merge: mergeReferences } }
    },
    Query: {
      fields: {
        // Note: the only fields that should need to be defined on Query are
        // fields that are reasonable assume they'll never be paginated.
        // For example, a user probably won't ever need to paginate their
        // widgets, so it's appropriate to place here. Most other fields should
        // instead use a paginated object to store their collections in. See
        // TopicList as an example.
        widgets: { merge: replaceReferences },
        agendaItems: {
          // Don't use startDate and endDate to separate the agendaItems: we
          // want to keep agendaItems we've already queried for and we'll handle
          // how they're displayed on the frontend.
          keyArgs: [],
          merge: mergeReferences
        },
        // FIXME: deprecate these and replace them with paginated objects so we
        // can cache them properly.
        getMemberships: { merge: replaceReferences },
        getTracksUsers: { merge: replaceReferences }
      }
    },
    Topic: {
      fields: { courses: { merge: mergeReferences } }
    },
    TopicList: {
      keyFields: [],
      fields: { topics: { merge: mergeReferences } }
    },
    Track: {
      fields: { courses: { merge: mergeReferences } }
    },
    TrackList: {
      keyFields: [],
      fields: { tracks: { merge: mergeReferences } }
    }
  }
};

export const cache = new InMemoryCache(cacheConfig);

/**
 * When new values come in for a collection, just replace the old collection
 * with the new one.
 *
 * @param _existing existing collection of objects
 * @param incoming incoming collection of objects
 */
function replaceReferences(
  _existing: Reference[],
  incoming: Reference[]
): Reference[] {
  return incoming;
}

/**
 * When new values come in for a collection, just merge them together. Make sure
 * to remove duplicates as well.
 *
 * @param existing existing collection of objects
 * @param incoming incoming collection of objects
 */
function mergeReferences(
  existing: Reference[] = [],
  incoming: Reference[]
): Reference[] {
  return uniqBy([...existing, ...incoming], ({ __ref }) => __ref);
}
