import * as React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressCircle from "./catalog/progress-circle";
import groupStatus from "./catalog/groups/group_status";

const Card = styled.div`
  position: relative;
  border-radius: 3px;
`;

const CardBody = styled.div`
  height: 170px;
  overflow: hidden;
  padding: 1em;
`;

const CardFooter = styled.div`
  line-height: 2em;
  height: 2em;
  color: #fff;
  padding-left: 1em;
`;

const Title = styled.h4`
  font-size: 18px;
`;

const Description = styled.p``;

const Stats = styled.div`
  position: relative;
  padding: 5px 0;
`;

const Stat = styled.div`
  line-height: 21px;
  padding: 0 1em;
`;

const Circle = styled.div`
  position: absolute;
  right: 1em;
  top: 5px;
  bottom: 5px;
  width: 42px;
`;

export interface GroupCardProps {
  name: string;
  groupId: string;
  description: string;
  userCount: number;
  courseCount: number;
  progress: number;
  requiredCount: number;
  dueDate: string;
  currentAccount: { disableProgressWheel: boolean };
}

export class GroupCard extends React.Component<GroupCardProps> {
  constructor(props) {
    super(props);
  }

  truncate = string => {
    if (string.length > 80) {
      return string.substring(0, 80).trim() + "…";
    } else {
      return string;
    }
  };

  render() {
    const {
      name,
      groupId,
      description,
      userCount,
      courseCount,
      progress,
      requiredCount,
      dueDate,
      currentAccount
    } = this.props;

    const { color, content } = groupStatus(requiredCount, dueDate);

    return (
      <Card className="border">
        <CardBody>
          <Title>
            <a
              href={`/catalog/groups/${groupId}`}
              title={name}
              style={{
                whiteSpace: "pre-wrap",
                display: "block"
              }}
            >
              {this.truncate(name)}
            </a>
          </Title>
          <Description>{description}</Description>
        </CardBody>

        <Stats className="border-top">
          <Stat>
            <FontAwesomeIcon icon={["fal", "users"]} fixedWidth />
            <span className="pdd-left-5">{userCount}</span>
          </Stat>
          <Stat>
            <FontAwesomeIcon icon={["fal", "tv"]} fixedWidth />
            <span className="pdd-left-5">{courseCount}</span>
          </Stat>
          <Circle>
            {!currentAccount.disableProgressWheel &&
              Number.isInteger(progress) && (
                <ProgressCircle progress={progress} />
              )}
          </Circle>
        </Stats>
        <CardFooter className={`bg-${color}`}>{content}</CardFooter>
      </Card>
    );
  }
}
