import * as React from "react";
import Loading from "../loading";
import Error from "../error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownItem, Modal, ModalHeader, ModalBody } from "reactstrap";
import { ApolloConsumer } from "@apollo/client";
import SEARCH from "../../queries/search";
import * as _ from "lodash";
import {
  GetMembershipsQuery,
  Membership,
  GET_MEMBERSHIPS
} from "../../queries/get_memberships";
import {
  GetTracksUsersQuery,
  TrackUser,
  GET_TRACKS_USERS
} from "../../queries/get_tracks_users";
import { Mutation } from "@apollo/client/react/components";
import { GET_GROUPS } from "../../queries/get_groups";
import REMOVE_MEMBERSHIP from "../../queries/remove_membership";
import REMOVE_TRACKS_USER from "../../queries/remove_tracks_user";
import CREATE_MEMBERSHIP from "../../queries/create_membership";
import CREATE_TRACKS_USER from "../../queries/create_tracks_user";

export class ManageTrackMembers extends React.PureComponent<{
  modalIsOpen: boolean;
  toggleModal: () => void;
  id: string;
}> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal
        className="modal-dialog modal-lg"
        isOpen={this.props.modalIsOpen}
        toggle={this.props.toggleModal}
      >
        <ModalHeader toggle={this.props.toggleModal}>
          Manage members
        </ModalHeader>
        <ModalBody>
          <div className="padding-15">
            <GetTracksUsersQuery variables={{ id: this.props.id, order: null }}>
              {({ loading, error, data, refetch, variables }) => {
                if (loading) return <Loading />;
                if (error) return <Error />;

                return (
                  <ManageTrackMembersBody
                    members={data.getTracksUsers}
                    id={this.props.id}
                    onRefetch={async (order) => {
                      await refetch({ id: this.props.id, order: order });
                    }}
                    order={variables.order}
                  />
                );
              }}
            </GetTracksUsersQuery>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export class ManageGroupMembers extends React.PureComponent<{
  modalIsOpen: boolean;
  toggleModal: () => void;
  id: string;
}> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal
        className="modal-dialog modal-lg"
        isOpen={this.props.modalIsOpen}
        toggle={this.props.toggleModal}
      >
        <ModalHeader toggle={this.props.toggleModal}>
          Manage members
        </ModalHeader>
        <ModalBody>
          <div className="padding-15">
            <GetMembershipsQuery
              variables={{ id: this.props.id, order: null }}
              fetchPolicy="network-only"
            >
              {({ loading, error, data, refetch, variables }) => {
                if (loading) return <Loading />;
                if (error) return <Error />;

                return (
                  <ManageMembersBody
                    members={data.getMemberships}
                    id={this.props.id}
                    onRefetch={async (order) => {
                      await refetch({ id: this.props.id, order: order });
                    }}
                    order={variables.order}
                  />
                );
              }}
            </GetMembershipsQuery>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

type Props = {
  onRefetch: (order: string) => void;
  id: string;
  order: string | null;
};

class ManageTrackMembersBody extends React.Component<
  Props & { members: TrackUser[] },
  {
    members: TrackUser[];
  }
> {
  constructor(props: Props & { members: TrackUser[] }) {
    super(props);
  }

  selectItemFromSearch = (item, client) => {
    const result = client.mutate({
      mutation: CREATE_TRACKS_USER,
      variables: { userId: item.id, id: this.props.id },
      refetchQueries: [
        {
          query: GET_TRACKS_USERS,
          variables: { id: this.props.id, order: this.props.order }
        }
      ]
    });
  };

  render() {
    return (
      <div>
        <ApolloConsumer>
          {(client) => (
            <Search
              onItemClick={this.selectItemFromSearch}
              client={client}
              type={["user"]}
              existingIds={this.props.members.map(({ user }) => user.id)}
            />
          )}
        </ApolloConsumer>
        <p>
          Order members by{" "}
          {
            <React.Fragment>
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  this.props.onRefetch(null);
                }}
              >
                Progress
              </button>{" "}
              |{" "}
            </React.Fragment>
          }
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              this.props.onRefetch("name");
            }}
          >
            Username
          </button>{" "}
          |{" "}
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              this.props.onRefetch("last");
            }}
          >
            Last Name
          </button>
        </p>
        {this.props.members.map((member) => {
          return (
            <div key={member.id} className="media">
              <img
                className="mr-3"
                src={member.user.avatar}
                alt={member.user.fullName}
              />
              <div className="media-body">
                <h4 className="my-0">
                  <a href={`/memberships/${member.id}`}>
                    {member.user.fullName}
                  </a>
                </h4>
                {member.user.fullName !== member.user.username && (
                  <small className="text-muted">
                    {member.user.username}
                    &nbsp;
                  </small>
                )}
                {
                  <div className="participant-progress__bar row">
                    <div className="progress-indicator progress-indicator--dark col-sm-9 hidden-xs">
                      <div
                        className="progress-indicator__value"
                        role="progressbar"
                        aria-valuenow={member.progress}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: `${member.progress}%` }}
                      />
                      <span className="sr-only">
                        {member.progress}% Complete
                      </span>
                    </div>
                    <div className="col-sm-3">
                      <small className="text-muted">
                        {member.progress}% Complete
                      </small>
                    </div>
                  </div>
                }
              </div>
              <div>
                <Mutation
                  mutation={REMOVE_TRACKS_USER}
                  refetchQueries={[
                    {
                      query: GET_TRACKS_USERS,
                      variables: { id: this.props.id, order: this.props.order }
                    }
                  ]}
                >
                  {(removeMember) => (
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        removeMember({
                          variables: { id: member.id }
                        });
                      }}
                    >
                      <FontAwesomeIcon fixedWidth icon={["fal", "trash"]} />
                    </button>
                  )}
                </Mutation>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

class ManageMembersBody extends React.Component<
  Props & { members: Membership[] },
  { userIds: string[], members: Membership[] }
> {

  constructor(props: Props & { members: Membership[] }) {
    super(props);
    this.state = {
      members: this.props.members,
      userIds: []
    }
  }

  selectItemFromSearch = (item, client) => {
    client.mutate({
      mutation: CREATE_MEMBERSHIP,
      variables: { userId: item.id, id: this.props.id },
      refetchQueries: [
        {
          query: GET_MEMBERSHIPS,
          variables: { id: this.props.id, order: this.props.order }
        },
        { query: GET_GROUPS }
      ]
    });
  };

  onCheckedUser = (e, id) => {
    let prevIds = [...this.state.userIds];
    if (prevIds.includes(id)) {
      const index = prevIds.indexOf(id);
      prevIds.splice(index, 1);
    } else {
      prevIds.push(id);
    }
    this.setState({ userIds: [...prevIds] });
  }

  clearSelection = () => {
    this.setState({ userIds: [] });
  }

  selectAllMembers = () => {
    const members = [...this.props.members];
    const userIds = members.map(m => m.id);
    this.setState({ userIds });
  }

  removeMembers = (action) => {
    if (confirm("Are you sure you want to remove selected users from this group?") == true) {
      action({
        variables: { id: this.state.userIds }
      });
      const userIds = this.state.userIds;
      let members = [...this.state.members];
      members = members.filter((m) => !userIds.includes(m.id));
      this.setState({userIds: [], members});
    }
  }


  render() {
    const showProgress = !(
      this.props.members.length > 0 &&
      this.props.members[0].currentAccount &&
      this.props.members[0].currentAccount.disableProgressWheel
    );

	let selectActions;

	if (this.state.userIds.length > 0) {
	  selectActions = <p>
		  <p className='mb-1' style={{marginTop: -20}}>Selection:</p>
        <button
          type="button"
          className="btn btn-success"
          onClick={this.selectAllMembers}
        >
          Select all
        </button>
        <button
          type="button"
          className="btn btn-info"
          onClick={this.clearSelection}
        >
          Clear selection
        </button>
        <Mutation
          mutation={REMOVE_MEMBERSHIP}
          refetchQueries={[
            {
              query: GET_MEMBERSHIPS,
              variables: { id: this.props.id, order: this.props.order }
            },
            { query: GET_GROUPS }
          ]}
        >
          {(removeMember) => (
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                this.removeMembers(removeMember);
              }}
            >
              <FontAwesomeIcon fixedWidth icon={["fal", "trash"]} />
              Remove from group
            </button>
          )}
        </Mutation>
	  </p>
	}

    return (
      <div>
        <ApolloConsumer>
          {(client) => (
            <Search
              onItemClick={this.selectItemFromSearch}
              client={client}
              type={["user"]}
              existingIds={this.props.members.map(({ user }) => user.id)}
            />
          )}
        </ApolloConsumer>
        <p>
          Order members by{" "}
          {showProgress && (
            <React.Fragment>
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  this.props.onRefetch(null);
                }}
              >
                Progress
              </button>{" "}
              |{" "}
            </React.Fragment>
          )}
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              this.props.onRefetch("name");
            }}
          >
            Username
          </button>{" "}
          |{" "}
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              this.props.onRefetch("last");
            }}
          >
            Last Name
          </button>
        </p>
        { selectActions }
        {this.props.members.map((member) => {
          return (
            <div key={member.id} className="media">
              <input
                type="checkbox"
                className={`mr-3 mt-3 user_id_${member.id}`}
                onChange={ (e) => this.onCheckedUser(e, member.id) }
                checked={this.state.userIds.includes(member.id)}
              />
              <img
                className="mr-3"
                src={member.user.avatar}
                alt={member.user.fullName}
              />
              <div className="media-body">
                <h4 className="my-0">
                  <a href={`/memberships/${member.id}`}>
                    {member.user.fullName}
                  </a>
                </h4>
                {member.user.fullName !== member.user.username && (
                  <small className="text-muted">
                    {member.user.username}
                    &nbsp;
                  </small>
                )}
                {(!member.currentAccount ||
                  !member.currentAccount.disableProgressWheel) && (
                  <div className="participant-progress__bar row">
                    <div className="progress-indicator progress-indicator--dark col-sm-9 hidden-xs">
                      <div
                        className="progress-indicator__value"
                        role="progressbar"
                        aria-valuenow={member.progress}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: `${member.progress}%` }}
                      />
                      <span className="sr-only">
                        {member.progress}% Complete
                      </span>
                    </div>
                    <div className="col-sm-3">
                      <small className="text-muted">
                        {member.progress}% Complete
                      </small>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <Mutation
                  mutation={REMOVE_MEMBERSHIP}
                  refetchQueries={[
                    {
                      query: GET_MEMBERSHIPS,
                      variables: { id: this.props.id, order: this.props.order }
                    },
                    { query: GET_GROUPS }
                  ]}
                >
                  {(removeMember) => (
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        removeMember({
                          variables: { id: [member.id] }
                        });
                      }}
                    >
                      <FontAwesomeIcon fixedWidth icon={["fal", "trash"]} />
                    </button>
                  )}
                </Mutation>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export class Search extends React.PureComponent<
  {
    onItemClick: (item: any, client: any) => void;
    client: any;
    type: string[];
    existingIds: string[];
  },
  { timer: number | null; term: string; results: any[]; showResults: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      term: "",
      results: [],
      showResults: false
    };
  }

  fetchResults = (event) => {
    this.setState({ term: event.target.value });
    this.delay(this.sendQuery);
  };

  sendQuery = () => {
    const { term } = this.state;

    if (term.length > 0) {
      const result = this.props.client.query({
        query: SEARCH,
        variables: { term: term, type: this.props.type }
      });
      result.then(({ data }) => {
        const results = data.search.filter(
          ({ id }) => !this.props.existingIds.includes(id)
        );
        this.setState({ results: results });
      });
    } else {
      this.setState({ results: [] });
    }
  };

  delay = (callback) => {
    clearTimeout(this.state.timer);

    this.setState({
      timer: window.setTimeout(callback, 500)
    });
  };

  render() {
    const { results, term } = this.state;
    return (
      <div>
        <input
          className="form-control"
          type="text"
          placeholder={`Search ${_.capitalize(this.props.type[0])} to add`}
          onChange={this.fetchResults}
          onFocus={() => this.setState({ showResults: true })}
          value={this.state.term}
        />
        {this.state.showResults && (
          <Results
            term={term}
            items={results}
            onItemClick={(item) => {
              this.props.onItemClick(item, this.props.client);
              this.setState({ results: [], term: "", showResults: false });
            }}
          />
        )}
      </div>
    );
  }
}

class Results extends React.PureComponent<{
  items: { type: string; name: string }[];
  term: string;
  onItemClick: (item: any) => void;
}> {
  constructor(props) {
    super(props);
  }

  resultIcon = (resultKey) => {
    switch (resultKey) {
      case "course":
        return "tv";
      case "group":
        return "users";
      case "track":
        return "train";
      default:
        return "book";
    }
  };

  render() {
    return (
      <div
        className="advanced-search active border border-top-0 rounded-bottom"
        style={{ overflowY: "auto" }}
      >
        <div className="search-wrapper">
          {this.props.items.length > 0 ? (
            <div>
              <div>
                <div className="pdd-vertical-10">
                  <ul className="list-unstyled list-info">
                    {this.props.items.map((item, index) => {
                      return (
                        <li key={index}>
                          <button
                            type="button"
                            style={{
                              appearance: "none",
                              background: "transparent",
                              border: 0,
                              textAlign: "left"
                            }}
                            onClick={() => this.props.onItemClick(item)}
                          >
                            <div className="thumb-icon">
                              <FontAwesomeIcon
                                icon={["fal", this.resultIcon(item.type)]}
                              />
                            </div>
                            <div className="info">
                              <span className="title">{item.name}</span>
                              <span className="sub-title text-capitalize">
                                {item.type}
                              </span>
                            </div>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="mrg-horizon-20 border top" />
              </div>
            </div>
          ) : (
            <p className="m-3">There are no results to display.</p>
          )}
        </div>
        <div
          className="search-footer"
          style={{
            borderTop: "1px solid #e6ecf5",
            textAlign: "center",
            padding: "15px"
          }}
        >
          <span>
            You are Searching for '
            <b className="text-dark">
              <span className="search-text-bind">{this.props.term}</span>
            </b>
            '
          </span>
        </div>
      </div>
    );
  }
}
