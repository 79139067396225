import { gql } from "@apollo/client";

const UPDATE_COURSE_GROUPS = gql`
  mutation updateCourseGroups($courseIds: [ID!]!, $groupId: ID!) {
    updateCourseGroups(input: { courseIds: $courseIds, groupId: $groupId }) {
      courseIds
    }
  }
`;

export default UPDATE_COURSE_GROUPS;
