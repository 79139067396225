import { gql } from "@apollo/client";

const CREATE_TRACKS_USER = gql`
  mutation createTracksUser($userId: ID, $id: ID!) {
    createTracksUser(input: { userId: $userId, trackId: $id }) {
      tracksUser {
        id
        progress
        user {
          id
          fullName
          avatar
          username
        }
      }
    }
  }
`;

export default CREATE_TRACKS_USER;
