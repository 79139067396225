export type ColorKey = "primary" | "danger" | "warning" | "success" | "info";

const colors: Record<ColorKey, string> = {
  primary: "#f37c35",
  danger: "#f95549",
  warning: "#ffd519",
  success: "#8fc32e",
  info: "#248dc1"
};

export default colors;
