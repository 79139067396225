import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Search } from "../catalog/manage_members";
import { FilestackDropPane } from "../filestack";
import Youtube from "react-youtube";
import SweetAlert from "sweetalert2-react";
import swal from "sweetalert2";
import { client, Content } from "./index";
import * as React from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import { IconButton } from "../buttons";
import * as _ from "lodash";

const Regex = "^[^/|=|?]+";

const reverse = string => {
  return string
    .split("")
    .reverse()
    .join("");
};

export const ContentsSection = ({
  videoContents,
  onChange,
  removeContent,
  updateVideoContents,
  stateEmbeddedVideo,
  removeEmbedVideo,
  documentContents,
  updateDocumentContents,
  selectItemFromSearch,
  courseForm,
  toggleEmbedAlert,
  embedAlertIsOpen,
  embedYoutubeLink,
  toggleContentModal,
  setFile
}) => (
  <React.Fragment>
    <div className="row">
      <div className="col-6">
        <p style={{ marginBottom: "5px" }}>
          <FontAwesomeIcon icon={["fal", "film"]} /> Videos
        </p>
        <div className="card" style={{ minHeight: "200px" }}>
          <div className="padding-15">
            {videoContents.length > 0 ? (
              <VideoSortableList
                helperClass="list-unstyled"
                contents={videoContents}
                removeContent={removeContent}
                onChange={onChange}
                useDragHandle={true}
                onSortEnd={data => {
                  updateVideoContents(data, onChange);
                }}
              />
            ) : stateEmbeddedVideo ? (
              <EmbedVideoSortableList
                helperClass="list-unstyled"
                embedVideo={stateEmbeddedVideo}
                useDragHandle={true}
                removeEmbedVideo={removeEmbedVideo}
                onChange={onChange}
              />
            ) : (
              <div className="alert alert-info">
                There are no video contents.
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-6">
        <p style={{ marginBottom: "5px" }}>
          <FontAwesomeIcon icon={["fal", "film"]} /> Documents
        </p>
        <div className="card" style={{ minHeight: "200px" }}>
          <div className="padding-15">
            {documentContents.length > 0 ? (
              <DocumentSortableList
                helperClass="list-unstyled"
                contents={documentContents}
                removeContent={removeContent}
                onChange={onChange}
                useDragHandle={true}
                onSortEnd={data => {
                  updateDocumentContents(data, onChange);
                }}
              />
            ) : (
              <div className="alert alert-info">
                There are no document contents.
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-12">
        <p style={{ marginBottom: "5px" }}>Add Content</p>
        <div className="card">
          <div className="card-block">
            <div className="tab-info">
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <a
                    href="#content-new"
                    className="nav-link active"
                    role="tab"
                    data-toggle="tab"
                  >
                    Upload
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#content-existing"
                    className="nav-link"
                    role="tab"
                    data-toggle="tab"
                  >
                    Existing Content
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#embed-youtube"
                    className="nav-link"
                    role="tab"
                    data-toggle="tab"
                  >
                    Embed Youtube
                  </a>
                </li>
              </ul>
              <div 
                className="tab-content" 
                style={{ margin: "initial" }}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              >
                <div
                  role="tabpanel"
                  className="tab-pane fade in active"
                  id="content-new"
                >
                  <FilestackDropPane
                    onSuccess={result => {
                      const file = result[0];
                      setFile({
                        key: file.key,
                        url: file.url,
                        filename: file.filename
                      });
                      toggleContentModal();
                    }}
                    accept={[
                      "video/*",
                      ".pdf",
                      ".doc",
                      ".docx",
                      ".ppt",
                      ".pptx",
                      ".xls",
                      ".xlsx"
                    ]}
                  />
                </div>
                <div
                  role="tabpanel"
                  className="tab-pane fade"
                  id="content-existing"
                  style={{ marginTop: "20px" }}
                >
                  <Search
                    onItemClick={item => selectItemFromSearch(item, onChange)}
                    client={client}
                    type={["content"]}
                    existingIds={courseForm.contentIds.map(id => id.toString())}
                  />
                </div>
                <div
                  role="tabpanel"
                  className="tab-pane fade"
                  id="embed-youtube"
                  style={{ marginTop: "20px" }}
                >
                  <label>Youtube URL</label>
                  <div className="input-group">
                    <input
                      id="embed-input"
                      type="text"
                      className="form-control"
                      placeholder="https://youtube.com/watch?v=123456789"
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-primary"
                        type="button"
                        onClick={toggleEmbedAlert}
                      >
                        Embed
                      </button>
                    </div>
                  </div>
                  <SweetAlert
                    show={embedAlertIsOpen}
                    title="Embed Youtube Video"
                    text="Are you sure you want to embed this video? Any other videos will be removed from this course."
                    type="warning"
                    showCancelButton={true}
                    onCancel={toggleEmbedAlert}
                    onConfirm={() => {
                      toggleEmbedAlert();
                      const input = document.getElementById(
                        "embed-input"
                      ) as HTMLInputElement;
                      embedYoutubeLink(input.value, onChange);
                      input.value = "";
                    }}
                  />
                  {stateEmbeddedVideo && (
                    <div
                      className="embed-responsive embed-responsive-16by9"
                      style={{ marginTop: "20px" }}
                    >
                      <Youtube
                        videoId={reverse(
                          new RegExp(Regex).exec(reverse(stateEmbeddedVideo))[0]
                        )}
                        opts={{
                          playerVars: {
                            modestbranding: 1,
                            rel: 0
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

const VideoSortableList = SortableContainer<{
  contents: Content[];
  onChange: (any) => void;
  removeContent: (contentId: string, onChange: (any) => void) => void;
}>(props => (
  <ul className="list-unstyled">
    {props.contents.map((content, index) => (
      <SortableItem
        content={content}
        removeContent={props.removeContent}
        onChange={props.onChange}
        key={`video-sortable-${index}`}
        index={index}
      />
    ))}
  </ul>
));

const EmbedVideoSortableList = SortableContainer<{
  embedVideo: string;
  onChange: (any) => void;
  removeEmbedVideo: (onChange: (any) => void) => void;
}>(props => (
  <ul className="list-unstyled">
    <SortableEmbedItem
      embedVideo={props.embedVideo}
      removeEmbedVideo={props.removeEmbedVideo}
      onChange={props.onChange}
      key={`video-sortable-${0}`}
      index={0}
    />
  </ul>
));

const SortableEmbedItem = SortableElement<{
  embedVideo: string;
  onChange: (any) => void;
  removeEmbedVideo: (onChange: (any) => void) => void;
}>(props => {
  const embeddedVideoId = reverse(
    new RegExp(Regex).exec(reverse(props.embedVideo))[0]
  );
  return (
    <li>
      <img
        src={`https://img.youtube.com/vi/${embeddedVideoId}/default.jpg`}
        alt="Youtube link thumbnail"
      />
      <IconButton
        icon={["fal", "trash"]}
        small
        pill
        danger
        onClick={() => {
          props.removeEmbedVideo(props.onChange);
        }}
      />
      <DragHandle />
      <p>{props.embedVideo}</p>
    </li>
  );
});

const DocumentSortableList = SortableContainer<{
  contents: Content[];
  onChange: (any) => void;
  removeContent: (contentId: string, onChange: (any) => void) => void;
}>(props => (
  <ul className="list-unstyled">
    {props.contents.map((content, index) => (
      <SortableItem
        content={content}
        removeContent={props.removeContent}
        onChange={props.onChange}
        key={`document-sortable-${index}`}
        index={index}
      />
    ))}
  </ul>
));

const SortableItem = SortableElement<{
  content: Content;
  onChange: (any) => void;
  removeContent: (contentId: string, onChange: (any) => void) => void;
}>(props => (
  <li>
    <img src={props.content.thumbnail} alt={props.content.name} />
    <IconButton
      icon={["fal", "trash"]}
      small
      pill
      danger
      onClick={event => {
        event.preventDefault();
        swal({
          title: "Delete this content",
          text:
            "Are you sure you want to delete this content? If you are replacing this with a new version, please click on the title of the content and use the replace option.",
          type: "warning",
          showCancelButton: true
        }).then(result => {
          if (result.value) {
            props.removeContent(props.content.id, props.onChange);
          }
        });
      }}
    />
    <DragHandle />
    <p>
      <a href={`/contents/${props.content.id}/edit`} data-turbolinks="false">
        {props.content.name}
      </a>
    </p>
  </li>
));

const DragHandle = SortableHandle(props => {
  return <IconButton icon={["fal", "arrows"]} small pill />;
});
