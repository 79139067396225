import * as React from "react";

import Fancy from "../forms/fancy";
import { Input } from "../forms/input";
import { Button } from "../buttons";

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  ApolloProvider
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import moment from 'moment-timezone';
import Loading from "../loading";
import Error from "../error";
import { Activity } from "./activity";
import CREATE_CLASSROOM_ACTIVITY from "../../queries/create_classroom_activity";
import GET_CLASSROOM_SESSION, {
  GetClassroomSession
} from "../../queries/get_classroom_session";
import Card from "reactstrap/lib/Card";
import { CardBody, ListGroup } from "reactstrap";
import CardHeader from "reactstrap/lib/CardHeader";
import ListGroupItem from "reactstrap/lib/ListGroupItem";
import Table from "reactstrap/lib/Table";
import * as _ from "lodash";

import * as Datetime from "react-datetime";
import { FunctionComponent } from "react";
import { cache } from "../../cache";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement(): any {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: () => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

interface RegistrationsProps {
  sessionId: string;
}

const Registrations: FunctionComponent<RegistrationsProps> = ({
  sessionId
}): JSX.Element => (
  <ApolloProvider client={client}>
    <GetClassroomSession variables={{ id: sessionId }}>
      {({ loading, error, data }) => {
        if (error) return <Error />;
        if (loading) return <Loading />;
        return <SessionActivities session={data.getClassroomSession} />;
      }}
    </GetClassroomSession>
  </ApolloProvider>
);

export default Registrations;

const SessionActivities = ({ session }) => {
  let showForm = true;
  const registrations = _.reject(session.registrations, ({ activities }) => {
    return (
      activities.length > 0 &&
      _.some(activities, ({ event, value, passingScore }) => {
        return (
          event == "completed" || (event == "exam" && value >= passingScore)
        );
      })
    );
  });

  if (
    _.every(session.registrations, ({ activities }) => {
      return _.some(activities, ({ event, value, passingScore }) => {
        return (
          event == "completed" || (event == "exam" && value >= passingScore)
        );
      });
    })
  ) {
    showForm = false;
  }

  return (
    <React.Fragment>
      {session.registrations.length > 0 && (
        <React.Fragment>
          <h1>Managing Results for {session.course.name}</h1>
          {showForm && (
            <Card>
              <CardBody>
                <h2 className="card-title">Submit Session Results</h2>
                <SessionResultForm
                  sessionId={session.id}
                  registrations={registrations}
                  classroomCourseId={session.classroomCourseId}
                  startTime={session.startTime}
                  endTime={session.endTime}
                />
              </CardBody>
            </Card>
          )}
          <Activities registrations={session.registrations} />
        </React.Fragment>
      )}

      {session.registrations.length == 0 && (
        <Card>
          <CardBody>No users are registered for this session yet.</CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

const Activities = ({ registrations }) => {
  const activities = _.flatten(
    registrations.map(({ activities }) => activities)
  )
    .slice()
    .reverse();

  const groupedActivities = _.groupBy(
    activities,
    ({ timestamp, event, duration, value }) => [
      timestamp,
      event,
      duration,
      value
    ]
  );

  return (
    <Card>
      <CardBody>
        <h2 className="card-title">Session Results</h2>
        <ListGroup flush={true}>
          {_.map(groupedActivities).map((activity, index) => (
            <ListGroupItem key={`activity-${index}`}>
              <Activity activity={activity} />
            </ListGroupItem>
          ))}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

type Props = {
  sessionId: string;
  registrations: any;
  classroomCourseId: string;
  startTime: string;
  endTime: string;
};

type State = {
  includeScore: boolean;
  score: number;
  duration: number;
  timestamp: string;
  userIds: string[];
};

type GroupedActivity = {
  users: User[];
  timestamp: string;
  event: string;
  duration: number;
  value: number;
};

type User = {
  id: string;
  fullName: string;
};

class SessionResultForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      includeScore: false,
      score: 0,
      duration: this.defaultDuration(),
      timestamp: this.props.endTime,
      userIds: this.props.registrations.map(({ user }) => user.id)
    };
  }

  defaultDuration = () => {
    const { startTime, endTime } = this.props;

    return moment.duration(moment(endTime).diff(moment(startTime))).asHours();
  };

  toggleIncludeScore = () =>
    this.setState((state) => ({ includeScore: !state.includeScore }));

  render() {
    const { includeScore } = this.state;

    return (
      <Fancy
        mutation={CREATE_CLASSROOM_ACTIVITY}
        variables={{
          classroomActivity: {
            userIds: this.state.userIds,
            classroomCourseId: this.props.classroomCourseId,
            event: this.state.includeScore ? "exam" : "completed",
            value: this.state.includeScore ? this.state.score : 1,
            duration: this.state.duration,
            timestamp: this.state.timestamp
          }
        }}
        onComplete={{
          message: "Results have been sent",
          callback: () => {}
        }}
        update={(cache, { createClassroomActivity }) => {
          this.setState({userIds: []});
        }}
        refetchQueries={[
          {
            query: GET_CLASSROOM_SESSION,
            variables: { id: this.props.sessionId }
          }
        ]}
        render={({ onChange, state }) => (
          <React.Fragment>
            <Input
              defaultValue={this.state.duration.toString()}
              name="duration"
              type="number"
              onChange={onChange}
              step="0.05"
            >
              Duration in hours:
            </Input>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>
                    Time:
                    <Datetime
                      dateFormat={"MM/DD/YYYY"}
                      displayTimeZone={localStorage.getItem('timeZone')}
                      timeFormat={"h:mm A"}
                      defaultValue={moment(state.timestamp).format(
                        "MM/DD/YYYY h:mm A"
                      )}
                      onChange={(date) => {
                        const target = {
                          name: "timestamp",
                          type: "text",
                          value: moment(date, moment.ISO_8601)
                        };
                        onChange({ target });
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                defaultChecked={includeScore}
                onChange={() => {
                  if (state.classroomActivity.event === "completed") {
                    onChange({
                      target: {
                        type: "text",
                        value: "exam",
                        name: "event"
                      }
                    });
                  } else {
                    onChange({
                      target: {
                        type: "text",
                        value: "completed",
                        name: "event"
                      }
                    });
                  }
                }}
                name="addScore"
                id="addScore"
              />
              <label htmlFor="addScore">Add score</label>
            </div>
            {state.classroomActivity.event === "exam" && (
              <Input
                type="number"
                min="0"
                max="100"
                defaultValue={this.state.score.toString()}
                name="value"
                onChange={onChange}
              >
                Score:
              </Input>
            )}
            <Table>
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>Attended?</th>
                  <th style={{ width: "80%" }}>Name</th>
                </tr>
              </thead>
              <tbody>
                {this.props.registrations.map(({ user }, index) => (
                  <tr key={`registration-${index}`}>
                    <td>
                      <div className="toggle-checkbox checkbox-inline toggle-sm mrg-top-10">
                        <input
                          type="checkbox"
                          name={`userId-${user.id}`}
                          id={`userId-${user.id}`}
                          checked={this.state.userIds.includes(
                            user.id
                          )}
                          onChange={(e) => {
                            let userIds = state.classroomActivity.userIds;
                            if (userIds != this.state.userIds){
                              userIds = this.state.userIds;
                            }
                            if (userIds.includes(user.id)) {
                              userIds = userIds.filter((userId) => userId !== user.id);
                            } else {
                              userIds = [user.id, ...this.state.userIds];
                            }
                            this.setState({userIds}, ()=> {
                              onChange({
                                target: {
                                  type: "text",
                                  value: userIds,
                                  name: "userIds"
                                }
                              });
                            });
                          }}
                        />
                        <label htmlFor={`userId-${user.id}`} />
                      </div>
                    </td>
                    <td>{user.fullName}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {state.classroomActivity.event === "exam" ? (
              <button className="btn btn-primary" disabled={this.state.userIds.length == 0}>Submit exam results</button>
            ) : (
              <button className="btn btn-primary" disabled={this.state.userIds.length == 0}>Mark complete</button>
            )}
          </React.Fragment>
        )}
      />
    );
  }
}
