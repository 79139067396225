import * as React from "react";
import * as ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactQuill from "react-quill";

function Input({ children, ...props }) {
  const error = props.errors && props.errors.find((error) => error.field == props.field);
  let type = "text";
  if (props.password) {
    type = "password";
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="form-group">
          <label>{children}</label>
          <input type={type} name={props.field} {...props} className={"form-control"} />
          {error && <div className="text-danger">{error.message}</div>}
          {props.required && <small className="form-text text-muted">Required.</small>}
        </div>
      </div>
    </div>
  );
}

function Textarea(props) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="form-group">
          {props.children && <label>{props.children}</label>}
          <textarea
            name={props.field}
            onChange={props.onChange}
            className="form-control"
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}
            value={props.value}
          />
        </div>
      </div>
    </div>
  );
}

function Quill(props) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="form-group">
          {props.children && <label>{props.children}</label>}
          <ReactQuill
            onChange={props.onChange}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}
          />
        </div>
      </div>
    </div>
  );
}

//THIS NO WORKIE!!
// function Checkbox({ children, ...props }) {
//   return (
//     <div className="row">
//       <div className="col-12">
//         <div className="checkbox">
//           <input type="checkbox" {...props} />
//           <label>{children}</label>
//         </div>
//       </div>
//     </div>
//   );
// }

// TODO: this takes a group of RadioButtons and modifies their name and id or whatever to make them act correctly
function RadioGroup({ children, ...props }) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="checkbox">
          <input type="checkbox" {...props} />
          <label>{children}</label>
        </div>
      </div>
    </div>
  );
}

function RadioButton({ children, ...props }) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="checkbox">
          <input type="checkbox" {...props} />
          <label>{children}</label>
        </div>
      </div>
    </div>
  );
}
export { Input, Textarea, Quill };
