import * as React from "react";
import Loading from "../loading";
import Error from "../error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { CourseCardNoLink } from "../course_cards";
import {
  GetExamAssignmentsQuery,
  ExamAssignment
} from "../../queries/get_exam_assignments";

interface Props {
  url: string;
  size: number;
}

interface Assignment {
  exam: Exam;
  courseThumbnail: string;
}

interface Exam {
  id: string;
  name: string;
  data: {};
  dueDays: string;
}

interface AssignmentQuery {
  refetch: () => void;
}

interface AssignmentInterface {
  setExamId: (examId: string) => void;
  examAssignment: ExamAssignment;
}

function ExamAssignments(props: Props) {
  const url = props.url;

  return (
    <GetExamAssignmentsQuery>
      {({ loading, error, data, refetch }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        return (
          <Assignments
            url={url}
            examAssignments={data.getExamAssignments}
            refetch={refetch}
            size={props.size}
          />
        );
      }}
    </GetExamAssignmentsQuery>
  );
}

interface AssignmentState {
  examId: string;
}

class Assignments extends React.Component<
  Props & AssignmentQuery & { examAssignments: ExamAssignment[] },
  AssignmentState
> {
  constructor(props) {
    super(props);

    this.state = {
      examId: null
    };
  }

  setExamId = (examId) => {
    this.setState({ examId: examId });
  };

  componentDidMount() {
    this.defineCarousel();
  }

  UNSAFE_componentWillUpdate() {
    this.destroyCarousel();
  }

  componentDidUpdate() {
    this.defineCarousel();
  }

  destroyCarousel() {
    $(`[data-url="${this.props.url}"]`).trigger("destroy.owl.carousel");
  }

  defineCarousel() {
    const url = this.props.url;
    ($ as any)('[data-url="' + url + '"]').owlCarousel({
      loop: false,
      navText: [
        "<i class='fal fa-chevron-left'></i>",
        "<i class='fal fa-chevron-right'></i>"
      ],
      nav: true,
      margin: 15,
      dots: false,
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        550: {
          items: 3
        },
        768: {
          items: 4
        },
        1024: {
          items: 5
        },
        1400: {
          items: 6
        },
        1700: {
          items: 7
        }
      },
      responsiveBaseElement: $('[data-url="' + url + '"]')[0]
    });
  }

  render() {
    const { examAssignments } = this.props;
    if (examAssignments.length <= 0) {
      return <div className="alert alert-info">You have no 4tifies.</div>;
    }

    let exams = null;
    if (this.props.size == 4) {
      exams = (
        <ul className="list-group list-group-flush">
          {examAssignments.map((examAssignment, index) => {
            return (
              <Assignment
                key={index}
                examAssignment={examAssignment}
                refetch={this.props.refetch}
                setExamId={this.setExamId}
                View={SmallAssignmentView}
              />
            );
          })}
        </ul>
      );
    } else {
      exams = (
        <div
          className="owl-carousel"
          style={{ zIndex: 0 }}
          data-url={this.props.url}
        >
          {examAssignments.map((examAssignment, index) => {
            return (
              <Assignment
                key={index}
                examAssignment={examAssignment}
                refetch={this.props.refetch}
                setExamId={this.setExamId}
                View={AssignmentView}
              />
            );
          })}
        </div>
      );
    }

    return (
      <React.Fragment>
        {this.state.examId && (
          <input type="hidden" id="exam_id" value={this.state.examId} />
        )}
        {exams}
      </React.Fragment>
    );
  }
}

class Assignment extends React.Component<
  AssignmentQuery & AssignmentInterface & { View: (props) => JSX.Element },
  { modalIsOpen: boolean }
> {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false
    };
  }

  toggleModal = () =>
    this.setState(
      (state) => {
        return { modalIsOpen: !state.modalIsOpen };
      },
      () => {
        if (this.state.modalIsOpen) {
          (window as any).UserExam.prepareExam(
            this.props.examAssignment.exam.data,
            this.props.examAssignment.disableExamResultsForwarding
          );
          this.props.setExamId(this.props.examAssignment.exam.id);
        } else {
          this.props.refetch();
          this.props.setExamId(null);
        }
      }
    );

  render() {
    const { View, examAssignment } = this.props;
    return (
      <View
        examAssignment={examAssignment}
        toggleModal={this.toggleModal}
        modalIsOpen={this.state.modalIsOpen}
      />
    );
  }
}

interface AssignmentViewProps {
  examAssignment: ExamAssignment;
  toggleModal: () => void;
  modalIsOpen: boolean;
}

const AssignmentView = ({
  examAssignment,
  toggleModal,
  modalIsOpen
}: AssignmentViewProps) => (
  <React.Fragment>
    <CourseCardNoLink
      name={examAssignment.exam.name}
      image={examAssignment.courseThumbnail}
      onClick={toggleModal}
    />
    <Modal
      className="modal-lg"
      isOpen={modalIsOpen}
      toggle={toggleModal}
      backdrop="static"
    >
      <ModalBody>
        <div className="js-exam" />
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  </React.Fragment>
);

const SmallAssignmentView = ({
  examAssignment,
  toggleModal,
  modalIsOpen
}: AssignmentViewProps) => (
  <React.Fragment>
    <li className="list-group-item px-0">
      <div className="media">
        <img
          className="mr-3 mb-0"
          src={examAssignment.courseThumbnail}
          alt={examAssignment.exam.name}
          width="50"
        />
        <div className="media-body">
          <h5 className="mt-0 pt-0 mb-1">
            <button
              type="button"
              className="btn btn-link"
              onClick={toggleModal}
            >
              {examAssignment.exam.name}
            </button>
          </h5>
          <div>
            <FontAwesomeIcon
              icon={["fas", "dot-circle"]}
              className="text-danger"
            />
            <span className="pdd-left-5">
              {examAssignment.exam.dueDays} days
            </span>
          </div>
        </div>
      </div>
    </li>
    <Modal className="modal-lg" isOpen={modalIsOpen} toggle={toggleModal}>
      <ModalBody>
        <div className="js-exam" />
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  </React.Fragment>
);

export default ExamAssignments;
