import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { widgets, widgetsVariables } from "./codegen/widgets";

export const GET_WIDGETS = gql`
  query widgets($userId: ID) {
    widgets(userId: $userId) {
      id
      url
      size
      name
      sizeOptions
    }
  }
`;

export const GetWidgetsQuery = (
  props: Omit<QueryComponentOptions<widgets, widgetsVariables>, "query">
): JSX.Element => (
  <Query<widgets, widgetsVariables> query={GET_WIDGETS} {...props} />
);
