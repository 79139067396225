import * as React from "react";
import * as ReactDOM from "react-dom";
import * as _ from "lodash";
import { Input } from "../../forms/input";
import moment from "moment-timezone";
import classNames from "classnames";

import * as Datetime from "react-datetime";

export type Requirement = boolean | string | Interval | Range;
export type Interval = { every: [boolean, number]; after: [boolean, number] };
export type Range = { start: string; end: string };

class Requirements extends React.Component<
  {
    requirement: Requirement;
    updateRequirement: (
      arg:
        | { requirement: Requirement }
        | (({ requirement: Requirement }) => { requirement: Requirement })
    ) => void;
    hideInterval?: boolean;
  },
  {}
> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { requirement } = this.props;
    if (isInterval(requirement)) {
      if (!isEveryRequirement(requirement)) {
        this.props.updateRequirement({
          requirement: Object.assign({}, requirement, {
            every: [true, 365]
          })
        });
      } else if (!isAfterRequirement(requirement)) {
        this.props.updateRequirement({
          requirement: Object.assign({}, requirement, {
            after: [true, 30]
          })
        });
      }
    }
  }
  setRequireWithoutDate = (event) => {
    this.props.updateRequirement({ requirement: event.target.checked });
  };

  setRequiredWithDate = (event) => {
    if (event.target.checked) {
      this.props.updateRequirement({
        requirement: moment().format("MM/DD/YYYY")
      });
    } else {
      this.props.updateRequirement({ requirement: false });
    }
  };

  setRequiredEveryNumber = (event) => {
    const value = parseInt(event.target.value, 10);
    this.props.updateRequirement((state) => {
      const { requirement } = state;
      if (isInterval(requirement)) {
        return {
          requirement: Object.assign({}, requirement, {
            every: [true, value]
          })
        };
      } else {
        return {
          requirement: {
            every: [true, value],
            after: [false, value]
          }
        };
      }
    });
  };

  setRecurringDefaults = (event) => {
    if (event.target.checked) {
      this.props.updateRequirement({
        requirement: {
          every: [true, 365],
          after: [true, 30]
        }
      });
    } else {
      this.props.updateRequirement({
        requirement: false
      });
    }
  };

  setRangeDefaults = (event) => {
    if (event.target.checked) {
      this.props.updateRequirement({
        requirement: {
          start: moment().format(),
          end: moment().format()
        }
      });
    } else {
      this.props.updateRequirement({
        requirement: false
      });
    }
  };

  setRequiredAfterNumber = (event) => {
    const value = parseInt(event.target.value, 10);
    this.props.updateRequirement((state) => {
      const { requirement } = state;
      if (isInterval(requirement)) {
        return {
          requirement: Object.assign({}, requirement, {
            after: [true, value]
          })
        };
      } else {
        return {
          requirement: {
            every: [false, value],
            after: [true, value]
          }
        };
      }
    });
  };

  setRequiredStartDate = (date) => {
    const formattedDate = moment(date).toISOString();
    this.props.updateRequirement((state) => {
      const { requirement } = state;
      if (isRange(requirement)) {
        return {
          requirement: Object.assign({}, requirement, {
            start: formattedDate
          })
        };
      } else {
        return {
          requirement: {
            start: formattedDate,
            end: moment().format()
          }
        };
      }
    });
  };

  setRequiredEndDate = (date) => {
    const formattedDate = moment(date).toISOString();
    this.props.updateRequirement((state) => {
      const { requirement } = state;
      if (isRange(requirement)) {
        return {
          requirement: Object.assign({}, requirement, {
            end: formattedDate
          })
        };
      } else {
        return {
          requirement: {
            start: moment().format(),
            end: formattedDate
          }
        };
      }
    });
  };

  render() {
    const { requirement } = this.props;

    const isDatelessRequirementActive = classNames({
      card: true,
      "border-success": isDatelessRequirement(requirement)
    });
    const isDateRequirementActive = classNames({
      card: true,
      "border-success": isDateRequirement(requirement)
    });
    const isIntervalRequirementActive = classNames({
      card: true,
      "border-success": isRecurringRequirement(requirement)
    });
    const isRangeRequirementActive = classNames({
      card: true,
      "border-success": isRangeRequirement(requirement)
    });

    return (
      <div>
        <p>Require this course to be completed at the user's convenience.</p>
        <div className={isDatelessRequirementActive}>
          <div className="card-body">
            <label>
              <input
                type="checkbox"
                checked={isDatelessRequirement(requirement)}
                onChange={this.setRequireWithoutDate}
              />{" "}
              Required without a date
            </label>
          </div>
        </div>
        <p>Require this course to be completed by a specific date.</p>
        <div className={isDateRequirementActive}>
          <div className="card-body">
            <label>
              This will be the date
              <Datetime
                dateFormat={"MM/DD/YYYY"}
                timeFormat={false}
                value={formatDate(requirement)}
                onChange={(date) => {
                  this.props.updateRequirement({
                    requirement: moment(date).toISOString()
                  });
                }}
              />
            </label>
          </div>
        </div>
        {!this.props.hideInterval && (
          <React.Fragment>
            <p>Require this course to be completed at a recurring interval.</p>
            <div>
              <div className={isIntervalRequirementActive}>
                <div className="card-body">
                  <div className="mb-2">
                    <label>
                      <input
                        type="checkbox"
                        checked={isRecurringRequirement(requirement)}
                        onChange={this.setRecurringDefaults}
                      />{" "}
                      Require users to take this course on a recurring schedule.
                    </label>
                  </div>
                  {isRecurringRequirement(requirement) && (
                    <React.Fragment>
                      <div className="mb-2">
                        <label>
                          Required every{" "}
                          <select
                            value={
                              isEveryRequirement(requirement)
                                ? requirement.every[1]
                                : ""
                            }
                            onChange={this.setRequiredEveryNumber}
                          >
                            <option value={90}>3 months</option>
                            <option value={180}>6 months</option>
                            <option value={365}>1 year</option>
                            <option value={730}>2 years</option>
                            <option value={1095}>3 years</option>
                          </select>
                        </label>
                      </div>
                      <div>
                        <label>
                          Required{" "}
                          <select
                            value={
                              isAfterRequirement(requirement)
                                ? requirement.after[1]
                                : ""
                            }
                            onChange={this.setRequiredAfterNumber}
                          >
                            <option value={2}>2</option>
                            <option value={7}>7</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={30}>30</option>
                            <option value={60}>60</option>
                            <option value={90}>90</option>
                            <option value={120}>120</option>
                            <option value={150}>150</option>
                            <option value={180}>180</option>
                            <option value={270}>270</option>
                            <option value={365}>365</option>
                          </select>{" "}
                          days after joining
                        </label>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>

            <p>Require this course to be completed within a date range.</p>
            <div className={isRangeRequirementActive}>
              <div className="card-body">
                <div className="mb-2">
                  <label>
                    <input
                      type="checkbox"
                      checked={isRangeRequirement(requirement)}
                      onChange={this.setRangeDefaults}
                    />{" "}
                    Require users to take this course within a date range.
                  </label>
                </div>
                {isRangeRequirement(requirement) && (
                  <React.Fragment>
                    <div className="mb-2">
                      <label>
                        This will be the date the assignment will be created
                        <Datetime
                          dateFormat={"MM/DD/YYYY"}
                          timeFormat={false}
                          value={formatStartDate(requirement)}
                          onChange={this.setRequiredStartDate}
                        />
                      </label>
                    </div>
                    <div>
                      <label>
                        This will be the date the assignment is due
                        <Datetime
                          dateFormat={"MM/DD/YYYY"}
                          timeFormat={false}
                          value={formatEndDate(requirement)}
                          onChange={this.setRequiredEndDate}
                        />
                      </label>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

function formatDate(requirement: Requirement): string {
  if (typeof requirement === "string") {
    return moment(requirement).format("MM/DD/YYYY");
  } else {
    return "";
  }
}

function formatStartDate(requirement: Requirement): string {
  if (isRange(requirement)) {
    return moment(requirement.start).format("MM/DD/YYYY");
  } else {
    return "";
  }
}

function formatEndDate(requirement: Requirement): string {
  if (isRange(requirement)) {
    return moment(requirement.end).format("MM/DD/YYYY");
  } else {
    return "";
  }
}

function isDatelessRequirement(requirement: Requirement): boolean {
  return requirement === true;
}

function isDateRequirement(requirement: Requirement): boolean {
  return typeof requirement === "string";
}

function isRecurringRequirement(requirement: Requirement): boolean {
  if (isInterval(requirement)) {
    return requirement.every[0] || requirement.after[0];
  }
}

function isRangeRequirement(requirement: Requirement): boolean {
  if (isRange(requirement)) {
    return (
      typeof requirement.start === "string" &&
      typeof requirement.end === "string"
    );
  }
  return false;
}

function isEveryRequirement(requirement: Requirement): requirement is Interval {
  if (isInterval(requirement)) {
    return requirement.every[0];
  }
  return false;
}

function isAfterRequirement(requirement: Requirement): requirement is Interval {
  if (isInterval(requirement)) {
    return requirement.after[0];
  }
  return false;
}

function isRequired(requirement: Requirement) {
  if (isInterval(requirement)) {
    return requirement.every[0] || requirement.after[0];
  } else {
    return !!requirement;
  }
}

function isInterval(requirement: Requirement): requirement is Interval {
  return (
    _.isObject(requirement) &&
    _.has(requirement, "every") &&
    _.has(requirement, "after")
  );
}

function isRange(requirement: Requirement): requirement is Range {
  return (
    _.isObject(requirement) &&
    _.has(requirement, "start") &&
    _.has(requirement, "end")
  );
}

export default Requirements;
