import moment from "moment";

export const humanizeTimestamp = (
  timestamp,
  today = moment().startOf("day")
) => {
  const tz = localStorage.getItem('timeZone') || 'America/Chicago'
  const time = moment(timestamp, moment.ISO_8601).tz(tz);
  today = today.tz(tz)
  if (today < time) {
    return time.format("h:mm a");
  } else if (today.subtract(6, "days") < time) {
    return time.format("ddd h:mm a");
  } else {
    return time.format("MM/DD/YYYY h:mm a");
  }
};
