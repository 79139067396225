import * as React from "react";

import Fancy from "../forms/fancy";
import { Input, Textarea } from "../forms/input";
import { Button } from "../buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Wizard from "../course_form/wizard";
import { Search } from "../catalog/manage_members";
import CREATE_OR_UPDATE_CLASSROOM_SESSION from "../../queries/create_or_update_classroom_session";
import * as Datetime from "react-datetime";
import "../forms/react-datetime.css";
import { CreateLocationForm } from "../locations/create_location_form";
import { GetLocationsQuery } from "../../queries/get_locations";
import Error from "../error";

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  ApolloProvider
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { cache } from "../../cache";

import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";
import { GetGroupsQuery } from "../../queries/get_groups";
import { GetTracksQuery } from "../../queries/get_tracks";
import { GetTopicsQuery } from "../../queries/get_topics";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { FilestackDropPane } from "../filestack";
import CREATE_CONTENT from "../../queries/create_content";

import moment from 'moment-timezone';
import { createOrUpdateClassroomSession } from "../../queries/codegen/createOrUpdateClassroomSession";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement(): any {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: () => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

type Props = {
  id: string;
  instructor: string;
  sessionCode: string;
  waitlist: boolean;
  openEnrollment: boolean;
  virtual: boolean;
  allowVirtual: boolean;
  classroomCourseId: string;
  title: string;
  classroomLocationId: string;
  prerequisites: string;
  url: string;
  minimumRegistrations: number;
  maximumRegistrations: number;
  contentIds: string[];
  handouts: Handout[];
  signupDeadline: string;
  startTime: string;
  endTime: string;
  location: { value: string; label: string };
  locationOptions: { value: string; label: string }[];
};

type State = {
  handouts: Handout[];
  modalIsOpen: boolean;
  locationModalIsOpen: boolean;
  location: { value: string; label: string };
  onChange: () => void | null;
};

type Handout = {
  id: string;
  name: string;
  thumbnail: string;
};

export default (props) => (
  <ApolloProvider client={client}>
    <QueryLocations Component={SessionForm} {...props} />
  </ApolloProvider>
);

const toSelectOption = ({ id, name }) => ({
  value: id,
  label: name
});

const QueryLocations = ({ Component, ...props }) => (
  <GetLocationsQuery>
    {({ loading, error, data }) => {
      if (error) return <Error />;
      if (loading) return <Component locationOptions={[]} {...props} />;
      const locations = data.getLocations.map(toSelectOption);
      return <Component locationOptions={locations} {...props} />;
    }}
  </GetLocationsQuery>
);

class SessionForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      handouts: this.props.handouts,
      location: this.props.location,
      modalIsOpen: false,
      locationModalIsOpen: false,
      onChange: null
    };
  }

  updateOptions = (options: Pick<State, "location">, callback) =>
    this.setState(options, callback);

  removeHandout = (handoutId, onChange) => {
    this.setState(
      (state) => {
        return {
          handouts: state.handouts.filter((handout) => {
            return handout.id !== handoutId;
          })
        };
      },
      () => {
        const target = {
          name: "contentIds",
          type: "list",
          value: this.state.handouts.map((handout) => handout.id)
        };
        onChange({ target });
      }
    );
  };

  toggleModal = () => {
    this.setState((state) => {
      return { modalIsOpen: !state.modalIsOpen };
    });
  };

  toggleLocationModal = () => {
    this.setState((state) => {
      return { locationModalIsOpen: !state.locationModalIsOpen };
    });
  };

  addLocation = (location, onChange) => {
    this.setState(
      (state) => {
        return { location: { value: location.id, label: location.name } };
      },
      () => {
        const target = {
          name: "classroomLocationId",
          type: "string",
          value: this.state.location ? this.state.location.value : null
        };
        onChange({ target });
      }
    );
  };

  selectItemFromSearch = (item, onChange) => {
    const handout = {
      id: item.id,
      name: item.name,
      thumbnail: item.thumbnail
    };
    this.setState(
      (state) => {
        return { handouts: [...state.handouts, handout] };
      },
      () => {
        const target = {
          name: "contentIds",
          type: "list",
          value: this.state.handouts.map((handout) => handout.id)
        };
        onChange({ target });
      }
    );
  };

  setOnChange = (onChange) => {
    if (!this.state.onChange) {
      this.setState({ onChange: onChange });
    }
  };

  render() {
    const { handouts, location, ...props } = this.props;
    return (
      <View
        updateOptions={this.updateOptions}
        handouts={this.state.handouts}
        classroomLocation={this.state.location}
        removeContent={this.removeHandout}
        toggleModal={this.toggleModal}
        modalIsOpen={this.state.modalIsOpen}
        selectItemFromSearch={this.selectItemFromSearch}
        locationModalIsOpen={this.state.locationModalIsOpen}
        toggleLocationModal={this.toggleLocationModal}
        addLocation={this.addLocation}
        onChange={this.state.onChange}
        setOnChange={this.setOnChange}
        {...props}
      />
    );
  }
}

class View extends React.Component<
  {
    updateOptions: any;
    handouts: any;
    removeContent: any;
    toggleModal: any;
    modalIsOpen: any;
    selectItemFromSearch: any;
    classroomLocation: any;
    locationOptions: any;
    toggleLocationModal: any;
    locationModalIsOpen: any;
    addLocation: any;
    title: string;
    onChange: any;
    setOnChange: any;
  },
  { contentModalIsOpen: boolean; file: any }
> {
  constructor(props) {
    super(props);

    this.state = {
      contentModalIsOpen: false,
      file: null
    };
  }

  toggleContentModal = () => {
    this.setState((state) => {
      return { contentModalIsOpen: !state.contentModalIsOpen };
    });
  };

  setFile = (file) => this.setState({ file });

  render() {
    const {
      updateOptions,
      handouts,
      removeContent,
      toggleModal,
      modalIsOpen,
      selectItemFromSearch,
      classroomLocation,
      locationOptions,
      toggleLocationModal,
      locationModalIsOpen,
      addLocation,
      title,
      onChange,
      setOnChange,
      ...props
    } = this.props;
    return (
      <React.Fragment>
        <Modal
          className="modal-dialog modal-lg"
          isOpen={this.state.contentModalIsOpen}
          toggle={this.toggleContentModal}
        >
          <ModalHeader toggle={this.toggleContentModal}>
            Add Content
          </ModalHeader>
          <ModalBody>
            <div className="padding-15">
              <Fancy
                mutation={CREATE_CONTENT}
                onComplete={{
                  message: "",
                  callback: () => {}
                }}
                update={(cache, { createContent }) => {
                  this.toggleContentModal();
                  selectItemFromSearch(createContent.content, onChange);
                }}
                variables={{
                  content: {
                    name: null,
                    description: null,
                    file: this.state.file
                  }
                }}
                render={({ errors, onChange }) => {
                  return (
                    <React.Fragment>
                      <Input
                        onChange={onChange}
                        field="name"
                        errors={errors}
                        required={true}
                      >
                        Name
                      </Input>
                      <Textarea
                        onChange={onChange}
                        field="description"
                        errors={errors}
                      >
                        Description
                      </Textarea>

                      <Button className="float-right" primary>
                        Create Content
                      </Button>
                    </React.Fragment>
                  );
                }}
              />
            </div>
          </ModalBody>
        </Modal>
        <Fancy
          mutation={CREATE_OR_UPDATE_CLASSROOM_SESSION}
          variables={{ classroomSession: props }}
          onComplete={{
            message: "",
            callback: (
              {
                createOrUpdateClassroomSession
              }: createOrUpdateClassroomSession,
              setDisableFormSubmit: (disableFormSubmit: boolean) => void
            ) => {
              if (createOrUpdateClassroomSession) {
                const alert = document.getElementById("react-alert");
                alert.innerText = "Your session has been saved.";
                alert.classList.remove("d-none");
                if (window.sessionStorage.getItem("pathname")) {
                  window.location.href = window.sessionStorage.getItem(
                    "pathname"
                  );
                } else {
                  window.location.href = "/classrooms";
                }
              } else {
                setDisableFormSubmit(false);
              }
            }
          }}
          render={({
            errors,
            onChange,
            disableFormSubmit,
            state: { classroomSession: session }
          }) => {
            return (
              <FormBody
                onChange={onChange}
                setOnChange={setOnChange}
                title={title}
                session={session}
                errors={errors}
                handouts={handouts}
                removeContent={removeContent}
                selectItemFromSearch={selectItemFromSearch}
                classroomLocation={classroomLocation}
                locationOptions={locationOptions}
                toggleLocationModal={toggleLocationModal}
                updateOptions={updateOptions}
                setFile={this.setFile}
                toggleContentModal={this.toggleContentModal}
                disableFormSubmit={disableFormSubmit}
              />
            );
          }}
        />
        <Modal
          className="modal-dialog modal-lg"
          isOpen={locationModalIsOpen}
          toggle={toggleLocationModal}
        >
          <ModalHeader toggle={toggleLocationModal}>
            Create Location
          </ModalHeader>
          <ModalBody>
            <div className="padding-15">
              <CreateLocationForm
                toggleForm={toggleLocationModal}
                addLocation={(location) => addLocation(location, onChange)}
              />
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

class FormBody extends React.Component<{
  onChange;
  setOnChange;
  title;
  session;
  errors;
  handouts;
  removeContent;
  selectItemFromSearch;
  classroomLocation;
  locationOptions;
  toggleLocationModal;
  toggleContentModal;
  updateOptions;
  setFile;
  disableFormSubmit;
}> {
  componentDidMount() {
    this.props.setOnChange(this.props.onChange);
  }
  render() {
    const {
      onChange,
      title,
      session,
      errors,
      handouts,
      removeContent,
      selectItemFromSearch,
      classroomLocation,
      locationOptions,
      toggleLocationModal,
      toggleContentModal,
      updateOptions,
      setFile,
      disableFormSubmit
    } = this.props;

    return (
      <div className="card">
        <div className="card-block">
          <h1>{title}</h1>
          <Wizard
            headings={["Start", "Handouts", "Location"]}
            sections={[
              <Start onChange={onChange} session={session} errors={errors} />,
              <Contents
                handouts={handouts}
                removeContent={removeContent}
                selectItemFromSearch={selectItemFromSearch}
                onChange={onChange}
                session={session}
                setFile={setFile}
                toggleContentModal={toggleContentModal}
              />,
              <Locations
                classroomLocation={classroomLocation}
                locationOptions={locationOptions}
                toggleLocationModal={toggleLocationModal}
                onChange={onChange}
                updateOptions={updateOptions}
              />
            ]}
            finishButton={
              <button
                className="btn btn-primary btn-rounded"
                style={{ float: "right" }}
                disabled={disableFormSubmit}
              >
                {disableFormSubmit ? "Saving Session..." : "Save Session"}
              </button>
            }
          />
        </div>
      </div>
    );
  }
}

const Start = ({ onChange, errors, session }) => (
  <React.Fragment>
    <div className="row">
      <div className="col-12">
        <div className="form-group">
          <label>Signup Deadline</label>
          <Datetime
            dateFormat={"MM/DD/YYYY"}
            timeFormat={"h:mm A"}
            value={moment(session.signupDeadline)}
            onChange={(date) => {
              const target = {
                name: "signupDeadline",
                type: "text",
                value: moment(date, moment.ISO_8601)
              };
              onChange({ target });
            }}
          />
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-6">
        <div className="form-group">
          <label>Start Date/Time</label>
          <Datetime
            dateFormat={"MM/DD/YYYY"}
            timeFormat={"h:mm A"}
            value={moment(session.startTime)}
            displayTimeZone={localStorage.getItem('timeZone')}
            onChange={(date) => {
              const target = {
                name: "startTime",
                type: "text",
                value: moment(date, moment.ISO_8601)
              };
              onChange({ target });
            }}
          />
          <small className="form-text text-muted">Required.</small>
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label>End Date/Time</label>
          <Datetime
            dateFormat={"MM/DD/YYYY"}
            timeFormat={"h:mm A"}
            value={moment(session.endTime)}
            displayTimeZone={localStorage.getItem('timeZone')}
            onChange={(date) => {
              const target = {
                name: "endTime",
                type: "text",
                value: moment(date, moment.ISO_8601)
              };
              onChange({ target });
            }}
          />
          <small className="form-text text-muted">
            Required. Must be <em>after</em> the start date/time.
          </small>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-sm-6">
        <div className="form-group">
          <label>Minimum Number of Registrations</label>
          <input
            type="number"
            onChange={onChange}
            defaultValue={session.minimumRegistrations}
            className="form-control"
            min="0"
            name="minimumRegistrations"
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <label>Maximum Number of Registrations</label>
          <input
            type="number"
            onChange={onChange}
            defaultValue={session.maximumRegistrations}
            className="form-control"
            min={session.maximumRegistrations}
            name="maximumRegistrations"
          />
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        {session.allowVirtual && (
          <small className="form-text text-muted">
            "Virtual Classroom" must be enabled for live streaming.
          </small>
        )}
        <div className="checkbox checkbox-inline">
          <input
            id="waitlist"
            name="waitlist"
            type="checkbox"
            onChange={onChange}
            defaultChecked={session.waitlist}
          />
          <label htmlFor="waitlist">Waitlist</label>
        </div>
        <div className="checkbox checkbox-inline">
          <input
            id="openEnrollment"
            name="openEnrollment"
            type="checkbox"
            onChange={onChange}
            defaultChecked={session.openEnrollment}
          />
          <label htmlFor="openEnrollment">Open Enrollment</label>
        </div>
        {session.allowVirtual && (
        <div className="checkbox checkbox-inline">
          <input
            id="virtual"
            name="virtual"
            type="checkbox"
            onChange={onChange}
            defaultChecked={session.virtual}
          />
          <label htmlFor="virtual">Virtual Classroom</label>
        </div>
      )}
      </div>
    </div>
    <Input
      onChange={onChange}
      field="instructor"
      errors={errors}
      defaultValue={session.instructor}
    >
      Instructor
    </Input>
    <Input
      onChange={onChange}
      field="sessionCode"
      errors={errors}
      defaultValue={session.sessionCode}
    >
      Session Code
    </Input>
    <Input
      onChange={onChange}
      field="url"
      errors={errors}
      defaultValue={session.url}
    >
      Remote viewing URL (GoToMeeting, WebEx, etc) *optional
    </Input>
    <Textarea
      onChange={onChange}
      field="prerequisites"
      errors={errors}
      defaultValue={session.prerequisites}
    >
      Prerequisites
    </Textarea>
  </React.Fragment>
);

const Contents = ({
  handouts,
  removeContent,
  onChange,
  selectItemFromSearch,
  session,
  setFile,
  toggleContentModal
}) => (
  <React.Fragment>
    <div className="row">
      <div className="col-12">
        <SortableList
          contents={handouts}
          removeContent={removeContent}
          onChange={onChange}
          onSortEnd={(data) => {
            console.log(data);
          }}
        />
      </div>
      <div className="col-12">
        <p style={{ marginBottom: "5px" }}>Add Handout</p>
        <div className="card">
          <div className="card-block">
            <div className="tab-info">
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <a
                    href="#handout-new"
                    className="nav-link active"
                    role="tab"
                    data-toggle="tab"
                  >
                    Upload
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#handout-existing"
                    className="nav-link"
                    role="tab"
                    data-toggle="tab"
                  >
                    Existing Document
                  </a>
                </li>
              </ul>
              <div className="tab-content" style={{ margin: "initial" }}>
                <div
                  role="tabpanel"
                  className="tab-pane fade in active"
                  id="handout-new"
                >
                  <FilestackDropPane
                    onSuccess={(result) => {
                      const file = result[0];
                      setFile({
                        key: file.key,
                        url: file.url,
                        filename: file.filename
                      });
                      toggleContentModal();
                    }}
                    accept={[
                      ".pdf",
                      ".doc",
                      ".docx",
                      ".ppt",
                      ".pptx",
                      ".xls",
                      ".xlsx"
                    ]}
                    documentOnly={true}
                  />
                </div>
                <div
                  role="tabpanel"
                  className="tab-pane fade"
                  id="handout-existing"
                  style={{ marginTop: "20px" }}
                >
                  <Search
                    onItemClick={(item) => selectItemFromSearch(item, onChange)}
                    client={client}
                    type={["document"]}
                    existingIds={session.contentIds}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

const Locations = ({
  classroomLocation,
  locationOptions,
  updateOptions,
  onChange,
  toggleLocationModal
}) => (
  <div className="row">
    <div className="col-12">
      <div className="form-group">
        <div
          className="mb-2"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div>Location</div>
          <div>
            <button
              type="button"
              className="btn btn-info btn-rounded"
              onClick={toggleLocationModal}
            >
              Create Location
            </button>
          </div>
        </div>
        <Select
          value={classroomLocation}
          options={locationOptions.filter(
            (locationOption) => locationOption.value != classroomLocation.value
          )}
          onChange={(selected) => {
            const target = {
              name: "classroomLocationId",
              type: "string",
              value: selected.value
            };
            updateOptions({ location: selected }, () => onChange({ target }));
          }}
        />
        <small className="form-text text-muted">Required.</small>
      </div>
    </div>
  </div>
);

const SortableList = SortableContainer<{
  contents: Handout[];
  onChange: (any) => void;
  removeContent: (contentId: string, onChange: (any) => void) => void;
}>((props) => {
  let items;
  if (props.contents.length > 0) {
    items = (
      <ul className="list-unstyled">
        {props.contents.map((content, index) => (
          <SortableItem
            content={content}
            removeContent={props.removeContent}
            onChange={props.onChange}
            key={`video-sortable-${index}`}
            index={index}
          />
        ))}
      </ul>
    );
  } else {
    items = <div className="alert alert-info">There are no handouts.</div>;
  }

  return (
    <React.Fragment>
      <p style={{ marginBottom: "5px" }}>
        <FontAwesomeIcon icon={["fal", "file-alt"]} /> Handouts
      </p>
      <div className="card" style={{ minHeight: "200px" }}>
        <div className="padding-15">{items}</div>
      </div>
    </React.Fragment>
  );
});

const SortableItem = SortableElement<{
  content: Handout;
  onChange: (any) => void;
  removeContent: (contentId: string, onChange: (any) => void) => void;
}>((props) => (
  <li className="d-flex align-items-center mb-2">
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        width: "124px",
        height: "160px",
        position: "relative",
        backgroundColor: "#EEEEEE"
      }}
    >
      <FontAwesomeIcon fixedWidth icon={["fal", "spinner"]} spin size="2x" />
      <img
        src={props.content.thumbnail}
        alt={props.content.name}
        style={{ position: "absolute", top: 0, left: 0 }}
      />
    </div>
    <button
      type="button"
      className="btn btn-link text-danger"
      onClick={() => {
        props.removeContent(props.content.id, props.onChange);
      }}
    >
      <FontAwesomeIcon fixedWidth icon={["fal", "times-circle"]} size="2x" />
    </button>
    <a href={`/contents/${props.content.id}/edit`}>{props.content.name}</a>
  </li>
));
