import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getMemberships,
  getMembershipsVariables,
  getMemberships_getMemberships
} from "./codegen/getMemberships";

export type Membership = getMemberships_getMemberships;

export const GET_MEMBERSHIPS = gql`
  query getMemberships($id: ID!, $order: String) {
    getMemberships(groupId: $id, order: $order) {
      id
      progress
      user {
        id
        fullName
        avatar
        username
      }
      currentAccount {
        disableProgressWheel
      }
    }
  }
`;

export const GetMembershipsQuery = (
  props: Omit<
    QueryComponentOptions<getMemberships, getMembershipsVariables>,
    "query"
  >
): JSX.Element => (
  <Query<getMemberships, getMembershipsVariables>
    query={GET_MEMBERSHIPS}
    {...props}
  />
);
