import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getAllTopics, getAllTopicsVariables } from "./codegen/getAllTopics";

const GET_ALL_TOPICS = gql`
  query getAllTopics($offset: Int, $limit: Int) {
    getAllTopics(offset: $offset, limit: $limit) {
      moreRecords
      topics {
        id
        slug
        name
        description
        courseCount
      }
      currentUser {
        id
        canUpdateContent
      }
    }
  }
`;

export const GetAllTopicsQuery = (
  props: Omit<
    QueryComponentOptions<getAllTopics, getAllTopicsVariables>,
    "query"
  >
): JSX.Element => (
  <Query<getAllTopics, getAllTopicsVariables>
    query={GET_ALL_TOPICS}
    {...props}
  />
);

export default GET_ALL_TOPICS;
