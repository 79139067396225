import { gql } from "@apollo/client";

const FOLLOW_USER = gql`
  mutation followUser($userId: ID!) {
    followUser(input: { userId: $userId }) {
      id
    }
  }
`;

export default FOLLOW_USER;
