import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getExamAssignments,
  getExamAssignments_getExamAssignments
} from "./codegen/getExamAssignments";

export type ExamAssignment = getExamAssignments_getExamAssignments;

const GET_EXAM_ASSIGNMENTS = gql`
  query getExamAssignments {
    getExamAssignments {
      id
      exam {
        id
        courseId
        name
        dueDays
        data {
          id
          name
          description
          passingScore
          questions {
            id
            text
            type
            media {
              id
              url
              type
              thumbnail
            }
            answers {
              id
              text
              textOptions
              image
              correct
              correctOptionId
            }
            options {
              id
              text
            }
            mandatory
            showCorrect
          }
        }
      }
      courseThumbnail
      disableExamResultsForwarding
    }
  }
`;

export const GetExamAssignmentsQuery = (
  props: Omit<QueryComponentOptions<getExamAssignments>, "query">
): JSX.Element => (
  <Query<getExamAssignments> query={GET_EXAM_ASSIGNMENTS} {...props} />
);
