import React, { Component } from "react";
import ReactDOM from "react-dom";
import StatusCorner from "./status-corner";

function CourseListItem(props) {
  let classNames;
  if (props.courseId == props.item.id) {
    classNames = "bg-light catalog-nav-active";
  }

  return (
    <a
      href={`${props.url}/${props.item.id}`}
      className={classNames}
      data-turbolinks="false"
    >
      <li className="email-item">
        <div className="open-mail">
          <div className="email-detail" style={{ paddingLeft: "0" }}>
            <h4>{props.item.name}</h4>
            <p>{props.item.shortDescription}</p>
          </div>
          {props.item.statusIcon && (
            <StatusCorner
              icon={props.item.statusIcon.icon}
              look={props.item.statusIcon.style}
              tooltip={props.item.statusIcon.tooltip}
              size="sm"
              key_id={props.item.id}
            />
          )}
        </div>
      </li>
    </a>
  );
}

export default CourseListItem;
