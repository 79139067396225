import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getTopUsersChart } from "./codegen/getTopUsersChart";

export const CHARTS_TOP_USERS = gql`
  query getTopUsersChart {
    charts_top_users {
      id
      label
      value
      amount
    }
  }
`;

export const GetChartsTopUsersQuery = (
  props: Omit<QueryComponentOptions<getTopUsersChart>, "query">
): JSX.Element => (
  <Query<getTopUsersChart> query={CHARTS_TOP_USERS} {...props} />
);
