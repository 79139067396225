import { gql } from "@apollo/client";

const DELETE_TRACK = gql`
  mutation deleteTrack($trackId: ID!) {
    deleteTrack(input: { trackId: $trackId }) {
      id
    }
  }
`;

export default DELETE_TRACK;
