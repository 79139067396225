import { gql } from "@apollo/client";

const CREATE_CONTENT = gql`
  mutation createContent($content: ContentAttributes!) {
    createContent(input: { content: $content }) {
      content {
        id
        name
        thumbnail
        fileType
      }
    }
  }
`;

export default CREATE_CONTENT;
