import { gql } from "@apollo/client";

const CREATE_CLASSROOM_ACTIVITY = gql`
  mutation createClassroomActivity(
    $classroomActivity: ClassroomActivityAttributes!
  ) {
    createClassroomActivity(input: { classroomActivity: $classroomActivity }) {
      classroomActivities {
        id
        user {
          id
          fullName
        }
        classroomCourseId
        event
        value
        duration
        timestamp
      }
    }
  }
`;

export default CREATE_CLASSROOM_ACTIVITY;
