import * as React from "react";
import Loading from "../../loading";
import Error from "../../error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GroupAdminButtons from "./group-admin-buttons";
import List from "../courses/list";
import { GetGroupQuery } from "../../../queries/get_group";
import InfiniteScroll from "react-infinite-scroller";
import { getGroup, getGroup_getGroup } from "../../../queries/codegen/getGroup";

interface Props {
  id: string;
  url: string;
}

class Group extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): JSX.Element {
    return (
      <GetGroupQuery
        variables={{ id: this.props.id }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, fetchMore }) => {
          if (loading) return <Loading />;
          if (error) return <Error />;

          document.title = `Thinkzoom - Groups: ${data.getGroup.name}`;
          return (
            <GroupBody
              group={data.getGroup}
              url={this.props.url}
              onLoadMore={({ offset }) => {
                void fetchMore({
                  variables: {
                    offset: offset ? data.getGroup.courses.length : null,
                    id: this.props.id
                  }
                });
              }}
            />
          );
        }}
      </GetGroupQuery>
    );
  }
}

class GroupBody extends React.PureComponent<
  {
    url: string;
    group: getGroup_getGroup;
    onLoadMore: ({ offset: boolean }) => void;
  },
  { hideCompleted: boolean; onlyRequired: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      hideCompleted: false,
      onlyRequired: false
    };
  }

  hideCompleted = () =>
    this.setState((state) => ({ hideCompleted: !state.hideCompleted }));

  onlyRequired = () =>
    this.setState((state) => ({ onlyRequired: !state.onlyRequired }));

  render() {
    const url = this.props.url;
    const data = this.props.group;

    let courses = data.courses;

    if (this.state.hideCompleted) {
      courses = courses.filter(({ completed }) => !completed);
    }

    if (this.state.onlyRequired) {
      courses = courses.filter(({ required }) => required);
    }

    return (
      <InfiniteScroll
        loadMore={() =>
          this.props.onLoadMore({
            offset: true
          })
        }
        hasMore={this.props.group.moreRecords}
        loader={<Loading key={0} />}
        threshold={500}
        useWindow={false}
      >
        <React.Fragment>
          {(data.currentUser.canCreateGroup ||
            data.currentUser.canManageReports) && (
            <GroupAdminButtons group={data} url={url} />
          )}
          <h1 className="clearfix">
            <FontAwesomeIcon icon={["fal", "users"]} /> {data.name}
          </h1>
          <p>{data.description}</p>
          <React.Fragment>
            <div className="checkbox checkbox-inline">
              <input
                id="hideCompleted"
                value="hideCompleted"
                name="hideCompleted"
                type="checkbox"
                onChange={this.hideCompleted}
              />
              <label htmlFor="hideCompleted">Hide Completed</label>
            </div>
            <div className="checkbox checkbox-inline">
              <input
                id="onlyRequired"
                value="onlyRequired"
                name="onlyRequired"
                type="checkbox"
                onChange={this.onlyRequired}
              />
              <label htmlFor="onlyRequired">Only Required</label>
            </div>
          </React.Fragment>
          <div className="card">
            <div className="card-body">
              <List data={courses} url={url} admin={data.currentUser.canCreateGroup || data.currentUser.canManageReports} />
            </div>
          </div>
          {!this.props.group.moreRecords && (
            <div className="alert alert-info">
              There are no more courses for this group.
            </div>
          )}
        </React.Fragment>
      </InfiniteScroll>
    );
  }
}

export default Group;
