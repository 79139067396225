import { gql } from "@apollo/client";

const REMOVE_COURSE_TRACK = gql`
  mutation removeCourseTrack($courseId: ID!, $trackId: ID!) {
    removeCourseTrack(input: { courseId: $courseId, trackId: $trackId }) {
      id
    }
  }
`;

export default REMOVE_COURSE_TRACK;
