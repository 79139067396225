import * as React from "react";
import * as moment from "moment";
import "react-dates/initialize";
import "./datepicker.css";
import { SingleDatePicker } from "react-dates";

type Props = {
  date: string;
  update: (date: string) => void;
  disabled: boolean;
};

type State = {
  date: moment.Moment;
  focused: boolean;
};

export default class DatePicker extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      date: this.initialDate(),
      focused: false
    };
  }

  initialDate = () => {
    const { date } = this.props;
    if (date == null) {
      return null;
    } else {
      let parsedDate = moment(date, "MM/DD/YYYY");
      if (parsedDate.isValid()) {
        return parsedDate;
      } else {
        return moment(date);
      }
    }
  };

  onDateChange = date => {
    if (date !== null) {
      this.setState({ date });
      this.props.update(date.format("MM/DD/YYYY"));
    }
  };

  render() {
    return (
      <SingleDatePicker
        date={this.state.date}
        onDateChange={this.onDateChange}
        focused={this.state.focused}
        onFocusChange={({ focused }) => {
          this.setState({ focused });
        }}
        isOutsideRange={() => false}
        id="fdsfds"
        disabled={this.props.disabled}
      />
    );
  }
}
