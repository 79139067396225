import * as React from "react";
import styled, { css, keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { lighten, transparentize, getLuminance } from "polished";
import colors, { ColorKey } from "./colors";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const defaultButton = {
  full: "#aaa",
  muted: "#fff"
};

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  pill?: boolean;
  pending?: boolean;
  error?: boolean;
  active?: boolean;
  hover?: boolean;
  small?: boolean;
  extraSmall?: boolean;
  full?: string;
  muted?: string;
  first?: boolean;
  middle?: boolean;
  last?: boolean;
  input?: boolean; // Used for Checkboxes and RadioButtons
  icon: IconProp;
  color?: string;
  // TODO: transition to something with a less messy API
  danger?: boolean;
  info?: boolean;
  primary?: boolean;
  borderradius?: boolean;
  focus?: boolean;
  text?: boolean;
};

function ButtonFactory(thing) {
  return (thing as any).attrs((props: Props) => {
    const colorKeys = Object.keys(colors);
    const matchingKey = colorKeys.find((key: ColorKey): key is ColorKey =>
      props.hasOwnProperty(key)
    );

    const fullColor = matchingKey ? colors[matchingKey] : defaultButton.full;

    const mutedColor = matchingKey
      ? lighten(0.3, colors[matchingKey])
      : defaultButton.muted;

    return {
      muted: mutedColor,
      full: fullColor,
      borderradius: props.pill ? "50px" : "3px",
      title: props.error ? "Something went wrong" : props.title,
      disabled: props.error ? true : props.disabled
    };
  })`
    background: ${({ full }: Props) => full};
    color: ${({ full }: Props) => readableColor(full)};
    border: 1px solid;
    border-color: ${({ full }: Props) => full};
    box-sizing: border-box;
    padding: 0 16px;
    font-size: 14px;
    line-height: 50px;
    display: inline-block;
    margin: 2px;
    border-radius: ${({ borderradius }: Props) => borderradius};

    &:focus {
      ${(props: Props) => focusStyle(props)}
    }

    ${(props: Props) => props.focus && !props.disabled && focusStyle(props)}

    ${(props: Props) => buttonGroupStyling(props)}

    ${({ small }: Props) =>
      small &&
      css`
        padding: 0 8px;
        line-height: 34px;
      `}

    ${({ small, input }: Props) =>
      small &&
      input &&
      css`
        width: 34px;
        height: 34px;
        padding: 0;
      `}

    ${({ extraSmall }: Props) =>
      extraSmall &&
      css`
        padding: 0 4px;
        line-height: 26px;
      `}

      ${({ extraSmall, input }: Props) =>
        extraSmall &&
        input &&
        css`
          width: 26px;
          height: 26px;
          padding: 0;
        `}


    ${({ text }: Props) =>
      text &&
      css`
        line-height: inherit;
      `}

    ${(props: Props) =>
      !props.disabled &&
      !props.pending &&
      !props.active &&
      css`
        :hover {
          ${hoverStyling(props)};
        }
      `};

    &:active {
      ${(props: Props) => activeStyle(props)}
    }

    ${(props: Props) => props.active && activeStyle(props)};

    ${({ pending, muted, full }: Props) =>
      pending &&
      css`
        background: ${muted};
        border-color: ${full};
        color: ${readableColor(muted)};
        cursor: wait;

        animation: ${pulse} 2s ease-in-out infinite;
      `};

    ${(props: Props) =>
      props.hover &&
      !props.disabled &&
      !props.pending &&
      !props.active &&
      hoverStyling(props)};

    ${({ input, muted }: Props) =>
      input &&
      css`
        background: ${muted};
        color: ${muted};
        border-color: #000;

        :hover {
          background: ${muted};
          color: ${transparentize(0.5, "#000")};
          border-color: #000;
        }
      `};

    ${({ input, active }: Props) =>
      input &&
      active &&
      css`
        color: #000;
        border-color: #000;

        :hover {
          color: ${transparentize(0.5, "#000")};
          border-color: #000;
        }
      `};

    ${(props) => props.disabled && disabledStyle(props)}
  `;
}

const Button = ButtonFactory(styled.button);

const pulse = keyframes`
  0% {
    opacity: 1
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
`;

const focusStyle = function (props: Props) {
  return css`
    box-shadow: 2px 2px ${transparentize(0.4, props.full)};
  `;
};

const activeStyle = function (props: Props) {
  const hoverStyle = css`
    background: ${props.full};
    border-color: ${props.full};
    color: ${readableColor(props.full)};
  `;
  return css`
    background: ${props.muted};
    border-color: ${props.full}
    color: ${readableColor(props.muted)};

    :hover {
      ${hoverStyle};
    }

    ${props.hover && hoverStyle}
  `;
};

const disabledStyle = function (props: { muted: string; full: string }) {
  return css`
    background: ${props.muted};
    color: ${readableColor(props.muted)};
    border-color: ${props.full}
    opacity: 0.75;
    cursor: not-allowed;
    border-style: dashed;
  `;
};

const buttonGroupStyling = function (props: Props) {
  return css`
    ${
      props.first &&
      css`
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
        margin: 2px 0 2px 2px;
      `
    }

    ${
      props.middle &&
      css`
        border-radius: 0;
        border-right: 0;
        margin: 0;
      `
    }

    ${
      props.last &&
      css`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin: 2px 2px 2px 0;
      `
    }
  `;
};

const hoverStyling = function (props: Props) {
  return css`
    background: ${props.muted};
    border-color: ${props.full};
    color: ${readableColor(props.muted)};
  `;
};

const ToggleButton = styled(Button)`
  ${(props: Props) =>
    props.active &&
    css`
      :hover {
        ${toggleActiveHoverStyle(props)};
      }
    `};

  ${(props) => props.active && props.hover && toggleActiveHoverStyle(props)};
`;

const toggleActiveHoverStyle = function (props: { muted?: string }) {
  return css`
    background: ${props.muted || defaultButton.muted};
    border-color: ${colors.danger};
    color: ${colors.danger};
  `;
};

function IconButton(props: Props): any {
  let icon;
  if (props.pending) {
    icon = <FontAwesomeIcon fixedWidth icon={["fal", "spinner"]} spin />;
  } else if (props.error) {
    icon = (
      <FontAwesomeIcon fixedWidth icon={["fal", "exclamation-triangle"]} />
    );
  } else {
    icon = <FontAwesomeIcon fixedWidth icon={props.icon} />;
  }
  if (props.children) {
    return (
      <Button {...props}>
        {icon} <span className="pdd-left-5">{props.children}</span>
      </Button>
    );
  } else {
    return <Button {...props}>{icon}</Button>;
  }
}

type IconToggleState = { hover: boolean };

class IconToggleButton extends React.Component<any, IconToggleState> {
  constructor(props: any) {
    super(props);
    this.state = { hover: false };
  }

  handleMouseEnter = () => {
    this.setState({ hover: true });
  };

  handleMouseLeave = () => {
    this.setState({ hover: false });
  };

  render(): any {
    let icon;
    if (this.props.pending) {
      icon = <FontAwesomeIcon fixedWidth icon={["fal", "spinner"]} spin />;
    } else if (this.props.error) {
      icon = (
        <FontAwesomeIcon fixedWidth icon={["fal", "exclamation-triangle"]} />
      );
    } else if (this.props.active && (this.props.hover || this.state.hover)) {
      icon = <FontAwesomeIcon fixedWidth icon={["fal", "times"]} />;
    } else {
      icon = (
        <FontAwesomeIcon
          fixedWidth
          icon={this.props.icon}
          color={this.props.color}
        />
      );
    }
    if (this.props.children) {
      return (
        <ToggleButton
          {...this.props}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {icon} <span className="pdd-left-5">{this.props.children}</span>
        </ToggleButton>
      );
    } else {
      return (
        <ToggleButton
          {...this.props}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {icon}
        </ToggleButton>
      );
    }
  }
}

type CheckboxProps = { checked: boolean; children: string };

class Checkbox extends React.Component<CheckboxProps, { checked: boolean }> {
  constructor(props: { checked: boolean; children: string }) {
    super(props);
    this.state = { checked: props.checked };
  }

  handleClick = () => {
    this.setState((state) => {
      return { checked: !state.checked };
    });
  };

  render() {
    let button;
    if (this.state.checked) {
      button = (
        <IconButton
          icon={["fal", "check"]}
          extraSmall
          input
          active
          onClick={this.handleClick}
        />
      );
    } else {
      button = (
        <IconButton
          icon={["fal", "check"]}
          extraSmall
          input
          onClick={this.handleClick}
        />
      );
    }
    return (
      <div>
        <label style={{ cursor: "pointer" }}>
          {button}
          <span className="pdd-left-5">{this.props.children}</span>
        </label>
      </div>
    );
  }
}

class RadioButton extends React.Component<CheckboxProps, { checked: boolean }> {
  constructor(props: { checked: boolean; children: string }) {
    super(props);
    this.state = { checked: props.checked };
  }

  handleClick = () => {
    this.setState((state) => {
      return { checked: !state.checked };
    });
  };

  render() {
    let button;
    if (this.state.checked) {
      button = (
        <IconButton
          icon={["fas", "circle"]}
          extraSmall
          pill
          input
          active
          onClick={this.handleClick}
        />
      );
    } else {
      button = (
        <IconButton
          icon={["fas", "circle"]}
          extraSmall
          pill
          input
          onClick={this.handleClick}
        />
      );
    }
    return (
      <div>
        <label style={{ cursor: "pointer" }}>
          {button}
          <span className="pdd-left-5">{this.props.children}</span>
        </label>
      </div>
    );
  }
}

function ButtonGroup(props: any) {
  const { children, small, extraSmall, pill } = props;
  if (!children) {
    return null;
  }
  const numberOfChildren = React.Children.toArray(children).length;
  const buttons = React.Children.map(children, (child, index) => {
    if (index === 0) {
      return React.cloneElement(child as React.ReactElement<any>, {
        first: "true",
        small: small,
        extraSmall: extraSmall,
        pill: pill
      });
    } else if (index !== numberOfChildren - 1) {
      return React.cloneElement(child as React.ReactElement<any>, {
        middle: "true",
        small: small,
        extraSmall: extraSmall,
        pill: pill
      });
    } else {
      return React.cloneElement(child as React.ReactElement<any>, {
        last: "true",
        small: small,
        extraSmall: extraSmall,
        pill: pill
      });
    }
  });

  return <React.Fragment>{buttons}</React.Fragment>;
}

function readableColor(color: string) {
  return getLuminance(color) > 0.479 ? "#000" : "#fff";
}

export {
  Button,
  ToggleButton,
  IconButton,
  IconToggleButton,
  Checkbox,
  RadioButton,
  ButtonGroup
};
