import * as React from "react";
import { GetUnreadCountQuery } from "../../queries/get_unread_count";
import Error from "../error";

type Props = {
  count: number;
};

export default (props) => (
  <GetUnreadCountQuery>
    {({ loading, error, data }) => {
      if (error) return <Error />;
      if (loading) return <Inbox count={0} {...props} />;
      return <Inbox count={data.unreadCount.count} {...props} />;
    }}
  </GetUnreadCountQuery>
);

class Inbox extends React.Component<Props> {
  render() {
    return <View {...this.props} />;
  }
}

const View = ({ count }) => (
  <a href="/messages">
    <span className="fa-layers fa-fw">
      <i className="fal fa-envelope" />
      {count != 0 && (
        <span className="fa-layers-counter">{count.toLocaleString()}</span>
      )}
    </span>
    <span className="pdd-left-10">Inbox</span>
  </a>
);
