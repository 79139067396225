import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getExams, getExamsVariables } from "./codegen/getExams";

const GET_EXAMS = gql`
  query getExams($userId: ID!) {
    getExams(userId: $userId) {
      id
      courseActivityId
      value
      createdAt
      name
      passingScore
      questions {
        id
        text
        status
        type
        options {
          id
          text
        }
        answers {
          id
          text
          textOptions
          correctOptionId
          correct
          selected
        }
      }
    }
  }
`;

export const GetExamsQuery = (
  props: Omit<QueryComponentOptions<getExams, getExamsVariables>, "query">
): JSX.Element => (
  <Query<getExams, getExamsVariables> query={GET_EXAMS} {...props} />
);
