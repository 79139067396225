import * as React from "react";
import Icon from "../icon";
import { humanizeTimestamp } from "../../date_utilities";
import * as moment from "moment";
import GET_NOTIFICATIONS from "../../queries/get_notifications";
import MARK_NOTIFICATION_AS_READ from "../../queries/mark_notification_as_read";
import { Mutation } from "@apollo/client/react/components";

type NotificationObject = {
  id: string;
  subject: string;
  body: string;
  unread: boolean;
  link: string;
  createdAt: string;
};

const Notification = ({
  id,
  subject,
  createdAt,
  unread,
  link,
  today = moment().startOf("day")
}: NotificationObject & { today: moment.Moment }) => (
  <Mutation
    mutation={MARK_NOTIFICATION_AS_READ}
    update={(cache, data) => {
      const { getNotifications } = cache.readQuery({
        query: GET_NOTIFICATIONS
      });
      cache.writeQuery({
        query: GET_NOTIFICATIONS,
        data: {
          getNotifications: Object.assign({}, getNotifications, {
            notifications: getNotifications.notifications.map(
              (notification) => {
                if (notification.id === id) {
                  return Object.assign({}, notification, { unread: false });
                } else {
                  return notification;
                }
              }
            )
          })
        }
      });
    }}
  >
    {(markNotificationAsRead) => (
      <li className={unread ? "" : "bg-light"} key={`notification-${id}`}>
        <a
          href={link !== "" ? link : "#"}
          onClick={() => {
            markNotificationAsRead({
              variables: { ids: [id] },
              optimisticResponse: {
                __typename: "Mutation",
                markNotificationAsRead: {
                  ids: [id],
                  __typename: "MarkNotificationAsReadPayload"
                }
              }
            });
          }}
          target="blank"
        >
          <span className={`thumb-img ${unread ? "bg-success" : "bg-white"}`}>
            <span className={unread ? "text-white" : "text-success"}>
              <Icon icon="paper-plane" />
            </span>
          </span>
          <div className="info">
            <span className="title">
              <span
                className={`font-size-14 text-semibold test-text ${
                  unread ? "" : "text-muted"
                }`}
              >
                {subject}
              </span>
            </span>
            <span
              className={`sub-title test-timestamp ${
                unread ? "" : "text-muted"
              }`}
            >
              {humanizeTimestamp(createdAt, today)}
            </span>
          </div>
        </a>
      </li>
    )}
  </Mutation>
);

export default Notification;
