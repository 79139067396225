import { gql } from "@apollo/client";

const UPDATE_COURSE_GROUP = gql`
  mutation updateCourseGroup($courseGroup: CourseGroupAttributes!) {
    updateCourseGroup(input: { courseGroup: $courseGroup }) {
      course {
        id
        name
        thumbnail
        duration
        fullDescription
        shortDescription
        completed
        required
        statusIcon {
          id
          icon
          style
          tooltip
        }
      }
    }
  }
`;

export default UPDATE_COURSE_GROUP;
