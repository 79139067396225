import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getCourseAssignments,
  getCourseAssignmentsVariables
} from "./codegen/getCourseAssignments";

export const GET_COURSE_ASSIGNMENTS = gql`
  query getCourseAssignments($userId: ID!, $limit: Int, $offset: Int) {
    getCourseAssignments(userId: $userId, limit: $limit, offset: $offset) {
      moreRecords
      userId
      courses {
        id
        name
        thumbnail
        duration
        fullDescription
        shortDescription
        completed
        required
        statusIcon {
          id
          icon
          style
          tooltip
        }
      }
    }
  }
`;

export const GetCourseAssignmentsQuery = (
  props: Omit<
    QueryComponentOptions<getCourseAssignments, getCourseAssignmentsVariables>,
    "query"
  >
): JSX.Element => (
  <Query<getCourseAssignments, getCourseAssignmentsVariables>
    query={GET_COURSE_ASSIGNMENTS}
    {...props}
  />
);
