import { gql } from "@apollo/client";

const UPDATE_CLASSROOM_COURSE = gql`
  mutation updateClassroomCourse($classroomCourse: ClassroomCourseAttributes!) {
    updateClassroomCourse(input: { classroomCourse: $classroomCourse }) {
      classroomCourse {
        id
      }
    }
  }
`;

export default UPDATE_CLASSROOM_COURSE;
