import * as React from "react";
import * as ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import { isEqual, isFunction } from "lodash";

import Loading from "../loading";
import { Button } from "../buttons";
import { Input } from "../forms/input";
import SEND_EXAM_RESULTS from "../../queries/send_exam_results";
import CREATE_EXAM_ACTIVITY from "../../queries/create_exam_activity";
import { ApolloConsumer } from "@apollo/client";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardTitle
} from "reactstrap";

import { IconButton, ButtonGroup } from "../buttons";

type Props = {
  data: { name: string; questions: Array<Question>; id: string };
  disableExamResultsForwarding: boolean;
  review: boolean;
};

type State = {
  id: string;
  name: string;
  open: boolean;
  answered: Question[];
  current: Question | null;
  unanswered: Question[];
  finished: boolean;
};

type Question = { text: string; answers: Answer[] };

type Answer = { text: string; correct: boolean; selected: boolean };

class Exam extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      id: props.data.id,
      open: false,
      name: props.data.name,
      answered: props.review ? props.data.questions : [],
      current: null,
      unanswered: props.review ? [] : props.data.questions,
      finished: props.review || false
    };
  }

  toggleModal = () => {
    this.setState((state) => {
      if (state.open) {
        return { open: false };
      } else {
        if (isFunction((window as any).jwplayer().pause)) {
          (window as any).jwplayer().pause();
        }
        return { open: true };
      }
    });
  };

  startExam = () => {
    this.setState((state) => {
      const [current, ...unanswered] = this.state.unanswered;
      return {
        current: current,
        unanswered: unanswered
      };
    });
  };

  sendExam = (client: any, state: State) => {
    const result = client.mutate({
      mutation: CREATE_EXAM_ACTIVITY,
      variables: {
        activity: {
          exam: {
            id: state.id,
            name: state.name,
            score: score(state.answered),
            questions: state.answered.map((answered) => {
              return {
                text: answered.text,
                answers: answered.answers.map((answer) => {
                  return {
                    text: answer.text,
                    selected: answer.selected,
                    correct: answer.correct
                  };
                })
              };
            })
          }
        }
      }
    });
    result
      .then(() => {
        this.setState({ ...state, finished: true });
      })
      .catch(() => console.log("Error"));
  };

  nextQuestion = (client: any) => {
    this.setState((state) => {
      if (state.current) {
        const answered = state.answered.concat([state.current]);
        return {
          ...state,
          answered: answered,
          current: null
        };
      } else if (state.unanswered.length === 0) {
        this.sendExam(client, state);
        return { ...state };
      } else if (state.answered.length > 0) {
        const [current, ...unanswered] = state.unanswered;
        return {
          ...state,
          current: current,
          unanswered: unanswered
        };
      } else {
        const answered = [];
        const [current, ...unanswered] = state.unanswered;
        return {
          ...state,
          answered: answered,
          current: current,
          unanswered: unanswered
        };
      }
    });
  };

  isNotStarted = () => {
    const { current, answered } = this.state;
    return current === null && answered.length === 0;
  };

  // Operates on the current question,
  // merging in a modified answer to the question's set of answers.
  toggleAnswer = (indexToToggle: number) => {
    const current = this.state.current;
    if (current) {
      const answers = current.answers.map((answer, index) => {
        if (indexToToggle === index) {
          return { ...answer, selected: !answer.selected };
        } else {
          return answer;
        }
      });
      this.setState({
        current: { ...current, answers: answers }
      });
    }
  };

  render() {
    const { answered, current, unanswered, name } = this.state;

    let exam;
    if (this.isNotStarted()) {
      exam = (
        <React.Fragment>
          <ExamTitle>{name}</ExamTitle>
          <GetStartedButton onClick={this.startExam}>
            Let's Get Started
          </GetStartedButton>
        </React.Fragment>
      );
    } else if (this.state.finished) {
      exam = (
        <Review
          data={this.props.data}
          disableExamResultsForwarding={this.props.disableExamResultsForwarding}
        />
      );
    } else {
      exam = (
        <ApolloConsumer>
          {(client) => (
            <QuestionView
              answered={answered}
              current={current}
              unanswered={unanswered}
              nextQuestion={() => {
                this.nextQuestion(client);
              }}
              toggleAnswer={this.toggleAnswer}
            />
          )}
        </ApolloConsumer>
      );
    }

    let button;
    if (this.props.review) {
      button = (
        <div className="btn btn-secondary btn-sm" onClick={this.toggleModal}>
          <FontAwesomeIcon icon={["fal", "search"]} /> View
        </div>
      );
    } else {
      button = (
        <IconButton
          icon={["fal", "file-alt"]}
          primary
          onClick={this.toggleModal}
        >
          Take Exam
        </IconButton>
      );
    }

    return (
      <React.Fragment>
        {button}
        <Modal
          className="modal-xl"
          isOpen={this.state.open}
          toggle={this.toggleModal}
        >
          <ModalBody style={{ textAlign: "center" }}>{exam}</ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.toggleModal}
            >
              Close
            </button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

const ExamTitle = styled.h1`
  margin: 0 auto;
  padding: 0 0 25px;
  max-width: 1000px;
  line-height: 55px;
  font-size: 48px;
  font-weight: 700;
`;

const GetStartedButton = styled.button`
  outline: none !important;
  display: inline-block;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  font-size: 18px;
  line-height: 1.3333333;
  white-space: nowrap;
  border-radius: 6px;
  transition: none;
  text-transform: capitalize;
  margin: 60px 2px 25px;
  height: 63px;
  border: 0;
  padding: 20px 30px;
  outline-offset: -2px;
  color: #fff;
  background-color: #8fc32e;

  :focus {
    background-color: #719a24;
    border-color: #344711;
  }
`;

const SubmitAnswerButton = styled.button`
  overflow: visible;
  outline: none;
  display: inline-block;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  color: #fff;
  background-color: #f37c35;
  border-color: #f26c1d;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
  transition: none;
  text-transform: capitalize;
  margin: 0 2px;
  height: 63px;
  margin-top: 60px;
  border: 0;
  padding: 20px 30px;
`;

const QuestionText = styled.h2`
  padding-bottom: 10px;
  min-height: 100px;
  line-height: 44px;
  color: #000000;
  font-size: 37px;
  font-weight: 700;
`;

const RemediationText = (styled.div as any)`
  text-align: center;
  text-transform: uppercase;
  line-height: 75px;
  font-size: 65px;
  font-weight: 700;
  filter: drop-shadow(3px 3px 0 #262626);

  ${({ correct }) =>
    correct &&
    css`
      color: #8fc32e;
    `};

  ${({ incorrect }) =>
    incorrect &&
    css`
      color: #f33535;
    `};
`;

const AnswerButton = (styled.button as any)`
  position: relative;
  float: left;
  transition: 0.3s ease-in all;
  margin: 0.5%;
  border-radius: 10px;
  border: 0;
  background: #e0e1e9;
  padding: 20px;
  width: 49%;
  height: auto;
  text-transform: none;
  line-height: 27px;
  white-space: normal;
  color: #545454;
  font-size: 28px;
  font-weight: normal;

  ${({ selected }) =>
    selected &&
    css`
      background: #248dc1;
      color: #fff;
    `};

  ${({ correct }) =>
    correct &&
    css`
      background: green;
      color: #fff;
    `};

  ${({ incorrect }) =>
    incorrect &&
    css`
      background: #f33535;
      color: #fff;
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2;
      cursor: default;
    `};
`;

const ReviewHeading = styled.h2`
  text-align: center;
  text-transform: uppercase;
  line-height: 75px;
  font-size: 65px;
  font-weight: 700;
`;

const ScoreHeading = styled.h3`
  display: inline-block;
  margin-bottom: 30px;
  border-bottom: 8px solid;
  padding: 50px 10px 30px;
  max-width: 500px;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
`;

const ScoreCount = styled.p`
  margin: 0 auto;
  max-width: 382px;
  text-align: left;
  font-size: 34px;
`;

const QuestionList = styled.ol`
  position: relative;
  text-align: left;
  margin-bottom: 50px;
  line-height: 1.47;
  font-size: 31px;
  font-weight: 700;
  list-style-position: inside;

  ol {
    list-style-type: upper-alpha;
  }
`;

function CorrectIcon(props: { style?: Object }) {
  const baseStyles = {
    color: "#8fc32e",
    filter: "drop-shadow(2px 2px 0 #262626)"
  };
  let styles;
  if (props.style) {
    styles = Object.assign({}, baseStyles, props.style);
  } else {
    styles = baseStyles;
  }
  return (
    <span style={styles}>
      <FontAwesomeIcon icon={["fas", "check"]} />
    </span>
  );
}

function IncorrectIcon(props: { style?: Object }) {
  const baseStyles = {
    color: "#f33535",
    filter: "drop-shadow(2px 2px 0 #262626)"
  };
  let styles;
  if (props.style) {
    styles = Object.assign({}, baseStyles, props.style);
  } else {
    styles = baseStyles;
  }
  return (
    <span style={styles}>
      <FontAwesomeIcon icon={["fas", "times"]} />
    </span>
  );
}

type QuestionProps = {
  answered: Array<Question>;
  current: Question | null;
  unanswered: Array<Question>;
  nextQuestion?: () => void;
  toggleAnswer?: (number) => void;
};

function isEveryAnswerCorrect(answers: Array<Answer>): boolean {
  const correct = answers.filter((answer) => answer.correct);
  const selected = answers.filter((answer) => answer.selected);
  return isEqual(correct, selected);
}

function correctCount(questions: Array<Question>): number {
  return questions.filter((question) => isEveryAnswerCorrect(question.answers))
    .length;
}

function score(questions: Array<Question>): number {
  return Math.round((correctCount(questions) / questions.length) * 100);
}

class QuestionView extends React.Component<QuestionProps> {
  constructor(props: QuestionProps) {
    super(props);
  }

  render() {
    const {
      answered,
      current,
      unanswered,
      nextQuestion,
      toggleAnswer
    } = this.props;
    if (current && toggleAnswer) {
      let answers = current.answers.map((answer, index) => {
        if (answer.selected) {
          return (
            <AnswerButton
              key={index}
              selected
              onClick={() => toggleAnswer(index)}
            >
              {answer.text}
            </AnswerButton>
          );
        } else {
          return (
            <AnswerButton key={index} onClick={() => toggleAnswer(index)}>
              {answer.text}
            </AnswerButton>
          );
        }
      });
      return (
        <React.Fragment>
          <ExamProgressBar
            answered={answered}
            current={current}
            unanswered={unanswered}
          />
          <QuestionText>{current.text}</QuestionText>
          {answers}
          <SubmitAnswerButton onClick={nextQuestion}>
            Submit Answer
          </SubmitAnswerButton>
        </React.Fragment>
      );
    } else {
      let question = answered[answered.length - 1];
      const correctAnswers = question.answers.filter(
        (answer) => answer.correct
      );
      const selectedAnswers = question.answers.filter(
        (answer) => answer.selected
      );
      let answers = question.answers.map((answer, index) => {
        if (answer.correct && answer.selected) {
          return (
            <AnswerButton key={index} correct>
              {answer.text}
            </AnswerButton>
          );
        } else if (answer.selected) {
          return (
            <AnswerButton key={index} incorrect>
              {answer.text}
            </AnswerButton>
          );
        } else {
          return (
            <AnswerButton key={index} disabled>
              {answer.text}
            </AnswerButton>
          );
        }
      });

      let remediation;
      if (isEqual(selectedAnswers, correctAnswers)) {
        remediation = (
          <RemediationText correct>
            <FontAwesomeIcon icon={["fas", "check"]} /> Awesome, you got it!
          </RemediationText>
        );
      } else {
        remediation = (
          <RemediationText incorrect>
            <FontAwesomeIcon icon={["fas", "check"]} /> Maybe next time!
          </RemediationText>
        );
      }

      return (
        <React.Fragment>
          <ExamProgressBar
            answered={answered}
            current={current}
            unanswered={unanswered}
          />
          <QuestionText>{question.text}</QuestionText>
          {remediation}
          {answers}
          <SubmitAnswerButton onClick={nextQuestion}>Next</SubmitAnswerButton>
        </React.Fragment>
      );
    }
  }
}

class ExamProgressBar extends React.Component<QuestionProps> {
  constructor(props: QuestionProps) {
    super(props);
  }

  totalNumberOfQuestions = () => {
    const { answered, current, unanswered } = this.props;
    if (current) {
      return answered.concat([current]).concat(unanswered).length;
    } else {
      return answered.concat(unanswered).length;
    }
  };

  render() {
    const { answered, current, unanswered } = this.props;
    const unitWidth = 100 / this.totalNumberOfQuestions();
    const answeredBar = (
      <div
        style={{
          display: "inline-block",
          height: "5px",
          background: "#248dc1",
          width: `${answered.length * unitWidth}%`
        }}
      />
    );
    let currentBar;
    if (current) {
      currentBar = (
        <div
          style={{
            display: "inline-block",
            height: "5px",
            background: "lightblue",
            width: `${unitWidth}%`
          }}
        />
      );
    }
    const unansweredBar = (
      <div
        style={{
          display: "inline-block",
          height: "5px",
          background: "lightgray",
          width: `${unanswered.length * unitWidth}%`
        }}
      />
    );
    return (
      <div>
        <div>
          {answeredBar}
          {currentBar}
          {unansweredBar}
        </div>
        <div>
          QUESTION {current ? answered.length + 1 : answered.length} /{" "}
          {this.totalNumberOfQuestions()}
        </div>
      </div>
    );
  }
}

class Review extends React.Component<
  {
    disableExamResultsForwarding: boolean;
    data: { name: string; questions: Array<Question>; id: string };
  },
  {}
> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    (window as any).UserExam.prepareExam(
      this.props.data,
      this.props.disableExamResultsForwarding
    );
  }

  render() {
    return <div className="js-exam" />;
  }
}

export default Exam;
