import * as React from "react";
import { Filestack } from "../filestack";
import { Button, IconButton } from "../buttons";
import CardHeader from "reactstrap/lib/CardHeader";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";

export const ScormContentsSection = ({ courseForm, onChange }) => (
  <React.Fragment>
    <div className="alert alert-info">
      You may upload a screenshot for the course.
    </div>
    <Card>
      <CardHeader>
        <h2>Upload a new screenshot</h2>
      </CardHeader>
      <CardBody>
        <div
          className="embed-responsive embed-responsive-16by9 scorm-container"
          style={{ backgroundImage: `url(${courseForm.screenshot})` }}
        />
        <Filestack
          options={{ accept: ["image/*"] }}
          onSuccess={result => {
            const file = result.filesUploaded[0];
            const target = {
              name: "screenshot",
              type: "string",
              value: file.url
            };
            onChange({ target });
          }}
          render={onPick => (
            <React.Fragment>
              <IconButton
                type="button"
                icon="cloud-upload"
                info
                onClick={onPick}
              >
                upload
              </IconButton>
              {courseForm.screenshot && (
                <Button
                  danger
                  onClick={() => {
                    const target = {
                      name: "screenshot",
                      type: "string",
                      value: null
                    };
                    onChange({ target });
                  }}
                >
                  Remove Screenshot
                </Button>
              )}
            </React.Fragment>
          )}
        />
      </CardBody>
    </Card>

    <Card>
      <CardHeader>
        <h2>Upload a new SCORM file</h2>
      </CardHeader>
      <CardBody>
        <Filestack
          options={{ accept: [".zip"] }}
          onSuccess={result => {
            const file = result.filesUploaded[0];
            const target = {
              name: "scormFile",
              type: "string",
              value: file.url
            };

            onChange({ target });
          }}
          render={onPick => (
            <IconButton type="button" icon="cloud-upload" info onClick={onPick}>
              upload
            </IconButton>
          )}
        />
      </CardBody>
    </Card>
  </React.Fragment>
);
