import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getCatalogTracks,
  getCatalogTracks_getCatalogTracks
} from "./codegen/getCatalogTracks";

export type Track = getCatalogTracks_getCatalogTracks;

const GET_CATALOG_TRACKS = gql`
  query getCatalogTracks {
    getCatalogTracks {
      id
      name
      description
      courseCount
      progress
    }
  }
`;

export const GetCatalogTracksQuery = (
  props: Omit<QueryComponentOptions<getCatalogTracks>, "query">
): JSX.Element => (
  <Query<getCatalogTracks> query={GET_CATALOG_TRACKS} {...props} />
);
