import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Topic from "./topics/topic";
import TopicEdit from "./topics/topic_edit";
import TopicList from "./topics/topic_list";
import Group from "./groups/group";
import GroupEdit from "./groups/group_edit";
import GroupList from "./groups/group_list";
import Track from "./tracks/track";
import TrackEdit from "./tracks/track_edit";
import TrackList from "./tracks/track_list";
import Classroom from "./classrooms/classroom";
import PackageList from "./packages/package_list";

import {
  GroupCourseList,
  TrackCourseList,
  TopicCourseList
} from "./course_list";

import { toggleCatalogClose } from "./utilities";

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  ApolloProvider
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { cache } from "../../cache";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement() {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: (string: string) => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

class Catalog extends React.Component<{ path: string }> {
  constructor(props) {
    super(props);
  }

  render() {
    const url = this.props.path;

    return (
      <ApolloProvider client={client}>
        <LeftSide url={url} />
      </ApolloProvider>
    );
  }
}

class LeftSide extends React.Component<{ url: string }> {
  constructor(props) {
    super(props);
  }

  render() {
    const url = this.props.url;
    const [_empty, _catalog, type, id, courses, courseId] = url.split("/");

    let content = null;
    if (type == "groups") {
      if (courses == "courses") {
        content = <GroupCourseList id={id} type={type} courseId={courseId} />;
      } else {
        document.title = "Thinkzoom - Groups";
        content = (
          <div className="full-container">
            <div className="email-app">
              <div className="email-wrapper row" style={{ marginLeft: "0" }}>
                <div className="email-list">
                  <GroupList type={type} />
                </div>
                <RightSide url={url} />
              </div>
            </div>
          </div>
        );
      }
    } else if (type == "tracks") {
      if (courses == "courses") {
        content = <TrackCourseList id={id} type={type} courseId={courseId} />;
      } else {
        document.title = "Thinkzoom - Tracks";
        content = (
          <div className="full-container">
            <div className="email-app">
              <div className="email-wrapper row" style={{ marginLeft: "0" }}>
                <div className="email-list">
                  <TrackList type={type} />
                </div>
                <RightSide url={url} />
              </div>
            </div>
          </div>
        );
      }
    } else if (type == "topics") {
      if (courses == "courses") {
        content = <TopicCourseList id={id} type={type} courseId={courseId} />;
      } else {
        document.title = "Thinkzoom - Topics";
        content = (
          <div className="full-container">
            <div className="email-app">
              <div className="email-wrapper row" style={{ marginLeft: "0" }}>
                <div className="email-list">
                  <TopicList type={type} />
                </div>
                <RightSide url={url} />
              </div>
            </div>
          </div>
        );
      }
    } else if (type == "packages") {
      document.title = "Thinkzoom - Topics";
      content = (
        <div className="full-container">
          <div className="email-app">
            <div className="email-wrapper row" style={{ marginLeft: "0" }}>
              <div className="email-list">
                <PackageList id={id} type={type} />
              </div>
              <RightSide url={url}>
                <div className="alert alert-info">
                  Choose a topic on the left to view the courses.
                </div>
              </RightSide>
            </div>
          </div>
        </div>
      );
    }
    return content;
  }
}

class RightSide extends React.Component<{ url: string }> {
  constructor(props) {
    super(props);
  }

  render() {
    const url = this.props.url;
    const [_empty, _catalog, type, id, edit, courseId] = url.split("/");

    let content;
    let open = "";
    if (id) {
      open = "open";
      if (type == "groups") {
        if (edit) {
          content = <GroupEdit url={url} id={id} />;
        } else {
          content = <Group url={url} id={id} />;
        }
      } else if (type == "tracks") {
        if (edit) {
          content = <TrackEdit url={url} id={id} />;
        } else {
          content = <Track url={url} id={id} />;
        }
      } else if (type == "topics") {
        if (edit) {
          content = <TopicEdit url={url} id={id} />;
        } else {
          content = <Topic url={url} id={id} />;
        }
      } else if (type == "packages") {
        if (courseId) {
          content = <Topic url={url} id={courseId} />;
        } else {
          content = (
            <div className="alert alert-info">
              Choose a topic on the left to view it.
            </div>
          );
        }
      }
    } else if (this.props.children) {
      content = this.props.children;
    } else {
      content = (
        <div className="alert alert-info">
          Choose a {type.slice(0, -1)} on the left to view it.
        </div>
      );
    }

    return (
      <div className={`email-content bg-light ${open}`}>
        <div className="email-content-tools">
          <ul>
            <li>
              <button
                type="button"
                className="btn-link back-to-mailbox"
                onClick={toggleCatalogClose}
              >
                <FontAwesomeIcon icon={["fal", "arrow-circle-left"]} /> Back to
                list
              </button>
            </li>
          </ul>
        </div>
        <div className="email-content-wrapper">
          <div className="email-content-detail">{content}</div>
        </div>
      </div>
    );
  }
}

export default Catalog;
