import * as React from "react";
import StatusCorner from "../status-corner";
import { ApolloConsumer } from "@apollo/client";
import { IconButton, Button, ButtonGroup } from "../../buttons";
import { UncontrolledTooltip } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Requirements from "../groups/requirements";
import * as _ from "lodash";
import UPDATE_COURSE_GROUP from "../../../queries/update_course_group";
import { GET_GROUP } from "../../../queries/get_group";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import Parser from 'html-react-parser';
import moment from 'moment'

const DragHandle = SortableHandle<{ courseId: number }>((props) => {
  return (
    <React.Fragment>
      <IconButton
        icon={["fal", "arrows"]}
        small
        id={`course-drag-${props.courseId}`}
      />
      <UncontrolledTooltip
        placement="bottom"
        target={`#course-drag-${props.courseId}`}
        delay={{ show: 0, hide: 0 }}
      >
        Click and drag to move this course.
      </UncontrolledTooltip>
    </React.Fragment>
  );
});

const SortableList = SortableContainer<{
  courses: any[];
  removeCourse?: (number, any) => void;
  url: string;
  id?: string;
  canUpdateCourse?: boolean;
  canRemoveCourse?: boolean;
  onCustomSortEnd: (data: any) => void;
  onSaveRequirement?: () => void;
}>((props) => {
  return (
    <div id="widgets" className="row">
      {props.courses.map((course, index) => {
        return (
          <SortableItem
            {...props}
            key={`item-${index}`}
            index={index}
            course={course}
            customIndex={index}
            courseLength={props.courses.length}
            onSaveRequirement={() => props.onSaveRequirement()}
          />
        );
      })}
    </div>
  );
});

const SortableItem = SortableElement<{
  course: any;
  removeCourse: (number, any) => void;
  url: string;
  id: string;
  canUpdateCourse: boolean;
  canRemoveCourse: boolean;
  onCustomSortEnd: (data: any) => void;
  customIndex: number;
  courseLength: number;
  onSaveRequirement?: () => void;
}>((props) => {
  return <EditListBody {...props} />;
});

class EditList extends React.PureComponent<{
  data: any[];
  removeCourse?: (number, any) => void;
  url: string;
  id?: string;
  canUpdateCourse?: boolean;
  canRemoveCourse?: boolean;
  onSortEnd: (client: any, data: any) => void;
  refetch?: () => void;
}> {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.data.length > 0) {
      return (
        <ApolloConsumer>
          {(client) => (
            <SortableList
              {...this.props}
              courses={this.props.data}
              onSortEnd={(data) => {
                this.props.onSortEnd(client, data); // how does onSortEnd differ form onCustomSortEnd?
              }}
              useDragHandle={true}
              onCustomSortEnd={(data) => {
                this.props.onSortEnd(client, data);
              }}
              onSaveRequirement={() => { this.props.refetch(); } }
            />
          )}
        </ApolloConsumer>
      );
    } else {
      return <div className="alert alert-info">There are no courses.</div>;
    }
  }
}

class EditListBody extends React.Component<
  {
    course: any;
    removeCourse?: (number, any) => void;
    url: string;
    id: string;
    canUpdateCourse: boolean;
    canRemoveCourse: boolean;
    onCustomSortEnd: (data: any) => void;
    customIndex: number;
    courseLength: number;
    onSaveRequirement: () => void;
  },
  { modalIsOpen: boolean; requirement: any, currentRequirement: any }
> {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      requirement: props.course.requirement,
      currentRequirement: props.course.requirement
    };
  }

  componentDidUpdate(prevProps: Readonly<{course: any;}>, prevState: Readonly<{requirement: any; currentRequirement: any;}>, snapshot?: any): void {
    if (this.props.course.requirement != prevProps.course.requirement && this.state.currentRequirement == prevState.currentRequirement){
      this.setState({ ...this.state, currentRequirement: this.props.course.requirement, requirement: this.props.course.requirement })
    }
  }

  translateRequirement = () => {
    let required = false;
    let requiredDate,
      requiredInterval,
      requiredBeginDays,
      requiredStartDate,
      requiredEndDate = null;
    const { requirement } = this.state;

    if (typeof requirement === "boolean") {
      required = requirement;
    } else if (typeof requirement === "string") {
      required = true;
      requiredDate = requirement;
    } else if (
      _.isObject(requirement) &&
      _.has(requirement, "every") &&
      _.has(requirement, "after")
    ) {
      if (requirement.every[0]) {
        requiredInterval = requirement.every[1];
      }
      if (requirement.after[0]) {
        requiredBeginDays = requirement.after[1];
      }
    } else if (
      _.isObject(requirement) &&
      _.has(requirement, "start") &&
      _.has(requirement, "end")
    ) {
      requiredStartDate = requirement.start;
      requiredEndDate = requirement.end;
    }

    return {
      required: required,
      requiredDate: requiredDate,
      requiredInterval: requiredInterval,
      requiredBeginDays: requiredBeginDays,
      requiredStartDate: requiredStartDate,
      requiredEndDate: requiredEndDate
    };
  };

  updateRequirement = (requirement) => {
    this.setState(requirement);
  };

  saveRequirements = (courseId, client) => {
    const onSaveRequirement = this.props.onSaveRequirement;
    client.mutate({
      mutation: UPDATE_COURSE_GROUP,
      variables: {
        courseGroup: Object.assign(
          {
            courseIds: [courseId],
            groupId: this.props.id
          },
          this.translateRequirement()
        )
      },
      update: (cache) => {
        this.toggleModal(true);
        this.setState({ ...this.state, currentRequirement: this.state.requirement });
        onSaveRequirement();
      }
    });
  };

  toggleModal = (update = false) => {
    if(!update && this.state.modalIsOpen) { // when modalIsOpen == true then the modal is closing
      this.setState({ ...this.state, requirement: this.state.currentRequirement });
    }

    this.setState((state) => {
      return {
        modalIsOpen: !state.modalIsOpen
      };
    });
  };

  render() {
    const course = this.props.course;

    return (
      <div className="courselist-item">
        <div className="courselist-thumbnail">
          <div className="embed-responsive embed-responsive-16by9">
            <div className="embed-responsive-item">
              <img
                className="mr-3 img-fluid border"
                src={course.thumbnail}
                alt={course.name}
              />
            </div>
          </div>
          {course.duration && (
            <div className="courselist-length">{course.duration}</div>
          )}
        </div>
        <div className="courselist-details">
          <h5 className="mt-0">{course.name}</h5>
          <ApolloConsumer>
            {(client) => (
              <React.Fragment>
                <ButtonGroup small pill>
                  <IconButton
                    icon={["fal", "caret-up"]}
                    small
                    pill
                    onClick={() => {
                      this.props.onCustomSortEnd({
                        oldIndex: this.props.customIndex,
                        newIndex: 0
                      });
                    }}
                    id={`course-move-top-${course.id}`}
                  />
                  <DragHandle courseId={course.id} />
                  <IconButton
                    icon={["fal", "caret-down"]}
                    small
                    pill
                    onClick={() => {
                      this.props.onCustomSortEnd({
                        oldIndex: this.props.customIndex,
                        newIndex: this.props.courseLength - 1
                      });
                    }}
                    id={`course-move-bottom-${course.id}`}
                  />
                </ButtonGroup>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`#course-move-top-${course.id}`}
                  delay={{ show: 0, hide: 0 }}
                >
                  Move to Top
                </UncontrolledTooltip>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`#course-move-bottom-${course.id}`}
                  delay={{ show: 0, hide: 0 }}
                >
                  Move to Bottom
                </UncontrolledTooltip>
                {this.props.canUpdateCourse && (
                  <React.Fragment>
                    <IconButton
                      icon={["fal", "cog"]}
                      onClick={() => this.toggleModal()}
                      small
                      pill
                      id={`update-course-${course.id}-requirements`}
                    />
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`#update-course-${course.id}-requirements`}
                      delay={{ show: 0, hide: 0 }}
                    >
                      Update Course Requirements
                    </UncontrolledTooltip>
                    {this.state.modalIsOpen && (
                      <Modal
                        className="modal-lg"
                        isOpen={this.state.modalIsOpen}
                        toggle={() => this.toggleModal()}
                        backdrop="static"
                      >
                        <ModalHeader>Update Course Requirements</ModalHeader>
                        <ModalBody>
                          <Requirements
                            updateRequirement={this.updateRequirement}
                            requirement={this.state.requirement}
                          />
                        </ModalBody>
                        <ModalFooter>
                          <button
                            className="btn btn-secondary"
                            onClick={() => this.toggleModal()}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              this.saveRequirements(course.id, client);
                            }}
                          >
                            Update Requirements
                          </button>
                        </ModalFooter>
                      </Modal>
                    )}
                  </React.Fragment>
                )}
                {this.props.canRemoveCourse &&
                  this.props.removeCourse !== undefined && (
                    <React.Fragment>
                      <IconButton
                        icon={["fal", "trash"]}
                        onClick={() => {
                          this.props.removeCourse(course.id, client);
                        }}
                        small
                        pill
                        danger
                        id={`course-delete-${course.id}`}
                      />
                      <UncontrolledTooltip
                        placement="bottom"
                        target={`#course-delete-${course.id}`}
                        delay={{ show: 0, hide: 0 }}
                      >
                        Remove this Course
                      </UncontrolledTooltip>
                    </React.Fragment>
                  )}
                <div className="ml-1 mb-2 pt-2">
                  <small><RenderRequirement requirement={this.state.currentRequirement}/></small>
                </div>
              </React.Fragment>
            )}
          </ApolloConsumer>
          <div className="courselist-description">{Parser(course.fullDescription)}</div>
        </div>
        {course.statusIcon && (
          <StatusCorner
            icon={course.statusIcon.icon}
            look={course.statusIcon.style}
            tooltip={course.statusIcon.tooltip}
            key_id={course.id}
          />
        )}
      </div>
    );
  }
}

export class RenderRequirement extends React.Component<
  { requirement?: any; }, { }
> {
  render () {
    const requirement = this.props.requirement;
    const rType = typeof requirement;
    try {
      if(rType == "boolean") {
        return <p className='mb-0'>This course is <u>{requirement ? "required without a date" : "not required"}</u></p>
      }else if(rType == "string"){
        let formattedDate = moment(requirement).format('MM-DD-yy')
        let titleDate = moment(requirement).format('Do MMMM yy')
        return <p className='mb-0'>This course is required to be completed by <u title={titleDate}>{formattedDate}</u></p>
      }else if(rType == "object"){
        if (requirement.hasOwnProperty('every') && requirement.hasOwnProperty('after')){
          return <p className='mb-0'>This course is required every <u>{requirement.every[1]} days</u> and <u>{requirement.after[1]} days after joining</u></p>
        }else if(requirement.hasOwnProperty('start') && requirement.hasOwnProperty('end')){
          const formattedStartDate = moment(requirement.start).format('MM-DD-yy')
          const titleStartDate = moment(requirement.start).format('Do MMMM yy')
          const formattedEndDate = moment(requirement.end).format('MM-DD-yy')
          const titleEndDate = moment(requirement.end).format('Do MMMM yy')
          return <p className='mb-0'>This course is required from <u title={titleStartDate}>{formattedStartDate}</u> to <u title={titleEndDate}>{formattedEndDate}</u></p>
        }
      }else {
        return null
      }
    } catch (e) {
      return <p className='mb-0'>This course is <u>not required</u></p>
    }
  }
}


export default EditList;
