import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import * as React from "react";

import {
  getGroups,
  getGroupsVariables,
  getGroups_getGroups_currentAccount,
  getGroups_getGroups_currentUser,
  getGroups_getGroups_groups
} from "./codegen/getGroups";

export type Group = getGroups_getGroups_groups;
export type GroupsCurrentUser = getGroups_getGroups_currentUser;
export type GroupsCurrentAccount = getGroups_getGroups_currentAccount;

export const GET_GROUPS = gql`
  query getGroups($offset: Int, $limit: Int) {
    getGroups(offset: $offset, limit: $limit) {
      moreRecords
      groups {
        id
        name
        description
        active
        courseCount
        userCount
        progress
        dueDate
        requiredCount
      }
      currentUser {
        id
        canCreateGroup
      }
      currentAccount {
        disableProgressWheel
      }
    }
  }
`;

export const GetGroupsQuery = (
  props: Omit<QueryComponentOptions<getGroups, getGroupsVariables>, "query">
): JSX.Element => (
  <Query<getGroups, getGroupsVariables> query={GET_GROUPS} {...props} />
);
