import { gql } from "@apollo/client";

const UNFOLLOW_USER = gql`
  mutation unfollowUser($userId: ID!) {
    unfollowUser(input: { userId: $userId }) {
      id
    }
  }
`;

export default UNFOLLOW_USER;
