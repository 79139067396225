import "core-js/stable";
import "regenerator-runtime/runtime";

import { library } from "@fortawesome/fontawesome-svg-core";
import { dom } from "@fortawesome/fontawesome-svg-core";

import { fal } from "@fortawesome/pro-light-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fal, fas, fab);
document.addEventListener("turbolinks:load", () => {
  dom.watch();
});

declare global {
  interface JQuery {
    owlCarousel: (string: string) => void;
  }
}

document.addEventListener("turbolinks:before-cache", function () {
  $(".owl-carousel").owlCarousel("destroy");
});

import Quill from "quill";
import "quill/dist/quill.snow.css";

declare global {
  interface Window {
    Quill: any;
  }
}
window.Quill = Quill;

//import Rails from "rails-ujs";
import "sweet-alert-confirm";
import "sweetalert2/dist/sweetalert2.css";
//Rails.start();

import ReactOnRails from "react-on-rails";
import Header from "../components/header";
import Catalog from "../components/catalog";
import User from "../components/users/user";
import Comments from "../components/comments";
import Dashboard from "../components/dashboard";
import AccountDashboard from "../components/account_dashboard";
import CourseForm from "../components/course_form";
import ContentUpload from "../components/content_upload";
import Contents from "../components/contents";
import CourseBuilder from "../components/course_builder";
import Locations from "../components/locations";
import Registrations from "../components/classroom_sessions/registrations";
import SessionForm from "../components/classroom_sessions/session_form";
import ManageWaitlist from "../components/classroom_sessions/manage_waitlist";
import YoutubeCourse from "../components/youtube_courses/course";
import Course from "../components/users/course";
import Assignments from "../components/assignments";
import { EditClassroomCourse } from "../components/classroom_courses/edit";

ReactOnRails.register({
  Header,
  Catalog,
  Dashboard,
  AccountDashboard,
  User,
  Comments,
  CourseForm,
  ContentUpload,
  Contents,
  CourseBuilder,
  Locations,
  Registrations,
  SessionForm,
  ManageWaitlist,
  YoutubeCourse,
  Course,
  Assignments,
  EditClassroomCourse
});
