import { gql } from "@apollo/client";

const DELETE_GROUP = gql`
  mutation deleteGroup($groupId: ID!) {
    deleteGroup(input: { groupId: $groupId }) {
      id
    }
  }
`;

export default DELETE_GROUP;
