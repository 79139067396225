import { gql } from "@apollo/client";

const UPDATE_TRACK = gql`
  mutation updateTrack($track: TrackAttributes!) {
    updateTrack(input: { track: $track }) {
      track {
        id
        name
        description
      }
    }
  }
`;

export default UPDATE_TRACK;
