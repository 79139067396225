import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2-react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonGroup
} from "reactstrap";
import { Mutation } from "@apollo/client/react/components";
import { GET_TRACKS } from "../../../queries/get_tracks";
import CLONE_TRACK from "../../../queries/clone_track";
import DELETE_TRACK from "../../../queries/delete_track";
import { ManageTrackMembers } from "../manage_members";
import REMOVE_TRACKS_USER from "../../../queries/remove_tracks_user";
import CREATE_TRACKS_USER from "../../../queries/create_tracks_user";
import GENERATE_TRACK_REPORT from "../../../queries/generate_track_report";
import { GET_TRACK } from "../../../queries/get_track";
import Icon from "../../icon";
import PUBLISH_TRACK from "../../../queries/publish_track";
import Fancy from "../../forms/fancy";
import { Input, Textarea } from "../../forms/input";
import { Button } from "../../buttons";
import UPDATE_TRACK from "../../../queries/update_track";

class TrackAdminButtons extends React.PureComponent<
  { url: string; track: any },
  {
    dropdownOpen: boolean;
    editModalIsOpen: boolean;
    deleteAlertIsOpen: boolean;
    cloneAlertIsOpen: boolean;
    membersModalIsOpen: boolean;
  }
> {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      editModalIsOpen: false,
      deleteAlertIsOpen: false,
      cloneAlertIsOpen: false,
      membersModalIsOpen: false
    };
  }

  toggleDeleteAlert = () => {
    this.setState((prevState) => {
      return { deleteAlertIsOpen: !prevState.deleteAlertIsOpen };
    });
  };

  toggleCloneAlert = () => {
    this.setState((prevState) => {
      return { cloneAlertIsOpen: !prevState.cloneAlertIsOpen };
    });
  };

  toggle = () => {
    this.setState((state) => {
      return {
        dropdownOpen: !state.dropdownOpen
      };
    });
  };

  toggleMembersModal = () => {
    this.setState((prevState) => {
      return {
        membersModalIsOpen: !prevState.membersModalIsOpen
      };
    });
  };

  toggleEditModal = () => {
    this.setState((prevState) => {
      return {
        editModalIsOpen: !prevState.editModalIsOpen
      };
    });
  };

  render() {
    const { track } = this.props;
    return (
      <React.Fragment>
        <ButtonGroup className="float-right">
          <UserEnrollment track={this.props.track} />
          {(this.props.track.currentUser.canManageReports ||
            this.props.track.currentUser.canManageTracks ||
            this.props.track.currentUser.hasChildren) && (
            <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle caret color="default" className="btn-rounded">
                <FontAwesomeIcon fixedWidth icon={["fal", "cogs"]} /> Admin
              </DropdownToggle>
              <DropdownMenu>
                {this.props.track.currentUser.canManageReports && (
                  <GenerateReport trackId={this.props.track.id} />
                )}
                {this.props.track.currentUser.canManageTracks && (
                  <React.Fragment>
                    <CloneTrack
                      track={this.props.track}
                      toggleCloneAlert={this.toggleCloneAlert}
                      cloneAlertIsOpen={this.state.cloneAlertIsOpen}
                    />
                    <DeleteTrack
                      track={this.props.track}
                      toggleDeleteAlert={this.toggleDeleteAlert}
                      deleteAlertIsOpen={this.state.deleteAlertIsOpen}
                    />
                    {track.belongsToCampus && (
                      <React.Fragment>
                        <a href={`/catalog/tracks/${track.id}/edit`} className="dropdown-item">
                          <FontAwesomeIcon fixedWidth icon={["fal", "book"]} /> Manage Courses
                        </a>
                        <DropdownItem onClick={this.toggleEditModal}>
                          <FontAwesomeIcon fixedWidth icon={["fal", "pencil"]} /> Edit Track
                        </DropdownItem>
                      </React.Fragment>
                    )}
                    <PublishTrackButton track={track} />
                  </React.Fragment>
                )}
                {(this.props.track.currentUser.canManageTracks ||
                  this.props.track.currentUser.hasChildren) && (
                  <DropdownItem onClick={this.toggleMembersModal}>
                    <FontAwesomeIcon fixedWidth icon={["fal", "users"]} /> Manage Members
                  </DropdownItem>
                )}
              </DropdownMenu>
            </ButtonDropdown>
          )}
        </ButtonGroup>
        {(this.props.track.currentUser.canManageTracks || this.props.track.currentUser.hasChildren) && (
          <ManageTrackMembers
            id={this.props.track.id}
            toggleModal={this.toggleMembersModal}
            modalIsOpen={this.state.membersModalIsOpen}
          />
        )}
        {this.props.track.currentUser.canManageTracks && track.belongsToCampus && (
          <TrackEditForm
            track={this.props.track}
            toggleModal={this.toggleEditModal}
            modalIsOpen={this.state.editModalIsOpen}
          />
        )}
      </React.Fragment>
    );
  }
}

const PublishTrackButton = ({ track }) => {
  if (track.belongsToCampus && track.currentUser.canManageTracks && !track.published) {
    return (
      <Mutation mutation={PUBLISH_TRACK} refetchQueries={[{ query: GET_TRACK, variables: { id: track.id } }]}>
        {(publishTrack) => (
          <DropdownItem
            onClick={() => {
              publishTrack({
                variables: { id: track.id }
              });
            }}
          >
            <Icon icon="upload" /> Publish Track
          </DropdownItem>
        )}
      </Mutation>
    );
  } else {
    return null;
  }
};

const UserEnrollment = ({ track }) => {
  if (track.isCurrentUserEnrolled) {
    return (
      <Mutation
        mutation={REMOVE_TRACKS_USER}
        refetchQueries={[{ query: GET_TRACK, variables: { id: track.id } }, { query: GET_TRACKS }]}
      >
        {(removeTracksUser) => (
          <button
            className="btn btn-danger btn-rounded"
            onClick={() => {
              removeTracksUser({
                variables: { id: track.enrollmentId }
              });
            }}
          >
            <FontAwesomeIcon fixedWidth icon={["fal", "user"]} /> Remove Yourself
          </button>
        )}
      </Mutation>
    );
  } else {
    return (
      <Mutation
        mutation={CREATE_TRACKS_USER}
        refetchQueries={[{ query: GET_TRACK, variables: { id: track.id } }, { query: GET_TRACKS }]}
      >
        {(createTracksUser) => (
          <button
            className="btn btn-primary btn-rounded"
            onClick={() => {
              createTracksUser({
                variables: { id: track.id }
              });
            }}
          >
            <FontAwesomeIcon fixedWidth icon={["fal", "user"]} /> Enroll Yourself
          </button>
        )}
      </Mutation>
    );
  }
};

class GenerateReport extends React.PureComponent<
  {
    trackId: number;
  },
  {}
> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Mutation
        mutation={GENERATE_TRACK_REPORT}
        update={(cache, { data }) => {
          window.location.href = `/reports/forms/${data.generateTrackReport.id}/edit`;
        }}
      >
        {(generateTrackReport) => (
          <React.Fragment>
            <DropdownItem
              onClick={() => {
                generateTrackReport({
                  variables: { trackId: this.props.trackId }
                });
              }}
            >
              <FontAwesomeIcon fixedWidth icon={["fal", "chart-bar"]} /> Generate Report
            </DropdownItem>
          </React.Fragment>
        )}
      </Mutation>
    );
  }
}

class DeleteTrack extends React.PureComponent<
  {
    track: any;
    toggleDeleteAlert: () => void;
    deleteAlertIsOpen: boolean;
  },
  {}
> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Mutation
        mutation={DELETE_TRACK}
        refetchQueries={[{ query: GET_TRACKS }]}
        update={() => {
          window.location.href = "/catalog/tracks";
        }}
      >
        {(deleteTrack) => (
          <React.Fragment>
            <DropdownItem onClick={this.props.toggleDeleteAlert}>
              <FontAwesomeIcon fixedWidth icon={["fal", "trash"]} /> Delete Track
            </DropdownItem>
            <SweetAlert
              show={this.props.deleteAlertIsOpen}
              title="Delete Track"
              text="Are you sure you want to delete this track?"
              showCancelButton={true}
              onCancel={() => {
                this.props.toggleDeleteAlert();
              }}
              onConfirm={() => {
                this.props.toggleDeleteAlert();
                deleteTrack({
                  variables: {
                    trackId: this.props.track.id
                  }
                });
              }}
            />
          </React.Fragment>
        )}
      </Mutation>
    );
  }
}

class CloneTrack extends React.PureComponent<
  {
    track: any;
    toggleCloneAlert: () => void;
    cloneAlertIsOpen: boolean;
  },
  {}
> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Mutation
        mutation={CLONE_TRACK}
        onCompleted={() => {
          const alert = document.getElementById("react-alert");
          alert.innerText = "Your track is being cloned.";
          alert.classList.remove("d-none");
          window.location.href = "/catalog/tracks";
        }}
      >
        {(cloneTrack) => (
          <React.Fragment>
            <DropdownItem onClick={this.props.toggleCloneAlert}>
              <FontAwesomeIcon fixedWidth icon={["fal", "clone"]} /> Clone Track
            </DropdownItem>
            <SweetAlert
              show={this.props.cloneAlertIsOpen}
              title="Clone Track"
              text="Cloned tracks do not automatically update when new content is added. This action will also deactivate the current track. Are you sure you want to clone this track?"
              showCancelButton={true}
              onCancel={() => {
                this.props.toggleCloneAlert();
              }}
              onConfirm={() => {
                this.props.toggleCloneAlert();
                cloneTrack({
                  variables: {
                    trackId: this.props.track.id
                  }
                });
              }}
            />
          </React.Fragment>
        )}
      </Mutation>
    );
  }
}

class TrackEditForm extends React.PureComponent<
  { track: any; toggleModal: () => void; modalIsOpen: boolean },
  {}
> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal
        className="modal-dialog modal-lg"
        isOpen={this.props.modalIsOpen}
        toggle={this.props.toggleModal}
      >
        <ModalHeader toggle={this.props.toggleModal}>Update {this.props.track.name}</ModalHeader>
        <ModalBody>
          <div className="padding-15">
            <Fancy
              mutation={UPDATE_TRACK}
              onComplete={{
                message: "Your track has been updated",
                callback: (e) => {
                  if (e.updateTrack !== null) {
                    // if there is not any errors with updating
                    this.props.toggleModal();
                  }
                }
              }}
              variables={{
                track: {
                  id: this.props.track.id,
                  name: this.props.track.name,
                  description: this.props.track.description
                }
              }}
              render={({ errors, onChange }) => {
                return (
                  <React.Fragment>
                    <Input
                      onChange={onChange}
                      defaultValue={this.props.track.name}
                      field="name"
                      errors={errors}
                    >
                      Name
                    </Input>
                    <Textarea
                      onChange={onChange}
                      defaultValue={this.props.track.description}
                      field="description"
                      errors={errors}
                    >
                      Description
                    </Textarea>

                    <Button primary>Update Track</Button>
                  </React.Fragment>
                );
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default TrackAdminButtons;
