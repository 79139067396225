import React, { PureComponent, Fragment } from "react";
import ReactDOM from "react-dom";
import Loading from "../../loading";
import Error from "../../error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopicAdminButtons from "./topic-admin-buttons";
import EditList from "../courses/edit_list";

import { GetTopicQuery, Topic, TopicCourse } from "../../../queries/get_topic";
import UPDATE_COURSE_TOPICS from "../../../queries/update_course_topics";
import arrayMove from "array-move";
import { Query } from "@apollo/client/react/components";

function TopicEdit(props) {
  return (
    <GetTopicQuery
      variables={{ id: props.id, limit: 0 }}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        document.title = `Thinkzoom - Topics: ${data.getTopic.name} - Edit`;
        if (data.getTopic.currentUser.canManageContent) {
          return <TopicEditBody {...props} topic={data.getTopic} />;
        } else {
          return (
            <div className="alert alert-danger">
              You are not authorized to edit this topic.
            </div>
          );
        }
      }}
    </GetTopicQuery>
  );
}

interface TopicEditBodyProps {
  topic: Topic;
  url: string;
}

interface State {
  courses: TopicCourse[];
}

class TopicEditBody extends PureComponent<TopicEditBodyProps, State> {
  constructor(props: TopicEditBodyProps) {
    super(props);

    this.state = {
      courses: props.topic.courses
    };
  }

  onSortEnd = (client, { oldIndex, newIndex }) => {
    const courses = arrayMove(this.state.courses, oldIndex, newIndex);
    const courseIds = courses.map((course) => {
      return course.id;
    });

    client.mutate({
      mutation: UPDATE_COURSE_TOPICS,
      update: () => {
        this.setState({ courses: courses });
      },
      variables: { courseIds: courseIds, topicId: this.props.topic.id }
    });
  };

  render() {
    const url = this.props.url;
    const data = this.props.topic;

    return (
      <Fragment>
        <div className="float-right">
          <a
            href={url.split("/").slice(0, -1).join("/")}
            className="btn btn-rounded btn-primary"
          >
            Done Editing
          </a>
        </div>
        <h1 className="clearfix">
          <FontAwesomeIcon icon={["fal", "users"]} /> {data.name}
        </h1>
        <p>{data.description}</p>
        <div className="card">
          <div className="card-body">
            <EditList
              data={this.state.courses}
              url={url}
              onSortEnd={this.onSortEnd}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TopicEdit;
