import { gql } from "@apollo/client";
import {
  getClassroomTopics,
  getClassroomTopicsVariables,
  getClassroomTopics_getClassroomTopics_topics
} from "./codegen/getClassroomTopics";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import * as React from "react";

export type ClassroomTopic = getClassroomTopics_getClassroomTopics_topics;

export const GET_CLASSROOM_TOPICS = gql`
  query getClassroomTopics($offset: Int, $limit: Int) {
    getClassroomTopics(offset: $offset, limit: $limit) {
      moreRecords
      topics {
        id
        name
        description
      }
    }
  }
`;

export const GetClassroomTopicsQuery = (
  props: Omit<
    QueryComponentOptions<getClassroomTopics, getClassroomTopicsVariables>,
    "query"
  >
): JSX.Element => (
  <Query<getClassroomTopics, getClassroomTopicsVariables>
    query={GET_CLASSROOM_TOPICS}
    {...props}
  />
);
