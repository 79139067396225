import * as React from "react";
import Loading from "../loading";
import Error from "../error";
import { GetBobIntegrationQuery } from "../../queries/get_bob_integration";

interface Props {
  data: string;
}

export const BOBWidget = (): JSX.Element => {
  return (
    <GetBobIntegrationQuery>
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        return <BOBWidgetBody data={data.getBobIntegration.key} />;
      }}
  </GetBobIntegrationQuery>);
};

class BOBWidgetBody extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  render() {
    const data = this.props.data;

    return (
      <React.Fragment>
      <div
        className="embed-responsive embed-responsive-16by9"
        style={{ margin: "0 auto", maxWidth: "460px" }}
      >
        <iframe
          scrolling="no"
          src={"https://www3.blueoceanbrain.com/iframe/" + data}
          className="embed-responsive-item"
        ></iframe>
      </div>
      </React.Fragment>
    );
  }
}
