import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getTopRecommendationsChart } from "./codegen/getTopRecommendationsChart";

export const CHARTS_TOP_RECOMMENDATIONS = gql`
  query getTopRecommendationsChart {
    charts_top_recommendations {
      id
      label
      value
      amount
    }
  }
`;

export const GetChartsTopRecommendationsQuery = (
  props: Omit<QueryComponentOptions<getTopRecommendationsChart>, "query">
): JSX.Element => (
  <Query<getTopRecommendationsChart>
    query={CHARTS_TOP_RECOMMENDATIONS}
    {...props}
  />
);
