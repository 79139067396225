import { gql } from "@apollo/client";

const CLONE_TRACK = gql`
  mutation cloneTrack($trackId: ID!) {
    cloneTrack(input: { trackId: $trackId }) {
      id
    }
  }
`;

export default CLONE_TRACK;
