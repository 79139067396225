import { gql } from "@apollo/client";

const UPDATE_CLASSROOM_WAITLIST = gql`
  mutation updateClassroomWaitlist(
    $classroomWaitlist: ClassroomWaitlistAttributes!
  ) {
    updateClassroomWaitlist(input: { classroomWaitlist: $classroomWaitlist }) {
      registration {
        id
        waitlisted
        createdAt
        user {
          id
          fullName
          longIdentifier
        }
        activities {
          id
          user {
            id
            fullName
          }
          event
          timestamp
          duration
          value
          passingScore
        }
      }
      status
    }
  }
`;

export default UPDATE_CLASSROOM_WAITLIST;
