import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getAchievements,
  getAchievementsVariables
} from "./codegen/getAchievements";

const GET_ACHIEVEMENTS = gql`
  query getAchievements($userId: ID!) {
    getAchievements(userId: $userId) {
      id
      name
      image
      description
    }
  }
`;

export const GetAchievementsQuery = (
  props: Omit<
    QueryComponentOptions<getAchievements, getAchievementsVariables>,
    "query"
  >
): JSX.Element => (
  <Query<getAchievements, getAchievementsVariables>
    query={GET_ACHIEVEMENTS}
    {...props}
  />
);
