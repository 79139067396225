import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getRequiredCourses } from "./codegen/getRequiredCourses";

export const CATALOG_COURSES_REQUIRED = gql`
  query getRequiredCourses {
    catalog_courses_required {
      id
      name
      shortDescription
      thumbnail
      duration
      statusIcon {
        id
        icon
        style
        tooltip
      }
    }
  }
`;

export const GetCatalogCoursesRequiredQuery = (
  props: Omit<QueryComponentOptions<getRequiredCourses>, "query">
): JSX.Element => (
  <Query<getRequiredCourses> query={CATALOG_COURSES_REQUIRED} {...props} />
);
