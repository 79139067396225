import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getActivityStream,
  getActivityStreamVariables
} from "./codegen/getActivityStream";

const GET_ACTIVITY = gql`
  query getActivityStream($userId: ID!) {
    getActivityStream(userId: $userId) {
      id
      name
      score
      timestamp
      verb
      course {
        id
        thumbnail
        name
      }
    }
  }
`;

export const GetActivityQuery = (
  props: Omit<
    QueryComponentOptions<getActivityStream, getActivityStreamVariables>,
    "query"
  >
): JSX.Element => (
  <Query<getActivityStream, getActivityStreamVariables>
    query={GET_ACTIVITY}
    {...props}
  />
);
