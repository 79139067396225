import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import UPDATE_CLASSROOM_COURSE from "../../queries/update_classroom_course";
import React, { ReactNode } from "react";
import { cache } from "../../cache";
import { GET_CLASSROOM_COURSE } from "../../queries/get_classroom_course";
import { GET_CLASSROOM_TOPICS } from "../../queries/get_classroom_topics";
import { ClassroomCourseEditForm } from "./edit_form";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement() {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: (_: string) => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
          console.error("Graphql message", message);
          console.error("Graphql locations", locations);
          console.error("Graphql path", path);
        });
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

interface Props {
  id: string;
  name: string;
  description: string;
  topics: Topic[];
  thumbnailUrl: string;
  children?: ReactNode;
}

type Topic = {
  id: string;
  value: string;
  label: string;
};

export const EditClassroomCourse = ({
  id,
  name,
  description,
  topics,
  thumbnailUrl
}: Props): JSX.Element => (
  <ApolloProvider client={client}>
    <h1>Edit {name}</h1>
    <ClassroomCourseEditForm
      mutation={UPDATE_CLASSROOM_COURSE}
      classroomCourse={{
        id: id,
        name: name,
        description: description,
        topicNames: topics.map((topic) => topic.value),
        thumbnail: thumbnailUrl
      }}
      topics={topics}
      refetchQueries={[
        { query: GET_CLASSROOM_COURSE, variables: { id: id } },
        { query: GET_CLASSROOM_TOPICS }
      ]}
      update={() => {
        if (window.sessionStorage.getItem("pathname")) {
          window.location.href = window.sessionStorage.getItem("pathname");
        } else {
          window.location.href = "/classrooms";
        }
      }}
    />
  </ApolloProvider>
);
