import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getUser, getUserVariables } from "./codegen/getUser";

export const GET_USER = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      id
      tinyAvatar
      avatar
      firstName
      lastName
      username
      email
      lastLogin
      fullName
      bio
      jobTitle
      followerCount
      followingCount
      active
      points
      totalTime
      disableExamResultsForwarding
      disableMessageSending
      disableQuad
      showLifetime
      currentUser {
        id
        fullName
        points
        canManageUser
        isFollowing
      }
    }
  }
`;

export const GetUserQuery = (
  props: Omit<QueryComponentOptions<getUser, getUserVariables>, "query">
): JSX.Element => (
  <Query<getUser, getUserVariables> query={GET_USER} {...props} />
);
