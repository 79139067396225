import * as React from "react";
import * as ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Mutation } from "@apollo/client/react/components";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip
} from "reactstrap";
import GENERATE_TOPIC_REPORT from "../../../queries/generate_topic_report";

class TopicAdminButtons extends React.PureComponent<
  { url: string; topic: any },
  { dropdownOpen: boolean }
> {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false
    };
  }

  toggle = () => {
    this.setState((state) => {
      return {
        dropdownOpen: !state.dropdownOpen
      };
    });
  };

  render() {
    const url = this.props.url;

    return (
      <ButtonDropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        className="float-right"
      >
        <DropdownToggle caret color="default" className="btn-rounded">
          <FontAwesomeIcon fixedWidth icon={["fal", "cogs"]} /> Admin
        </DropdownToggle>
        <DropdownMenu>
          {this.props.topic.currentUser.canManageReports && (
            <GenerateReport topicId={this.props.topic.id} />
          )}
          {this.props.topic.currentUser.canManageContent && (
            <div className="dropdown-item">
              <a href={`${url}/edit`} style={{ color: "#212529" }}>
                <FontAwesomeIcon fixedWidth icon={["fal", "book"]} /> Reorder
                Courses
              </a>
            </div>
          )}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}

class GenerateReport extends React.PureComponent<
  {
    topicId: number;
  },
  {}
> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Mutation
        mutation={GENERATE_TOPIC_REPORT}
        update={(cache, { data }) => {
          window.location.href = `/reports/forms/${data.generateTopicReport.id}/edit`;
        }}
      >
        {(generateTopicReport) => (
          <React.Fragment>
            <DropdownItem
              onClick={() => {
                generateTopicReport({
                  variables: { topicId: this.props.topicId }
                });
              }}
            >
              <FontAwesomeIcon fixedWidth icon={["fal", "chart-bar"]} />{" "}
              Generate Report
            </DropdownItem>
          </React.Fragment>
        )}
      </Mutation>
    );
  }
}

export default TopicAdminButtons;
