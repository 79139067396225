import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getActivities,
  getActivities_activities
} from "./codegen/getActivities";

export type Activity = getActivities_activities;

const GET_ACTIVITIES = gql`
  query getActivities {
    activities {
      id
      message
      ending
      createdAt
      trackable {
        id
        score
        link
        name
      }
      owner {
        id
        name
        link
        thumb
      }
      recipient {
        id
        name
        link
      }
    }
  }
`;

export const GetActivitiesQuery = (
  props: Omit<QueryComponentOptions<getActivities>, "query">
): JSX.Element => <Query<getActivities> query={GET_ACTIVITIES} {...props} />;
