import * as React from "react";
import Loading from "../loading";
import Error from "../error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressCircle from "../catalog/progress-circle";
import GroupFooter from "../catalog/groups/group-footer";
import { GroupCard, GroupCardProps } from "../group_cards";
import { GetCatalogGroupsQuery } from "../../queries/get_catalog_groups";

interface Groups {
  url: string;
  size: number;
}

function Groups(props: Groups): JSX.Element {
  const url = props.url;
  return (
    <GetCatalogGroupsQuery fetchPolicy="network-only">
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        return <GroupsBody url={url} data={data} size={props.size} />;
      }}
    </GetCatalogGroupsQuery>
  );
}

class GroupsBody extends React.PureComponent<Groups & { data: any }> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.defineCarousel();
  }

  componentDidUpdate() {
    this.defineCarousel();
  }

  defineCarousel() {
    const url = this.props.url;
    ($ as any)('[data-url="' + url + '"]').owlCarousel({
      loop: false,
      navText: [
        "<i class='fal fa-chevron-left'></i>",
        "<i class='fal fa-chevron-right'></i>"
      ],
      nav: true,
      dots: false,
      margin: 15,
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        550: {
          items: 3
        },
        768: {
          items: 4
        },
        1024: {
          items: 5
        },
        1400: {
          items: 6
        },
        1700: {
          items: 7
        }
      },
      responsiveBaseElement: $('[data-url="' + url + '"]')[0]
    });
  }

  render() {
    const data = this.props.data[Object.keys(this.props.data)[0]];
    if (data.length <= 0) {
      return (
        <div className="alert alert-info">
          You are not a member of any groups.
        </div>
      );
    }

    let groups = null;
    if (this.props.size == 4) {
      groups = (
        <table className="table table-lg table-hover">
          <tbody>
            {data.map((group, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div className="list-info">
                      <div className="info" style={{ paddingLeft: "0" }}>
                        <span className="title">
                          <a href={`catalog/groups/${group.id}`}>
                            {group.name}
                          </a>
                        </span>
                        <span className="sub-title">{group.description}</span>
                        <div className="d-inline-block pdd-right-15">
                          <FontAwesomeIcon icon={["fal", "users"]} fixedWidth />
                          <span className="pdd-left-5">{group.userCount}</span>
                        </div>
                        <div className="d-inline-block">
                          <FontAwesomeIcon icon={["fal", "tv"]} fixedWidth />
                          <span className="pdd-left-5">
                            {group.courseCount}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="float-right" style={{ marginTop: "-5px" }}>
                      <GroupFooter
                        count={group.requiredCount}
                        date={group.dueDate}
                      />

                      {!group.currentAccount.disableProgressWheel &&
                        Number.isInteger(group.progress) && (
                          <div className="progress-small-right">
                            <ProgressCircle progress={group.progress} />
                          </div>
                        )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      groups = (
        <div
          className="owl-carousel"
          style={{ zIndex: 0 }}
          data-url={this.props.url}
        >
          {data.map((group, index) => {
            return (
              <GroupCard
                name={group.name}
                groupId={group.id}
                description={group.description}
                userCount={group.userCount}
                courseCount={group.courseCount}
                progress={group.progress}
                requiredCount={group.requiredCount}
                dueDate={group.dueDate}
                key={index}
                currentAccount={group.currentAccount}
              />
            );
          })}
        </div>
      );
    }

    return groups;
  }
}

export default Groups;
