import * as React from "react";
import { Filestack } from "../filestack";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../buttons";
import Fancy from "../forms/fancy";
import { Input, Textarea } from "../forms/input";
import CREATE_CONTENT from "../../queries/create_content";
import UPLOAD_SCORM_PACKAGE from "../../queries/upload_scorm_package";
import SweetAlert from "sweetalert2-react";
import ClassroomCourseForm from "../classroom_courses/form";
import CREATE_CLASSROOM_COURSE from "../../queries/create_classroom_course";
import { GET_CLASSROOM_TOPICS } from "../../queries/get_classroom_topics";

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  ApolloProvider
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { cache } from "../../cache";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement() {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: (string: string) => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

export default class CourseBuilder extends React.Component<
  { allowScorm: boolean },
  {
    modalIsOpen: boolean;
    courseModalIsOpen: boolean;
    alertIsOpen: boolean;
    file: { key: string; url: string; filename: string };
  }
> {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      courseModalIsOpen: false,
      alertIsOpen: false,
      file: {
        key: null,
        url: null,
        filename: null
      }
    };
  }

  toggleModal = () => {
    this.setState((state) => {
      return { modalIsOpen: !state.modalIsOpen };
    });
  };

  toggleCourseModal = () => {
    this.setState((state) => {
      return { courseModalIsOpen: !state.courseModalIsOpen };
    });
  };

  toggleAlert = () => {
    this.setState((state) => {
      return { alertIsOpen: !state.alertIsOpen };
    });
  };

  render() {
    return (
      <ApolloProvider client={client}>
        <h1>Build a new course</h1>
        <p>
          Create a new course by selecting the method you would like to use for
          creating the course below.
        </p>
        <div className="card-deck">
          <div className="card border-info">
            <div className="card-body">
              <Filestack
                options={{
                  accept: [
                    "video/*",
                    ".pdf",
                    ".doc",
                    ".docx",
                    ".ppt",
                    ".pptx",
                    ".xls",
                    ".xlsx"
                  ]
                }}
                onSuccess={(result) => {
                  const file = result.filesUploaded[0];
                  this.setState({ file });
                  this.toggleModal();
                }}
                render={(onPick) => (
                  <a href="javascript:void(0);" onClick={onPick}>
                    <p className="text-center">
                      <FontAwesomeIcon icon={["fal", "upload"]} size="6x" />
                    </p>
                    <p className="text-center">Upload a Video/Document</p>
                  </a>
                )}
              />
            </div>
          </div>
          <div className="card border-info">
            <div className="card-body">
              <a
                href="javascript:void(0);"
                data-toggle="modal"
                data-target="#recorder_launchv2"
                data-backdrop="static"
              >
                <p className="text-center">
                  <FontAwesomeIcon icon={["fal", "microphone-alt"]} size="6x" />
                </p>
                <p className="text-center">Make a Recording</p>
              </a>
            </div>
          </div>
        </div>
        <div className="card-deck mt-3">
          <div className="card border-info">
            <div className="card-body">
              <a href="/course_forms/new">
                <p className="text-center">
                  <FontAwesomeIcon icon={["fal", "file-export"]} size="6x" />
                </p>
                <p className="text-center">Use Existing Content</p>
              </a>
            </div>
          </div>
          {this.props.allowScorm && (
            <div className="card border-info">
              <div className="card-body">
                <Filestack
                  options={{
                    accept: [".zip"],
                    fromSources: ["local_file_system", "googledrive", "box"],
                    customText: {
                      "Select Files to Upload": "Select a File to Upload",
                      "or Drag and Drop, Copy and Paste Files":
                        "or Drag and Drop, Copy and Paste a File",
                      "Drop your files anywhere": "Drop your file anywhere",
                      "We only extract images and never modify or delete them.":
                        "We only extract files and never modify or delete them."
                    },
                    maxFiles: 1
                  }}
                  onSuccess={(result) => {
                    const file = result.filesUploaded[0];
                    client.mutate({
                      mutation: UPLOAD_SCORM_PACKAGE,
                      variables: { url: file.url, title: file.name },
                      update: () => {
                        this.toggleAlert();
                      }
                    });
                  }}
                  render={(onPick) => (
                    <a href="javascript:void(0);" onClick={onPick}>
                      <p className="text-center">
                        <FontAwesomeIcon
                          icon={["fal", "file-archive"]}
                          size="6x"
                        />
                      </p>
                      <p className="text-center">Upload a SCORM Package</p>
                    </a>
                  )}
                />
              </div>
            </div>
          )}
          <div className="card border-info">
            <div className="card-body">
              <a href="javascript:void(0);" onClick={this.toggleCourseModal}>
                <p className="text-center">
                  <FontAwesomeIcon
                    icon={["fal", "chalkboard-teacher"]}
                    size="6x"
                  />
                </p>
                <p className="text-center">Instructor Led Course</p>
              </a>
            </div>
          </div>
        </div>
        <Modal
          className="modal-dialog modal-lg"
          isOpen={this.state.courseModalIsOpen}
          toggle={this.toggleCourseModal}
        >
          <ModalHeader toggle={this.toggleCourseModal}>
            Create Classroom Course
          </ModalHeader>
          <ModalBody>
            <div className="padding-15">
              <ClassroomCourseForm
                mutation={CREATE_CLASSROOM_COURSE}
                classroomCourse={{
                  id: null,
                  name: null,
                  description: null,
                  topicNames: [],
                  thumbnail: null
                }}
                refetchQueries={[{ query: GET_CLASSROOM_TOPICS }]}
                update={(cache, data) => {
                  this.toggleCourseModal();
                  window.location.href = `/classroom_sessions/new?course_id=${data.createClassroomCourse.classroomCourse.id}`;
                }}
              />
            </div>
          </ModalBody>
        </Modal>
        <Modal
          className="modal-dialog modal-lg"
          isOpen={this.state.modalIsOpen}
          toggle={this.toggleModal}
        >
          <ModalHeader toggle={this.toggleModal}>Add Content</ModalHeader>
          <ModalBody>
            <div className="padding-15">
              <Fancy
                mutation={CREATE_CONTENT}
                onComplete={{
                  message: "Your content has been created.",
                  callback: () => {
                    return null;
                  }
                }}
                update={(cache, { createContent }) => {
                  this.toggleModal();
                  window.location.href = `/course_forms/new?content_id=${createContent.content.id}`;
                }}
                variables={{
                  content: {
                    name: null,
                    description: null,
                    file: {
                      key: this.state.file.key,
                      url: this.state.file.url,
                      filename: this.state.file.filename
                    }
                  }
                }}
                render={({ errors, onChange }) => {
                  return (
                    <React.Fragment>
                      <Input onChange={onChange} field="name" errors={errors}>
                        Name
                      </Input>
                      <Textarea
                        onChange={onChange}
                        field="description"
                        errors={errors}
                      >
                        Description
                      </Textarea>

                      <Button primary>Create Content</Button>
                    </React.Fragment>
                  );
                }}
              />
            </div>
          </ModalBody>
        </Modal>
        <SweetAlert
          show={this.state.alertIsOpen}
          title="Upload Processing"
          text="Your SCORM package is being processed. You will be notified when it has completed."
          onConfirm={() => {
            this.toggleAlert();
          }}
        />
      </ApolloProvider>
    );
  }
}
