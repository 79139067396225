import * as React from "react";
import Loading from "../../loading";
import Error from "../../error";
import PackageTopicListItem from "./package_topic_list_item";
import { GetPackageTopicsQuery } from "../../../queries/get_package_topics";
import InfiniteScroll from "react-infinite-scroller";

interface Props {
  id: string;
  type: string;
}

const PackageList = ({ ...props }: Props): JSX.Element => {
  return (
    <GetPackageTopicsQuery variables={{ id: props.id }}>
      {({ loading, error, data, fetchMore }) => {
        if (loading) return <Loading />;
        if (error) return <Error />;

        return (
          <PackageListBody
            {...props}
            data={data.getPackageTopics}
            onLoadMore={() => {
              void fetchMore({
                variables: {
                  offset: data.getPackageTopics.topics.length,
                  id: props.id
                }
              });
            }}
          />
        );
      }}
    </GetPackageTopicsQuery>
  );
};

export default PackageList;

const PackageListBody = ({ onLoadMore, data, ...props }) => (
  <div
    className="email-list-wrapper scrollable"
    style={{ overflowX: "hidden" }}
  >
    <InfiniteScroll
      loadMore={onLoadMore}
      hasMore={data.moreRecords}
      loader={<Loading key={0} />}
      useWindow={false}
    >
      <div className="list-view-group-container">
        <ul className="email-list-item">
          {data.topics.map((topic, index) => {
            return (
              <PackageTopicListItem
                type={props.type}
                key={index}
                item={topic}
                packageId={props.id}
              />
            );
          })}
        </ul>
        {!data.moreRecords && (
          <p className="text-center pt-3">
            You have reached the end of the list.
          </p>
        )}
      </div>
    </InfiniteScroll>
  </div>
);
