import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SEARCH from "../../../queries/search";
import { ApolloConsumer } from "@apollo/client";
import ReactDOM from "react-dom";

function SearchFooter(props) {
  if (!props.showFooter) {
    return null;
  }

  return (
    <div className="search-footer-container">
      <span>
        You are Searching for '<b className="text-dark">{props.searchString}</b>
        '
      </span>
      <div data-attribute={props.hidden ? "hidden" : ""}>
        <a
          href={`/search?q=${encodeURI(props.searchString)}`}
          className="search-link-bind"
        >
          View full results
        </a>
      </div>
    </div>
  );
}

class Search extends React.PureComponent<
  {},
  {
    searchResults: any;
    timer: any;
    searchStatus: string;
    showSearchFooter: boolean;
    inputHidden: boolean;
    searchString: string;
  }
> {
  constructor(props) {
    super(props);

    this.state = {
      searchResults: {},
      searchStatus: "There are no results to display.",
      showSearchFooter: false,
      inputHidden: false,
      searchString: null,
      timer: null
    };
  }

  componentDidUpdate() {
    $(".search-input input").focus();
  }

  resultIcon = (resultKey) => {
    switch (resultKey) {
      case "course":
        return "tv";
      case "classroom-course":
        return "chalkboard-teacher";
      case "group":
        return "users";
      case "track":
        return "train";
      default:
        return "book";
    }
  };

  advanceSearch = (e, client) => {
    var value = e.target.value;
    var self = this;
    clearTimeout(self.state.timer);
    self.setState({
      searchString: value,
      timer: setTimeout(
        function () {
          if (value.length > 0) {
            self.setState({
              searchStatus: "Searching..."
            });

            const result = client.query({
              query: SEARCH,
              update: (cache, { data: { search } }) => {
                cache.writeQuery({
                  query: SEARCH,
                  data: {
                    search: search
                  }
                });
              },
              variables: {
                term: value,
                type: ["course", "topic", "group", "track", "classroom"]
              }
            });

            result.then(({ data }) => {
              self.setState({
                searchString: value,
                searchResults: data.search,
                showSearchFooter: true,
                searchStatus: ""
              });
              if (data.search.length == 0) {
                self.setState({
                  searchString: value,
                  searchResults: {},
                  showSearchFooter: true,
                  searchStatus: "There are no results to display."
                });
              }
            });
          } else {
            self.setState({
              searchString: value,
              searchResults: {},
              showSearchFooter: true,
              searchStatus: ""
            });
          }

          if (value.length < 0) {
            self.setState({
              searchString: value,
              showSearchFooter: false,
              searchStatus: "There are no results to display."
            });
          }
        }.bind(self),
        500
      )
    });
  };

  searchToggle = () => {
    this.setState({ inputHidden: !this.state.inputHidden });
  };

  render() {
    const self = this;

    return (
      <ul className="nav-left">
        <li>
          <button
            type="button"
            className="btn btn-link side-nav-toggle js-side-nav-toggle"
          >
            <i className="fal fa-bars" />
          </button>
        </li>
        <li className={`search-box ${self.state.inputHidden ? "active" : ""}`}>
          <button
            type="button"
            className="btn btn-link search-toggle no-pdd-right"
            onClick={this.searchToggle.bind(this)}
          >
            <FontAwesomeIcon icon={["fal", "search"]} className="search-icon" />
            <span className="pdd-left-10" />
            <FontAwesomeIcon
              icon={["fal", "times"]}
              className="search-icon-close"
            />
            <span className="pdd-left-10" />
          </button>
        </li>
        <li
          className={`search-input ${self.state.inputHidden ? "active" : ""}`}
        >
          <ApolloConsumer>
            {(client) => (
              <input
                className="form-control"
                type="text"
                placeholder="Search..."
                onKeyUp={(event) => {
                  self.advanceSearch(event, client);
                }}
              />
            )}
          </ApolloConsumer>
          {self.state.searchResults && (
            <div
              className="advanced-search active border border-top-0 rounded-bottom"
              style={{ overflowY: "auto" }}
            >
              <div className="search-wrapper">
                {self.state.searchResults.length > 0 ? (
                  <div>
                    <div>
                      <div className="pdd-vertical-10">
                        <ul className="list-unstyled list-info">
                          {self.state.searchResults.map(function (item, index) {
                            return (
                              <li key={index}>
                                <a
                                  data-turbolinks="false"
                                  href={item.url}
                                  onClick={() => {
                                    self.searchToggle();
                                  }}
                                >
                                  <div className="thumb-icon">
                                    <FontAwesomeIcon
                                      icon={["fal", self.resultIcon(item.type)]}
                                    />
                                  </div>
                                  <div className="info">
                                    <span className="title">{item.name}</span>
                                    <span className="sub-title text-capitalize">
                                      {item.type.replace("-", " ")}
                                    </span>
                                  </div>
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="mrg-horizon-20 border top" />
                    </div>
                  </div>
                ) : (
                  <p className="m-3">{self.state.searchStatus}</p>
                )}
              </div>
              <div className="search-footer">
                <SearchFooter
                  showFooter={self.state.showSearchFooter}
                  searchString={self.state.searchString}
                  hidden={self.state.searchResults.length > 0 ? false : true}
                />
              </div>
            </div>
          )}
        </li>
      </ul>
    );
  }
}

export default Search;
