import {
  gql,
  MutationTuple,
  Reference,
  StoreObject,
  useMutation
} from "@apollo/client";
import { storeValueIsStoreObject } from "@apollo/client/cache/inmemory/helpers";
import { getCourseAssignments_getCourseAssignments } from "./codegen/getCourseAssignments";

import {
  removeAssignment,
  removeAssignmentVariables
} from "./codegen/removeAssignment";

const REMOVE_ASSIGNMENT = gql`
  mutation removeAssignment($courseId: ID!, $userId: ID!) {
    removeAssignment(input: { courseId: $courseId, userId: $userId }) {
      userId
      courseId
    }
  }
`;

export default REMOVE_ASSIGNMENT;

export const useRemoveAssignment = (
  courseAssignments: getCourseAssignments_getCourseAssignments
): MutationTuple<removeAssignment, removeAssignmentVariables> =>
  useMutation<removeAssignment, removeAssignmentVariables>(REMOVE_ASSIGNMENT, {
    update(cache, { data: { removeAssignment: assignment } }) {
      // There's a weird incompatibility between StoreObject and
      // courseAssignments, as StoreObject is defined to to have arbitrary
      // string keys, and courseAssignments has a fully defined interface
      // without that flexibility. So we'll use storeValueIsStoreObject to
      // convince the TypeScript compiler that it's okay to call cache.identify:
      if (storeValueIsStoreObject(courseAssignments)) {
        cache.modify({
          id: cache.identify(courseAssignments),
          fields: {
            courses(existingCourseRefs: Reference[], { readField }) {
              return existingCourseRefs.filter(
                (courseRef) =>
                  assignment.courseId !== readField("id", courseRef)
              );
            }
          }
        });
      }
    }
  });
