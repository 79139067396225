import * as React from "react";
import Loading from "../loading";
import Error from "../error";
import { Widgets } from "../widgets";
import { GetWidgetsQuery } from "../../queries/get_widgets";

import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  ApolloProvider,
  ApolloConsumer
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Widget, isWidgetUrl } from "../widgets/widget";
import { cache } from "../../cache";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement(): { getAttribute: (attribute: string) => string } {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: () => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

export default class AccountDashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ApolloProvider client={client}>
        <React.Fragment>
          <h1>Acount Dashboard</h1>
          <p>
            This page represents the default set of widgets that will display
            for users.
          </p>
        </React.Fragment>
        <GetWidgetsQuery variables={{ userId: null }}>
          {({ loading, error, data }) => {
            if (loading) return <Loading />;
            if (error) return <Error />;

            const widgets: Widget[] = data.widgets
              .map((widget): Widget | undefined => {
                if (isWidgetUrl(widget.url)) {
                  return {
                    __typename: "Widget",
                    url: widget.url,
                    id: widget.id,
                    name: widget.name,
                    sizeOptions: widget.sizeOptions,
                    size: widget.size
                  };
                }
              })
              .filter(
                (widget: Widget | undefined): widget is Widget =>
                  widget !== undefined
              );
            return (
              <ApolloConsumer>
                {(client) => (
                  <Widgets widgets={widgets} client={client} userId={null} />
                )}
              </ApolloConsumer>
            );
          }}
        </GetWidgetsQuery>
      </ApolloProvider>
    );
  }
}
