import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getTracksUsers,
  getTracksUsersVariables,
  getTracksUsers_getTracksUsers
} from "./codegen/getTracksUsers";

export type TrackUser = getTracksUsers_getTracksUsers;

export const GET_TRACKS_USERS = gql`
  query getTracksUsers($id: ID!, $order: String) {
    getTracksUsers(trackId: $id, order: $order) {
      id
      progress
      user {
        id
        fullName
        avatar
        username
      }
    }
  }
`;

export const GetTracksUsersQuery = (
  props: Omit<
    QueryComponentOptions<getTracksUsers, getTracksUsersVariables>,
    "query"
  >
): JSX.Element => (
  <Query<getTracksUsers, getTracksUsersVariables>
    query={GET_TRACKS_USERS}
    {...props}
  />
);
