import * as React from "react";
import * as ReactDOM from "react-dom";
import * as moment from "moment";
import { Button } from "../buttons";
import { Textarea } from "../forms/input";
import { Mutation } from "@apollo/client/react/components";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Fancy from "../forms/fancy";
import Loading from "../loading";
import Error from "../error";
import CREATE_COMMENT from "../../queries/create_comment";
import UPDATE_COMMENT from "../../queries/update_comment";
import REMOVE_COMMENT from "../../queries/remove_comment";
import { GetCommentsQuery } from "../../queries/get_comments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ApolloClient, HttpLink, ApolloLink, ApolloProvider, ApolloConsumer } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { cache } from "../../cache";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement() {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: (string: string) => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

type Props = {
  comments: Array<CommentType>;
  courseId: number;
};

type State = {
  comments: Array<CommentType>;
  courseId: number;
  textbox: string;
};

type CommentProps = {
  comment: CommentType;
  courseId: number;
  removeComment: any;
  addComment: any;
  updateComment: any;
};

type CommentState = {
  replyModalIsOpen: boolean;
  editModalIsOpen: boolean;
};

type CommentType = {
  id: string;
  name: string;
  url: string;
  content: string;
  avatarUrl: string;
  createdAt: string;
  updatedAt: string;
  ancestry: Array<string>;
};

function Comments(props) {
  return (
    <ApolloProvider client={client}>
      <GetCommentsQuery variables={{ courseId: props.courseId }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />;
          if (error) return <Error />;

          return <CommentBody courseId={props.courseId} comments={data.getComments} />;
        }}
      </GetCommentsQuery>
    </ApolloProvider>
  );
}

class CommentBody extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      comments: props.comments,
      courseId: props.courseId,
      textbox: ""
    };
  }

  removeComment = (client, commentId) => {
    client.mutate({
      mutation: REMOVE_COMMENT,
      variables: { id: commentId },
      update: () => {
        this.setState((state) => {
          const comments = state.comments.filter((comment) => {
            return comment.id !== commentId;
          });
          return { comments: comments };
        });
        const $count = $(".js-comments__count");
        $count.text(parseInt($count.text(), 10) - 1);
      }
    });
  };

  addComment = (comment) => {
    this.setState((state) => {
      return { comments: [...state.comments, comment], textbox: "" };
    });
    const $count = $(".js-comments__count");
    $count.text(parseInt($count.text(), 10) + 1);
  };

  updateComment = (updatedComment) => {
    this.setState((state) => {
      const comments = state.comments.map((comment) => {
        if (comment.id == updatedComment.id) {
          return updatedComment;
        } else {
          return comment;
        }
      });
      return { comments: comments };
    });
  };

  onChange(e, fancyOnChange) {
    this.setState({ ...this.state, textbox: e.target.value });
    return fancyOnChange(e);
  }

  render() {
    let comment_length = this.state.textbox.length;
    let that = this;
    return (
      <React.Fragment>
        <ul className="list-unstyled list-info">
          {this.state.comments.map((comment, index) => {
            return (
              <Comment
                key={index}
                comment={comment}
                courseId={this.state.courseId}
                removeComment={this.removeComment}
                addComment={this.addComment}
                updateComment={this.updateComment}
              />
            );
          })}
        </ul>
        <Fancy
          mutation={CREATE_COMMENT}
          onComplete={{
            message: "Your comment has been created.",
            callback: () => {
              this.setState({ ...this.state, textbox: "" });
            }
          }}
          variables={{
            comment: {
              content: null,
              parentId: null,
              commentableId: this.state.courseId,
              commentableType: "Course"
            }
          }}
          update={(cache, { createComment }) => {
            this.addComment(createComment.comment);
          }}
          render={({ errors, onChange }) => {
            return (
              <React.Fragment>
                <Textarea
                  onChange={(e) => this.onChange(e, onChange)}
                  field="content"
                  errors={errors}
                  placeholder="Share your thoughts"
                  value={this.state.textbox}
                />
                <Button success disabled={comment_length < 1} className="float-right">
                  Create Comment
                </Button>
              </React.Fragment>
            );
          }}
        />
      </React.Fragment>
    );
  }
}

class Comment extends React.Component<CommentProps, CommentState> {
  constructor(props: CommentProps) {
    super(props);
    this.state = {
      replyModalIsOpen: false,
      editModalIsOpen: false
    };
  }

  toggleReplyModal = () => {
    this.setState((state) => {
      return { replyModalIsOpen: !state.replyModalIsOpen };
    });
  };

  toggleEditModal = () => {
    this.setState((state) => {
      return { editModalIsOpen: !state.editModalIsOpen };
    });
  };

  render() {
    const comment = this.props.comment;
    const createdAt = moment(comment.createdAt);
    const updatedAt = moment(comment.updatedAt);

    let style;
    if (comment.ancestry.length > 0) {
      style = { marginLeft: `${20 * comment.ancestry.length}px` };
    }

    let timeString = "";
    if (createdAt.isSame(updatedAt, "minute")) {
      timeString = createdAt.fromNow();
    } else {
      timeString = `${createdAt.fromNow()} (Edited ${updatedAt.fromNow()})`;
    }

    return (
      <li className="comment-item" style={style} key={comment.id}>
        <img alt={`${comment.name}'s avatar`} className="thumb-img img-circle" src={comment.avatarUrl} />
        <div className="comment-info">
          <a className="title no-pdd-vertical text-semibold inline-block" href={comment.url}>
            {comment.name}
          </a>
          <span className="sub-title inline-block float-right">
            <FontAwesomeIcon icon={["fal", "clock"]} /> <span>{timeString}</span>
          </span>
          <p className="width-80">
            {comment.content}
            <br />
            <ApolloConsumer>
              {(client) => (
                <React.Fragment>
                  <a
                    href="#"
                    className="btn btn-sm btn-info"
                    onClick={(e) => {
                      e.preventDefault();
                      this.toggleReplyModal();
                    }}
                  >
                    <FontAwesomeIcon icon={["fal", "reply"]} /> Reply
                  </a>
                  <a
                    href="#"
                    className="btn btn-sm btn-info"
                    onClick={(e) => {
                      e.preventDefault();
                      this.toggleEditModal();
                    }}
                  >
                    <FontAwesomeIcon icon={["fal", "pencil"]} /> Edit
                  </a>
                  <a
                    href="#"
                    className="btn btn-sm btn-danger"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.removeComment(client, comment.id);
                    }}
                  >
                    <FontAwesomeIcon icon={["fal", "trash"]} /> Remove
                  </a>
                  <Modal
                    className="modal-dialog modal-lg"
                    isOpen={this.state.replyModalIsOpen}
                    toggle={this.toggleReplyModal}
                  >
                    <ModalHeader toggle={this.toggleReplyModal}>
                      Reply to {comment.name}
                      's comment:
                    </ModalHeader>
                    <ModalBody>
                      <div className="padding-15">
                        <h3 className="mb-4">
                          <em>{comment.content}</em>
                        </h3>
                        <Fancy
                          mutation={CREATE_COMMENT}
                          onComplete={{
                            message: "Your comment has been created.",
                            callback: () => {
                              this.toggleReplyModal();
                            }
                          }}
                          update={(cache, { createComment }) => {
                            this.props.addComment(createComment.comment);
                          }}
                          variables={{
                            comment: {
                              content: null,
                              parentId: comment.id,
                              commentableId: this.props.courseId,
                              commentableType: "Course"
                            }
                          }}
                          render={({ errors, onChange }) => {
                            return (
                              <React.Fragment>
                                <Textarea
                                  onChange={onChange}
                                  field="content"
                                  errors={errors}
                                  placeholder="Share your thoughts"
                                >
                                  Your reply
                                </Textarea>
                                <Button success className="float-right">
                                  Create Comment
                                </Button>
                              </React.Fragment>
                            );
                          }}
                        />
                      </div>
                    </ModalBody>
                  </Modal>
                  <Modal
                    className="modal-dialog modal-lg"
                    isOpen={this.state.editModalIsOpen}
                    toggle={this.toggleEditModal}
                  >
                    <ModalHeader toggle={this.toggleEditModal}>Edit your comment:</ModalHeader>
                    <ModalBody>
                      <div className="padding-15">
                        <Fancy
                          mutation={UPDATE_COMMENT}
                          onComplete={{
                            message: "Your comment has been edited.",
                            callback: () => {
                              this.toggleEditModal();
                            }
                          }}
                          update={(cache, { updateComment }) => {
                            this.props.updateComment(updateComment.comment);
                          }}
                          variables={{
                            comment: {
                              id: comment.id,
                              content: comment.content
                            }
                          }}
                          render={({ errors, onChange }) => {
                            return (
                              <React.Fragment>
                                <Textarea
                                  onChange={onChange}
                                  field="content"
                                  errors={errors}
                                  defaultValue={comment.content}
                                />
                                <Button success className="float-right">
                                  Update Comment
                                </Button>
                              </React.Fragment>
                            );
                          }}
                        />
                      </div>
                    </ModalBody>
                  </Modal>
                </React.Fragment>
              )}
            </ApolloConsumer>
          </p>
        </div>
      </li>
    );
  }
}

export default Comments;
