import { gql } from "@apollo/client";

const CREATE_COMMENT = gql`
  mutation createComment($comment: CommentAttributes!) {
    createComment(input: { comment: $comment }) {
      comment {
        id
        name
        url
        content
        avatarUrl
        createdAt
        updatedAt
        ancestry
      }
    }
  }
`;

export default CREATE_COMMENT;
