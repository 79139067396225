import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getTopCoursesChart } from "./codegen/getTopCoursesChart";

export const CHARTS_TOP_COURSES = gql`
  query getTopCoursesChart {
    charts_top_courses {
      id
      label
      value
      amount
    }
  }
`;

export const GetChartsTopCoursesQuery = (
  props: Omit<QueryComponentOptions<getTopCoursesChart>, "query">
): JSX.Element => (
  <Query<getTopCoursesChart> query={CHARTS_TOP_COURSES} {...props} />
);
