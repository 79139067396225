import { SortableContainer } from "react-sortable-hoc";
import { Widget } from "./widget";
import * as React from "react";

interface Props {
  widgets: Widget[];
  userId: string;
}

export const WidgetList = SortableContainer(({ widgets, userId }: Props) => {
  return (
    <div id="widgets" className="row">
      {widgets.map((widget, index) => {
        return (
          <Widget
            key={`item-${index}`}
            index={index}
            widget={widget}
            userId={userId}
          />
        );
      })}
    </div>
  );
});
