import { gql } from "@apollo/client";

const PUBLISH_TRACK = gql`
  mutation publishTrack($id: ID!) {
    publishTrack(input: { id: $id }) {
      id
    }
  }
`;

export default PUBLISH_TRACK;
