import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getBobIntegration } from "./codegen/getBobIntegration";

const GET_BOB_INTEGRATION = gql`
  query getBobIntegration {
    getBobIntegration {
      key
    }
  }
`;

export const GetBobIntegrationQuery = (
  props: Omit<QueryComponentOptions<getBobIntegration>, "query">
): JSX.Element => <Query<getBobIntegration> query={GET_BOB_INTEGRATION} {...props} />;
