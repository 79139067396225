import * as React from "react";
import ActivityNotification from "./activity_notification";
import Icon from "../icon";

type Props = {
  activities: Activity[];
  newActivities: Activity[];
};

type Activity = {
  id: string;
  message: string;
  ending: string;
  createdAt: string;
};

export default class Activities extends React.Component<Props> {
  list = () => {
    const { activities } = this.props;

    const activityNotifications = activities.map(this.toNotification);

    return <React.Fragment>{activityNotifications}</React.Fragment>;
  };

  notifications = () => this.props.newActivities.map(this.toNotification);

  toNotification = (notification, index) => {
    return <ActivityNotification key={index} activity={notification} />;
  };

  render() {
    return <View NewNotifications={this.notifications} List={this.list} />;
  }
}

const View = ({ NewNotifications, List }) => (
  <div className="padding-20">
    <ul className="list-unstyled list-info" style={{ overflowX: "hidden" }}>
      <NewNotifications />
      <List />
    </ul>
  </div>
);
