import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApolloConsumer } from "@apollo/client";
import { GET_TOPIC } from "../../../queries/get_topic";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import SEARCH from "../../../queries/search";
import CREATE_COURSE_GROUPS from "../../../queries/create_course_groups";
import Requirements from "./requirements";
import * as _ from "lodash";
import Wizard from "../../course_form/wizard";

class AddCourses extends React.Component<
  {
    isOpen: boolean;
    toggle: () => void;
    addCourses: (any, number) => void;
    client: any;
    groupId: string;
    courseIds: string[];
  },
  {
    courses: any[];
    requirement: any;
    addToEnd: boolean;
  }
> {
  constructor(props) {
    super(props);

    this.state = {
      courses: [],
      requirement: false,
      addToEnd: false
    };
  }

  getTopicCourses = async ({ id }) => {
    const { data } = await this.props.client.query({
      query: GET_TOPIC,
      variables: { id }
    });

    const courses = data.getTopic.courses
      .map(({ id, name }) => {
        return { id, name, type: "course" };
      })
      .filter(({ id }) => !this.props.courseIds.includes(id));

    this.setState((state) => {
      return { courses: state.courses.concat(courses) };
    });
  };

  selectItemFromSearch = (item) => {
    if (item.type === "course") {
      this.setState((state) => {
        return { courses: state.courses.concat([item]) };
      });
    } else {
      this.getTopicCourses(item);
    }
  };

  removeItem = (id) => {
    this.setState((previousState) => {
      return {
        courses: previousState.courses.filter((course) => {
          return course.id !== id;
        })
      };
    });
  };

  saveCourses = () => {
    this.props.client.mutate({
      mutation: CREATE_COURSE_GROUPS,
      variables: {
        courseGroups: Object.assign(
          {
            courseIds: this.state.courses.map((course) => {
              return course.id;
            }),
            groupId: this.props.groupId
          },
          this.translateRequirement()
        ),
        addToEnd: this.state.addToEnd
      },
      update: (cache, { data }) => {
        this.props.addCourses(
          data.createCourseGroups.courses,
          this.state.addToEnd
        );
        this.props.toggle();
        this.setState({
          courses: [],
          requirement: false,
          addToEnd: false
        });
      }
    });
  };

  translateRequirement = () => {
    let required = false;
    let requiredDate,
      requiredInterval,
      requiredBeginDays,
      requiredStartDate,
      requiredEndDate = null;
    const { requirement } = this.state;

    if (typeof requirement === "boolean") {
      required = requirement;
    } else if (typeof requirement === "string") {
      required = true;
      requiredDate = requirement;
    } else if (
      _.isObject(requirement) &&
      _.has(requirement, "every") &&
      _.has(requirement, "after")
    ) {
      if (requirement.every[0]) {
        requiredInterval = requirement.every[1];
      }
      if (requirement.after[0]) {
        requiredBeginDays = requirement.after[1];
      }
    } else if (
      _.isObject(requirement) &&
      _.has(requirement, "start") &&
      _.has(requirement, "end")
    ) {
      requiredStartDate = requirement.start;
      requiredEndDate = requirement.end;
    }

    return {
      required: required,
      requiredDate: requiredDate,
      requiredInterval: requiredInterval,
      requiredBeginDays: requiredBeginDays,
      requiredStartDate: requiredStartDate,
      requiredEndDate: requiredEndDate
    };
  };

  updateRequirement = (requirement) => {
    this.setState(requirement);
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          className="modal-dialog modal-lg"
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
        >
          <ModalHeader toggle={this.props.toggle}>Add Courses</ModalHeader>
          <ModalBody>
            <div className="padding-15">
              <Wizard
                headings={["Courses", "Requirements", "Options"]}
                sections={[
                  <React.Fragment>
                    <ApolloConsumer>
                      {(client) => (
                        <Search
                          onItemClick={this.selectItemFromSearch}
                          client={client}
                          courseIds={this.props.courseIds}
                        />
                      )}
                    </ApolloConsumer>

                    <CourseList
                      courses={this.state.courses}
                      removeItem={this.removeItem}
                    />
                  </React.Fragment>,
                  <React.Fragment>
                    <Requirements
                      updateRequirement={this.updateRequirement}
                      requirement={this.state.requirement}
                    />
                  </React.Fragment>,
                  <React.Fragment>
                    <div className="checkbox checkbox-inline">
                      <input
                        id="addToEnd"
                        defaultChecked={this.state.addToEnd}
                        name="addToEnd"
                        type="checkbox"
                        onChange={(value) => {
                          this.setState({ addToEnd: value.target.checked });
                        }}
                      />
                      <label htmlFor="addToEnd">
                        Add courses to end of list
                      </label>
                    </div>
                  </React.Fragment>
                ]}
                finishButton={
                  <button
                    className="btn btn-primary btn-rounded"
                    style={{ float: "right" }}
                    onClick={this.saveCourses}
                  >
                    Save Courses
                  </button>
                }
              />
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

function CourseList(props) {
  const courseHtml = props.courses.map((course, index) => {
    return (
      <li key={index} className="py-1">
        {course.name}{" "}
        <button
          className="btn btn-sm btn-danger"
          onClick={() => {
            props.removeItem(course.id);
          }}
        >
          X
        </button>
      </li>
    );
  });
  return <ol>{courseHtml}</ol>;
}

class Search extends React.PureComponent<
  { onItemClick: (item: any) => void; client: any; courseIds: string[] },
  { timer: number | null; term: string; results: any[]; showResults: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      term: "",
      results: [],
      showResults: false
    };
  }

  fetchResults = (event) => {
    this.setState({ term: event.target.value });
    this.delay(this.sendQuery);
  };

  sendQuery = () => {
    const { term } = this.state;

    if (term.length > 0) {
      const result = this.props.client.query({
        query: SEARCH,
        // I'm not sure updating the cache is meaningful here...
        update: (cache, { data: { search } }) => {
          cache.writeQuery({
            query: SEARCH,
            data: {
              search: search
            }
          });
        },
        variables: { term: term, type: ["course", "topic"] }
      });
      result.then(({ data }) => {
        const results = data.search.filter(
          ({ id }) => !this.props.courseIds.includes(id)
        );
        this.setState({ results: results });
      });
    } else {
      this.setState({ results: [] });
    }
  };

  delay = (callback) => {
    clearTimeout(this.state.timer);

    this.setState({
      timer: window.setTimeout(callback, 500)
    });
  };

  render() {
    const { results, term } = this.state;
    return (
      <div>
        <input
          className="form-control"
          type="text"
          placeholder="Search..."
          onChange={this.fetchResults}
          onFocus={() => this.setState({ showResults: true })}
          value={this.state.term}
        />
        {this.state.showResults && (
          <Results
            term={term}
            items={results}
            onItemClick={(item) => {
              this.props.onItemClick(item);
              this.setState({ results: [], term: "", showResults: false });
            }}
          />
        )}
      </div>
    );
  }
}

class Results extends React.PureComponent<{
  items: { type: string; name: string }[];
  term: string;
  onItemClick: (item: any) => void;
}> {
  constructor(props) {
    super(props);
  }

  resultIcon = (resultKey) => {
    switch (resultKey) {
      case "course":
        return "tv";
      case "group":
        return "users";
      case "track":
        return "train";
      default:
        return "book";
    }
  };

  render() {
    return (
      <div
        className="advanced-search active border border-top-0 rounded-bottom"
        style={{ overflowY: "auto" }}
      >
        <div className="search-wrapper">
          {this.props.items.length > 0 ? (
            <div>
              <div>
                <div className="pdd-vertical-10">
                  <ul className="list-unstyled list-info">
                    {this.props.items.map((item, index) => {
                      return (
                        <li key={index}>
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={() => this.props.onItemClick(item)}
                          >
                            <div className="thumb-icon">
                              <FontAwesomeIcon
                                icon={["fal", this.resultIcon(item.type)]}
                              />
                            </div>
                            <div className="info">
                              <span className="title">{item.name}</span>
                              <span className="sub-title text-capitalize">
                                {item.type}
                              </span>
                            </div>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="mrg-horizon-20 border top" />
              </div>
            </div>
          ) : (
            <p className="m-3">There are no results to display.</p>
          )}
        </div>
        <div
          className="search-footer"
          style={{
            borderTop: "1px solid #e6ecf5",
            textAlign: "center",
            padding: "15px"
          }}
        >
          <span>
            You are Searching for '
            <b className="text-dark">
              <span className="serach-text-bind">{this.props.term}</span>
            </b>
            '
          </span>
        </div>
      </div>
    );
  }
}

export default AddCourses;
