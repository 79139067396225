import { gql } from "@apollo/client";

const REMOVE_COURSE_GROUP = gql`
  mutation removeCourseGroup($courseId: ID!, $groupId: ID!) {
    removeCourseGroup(input: { courseId: $courseId, groupId: $groupId }) {
      id
    }
  }
`;

export default REMOVE_COURSE_GROUP;
