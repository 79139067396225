import { gql } from "@apollo/client";

const CREATE_MEMBERSHIP = gql`
  mutation createMembership($userId: ID!, $id: ID!) {
    createMembership(input: { userId: $userId, groupId: $id }) {
      membership {
        id
        progress
        user {
          id
          fullName
          avatar
          username
        }
      }
    }
  }
`;

export default CREATE_MEMBERSHIP;
