import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import {
  getCertificates,
  getCertificatesVariables
} from "./codegen/getCertificates";

const GET_CERTIFICATES = gql`
  query getCertificates($userId: ID!) {
    getCertificates(userId: $userId) {
      id
      name
      score
      createdAt
      url
    }
  }
`;

export const GetCertificatesQuery = (
  props: Omit<
    QueryComponentOptions<getCertificates, getCertificatesVariables>,
    "query"
  >
): JSX.Element => (
  <Query<getCertificates, getCertificatesVariables>
    query={GET_CERTIFICATES}
    {...props}
  />
);
