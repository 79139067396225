import { gql } from "@apollo/client";

const CREATE_EXAM_ACTIVITY = gql`
  mutation createExamActivity($activity: ExamActivityAttributes!) {
    createExamActivity(input: { activity: $activity }) {
      id
    }
  }
`;

export default CREATE_EXAM_ACTIVITY;
