import { gql } from "@apollo/client";

const REMOVE_MEMBERSHIP = gql`
  mutation removeMembership($id: [ID!]!) {
    removeMembership(input: { id: $id }) {
      id
    }
  }
`;

export default REMOVE_MEMBERSHIP;
