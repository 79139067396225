import { gql } from "@apollo/client";

const REMOVE_TRACKS_USER = gql`
  mutation removeTracksUser($id: ID!) {
    removeTracksUser(input: { id: $id }) {
      id
    }
  }
`;

export default REMOVE_TRACKS_USER;
