import { gql } from "@apollo/client";
import { getTrack, getTrackVariables } from "./codegen/getTrack";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import * as React from "react";

export const GET_TRACK = gql`
  query getTrack($id: ID!, $limit: Int, $offset: Int) {
    getTrack(id: $id, limit: $limit, offset: $offset) {
      id
      name
      moreRecords
      description
      isCurrentUserEnrolled
      enrollmentId
      belongsToCampus
      published
      courses {
        id
        name
        thumbnail
        duration
        fullDescription
        shortDescription
        completed
        required
        statusIcon {
          id
          icon
          style
          tooltip
        }
      }
      currentUser {
        id
        canManageReports
        canManageTracks
        hasChildren
      }
    }
  }
`;

export const GetTrackQuery = (
  props: Omit<QueryComponentOptions<getTrack, getTrackVariables>, "query">
): JSX.Element => (
  <Query<getTrack, getTrackVariables> query={GET_TRACK} {...props} />
);
