import { gql } from "@apollo/client";

const CREATE_TOPIC = gql`
  mutation createTopic($topic: TopicAttributes!) {
    createTopic(input: { topic: $topic }) {
      topic {
        id
        name
        description
        slug
      }
    }
  }
`;

export default CREATE_TOPIC;
