import * as React from "react";
import { Input, Textarea } from "../forms/input";
import { Button } from "../buttons";
import { blankLocation } from ".";
export const Fields = ({
  errors,
  onChange,
  location = blankLocation,
  submitLabel = "Create Location",
  cancel
}) => (
  <React.Fragment>
    <Input
      onChange={onChange}
      field="name"
      errors={errors}
      defaultValue={location.name}
      required={true}
    >
      Name
    </Input>
    <Textarea
      onChange={onChange}
      field="address"
      errors={errors}
      defaultValue={location.address}
    >
      Address
    </Textarea>
    <Textarea
      onChange={onChange}
      field="equipmentAvailable"
      errors={errors}
      defaultValue={location.equipmentAvailable}
    >
      Equipment Available
    </Textarea>
    <Input
      onChange={onChange}
      field="capacity"
      errors={errors}
      type="number"
      defaultValue={location.capacity}
    >
      Capacity
    </Input>
    <div className="float-right">
      <button className="btn btn-secondary" type="button" onClick={cancel}>
        Cancel
      </button>
      <button className="btn btn-primary">{submitLabel}</button>
    </div>
  </React.Fragment>
);
