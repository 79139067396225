import { gql } from "@apollo/client";

const SEARCH = gql`
  query search($term: String!, $type: [String]!) {
    search(term: $term, type: $type) {
      id
      type
      name
      url
      fileType
      thumbnail
    }
  }
`;

export default SEARCH;
