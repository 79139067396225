import * as React from "react";
import { gql } from "@apollo/client";
import { Query, QueryComponentOptions } from "@apollo/client/react/components";
import { getSuggestedCourses } from "./codegen/getSuggestedCourses";

export const CATALOG_COURSES_SUGGESTED = gql`
  query getSuggestedCourses {
    catalog_courses_suggested {
      id
      name
      shortDescription
      thumbnail
      duration
      statusIcon {
        id
        icon
        style
        tooltip
      }
    }
  }
`;

export const GetCatalogCoursesSuggestedQuery = (
  props: Omit<QueryComponentOptions<getSuggestedCourses>, "query">
): JSX.Element => (
  <Query<getSuggestedCourses> query={CATALOG_COURSES_SUGGESTED} {...props} />
);
