import { gql } from "@apollo/client";

const REMOVE_COMMENT = gql`
  mutation removeComment($id: ID!) {
    removeComment(input: { id: $id }) {
      id
    }
  }
`;

export default REMOVE_COMMENT;
