import { gql } from "@apollo/client";

const REGENERATE_GROUP = gql`
  mutation regenerateGroup($groupId: ID!) {
    regenerateGroup(input: { groupId: $groupId }) {
      id
    }
  }
`;

export default REGENERATE_GROUP;
