import * as React from "react";
import { Mutation } from "@apollo/client/react/components";

let throttle = false;

interface Props {
  MutationComponent?: (props: any) => JSX.Element;
  variables: Record<string, any>;
  onComplete: {
    message: string;
    callback: (
      data: any,
      setDisableFormSubmit: (disableFormSubmit: boolean) => void
    ) => void;
  };
  update?: (cache: any, object: Record<string, any>) => void;
  refetchQueries?: {
    query: any;
    variables?: {
      id: any;
    };
  }[];
  render: ({
    errors,
    onChange,
    mutate,
    disableFormSubmit,
    state
  }: {
    errors: any;
    onChange: any;
    mutate: any;
    disableFormSubmit: boolean;
    state: Record<string, any>;
  }) => JSX.Element;
  mutation: any;
  submitOnUpdate?: boolean;
}

type State = { record: Record<string, any>; disableFormSubmit: boolean };

const FancyContainer = ({
  MutationComponent = Mutation,
  ...props
}: Props): JSX.Element => (
  <Fancy MutationComponent={MutationComponent} {...props} />
);

export default FancyContainer;

class Fancy extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      record: Object.assign({}, props.variables),
      disableFormSubmit: false
    };
  }

  setDisableFormSubmit = (disableFormSubmit) =>
    this.setState({ disableFormSubmit });

  onSubmit = (mutate) => {
    return (event) => {
      event.preventDefault();
      this.setState({ disableFormSubmit: true });
      mutate({
        variables: this.state.record
      });
    };
  };

  buildOnChange = (mutate) => {
    return (event) => {
      const { target } = event;
      if (this.props.submitOnUpdate) {
        this.setState(
          ({ record }) => ({
            record: Object.assign(
              {},
              record,
              this.changeVariable(record, target)
            )
          }),
          () => {
            // TODO: Throttle this
            if (!throttle) {
              throttle = true;
              mutate({ variables: this.state.record });
            } else {
              window.setTimeout(() => {
                throttle = false;
                // Make sure we're consistent on the server after unthrottling:
                mutate({ variables: this.state.record });
              }, 3000);
            }
          }
        );
      } else {
        this.setState(({ record }) => ({
          record: Object.assign({}, record, this.changeVariable(record, target))
        }));
      }
    };
  };

  changeVariable = (state, target) => {
    const variable = Object.keys(this.props.variables)[0];
    if (target.type === "checkbox" || target.type === "radio") {
      return {
        [variable]: Object.assign(state[variable], {
          [target.name]: target.checked
        })
      };
    } else if (target.type === "number") {
      return {
        [variable]: Object.assign(state[variable], {
          [target.name]: target.value.match(/\./)
            ? parseFloat(target.value)
            : parseInt(target.value, 10)
        })
      };
    } else {
      return {
        [variable]: Object.assign(state[variable], {
          [target.name]: target.value
        })
      };
    }
  };

  render() {
    const { MutationComponent, mutation } = this.props;

    return (
      <MutationComponent
        mutation={mutation}
        onCompleted={(data) => {
          const { callback, message } = this.props.onComplete;
          if (message.length > 0) {
            const alert = document.getElementById("react-alert");
            alert.innerText = message;
            alert.classList.remove("d-none");
          }
          callback(data, this.setDisableFormSubmit);
        }}
        update={(cache, { data }) => {
          if (this.props.hasOwnProperty("update")) {
            this.props.update(cache, data);
          }
        }}
        refetchQueries={this.props.refetchQueries}
        errorPolicy="all"
      >
        {(mutate, { error }) => {
          let errors = null;
          if (error) {
            errors = error.graphQLErrors;
          }

          return (
            <form onSubmit={this.onSubmit(mutate)}>
              {errors && errors.length > 0 && (
                <div className="alert alert-danger">
                  {errors[errors.length - 1].message}
                  <ul>
                    {errors.slice(0, -1).map((error, index) => {
                      return <li key={index}>{error.message}</li>;
                    })}
                  </ul>
                </div>
              )}
              {this.props.render({
                errors: errors,
                onChange: this.buildOnChange(mutate),
                mutate: mutate,
                disableFormSubmit: this.state.disableFormSubmit,
                state: this.state.record
              })}
            </form>
          );
        }}
      </MutationComponent>
    );
  }
}
