import React from "react";
import ReactDOM from "react-dom";
import { UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const looks = ["primary", "success", "info", "warning", "danger"];

const sizes = ["sm"];

function StatusCorner(props) {
  const look = looks.includes(props.look) ? props.look : "primary";
  const size = sizes.includes(props.size) ? `--${props.size}` : "";

  return (
    <div className={`courselist-corner${size} courselist-corner--${look}`}>
      <div id={`cornertooltip-${props.key_id}`}>
        <div className={`courselist-corner-text${size}`}>
          <FontAwesomeIcon icon={["fal", props.icon]} />
        </div>
      </div>
      <UncontrolledTooltip
        placement="top"
        target={`cornertooltip-${props.key_id}`}
      >
        {props.tooltip}
      </UncontrolledTooltip>
    </div>
  );
}
export default StatusCorner;
